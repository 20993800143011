<template>
  <div>
    <b-modal
      :id="planSpecId"
      no-close-on-backdrop
      ok-title="確認"
      centered
      ok-only
      @ok="handleOk"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ selectedIndex !== null ? '編輯' : '新增' }}規格
        </h4>
      </template>

      <div>
        <b-form-group
          label="元件"
          label-for="plan-key"
        >
          <v-select
            v-model="ruleData.key"
            :options="deviceTypeOptions"
            :reduce="option => option.type"
            :clearable="false"
            class="edit-col-select"
            label="name"
            placeholder="請選擇元件"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div>
                <div class="font-weight-bold d-block text-nowrap mb-0">
                  {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                </div>
              </div>
            </template>

            <template #selected-option="option">
              <div>
                <div class="font-weight-bold d-block text-nowrap mb-0">
                  {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                </div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group
          label="數值"
          label-for="plan-value"
        >
          <b-form-input
            id="plan-value"
            v-model="ruleData.value"
            name="plan-value"
            trim
            placeholder="數值"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  VBTooltip, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useAlert } from '@/libs/mixins/index'
import { usePlanSetting } from '../../usePlan'

export default {
  components: {
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    planSpecId: {
      type: String,
      default: 'plan-spec-modal',
    },
    planArray: {
      type: Array,
      default: () => [],
    },
    deviceTypeOptions: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  methods: {
    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const requiredFields = {
        key: '元件',
        value: '元件數值',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.ruleData[Object.keys(requiredFields)[i]] === null || this.ruleData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.$nextTick(() => {
        if (this.selectedIndex === null) {
          const sameKey = this.planArray.some(item => item.key === this.ruleData.key)
          if (sameKey) {
            this.useAlertToast(false, '元件類型不可重複')
            return
          }
        } else {
          const sameKey = this.planArray.some((item, index) => index !== this.selectedIndex && item.key === this.ruleData.key)
          if (sameKey) {
            this.useAlertToast(false, '元件類型不可重複')
            return
          }
        }
        this.$emit('edit-rule', this.ruleData)
        this.$bvModal.hide(this.planSpecId)
      })
    },

    // (取得)編輯資料
    getData(rule) {
      const resolveData = {
        ...this.syncObject(this.blankRuleData, rule),
      }

      this.ruleData = resolveData
      this.$bvModal.show(this.planSpecId)
    },
  },
  setup() {
    const blankRuleData = {
      key: null,
      value: null,
    }

    const ruleData = ref(JSON.parse(JSON.stringify(blankRuleData)))

    const resetRuleData = () => {
      ruleData.value = JSON.parse(JSON.stringify(blankRuleData))
    }

    const {
      syncObject,
    } = usePlanSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ruleData,
      blankRuleData,
      resetRuleData,

      syncObject,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  padding: 5px 0;
}
</style>
