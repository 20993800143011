var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.propertiesSpecId,"no-close-on-backdrop":"","ok-title":"確認","centered":"","ok-only":""},on:{"ok":_vm.handleOk},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.selectedIndex !== null ? '編輯' : '新增')+"規格 ")])]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"類別","label-for":"type"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"edit-col-select w-100 mr-1",attrs:{"options":!_vm.isSlot ?
                  [
                    { name: '元件', value: 'component' },
                    { name: '接點', value: 'slot' }] :
                  [
                    { name: '接點', value: 'slot' }
                  ],"reduce":function (option) { return option.value; },"clearable":false,"label":"name","placeholder":"請選擇類型"},on:{"input":function () {
                  _vm.ruleData.key = null
                  _vm.ruleData.label = null
                }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}]),model:{value:(_vm.ruleData.type),callback:function ($$v) {_vm.$set(_vm.ruleData, "type", $$v)},expression:"ruleData.type"}}),_c('toggle-button',{attrs:{"color":{checked: '#4683bb', unchecked: '#8f8f8f'},"switch-color":{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'},"width":75,"height":30,"labels":{checked: '必填', unchecked: '非必填'},"sync":true},model:{value:(_vm.ruleData.required),callback:function ($$v) {_vm.$set(_vm.ruleData, "required", $$v)},expression:"ruleData.required"}})],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.ruleData.type === 'slot' ? '接點' : '元件',"label-for":"key"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.filiterDeviceTypeOptions,"reduce":function (option) { return option.type; },"clearable":false,"label":"name","placeholder":("請選擇" + (_vm.ruleData.type === 'component' ? '元件' : '接點類型'))},on:{"input":_vm.selectedComponent},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}},{key:"option",fn:function(option){return [_c('div',[_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.name)),_c('small',{staticClass:"text-muted"},[_vm._v(" ( "+_vm._s(option.type)+" )")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',[_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.name)),_c('small',{staticClass:"text-muted"},[_vm._v(" ( "+_vm._s(option.type)+" )")])])])]}}]),model:{value:(_vm.ruleData.key),callback:function ($$v) {_vm.$set(_vm.ruleData, "key", $$v)},expression:"ruleData.key"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"數量限制","label-for":"limit"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":[
                { name: '限制', value: true },
                { name: '不限', value: false }
              ],"reduce":function (option) { return option.value; },"clearable":false,"label":"name","placeholder":"數量限制"},on:{"input":function () { _vm.ruleData.number = 0 }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
              var search = ref.search;
              var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}]),model:{value:(_vm.ruleData.limit),callback:function ($$v) {_vm.$set(_vm.ruleData, "limit", $$v)},expression:"ruleData.limit"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"數量","label-for":"number"}},[_c('b-form-input',{attrs:{"id":"plan-value","name":"number","type":"number","disabled":!_vm.ruleData.limit,"placeholder":"限制數量"},model:{value:(_vm.ruleData.number),callback:function ($$v) {_vm.$set(_vm.ruleData, "number", $$v)},expression:"ruleData.number"}})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }