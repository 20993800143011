<template>
  <div>
    <validation-observer
      ref="domainEditForm"
      #default="{invalid}"
    >
      <b-modal
        id="epp-modal"
        ref="epp-modal"
        no-close-on-backdrop
        ok-title="確認"
        centered
        header-bg-variant="primary"
        ok-only
        @hidden="handleHide"
        @close="handleHide"
        @ok="handleOk"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            編輯設定
          </h4>
        </template>

        <div>
          <b-form
            @reset.prevent="resetForm"
            @submit.prevent="handleOk"
          >
            <!-- EPP密碼 -->
            <div class="mb-1">
              <b-form-group
                label="EPP密碼"
                label-for="transKey"
              >
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      EPP密碼
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>

                <b-form-input
                  id="transKey"
                  v-model="domainData.transKey"
                  trim
                  autofocus
                  placeholder="請輸入EPP密碼"
                />

              </b-form-group>
            </div>

            <!-- 說明 -->
            <div v-if="domainData.content">
              <label>
                說明
              </label>
              <div class="introduce-content">{{ domainData.content }}
              </div>
            </div>
          </b-form>
        </div>

        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              :disabled="invalid || isBusy"
              @click="handleOk"
            >
              <span v-if="!isBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BModal, BForm, VBTooltip, BButton, BSpinner, BFormGroup, BFormInput, // BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'

import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDomainServiceList, useDomainSetting } from '../useDomain'

export default {
  components: {
    BForm,
    BModal,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    // BFormTextarea,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      missingFields: [],
      required,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDomainData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('epp-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      const requiredFields = {
        // customer: '會員',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.domainData[Object.keys(requiredFields)[i]] === null || this.domainData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const resolveData = {
        id: this.domainData.id,
        // content: this.domainData.content,
        transKey: this.domainData.transKey,
      }

      this.isBusy = true
      this.$refs.domainEditForm.validate()
        .then(success => {
          if (success) {
            this.setDomainActionUpdate(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDomainData()
                this.$nextTick(() => {
                  this.$bvModal.hide('epp-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (開啟)編輯服務
    getData(item) {
      this.$bvModal.show('epp-modal')
      const resolveData = {
        ...this.syncObject(this.blankDomainData, item),
      }
      this.domainData = JSON.parse(JSON.stringify(resolveData))
    },
  },
  setup() {
    const {
      syncObject,
    } = useDomainSetting()

    const {
      isBusy,
      setDomainActionUpdate,
    } = useDomainServiceList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const domainData = ref(null)

    const blankDomainData = {
      id: null,
      transKey: '',
      content: '',
    }

    domainData.value = JSON.parse(JSON.stringify(blankDomainData))

    const resetDomainData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainData))
    }

    return {
      isBusy,
      useAlertToast,
      useHttpCodeAlert,
      domainData,
      blankDomainData,
      setDomainActionUpdate,
      resetDomainData,

      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
.introduce-content {
  white-space: pre-wrap;
}
</style>
