import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert, usePublicData,
} from '@/libs/mixins/index'
import store from '@/store'

export const useOrderSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    stateType: [{
      0: '未繳費', 1: '已繳費', 2: '已退款', 3: '已取消', 4: '待處理', 5: '已完成',
    }, {
      0: 'warning', 1: 'info', 2: 'warning', 3: 'light-secondary', 4: 'danger', 5: 'success',
    }, {
      0: 'light-warning', 1: 'light-info', 2: 'light-info', 3: 'light-secondary', 4: 'light-danger', 5: 'light-success',
    }],
    paymentType: {
      account: '虛擬帳號', credit: '信用卡', store: '超商代碼',
    },
    productType: {
      domain_new: '網域購買託管',
      domain_transfer: '網域攜入',
      domain_continue: '網域續約',
      domain_free: '免費網址',
    },
    product: {
      domain: '網域', vps: 'VPS', host: '實體主機',
    },
  }

  const stateTypeOptions = [
    { label: '未繳費', value: 0 },
    { label: '已繳費', value: 1 },
    { label: '已退款', value: 2 },
    { label: '已取消', value: 3 },
    { label: '待處理', value: 4 },
    { label: '已完成', value: 5 },
  ]

  const getOrderProductSearch = (...args) => store.dispatch('admin-order/getOrderProductSearch', ...args)
  const setOrderCreate = (...args) => store.dispatch('admin-order/setOrderCreate', ...args)
  const getCustomerAdditionalInfoList = (...args) => store.dispatch('admin-order/getCustomerAdditionalInfoList', ...args)
  const setOrderUpdate = (...args) => store.dispatch('admin-order/setOrderUpdate', ...args)
  const setOrderServiceDomain = (...args) => store.dispatch('admin-order/setOrderServiceDomain', ...args)
  const setOrderCompleted = (...args) => store.dispatch('admin-order/setOrderCompleted', ...args)
  const setOrderPay = (...args) => store.dispatch('admin-order/setOrderPay', ...args)
  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  // (查詢)網域
  const setDomainCheck = (...arg) => store.dispatch('api/setDomainCheck', ...arg)
  const getDomainContinueCheck = (...arg) => store.dispatch('api/getDomainContinueCheck', ...arg)

  return {
    ui,
    stateTypeOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getOrderProductSearch,
    setOrderCreate,
    getCustomerAdditionalInfoList,
    setOrderUpdate,
    setOrderServiceDomain,
    setOrderCompleted,
    setOrderPay,
    getMetaListData,
    setDomainCheck,
    getDomainContinueCheck,
  }
}

export const useOrderList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '訂單編號', key: 'ssid', sortable: true, searchable: true, select: true,
    },
    {
      label: '會員', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '繳費方式', key: 'payment_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '金額', key: 'price', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchStateType = ref(null)

  const blankOrderData = {
    id: null,
    ssid: null,
    state: 0,
    customer: null,
    creator: null,
    price: null,
    discount: 0,
    bill_time: null, // 繳費時間
    expire_time: null,
    acc_last5: null,
    credit_last4: null,
    payment_id: null,
    tag_id: [],
    point: 0,
    remark_display: false,
    content: null,
    created_at: null,
    updated_at: null,
  }

  const blankCustomerData = {
    id: null,
    image: null,
    account: null,
    name: null,
    family_name: null,
    branch_id: null,
    point: 0,
    last_login_at: null,
    email_state: 0,
    phone_state: 0,
    kyc_state: 0,
    updated_at: null,
  }

  const getOrderListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchStateType.value !== null) {
      resolveFilters = `state: ${searchStateType.value}`
    }

    store.dispatch('admin-order/getOrderList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankOrderData, item),
            payment_type: item.payment_id ? item.payment.type : null,
            customer_info: item.customer ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchStateType], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    searchStateType,
    blankOrderData,
    blankCustomerData,
    refetchData,
    refetchTable,

    getOrderListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useOrderView = () => {
  const {
    syncObject,
  } = useCommenSettings()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    blankOrderData,
    blankCustomerData,
  } = useOrderList()

  const {
    allCountries,
  } = usePublicData()

  const usePoint = ref(null)
  const pointType = ref(0)
  const pointNumber = ref(null)
  const refundNumber = ref(0)

  const blankBranchData = {
    id: null,
    name: null,
    icon: null,
  }

  const blankPaymentData = {
    id: null,
    name: null,
    type: null,
  }

  const blankProductData = {
    id: null,
    type: null,
    product: null,
    price: null,
    comment: null,
    state: 0,
  }

  const blankProductInfoData = {
    domain: {
      prefix: null,
      quantity: 1,
      suffix: null,
      start_time: null,
      end_time: null,
    },
    vps: {},
    host: {},
    domain_new: {},
    domain_transfer: {
      transkey: null,
    },
    domain_continue: {},
    domain_free: {
      vpsId: null,
    },
  }

  const resolvePhoneCode = code => {
    const country = allCountries.find(([, , dialCode]) => dialCode === code)
    return country ? country[1] : null
  }

  const resolveCountriesCode = iso2 => {
    const country = allCountries.find(([, code]) => code === iso2.toLowerCase())
    return country ? country[0] : null
  }

  const getOrderInfoData = (...args) => {
    store.commit('admin-order/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('admin-order/getOrderData', ...args)
      .then(response => {
        const { order, branch } = response.data.data
        const resolveData = {
          ...syncObject(blankOrderData, order),
          payment: order.payment_id ? syncObject(blankPaymentData, order.payment) : syncObject(blankPaymentData, {}),
          customer_info: order.customer ? syncObject(blankCustomerData, order.customer_info) : syncObject(blankCustomerData, {}),
          branch_info: branch ? syncObject(blankBranchData, branch) : syncObject(blankBranchData, {}),
          creator_info: { id: null, name: null, identity: 'client' },
          product: [],
        }

        if (order.creator && order.creator.identity === 'admin') {
          resolveData.creator_info = syncObject({ id: null, name: null, identity: 'admin' }, order.creator)
        }

        resolveData.product = order.product.map(item => {
          const resolve = {
            ...syncObject(blankProductData, item),
            product_type: item.type.split('_')[0],
          }
          resolve.info = {
            ...syncObject(blankProductInfoData[resolve.product_type], item.info),
            ...syncObject(blankProductInfoData[resolve.type.toLowerCase()], item.info),
          }
          return resolve
        })

        store.commit('admin-order/UPDATE_ORDER_INFO_STATE', resolveData)
        store.commit('admin-order/UPDATE_BUSY_LOADING_STATE', false)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    usePoint,
    pointType,
    pointNumber,
    refundNumber,
    blankOrderData,
    blankBranchData,
    blankPaymentData,
    blankCustomerData,

    getOrderInfoData,
    useAlertToast,
    useHttpCodeAlert,
    resolvePhoneCode,
    resolveCountriesCode,
  }
}

export const useOrderHistory = orderID => {
  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const isBusyLoading = ref(false)
  const historyLog = ref([])

  const blankOrderHistory = {
    id: null,
    action: null,
    content: null,
    created_at: null,
    updated_at: null,
    data: null,
    identity: null,
    ip: null,
    user: null,
  }

  const blankAdminData = {
    id: null,
    name: null,
  }

  const blankCustomerData = {
    id: null,
    name: null,
    family_name: null,
  }

  const getOrderHistory = () => {
    isBusyLoading.value = true
    store.dispatch('admin-order/getOrderHistory', {
      order_id: orderID,
    })
      .then(response => {
        const { data } = response.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankOrderHistory, item),
          }
          if (item.identity === 'admin') {
            resolve.user_info = item.user_info ? syncObject(blankAdminData, item.user_info) : syncObject(blankAdminData, {})
          }

          if (item.identity === 'client') {
            resolve.user_info = item.user_info ? syncObject(blankCustomerData, item.user_info) : syncObject(blankCustomerData, {})
          }
          return resolve
        })

        historyLog.value = resolveData
        isBusyLoading.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isBusyLoading.value = false
      })
  }

  return {
    isBusyLoading,
    historyLog,
    getOrderHistory,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useOrderCustomerList = customerID => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類別', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '商品', key: 'product', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const blankOrderData = {
    id: null,
    product: null,
    type: null,
    created_at: null,
    updated_at: null,
  }

  const blankInfoData = {
    domain: {
      prefix: null,
      quantity: 1,
    },
    vps: {},
    host: {},
    domain_new: {},
    domain_transfer: {},
    domain_continue: {},
    domain_free: {
      vpsId: null,
    },
  }

  const blankProductInfoData = {
    domain: {
      id: null,
      suffix: null,
    },
    vps: {},
    host: {},
  }

  const setOrderDelete = (...arg) => store.dispatch('admin-order/setOrderDelete', ...arg)

  const getOrderListData = (ctx, callback) => {
    store.dispatch('admin-order/getCustomerOrderList', {
      customer_id: customerID,
      queryParams: {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        range: timeRange.value,
        _: Date.now(),
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankOrderData, item),
            product_type: item.type.split('_')[0],
          }
          resolve.info = {
            ...syncObject(blankInfoData[resolve.product_type], item.info),
            ...syncObject(blankInfoData[resolve.type.toLowerCase()], item.info),
          }
          resolve.product_info = syncObject(blankProductInfoData[resolve.product_type], item.product_info)
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankOrderData,
    refetchData,
    refetchTable,

    setOrderDelete,
    getOrderListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
