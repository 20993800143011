<template>
  <b-modal
    id="add-modal"
    ref="add-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ configData.id ? '複製' : '新增' }}設定
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 分站 -->
        <b-form-group label-for="branch_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                分站
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="configData.branch_id"
            :options="branchOptions"
            :reduce="option => option.id"
            :clearable="false"
            label="branch"
            class="edit-col-select"
            placeholder="請選擇分站"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div>
                <div
                  v-if="option.name"
                  class="font-weight-bold d-block text-nowrap mb-0"
                >
                  {{ option.name }}<small class="text-muted"> ( {{ option.branch }} )</small>
                </div>

                <div v-else>
                  {{ option.branch }}
                </div>
              </div>
            </template>

            <template #selected-option="option">
              <div>
                <div
                  v-if="option.name"
                  class="font-weight-bold d-block text-nowrap mb-0"
                >
                  {{ option.name }}<small class="text-muted"> ( {{ option.branch }} )</small>
                </div>

                <div v-else>
                  {{ option.branch }}
                </div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col
            cols="12"
            sm="7"
          >
            <!-- 名稱 -->
            <validation-provider
              #default="validationContext"
              name="設定名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      設定名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="configData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入設定名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            sm="5"
          >
            <validation-provider
              #default="{ errors }"
              name="代號"
            >
              <b-form-group label-for="source_key">
                <template #label>
                  <label class="mb-0">
                    代號
                    <span class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="source_key"
                  v-model="configData.source_key"
                  :state="errors.length > 0 ? false : null"
                  trim
                  placeholder="請輸入類別代號"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div>
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">參數設定</span>
            </div>

          </div>

          <hr class="mt-50">

          <draggable
            tag="ul"
            :list="configData.format"
            class="list-group"
          >
            <li
              v-for="(element, index) in configData.format"
              :key="`format-${element.key}`"
              class="list-group-item"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                      class="cursor-pointer mr-1"
                    />

                    <div>
                      <!-- 名稱 -->
                      <div
                        class="font-weight-bold cursor-pointer"
                        @click="selectedEditFormat(element, index)"
                      >
                        {{ element.label }}
                        <small>( {{ element.key }} )</small>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex text-nowrap align-items-center">
                    <div
                      class="actions-link-btn mr-25"
                      @click="selectedEditFormat(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      class="actions-link-btn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="移除"
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </draggable>

          <!-- 新增參數 -->
          <div class="text-center mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addNewItem"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>新增參數</span>
            </b-button>
          </div>

          <!-- 規則設定 -->
          <config-rule
            ref="configRuleAddModal"
            :config-array="configData.format"
            :selected-index="selectedIndex"
            config-rule-id="config-rule-add-modal"
            @edit-rule="editConfigFormat"
          />
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BRow, BCol, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import formValidation from '@core/comp-functions/forms/form-validation'
import ConfigRule from './components/ConfigRule.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useConfigList, useConfigSetting } from '../useConfig'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    draggable,
    ConfigRule,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      isBusy: false,
      required,
      selectedIndex: null,
    }
  },
  methods: {
    // (編輯)參數設定
    editConfigFormat(item) {
      const resolveData = this.syncObject(this.blankSetting, item)
      if (this.selectedIndex !== null) {
        this.$set(this.configData.format, this.selectedIndex, resolveData)
        return
      }
      this.configData.format.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditFormat(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.configRuleAddModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      this.selectedIndex = null
      this.$refs.configRuleAddModal.getData(resolveData)
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.configData.format.splice(index, 1)
        }
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetConfigData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('add-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        branch_id: '分站',
        name: '設定名稱',
        source_key: 'KEY',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.configData[Object.keys(requiredFields)[i]] === null || this.configData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const result = {}
      this.configData.format.forEach(({ label, key }) => {
        result[key] = label
      })
      const resolveData = {
        branch_id: this.configData.branch_id,
        name: this.configData.name,
        source_key: this.configData.source_key,
        format: result,
        value: this.configData.value,
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setConfigCreate(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetConfigData()
                this.$nextTick(() => {
                  this.$bvModal.hide('add-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData(copyItem) {
      this.getBranchData()
      if (copyItem) {
        const resolveData = {
          ...this.syncObject(this.blankConfigData, copyItem),
        }
        const resolveFormat = copyItem.format ? Object.entries(copyItem.format).map(([key, label]) => ({ label, key })) : []
        resolveData.format = resolveFormat
        this.configData = resolveData
      }
      if (this.configData.format.length === 0) {
        const previewValue = [
          { label: '名稱', key: 'name' },
          { label: 'KEY', key: 'key' },
          { label: '數值', key: 'value' },
        ]
        previewValue.forEach(item => {
          this.configData.format.push(item)
        })
      }
      this.$bvModal.show('add-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useConfigSetting()

    const {
      configData,
      branchOptions,
      isLoadingBusy,
      setConfigCreate,
      getBranchData,
    } = useConfigList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankConfigData = {
      id: null,
      branch_id: null,
      name: null,
      source_key: null,
      format: [],
      value: [],
    }

    const blankSetting = {
      label: null,
      key: null,
    }

    configData.value = JSON.parse(JSON.stringify(blankConfigData))

    const resetConfigData = () => {
      configData.value = JSON.parse(JSON.stringify(blankConfigData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetConfigData)

    return {
      configData,
      blankConfigData,
      isLoadingBusy,
      setConfigCreate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      branchOptions,
      blankSetting,

      resetConfigData,
      refFormObserver,
      getValidationState,
      resetForm,
      getBranchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
