import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useQuotaSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    type: {
      domain_new: '網域購買託管',
      domain_transfer: '網域攜入',
      domain_continue: '網域續約',
      domain_free: '免費網址',
    },
  }

  const productTypeOptions = {
    domain: [
      { label: '網域購買託管', value: 'domain_new' },
      { label: '網域攜入', value: 'domain_transfer' },
      { label: '網域續約', value: 'domain_continue' },
      { label: '免費網址', value: 'domain_free' },
    ],
    vps: [],
    host: [],
  }

  const resolveProductType = type => {
    if (type === 'domain') {
      return {
        label: '網域',
        value: type,
        color: '#8bced6',
      }
    }
    if (type === 'vps') {
      return {
        label: 'VPS',
        value: type,
        color: '#e6c019',
      }
    }
    if (type === 'host') {
      return {
        label: '實體主機',
        value: type,
        color: '#cc0836',
      }
    }
    return false
  }

  const productDictionary = {
    domain: {
      prefix: '網域前綴',
      quantity: '週期(年)',
    },
    vps: {},
    host: {},
  }

  return {
    ui,
    productDictionary,
    productTypeOptions,
    resolveProductType,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useQuotaList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '備註', key: 'content', sortable: true, searchable: true, select: true,
    },
    {
      label: '使用點數', key: 'point', sortable: true, searchable: true, select: true,
    },
    {
      label: '會員', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '餘額', key: 'before_quota', sortable: true, searchable: true, select: true,
    },
    {
      label: '操作者', key: 'creator', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)

  const blankQuotaData = {
    id: null,
    system_order_id: null,
    customer: null,
    creator: null,
    ip: null,
    before_quota: null,
    point: null,
    content: '',
    remark_display: false,
    created_at: null,
  }

  const blankCustomerData = {
    id: null,
    image: null,
    account: null,
    name: null,
    family_name: null,
    last_login_at: null,
    email_state: 0,
    phone_state: 0,
    kyc_state: 0,
    updated_at: null,
  }

  const blankAdminData = {
    id: null,
    name: null,
  }

  const getQuotaListData = (ctx, callback) => {
    // 整理filters

    store.dispatch('admin-quota/getQuotaList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankQuotaData, item),
            customer_info: item.customer ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null }, item.order) : syncObject({ id: null }, {}),
          }
          if (item.creator) {
            resolve.creator_info = item.creator_info ? syncObject(blankAdminData, item.creator_info) : syncObject(blankAdminData, {})
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    getQuotaListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useQuotaCustomerList = customerID => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '備註', key: 'content', sortable: true, searchable: true, select: true,
    },
    {
      label: '使用點數', key: 'point', sortable: true, searchable: true, select: true,
    },
    {
      label: '餘額', key: 'before_quota', sortable: true, searchable: true, select: true,
    },
    {
      label: '操作者', key: 'creator', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)

  const blankQuotaData = {
    id: null,
    system_order_id: null,
    customer: null,
    creator: null,
    ip: null,
    before_quota: null,
    point: null,
    content: '',
    remark_display: false,
    created_at: null,
  }

  const blankAdminData = {
    id: null,
    name: null,
  }

  const getQuotaListData = (ctx, callback) => {
    // 整理filters
    store.dispatch('admin-quota/getCustomerQuotaList', {
      customer_id: customerID,
      queryParams: {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        range: timeRange.value,
        _: Date.now(),
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankQuotaData, item),
            order_info: item.system_order_id && item.order ? syncObject({ id: null }, item.order) : syncObject({ id: null }, {}),
          }
          if (item.creator) {
            resolve.creator_info = item.creator_info ? syncObject(blankAdminData, item.creator_info) : syncObject(blankAdminData, {})
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    getQuotaListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
