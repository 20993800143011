import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import { useCommenSettings, usePublicData } from '@/libs/mixins/index'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// (判斷)是否登入
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

// (獲取)使用者資料
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

// (登出)
export const logout = () => {
  const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  if (!token) {
    localStorage.clear()
    return
  }
  store.dispatch('api/logout')
  localStorage.clear()
}

// (初始化)
export const init = () => {
}

// (權限)更新
export const setUserAuthPower = data => {
  const {
    syncObject,
  } = useCommenSettings()

  const {
    routerGroupKey,
  } = usePublicData()

  const resolveAuth = data.user_data.power
  const resolvePower = data.power_data

  // (更新)會員權限
  const userData = getUserData()
  userData.power = resolveAuth
  if (data.user_data && data.user_data.name) userData.name = data.user_data.name
  if (data.user_data && data.user_data.image) userData.image = data.user_data.image
  localStorage.setItem('userData', JSON.stringify(userData))

  // (初始化)權限
  const blnkPowerData = {
    id: null,
    name: null,
    route_path: null,
    group: null,
  }

  const authArray = resolveAuth.split('.').map(id => Number(id))

  const powerOptions = resolvePower.map(item => {
    const resolve = {
      ...syncObject(blnkPowerData, item),
      key: routerGroupKey[item.group] || null,
      state: authArray.includes(item.id),
    }
    return resolve
  })

  const groupPowerOptions = powerOptions.reduce((acc, item) => {
    if (!acc[item.key]) {
      acc[item.key] = []
    }
    acc[item.key].push(item)
    return acc
  }, {})

  store.commit('app/UPDATE_AUTHPOWER_STATE', groupPowerOptions)
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'page-login' }
}
