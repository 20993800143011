import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // ----------------------機器列表----------------------
    // (查詢)機器列表
    getMachineList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/machine', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)機器
    setMachineCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/machine', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)機器
    setMachineUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/machine/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)複數機器
    setMachineMutiUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/machine', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)機器
    setMachineDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/machine/${resolveData.id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          params: resolveData.queryParams,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
