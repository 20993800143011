<template>
  <div>
    <b-modal
      id="update-extra-modal"
      no-close-on-backdrop
      ok-title="確認"
      centered
      header-bg-variant="primary"
      ok-only
      @hidden="handleHide"
      @close="handleHide"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ extraDataItem.planId ? '編輯' : '新增' }}附加商品
        </h4>
      </template>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- 方案 -->
          <b-form-group label-for="planId">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  方案
                  <span class="text-danger">*</span>
                </label>

                <toggle-button
                  v-model="productData.required"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="52"
                  :height="20"
                  class="mb-50 mr-50"
                  :labels="{checked: '必填', unchecked: '可選'}"
                  :sync="true"
                />
              </div>
            </template>

            <v-select
              v-model="productData.planId"
              :options="planOptions"
              :clearable="false"
              :reduce="option => option.id"
              label="name"
              :selectable="isPlanSelectable"
              placeholder="請選擇狀態"
              :disabled="extraDataItem.planId ? true : false"
              class="disabled-show"
              @input="handlePlanChange"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <div
            v-if="productData.planId"
            class="mt-1"
          >
            <div class="d-flex align-items-center justify-content-between">
              <label>
                附加商品
                <span class="text-danger">*</span>
              </label>

              <toggle-button
                v-model="productData.muti"
                :color="{checked: '#20c997', unchecked: '#4683bb'}"
                :switch-color="{checked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)', unchecked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)'}"
                :width="52"
                :height="20"
                class="mb-75 mr-50"
                :labels="{checked: '多選', unchecked: '單選'}"
                :sync="true"
              />
            </div>

            <draggable
              tag="ul"
              :list="productOptionsFilter"
              class="list-group"
            >
              <li
                v-for="(option, index) in productOptionsFilter"
                :key="`option-${index}`"
                class="list-group-item"
              >
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <feather-icon
                        icon="AlignJustifyIcon"
                        size="20"
                        class="cursor-pointer mr-1"
                      />

                      <div>
                        <!-- 名稱 -->
                        <div
                          class="font-weight-bold cursor-pointer"
                        >
                          <!-- @click="selectedEditRow(element, index)" -->
                          {{ option.name }}
                          <small
                            v-if="option.price"
                            class="text-info"
                          >( +$ {{ parseInt(option.price, 10).toLocaleString() }} )</small>
                        </div>

                        <!-- 參數類型 -->
                        <div v-if="option.ssid">
                          <small class="text-muted d-flex">
                            <div class="item-description">{{ option.ssid }}</div>
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex text-nowrap align-items-center">
                      <toggle-button
                        v-model="option.value"
                        :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                        :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                        :width="35"
                        :height="20"
                        class="m-25 mb-50"
                        :sync="true"
                      />
                    </div>
                  </div>

                </div>
              </li>
            </draggable>

            <div
              v-if="productOptionsFilter.length === 0"
              class="border"
            >
              <div class="text-center my-2 animate__animated animate__fadeIn">
                <b-img
                  :src="$store.state.app.themeImages.notFoundImg"
                  fluid
                  width="240"
                  alt="查無資料"
                />
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button
            variant="primary"
            :disabled="isBusy || !productData.planId || productOptionsFilter.length === 0"
            @click="handleOk"
          >
            <span v-if="!isBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, VBToggle, BButton, BSpinner, BFormGroup, VBTooltip, BImg,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useProductList, useProductSetting } from '../../useProduct'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,

    ValidationObserver,
    vSelect,
    draggable,
    ToggleButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    extraDataItem: {
      type: Object,
      required: true,
    },
    extraProductOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    productOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      isBusy: false,
      productOptionsFilter: [],
    }
  },
  methods: {
    // (可選)方案類型
    isPlanSelectable(option) {
      const selectedIds = this.extraProductOptions.map(item => item.planId)
      return !selectedIds.includes(option.id)
    },

    // (切換)方案
    handlePlanChange(planId) {
      this.productOptionsFilter = []
      if (!planId) return

      const resolveArray = []
      this.productOptions.forEach(item => {
        const resolveData = {
          id: item.id,
          ssid: item.ssid,
          price: item.price,
          name: item.name,
          value: this.productData.options.includes(item.id),
        }

        if (item.plan_id === planId) resolveArray.push(resolveData)
      })

      const sortedData = [
        ...this.productData.options.map(id => resolveArray.find(item => item.id === id)).filter(Boolean),
        ...resolveArray.filter(item => !this.productData.options.includes(item.id)),
      ]

      sortedData.forEach(item => {
        this.productOptionsFilter.push(item)
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetProductData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-extra-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const optionIds = this.productOptionsFilter.filter(f => f.value).map(m => m.id)

      if (optionIds.length <= 0) {
        this.useAlertToast(false, '附加商品不可為空')
        return
      }

      this.productData.options = optionIds

      this.isBusy = true

      this.$nextTick(() => {
        this.$emit('edit-extra-product', this.productData)
        this.$bvModal.hide('update-extra-modal')
        this.isBusy = false
      })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankProductData, this.extraDataItem),
      }
      this.productData = resolveData
      this.handlePlanChange(resolveData.planId)
      this.$bvModal.show('update-extra-modal')
    },
  },
  setup() {
    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      syncObject,
    } = useProductSetting()

    const {
      isBusy,
      productData,
    } = useProductList()

    const blankProductData = {
      planId: null,
      options: [],
      required: true,
      muti: false,
    }

    const resetProductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
      isBusy.value = false
    }

    resetProductData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    return {
      isBusy,
      productData,
      blankProductData,

      resetProductData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style>
/* ? */
.dark-layout .vs--disabled .vs__dropdown-toggle,
.dark-layout .vs--disabled .vs__selected {
  opacity: 1 !important;
}
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
.list-group-item {
  &:hover {
    background: transparent !important;
  }
}
</style>
