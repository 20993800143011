import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    productDataInfo: null,
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_PRODUCT_DATA_INFO_STATE(state, val) {
      state.productDataInfo = val
    },
  },
  actions: {
    // (查詢)商品列表
    getProductList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/product', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一商品
    getProductData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/product/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)商品
    setProductCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/product', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)商品
    setProductUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/product/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)商品
    setProductDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/product/${resolveData.id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
