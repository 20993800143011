import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useBrandSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = []

  const ui = {}

  return {
    ui,
    stateOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useBrandList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '圖標', key: 'image', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const blankBrandData = {
    id: null,
    image: null,
    name: null,
    description: null,
    created_at: null,
    updated_at: null,
  }

  const setBrandDelete = (...arg) => store.dispatch('admin-brand/setBrandDelete', ...arg)
  const setBrandCreate = (...arg) => store.dispatch('admin-brand/setBrandCreate', ...arg)
  const setBrandUpdate = (...arg) => store.dispatch('admin-brand/setBrandUpdate', ...arg)
  const setBrandImageUpload = (...arg) => store.dispatch('admin-brand/setBrandImageUpload', ...arg)
  const setBrandImageDelete = (...arg) => store.dispatch('admin-brand/setBrandImageDelete', ...arg)

  const getBrandListData = (ctx, callback) => {
    store.dispatch('admin-brand/getBrandList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankBrandData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankBrandData,
    refetchData,
    refetchTable,

    setBrandDelete,
    setBrandCreate,
    setBrandUpdate,
    setBrandImageUpload,
    setBrandImageDelete,
    getBrandListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
