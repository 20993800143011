<template>
  <b-modal
    id="add-modal"
    ref="add-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ planData.id ? '複製' : '新增' }}方案
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 商品類別 -->
        <b-form-group label-for="type-group">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                類別
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="searchType"
            :options="typeOptions"
            :reduce="option => option.value"
            :clearable="false"
            class="edit-col-select"
            placeholder="請選擇類別"
            @input="handleTypeChange"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col
            cols="12"
            sm="7"
          >
            <!-- 名稱 -->
            <validation-provider
              #default="validationContext"
              name="方案名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      方案名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="planData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入方案名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            sm="5"
          >
            <validation-provider
              #default="{ errors }"
              name="代號"
            >
              <b-form-group label-for="type">
                <template #label>
                  <label class="mb-0">
                    代號
                    <span class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  v-if="searchType !== 'main'"
                  id="type"
                  v-model="planData.type"
                  :state="errors.length > 0 ? false : null"
                  trim
                  placeholder="請輸入類別代號"
                />

                <v-select
                  v-else
                  v-model="planData.type"
                  :options="$store.getters['appConfig/mainProductServerType']"
                  :reduce="option => option.key"
                  :clearable="false"
                  class="edit-col-select"
                  placeholder="請選擇代號"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div>
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.label }}<small class="text-muted"> ( {{ option.key }} )</small>
                      </div>
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div>
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.label }}<small class="text-muted"> ( {{ option.key }} )</small>
                      </div>
                    </div>
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- 說明 -->
        <div>
          <label>說明</label>
          <b-form-textarea
            v-model="planData.description"
            placeholder="請輸入說明"
            :state="planData.description.length <= maxChar ? null : false"
            rows="2"
          />
          <small
            class="textarea-counter-value float-right"
            :class="planData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ planData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BFormTextarea,
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { usePlanList, usePlanSetting } from '../usePlan'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // (切換)主次商品
    handleTypeChange(value) {
      if (value === 'main') {
        this.planData.type = this.$store.getters['appConfig/mainProductServerType'][0].key
      } else {
        this.planData.type = null
      }
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetPlanData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('add-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      if (this.planData.description.length > this.maxChar) {
        this.useAlertToast(false, '說明字數超過上限')
        return
      }

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '方案名稱',
        type: '分類代號',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.planData[Object.keys(requiredFields)[i]] === null || this.planData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setPlanCreate({
              ...this.planData,
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetPlanData()
                this.$nextTick(() => {
                  this.$bvModal.hide('add-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData(copyItem) {
      if (copyItem) {
        const resolveData = {
          ...this.syncObject(this.blankPlanPrice, copyItem),
        }
        const keys = this.$store.getters['appConfig/mainProductServerType'].map(item => item.key)
        if (keys.includes(resolveData.type)) {
          this.searchType = 'main'
          this.handleTypeChange(this.searchType)
        } else {
          this.searchType = 'sub'
          this.handleTypeChange(this.searchType)
        }
        this.planData = resolveData
      } else {
        this.searchType = this.typeOptions[0].value
        this.handleTypeChange(this.searchType)
      }
      this.$bvModal.show('add-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
      typeOptions,
    } = usePlanSetting()

    const {
      planData,
      searchType,
      isLoadingBusy,
      setPlanCreate,
    } = usePlanList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPlanPrice = {
      name: null,
      type: null,
      description: '',
    }

    planData.value = JSON.parse(JSON.stringify(blankPlanPrice))

    const resetPlanData = () => {
      planData.value = JSON.parse(JSON.stringify(blankPlanPrice))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPlanData)

    return {
      planData,
      searchType,
      blankPlanPrice,
      isLoadingBusy,
      setPlanCreate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      typeOptions,

      resetPlanData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
