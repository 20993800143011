<template>
  <b-modal
    id="detail-modal"
    ref="detail-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯預設值
      </h4>
    </template>

    <div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          ref="refDataTypeTable"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- 名稱 -->
          <validation-provider
            #default="validationContext"
            name="設定名稱"
            rules="required"
          >
            <b-form-group label-for="name">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    設定名稱
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>
              <b-form-input
                id="name"
                v-model="configData.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="請輸入設定名稱"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">預設值</span>
            </div>
          </div>

          <hr class="mt-50">

          <draggable
            tag="ul"
            :list="configData.value"
            class="list-group"
          >
            <li
              v-for="(element, index) in configData.value"
              :key="`format-${index}`"
              class="list-group-item"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                      class="cursor-pointer mr-1"
                    />

                    <div>
                      <!-- 名稱 -->
                      <div
                        class="font-weight-bold cursor-pointer"
                        @click="selectedEditValue(element, index)"
                      >
                        {{ element.name ? element.name : `項目 ${index + 1}` }}
                        <small v-if="element.key">( {{ element.key }} )</small>
                      </div>

                      <!-- 預設 -->
                      <div v-if="element.value">
                        <small class="text-muted d-flex">
                          <div class="item-description">{{ element.value }}</div>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex text-nowrap align-items-center">
                    <div
                      class="actions-link-btn mr-25"
                      @click="selectedEditValue(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      class="actions-link-btn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="移除"
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </draggable>

          <!-- 新增預設值 -->
          <div class="text-center mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addNewItem"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>新增預設值</span>
            </b-button>
          </div>

          <!-- 規則設定 -->
          <config-value
            ref="configValueModal"
            :config-format="configData.format"
            :config-array="configData.value"
            :selected-index="selectedIndex"
            config-value-id="config-value-modal"
            @edit-rule="editConfigValue"
          />
        </b-form>
      </validation-observer>
    </div>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BSpinner, BImg, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import formValidation from '@core/comp-functions/forms/form-validation'
import ConfigValue from './components/ConfigValues.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useConfigList, useConfigSetting } from '../useConfig'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BImg,
    BButton,
    BSpinner,
    draggable,
    ConfigValue,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    configItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      selectedIndex: null,
    }
  },
  methods: {
    // (編輯)參數設定
    editConfigValue(item) {
      const resolveData = this.syncObject(this.blankData, item)
      if (this.selectedIndex !== null) {
        this.$set(this.configData.value, this.selectedIndex, resolveData)
        return
      }
      this.configData.value.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditValue(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.configValueModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankData))
      this.selectedIndex = null
      this.$refs.configValueModal.getData(resolveData)
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.configData.value.splice(index, 1)
        }
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetConfigData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('detail-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '設定名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.configData[Object.keys(requiredFields)[i]] === null || this.configData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const resolveData = {
        key: this.configData.source_key,
        branch_id: this.configItem.branch_id,
        data: {
          name: this.configData.name,
          value: this.configData.value,
        },
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setConfigUpdate(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetConfigData()
                this.$nextTick(() => {
                  this.$bvModal.hide('detail-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (整理)設定值
    resolveFormatValue(format, value) {
      const defaultBlankObject = format.reduce((acc, { key }) => {
        acc[key] = null
        return acc
      }, {})

      this.blankData = defaultBlankObject

      const resolveArray = value.map(item => {
        const resolve = {
          ...this.syncObject(defaultBlankObject, item),
        }
        return resolve
      })

      return resolveArray
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankConfigData, this.configItem),
      }
      const resolveFormat = this.configItem.format ? Object.entries(this.configItem.format).map(([key, label]) => ({ label, key })) : []
      resolveData.format = resolveFormat
      resolveData.value = this.resolveFormatValue(resolveFormat, this.configItem.value || [])
      this.configData = resolveData
      this.$bvModal.show('detail-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useConfigSetting()

    const {
      configData,
      blankData,
      isLoadingBusy,
      setConfigUpdate,
    } = useConfigList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankConfigData = {
      id: null,
      name: null,
      source_key: null,
      format: [],
      value: [],
    }

    configData.value = JSON.parse(JSON.stringify(blankConfigData))

    const resetConfigData = () => {
      configData.value = JSON.parse(JSON.stringify(blankConfigData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetConfigData)

    return {
      configData,
      blankData,
      blankConfigData,
      isLoadingBusy,
      setConfigUpdate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,

      resetConfigData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
