var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add-modal",attrs:{"id":"add-modal","no-close-on-backdrop":"","ok-title":"確認","centered":"","header-bg-variant":"primary","ok-only":""},on:{"hidden":_vm.handleHide,"close":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.planData.id ? '複製' : '新增')+"方案 ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}])},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refDataTypeTable",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"type-group"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 類別 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.typeOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"請選擇類別"},on:{"input":_vm.handleTypeChange},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"7"}},[_c('validation-provider',{attrs:{"name":"方案名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 方案名稱 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"請輸入方案名稱"},model:{value:(_vm.planData.name),callback:function ($$v) {_vm.$set(_vm.planData, "name", $$v)},expression:"planData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":"代號"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"type"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 代號 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[(_vm.searchType !== 'main')?_c('b-form-input',{attrs:{"id":"type","state":errors.length > 0 ? false : null,"trim":"","placeholder":"請輸入類別代號"},model:{value:(_vm.planData.type),callback:function ($$v) {_vm.$set(_vm.planData, "type", $$v)},expression:"planData.type"}}):_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.$store.getters['appConfig/mainProductServerType'],"reduce":function (option) { return option.key; },"clearable":false,"placeholder":"請選擇代號"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}},{key:"option",fn:function(option){return [_c('div',[_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.label)),_c('small',{staticClass:"text-muted"},[_vm._v(" ( "+_vm._s(option.key)+" )")])])])]}},{key:"selected-option",fn:function(option){return [_c('div',[_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.label)),_c('small',{staticClass:"text-muted"},[_vm._v(" ( "+_vm._s(option.key)+" )")])])])]}}],null,true),model:{value:(_vm.planData.type),callback:function ($$v) {_vm.$set(_vm.planData, "type", $$v)},expression:"planData.type"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('div',[_c('label',[_vm._v("說明")]),_c('b-form-textarea',{attrs:{"placeholder":"請輸入說明","state":_vm.planData.description.length <= _vm.maxChar ? null : false,"rows":"2"},model:{value:(_vm.planData.description),callback:function ($$v) {_vm.$set(_vm.planData, "description", $$v)},expression:"planData.description"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.planData.description.length > _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.planData.description.length))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }