import { ref, watch } from '@vue/composition-api'
import { $themeColors } from '@themeConfig'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useProductSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const kycRequiredOptions = [
    { label: '不需驗證', value: false },
    { label: '需驗證', value: true },
  ]

  const displayOptions = [
    { label: '未啟用', value: false },
    { label: '上架中', value: true },
  ]

  const ui = {
    displayList: [{
      false: '未啟用', true: '上架中',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
    kycRequired: {
      false: '不需驗證', true: '需驗證',
    },
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)
  const getConfigKeyData = (...arg) => store.dispatch('api/getConfigData', ...arg)

  return {
    ui,
    kycRequiredOptions,
    displayOptions,
    getConfigKeyData,
    getMetaListData,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useProductList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    getConfigKeyData,
    getMetaListData,
  } = useProductSetting()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '上架', key: 'display', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '分站', key: 'branch_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '身分驗證', key: 'require_kyc', sortable: true, searchable: true, select: true,
    },
    {
      label: '售價', key: 'price', sortable: true, searchable: true, select: true,
    },
    {
      label: '方案', key: 'plan', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '庫存', key: 'machine_total', sortable: false, searchable: true, select: true,
    // },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const productData = ref(null)
  const searchBranch = ref(null)
  const searchDisplay = ref(null)
  const searchPlan = ref(null)
  const searchDisplayGroup = ref(null)
  const planOptions = ref([])
  const branchOptions = ref([])
  const displayGroupOptions = ref([])
  const metaDisplayGroup = ref({})

  const isBusy = ref(false)
  const blankProductData = {
    id: null,
    ssid: null,
    name: null,
    price: 0,
    type: null,
    display: false,
    branch_id: null,
    plan_id: null,
    require_kyc: false,
    display_group: null, // 版塊
    // detail: '', // 形象
    // extra_product: [], // 附加商品
    created_at: null,
    updated_at: null,
  }

  const blankBranchInfoData = {
    id: null,
    name: null,
    branch: null,
  }

  const blankPlanInfoData = {
    id: null,
    name: null,
    type: null,
    machine_total: 0,
  }

  const setProductDelete = (...arg) => store.dispatch('admin-product/setProductDelete', ...arg)
  const setProductCreate = (...arg) => store.dispatch('admin-product/setProductCreate', ...arg)
  const setProductUpdate = (...arg) => store.dispatch('admin-product/setProductUpdate', ...arg)

  const getDisplayGroupData = branchId => {
    if (!branchId) return
    getConfigKeyData({ key: 'display_group', branch_id: branchId })
      .then(response => {
        const { data } = response.data
        if (!data) return
        const { value } = data
        displayGroupOptions.value = value.map(item => {
          const resolve = {
            ...syncObject({ name: null, key: null }, item),
          }
          return resolve
        })
      })
  }

  const getMetaSystemConfigAll = () => {
    getMetaListData({ key: 'systemConfig', queryParams: { noPagination: true } }) //
      .then(response => {
        const { data } = response.data.data
        const displayGroup = data.filter(item => item.source_key === 'display_group')
        const transformedData = displayGroup.reduce((acc, item) => {
          acc[item.branch_id] = item.value
          return acc
        }, {})
        metaDisplayGroup.value = transformedData
      })
  }

  const getProductListData = (ctx, callback) => {
    getMetaSystemConfigAll()
    // 整理filters
    let resolveFilters = ''

    if (searchBranch.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};branch_id: ${searchBranch.value}`
      } else resolveFilters = `branch_id: ${searchBranch.value}`
    }

    if (searchDisplay.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};display: ${searchDisplay.value ? 1 : 0}`
      } else resolveFilters = `display: ${searchDisplay.value ? 1 : 0}`
    }

    if (searchPlan.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};plan_id: ${searchPlan.value}`
      } else resolveFilters = `plan_id: ${searchPlan.value}`
    }

    if (searchDisplayGroup.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};display_group: ${searchDisplayGroup.value}`
      } else resolveFilters = `display_group: ${searchDisplayGroup.value}`
    }

    store.dispatch('admin-product/getProductList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankProductData, item),
            branch_info: item.branch_id && item.branch_info ? syncObject(blankBranchInfoData, item.branch_info) : syncObject(blankBranchInfoData, {}),
            plan_info: item.plan_id && item.plan_info ? syncObject(blankPlanInfoData, item.plan_info) : syncObject(blankPlanInfoData, {}),
          }
          return resolve
        })

        branchOptions.value = option.branch.map(item => {
          const resolve = {
            ...syncObject(blankBranchInfoData, item),
          }
          return resolve
        })

        planOptions.value = option.plan.map(item => {
          const resolve = {
            ...syncObject(blankPlanInfoData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchBranch, searchDisplay, searchPlan, searchDisplayGroup], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    productData,
    blankProductData,
    blankPlanInfoData,
    blankBranchInfoData,
    refetchData,
    refetchTable,
    getMetaSystemConfigAll,

    searchBranch,
    searchDisplay,
    searchPlan,
    searchDisplayGroup,
    planOptions,
    branchOptions,
    displayGroupOptions,
    metaDisplayGroup,

    setProductDelete,
    setProductCreate,
    setProductUpdate,
    getProductListData,
    useAlertToast,
    useHttpCodeAlert,
    getDisplayGroupData,
  }
}

export const useProductView = () => {
  const {
    blankPlanInfoData,
    blankBranchInfoData,
    getMetaSystemConfigAll,
    metaDisplayGroup,
    planOptions,
    setProductUpdate,
  } = useProductList()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    getMetaListData,
  } = useProductSetting()

  const $strokeColor = '#ebe9f1'
  const $textHeadingColor = '#5e5873'
  const $goalStrokeColor2 = '#51e5a8'
  const goalOverviewRadialBar = {
    chart: {
      height: 200,
      type: 'radialBar',
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        blur: 3,
        left: 1,
        top: 1,
        opacity: 0.1,
      },
    },
    colors: [$goalStrokeColor2],
    plotOptions: {
      radialBar: {
        offsetY: -10,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: '77%',
        },
        track: {
          background: $strokeColor,
          strokeWidth: '50%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            color: $textHeadingColor,
            fontSize: '2.86rem',
            fontWeight: '600',
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [$themeColors.success],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    grid: {
      padding: {
        bottom: 30,
      },
    },
  }

  const blankProductData = {
    id: null,
    ssid: null,
    name: null,
    price: 0,
    type: null,
    display: false,
    branch_id: null,
    plan_id: null,
    require_kyc: false,
    display_group: null, // 版塊
    detail: '', // 形象
    extra_product: [], // 附加商品
    created_at: null,
    updated_at: null,
  }

  const getPlanData = () => {
    getMetaListData({ key: 'plan', queryParams: { noPagination: true } })
      .then(response => {
        const { data } = response.data.data
        planOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankPlanInfoData, item),
          }
          return resolve
        })
      })
  }

  const getProductInfoData = updateData => new Promise((resolve, reject) => {
    store.dispatch('admin-product/getProductData', { ...updateData })
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankProductData, data),
          branch_info: data.branch_id && data.branch_info ? syncObject(blankBranchInfoData, data.branch_info) : syncObject(blankBranchInfoData, {}),
          plan_info: data.plan_id && data.plan_info ? syncObject(blankPlanInfoData, data.plan_info) : syncObject(blankPlanInfoData, {}),
        }
        store.commit('admin-product/UPDATE_PRODUCT_DATA_INFO_STATE', resolveData)
        resolve()
      })
      .catch(error => reject(error))
  })

  return {
    useAlertToast,
    useHttpCodeAlert,

    goalOverviewRadialBar,
    blankProductData,
    blankPlanInfoData,
    getProductInfoData,
    getMetaSystemConfigAll,
    getPlanData,
    setProductUpdate,
    planOptions,
    metaDisplayGroup,
  }
}
