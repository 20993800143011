import { render, staticRenderFns } from "./ConfigListEditModal.vue?vue&type=template&id=15a87ddc&scoped=true&"
import script from "./ConfigListEditModal.vue?vue&type=script&lang=js&"
export * from "./ConfigListEditModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfigListEditModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ConfigListEditModal.vue?vue&type=style&index=1&id=15a87ddc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a87ddc",
  null
  
)

export default component.exports