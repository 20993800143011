<template>
  <!-- 頁面: 商品詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
  <div>
    <div v-if="checkAuthAbility('device', 'API.Admin.Device.Id')">
      <div v-if="adminDeviceState.deviceDataInfo">
        <image-device-explorer-modal
          ref="imageDeviceExplorerModal"
          :is-hiden-refresh="false"
          :device-id="`${adminDeviceState.deviceDataInfo.id}`"
          :use-callback-url="false"
          :use-callback-trash="false"
          @reset-data="refreshDeviceInfoData"
        />

        <device-view-info
          @click-image-device-explorer="onSubmitImageDeviceExplorer"
          @refresh-device-info="refreshDeviceInfoData"
        />

        <device-view-setting
          ref="refSettings"
          @refresh-device-info="refreshDeviceInfoData"
        />
      </div>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { BAlert } from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import DeviceViewInfo from './DeviceViewInfo.vue'
import DeviceViewSetting from './DeviceViewSetting.vue'
import ImageDeviceExplorerModal from '@/layouts/components/ImageDeviceExplorer/ImageExplorer.vue'
import { useDeviceView } from '../useDevice'
import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BAlert,
    DeviceViewInfo,
    DeviceViewSetting,
    ImageDeviceExplorerModal,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    adminDeviceState() {
      return this.$store.state['admin-device']
    },
  },
  watch: {
    '$route.params.id': 'refreshDeviceInfoData',
  },
  beforeDestroy() {
    this.$store.commit('admin-device/UPDATE_DEVICE_DATA_INFO_STATE', null)
  },
  methods: {
    // (觸發)圖片總管
    onSubmitImageDeviceExplorer() {
      setTimeout(() => { this.$refs.imageDeviceExplorerModal.getData() }, 200)
    },

    // (檢查)是否為主要商品類型
    isDeviceMainType(type) {
      return this.$store.getters['appConfig/mainDeviceServerType'].map(item => item.key).includes(type)
    },
  },
  setup() {
    const DEVICE_ADMIN_STORE_MODULE_NAME = 'admin-device'

    if (!store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_ADMIN_STORE_MODULE_NAME)
    })

    // const deviceID = router.currentRoute.params.id

    const refSettings = ref(null)

    const {
      getDeviceInfoData,
    } = useDeviceView()

    const refreshDeviceInfoData = () => {
      store.commit('admin-device/UPDATE_BUSY_LOADING_STATE', true)
      const deviceID = router.currentRoute.params.id
      getDeviceInfoData({ id: deviceID })
        .then(() => {
          if (refSettings.value) {
            refSettings.value.initDeviceSpecData(true)
          }
          store.commit('admin-device/UPDATE_BUSY_LOADING_STATE', false)
        })
        .catch(() => {
          // router.replace({ name: 'admin-device' })
        })
    }

    refreshDeviceInfoData()

    return {
      refSettings,
      refreshDeviceInfoData,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
