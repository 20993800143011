<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯{{ isFree ? '免費' : '付費' }}網域
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 類型 -->
        <!-- <b-form-group
          label="類型"
          label-for="isFree"
        >
          <v-select
            v-model="domainData.isFree"
            :options="isFreeOptions"
            :reduce="option => option.value"
            :clearable="false"
            class="edit-col-select"
            disabled
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group> -->

        <!-- 名稱 -->
        <validation-provider
          #default="validationContext"
          name="網域後綴"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  網域後綴
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>

            <b-form-input
              id="name"
              v-model="domainData.suffix"
              trim
              disabled
              placeholder="請輸入網域商品名稱"
            />
            <!-- :state="getValidationState(validationContext)" -->

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <!-- 上架狀態 -->
            <b-form-group
              label="上架狀態"
              label-for="display"
            >
              <v-select
                v-model="domainData.display"
                :options="displayOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 排序 -->
            <b-form-group
              label="排序"
              label-for="rank"
            >
              <b-form-input
                id="name"
                v-model="domainData.rank"
                type="number"
                placeholder="請輸入商品排序"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 身分驗證 -->
            <b-form-group
              label="身分驗證"
              label-for="require_kyc"
            >
              <v-select
                v-model="domainData.require_kyc"
                :options="kycRequiredOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 熱銷狀態 -->
            <b-form-group
              label="熱銷狀態"
              label-for="is_fire"
            >
              <v-select
                v-model="domainData.is_fire"
                :options="isFireOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- 上架狀態 -->
        <!-- <b-form-group
          label="上架狀態"
          label-for="display"
        >
          <v-select
            v-model="domainData.display"
            :options="displayOptions"
            :reduce="option => option.value"
            :clearable="false"
            class="edit-col-select"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group> -->

        <!-- 身分驗證 -->
        <!-- <b-form-group
          label="身分驗證"
          label-for="require_kyc"
        >
          <v-select
            v-model="domainData.require_kyc"
            :options="kycRequiredOptions"
            :reduce="option => option.value"
            :clearable="false"
            class="edit-col-select"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group> -->

        <!-- 熱銷狀態 -->
        <!-- <b-form-group
          label="熱銷狀態"
          label-for="is_fire"
        >
          <v-select
            v-model="domainData.is_fire"
            :options="isFireOptions"
            :reduce="option => option.value"
            :clearable="false"
            class="edit-col-select"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group> -->

        <!-- 網域商 -->
        <b-form-group label-for="searchAgentId">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                網域商
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>
          <v-select
            v-model="searchAgentId"
            :options="adminDomainState.domainAgentOptions"
            class="w-100"
            label="agent"
            :clearable="false"
            :reduce="option => option.id"
            @input="() => {
              getDomainDealerData()
              domainData.domain_name_dealer_id = null
            }"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 經銷商 -->
        <b-form-group label-for="domain_name_dealer_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                經銷商
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>
          <v-select
            v-if="searchAgentId"
            v-model="domainData.domain_name_dealer_id"
            :options="dealerOptions"
            class="w-100"
            label="name"
            :clearable="false"
            :reduce="option => option.id"
            :disabled="isLoadingBusy"
            placeholder="請選擇經銷商"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDomainSetting, useDomainPrice } from '../../useDomain'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    domainItem: {
      type: Object,
      required: true,
    },
    isFree: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      selectedIndex: null,
    }
  },
  computed: {
    adminDomainState() {
      return this.$store.state['admin-domain']
    },
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDomainData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            if (!this.domainData.domain_name_dealer_id) {
              this.useAlertToast(false, '經銷商不得為空')
              this.isBusy = false
              return
            }
            this.setDomainUpdate({
              id: this.domainItem.id,
              data: {
                ...this.domainData,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDomainData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankDomainPrice, this.domainItem),
      }
      const firstAgentId = this.domainItem.domain_name_agent_id
      if (firstAgentId) {
        this.searchAgentId = firstAgentId
        this.getDomainDealerData()
      }
      this.domainData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
      kycRequiredOptions,
      displayOptions,
      isFireOptions,
      isFreeOptions,
    } = useDomainSetting()

    const {
      domainData,
      dealerOptions,
      searchAgentId,
      isLoadingBusy,
      setDomainUpdate,
      getDomainDealerData,
    } = useDomainPrice()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankDomainPrice = {
      suffix: null,
      display: false,
      is_free: false,
      is_fire: false,
      require_kyc: false,
      rank: null,
      domain_name_dealer_id: null,
    }

    domainData.value = JSON.parse(JSON.stringify(blankDomainPrice))

    const resetDomainData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainPrice))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDomainData)

    return {
      domainData,
      dealerOptions,
      searchAgentId,
      blankDomainPrice,
      isLoadingBusy,
      setDomainUpdate,
      getDomainDealerData,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      kycRequiredOptions,
      displayOptions,
      isFireOptions,
      isFreeOptions,

      resetDomainData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.required-key {
  color: red;
  margin-top: 3px;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
