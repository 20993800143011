<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ placeDataItem.id ? '編輯地點' : '新增地點' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 名稱 -->
        <validation-provider
          #default="{ errors }"
          name="名稱"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="placeData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="placeDataItem.name ? placeDataItem.name : '請輸入地點名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 說明 -->
        <div>
          <label>說明</label>
          <b-form-textarea
            v-model="placeData.description"
            :placeholder="placeDataItem.description ? placeDataItem.description : '請輸入說明'"
            :state="placeData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="placeData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ placeData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, VBTooltip,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import formValidation from '@core/comp-functions/forms/form-validation'
import { usePlaceList, usePlaceSetting } from '../usePlace'
import { useAlert, usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    // BImg,
    BForm,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [usePublicFunction],
  props: {
    placeDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetplaceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.placeData.description.length > this.maxChar) {
        this.useAlertToast(false, '說明字數超過上限')
        return
      }

      this.isBusy = true

      if (this.placeDataItem.id) {
        this.setPlaceUpdate({
          id: this.placeDataItem.id,
          data: {
            name: this.placeData.name,
            description: this.placeData.description,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetplaceData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setPlaceCreate({
          name: this.placeData.name,
          description: this.placeData.description,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetplaceData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankplaceData, this.placeDataItem),
      }
      this.placeData = resolveData
      this.$bvModal.show('update-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setPlaceCreate,
      setPlaceUpdate,
    } = usePlaceList()

    const {
      stateOptions,
      getMetaListData,
      departmentsOptions,
      syncObject,
    } = usePlaceSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankplaceData = {
      id: null,
      name: null,
      description: '',
    }

    const placeData = ref(JSON.parse(JSON.stringify(blankplaceData)))

    const resetplaceData = () => {
      placeData.value = JSON.parse(JSON.stringify(blankplaceData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetplaceData)

    return {
      isBusy,

      placeData,
      stateOptions,
      departmentsOptions,
      blankplaceData,
      setPlaceCreate,
      setPlaceUpdate,
      getMetaListData,

      resetplaceData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
