import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    deviceDataInfo: null,
    deviceTypeArray: [],
    // deviceChildrenArray: [],
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_DEVICE_DATA_INFO_STATE(state, val) {
      state.deviceDataInfo = val
    },
    UPDATE_DEVICE_TYPE_ARRAY_STATE(state, val) {
      state.deviceTypeArray = val
    },
    // UPDATE_DEVICE_CHILDREN_ARRAY_STATE(state, val) {
    //   state.deviceChildrenArray = val
    // },
  },
  actions: {
    // ----------------------元件類別管理----------------------
    // (查詢)元件類別
    getDeviceTypeList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/device/type', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)元件類別
    getDeviceType(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/device/type/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)元件類別
    setDeviceTypeCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/type', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)元件類別
    setDeviceTypeUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/device/type/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)元件類別Icon
    setDeviceTypeImageUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/device/type/${resolveData.id}/icon`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ----------------------元件----------------------
    // (查詢)元件列表
    getDeviceList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/device', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一元件
    getDeviceData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/device/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)複數元件
    setDeviceCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)元件
    setDeviceUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/device/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)複數元件
    setDeviceMutiUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)複數元件
    setDeviceMutiDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (綁定)複數元件
    setDeviceMutiBind(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/bind', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (解除綁定)複數元件
    setDeviceMutiUnbind(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/unbind', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (綁定)複數接點
    setDeviceMutiSlotBind(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/connect', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (解除綁定)複數接點
    setDeviceMutiSlotUnbind(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/disconnect', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ----------------------元件圖片----------------------
    // (查詢)元件圖片總管
    getDeviceImages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/device/image', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)圖片
    uploadImageExplorer(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/image', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: imageData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)圖片
    updateImageExplorer(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/device/image/${imageData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...imageData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)圖片
    deleteImageExplorer(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios('/admin/device/image', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...imageData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
