<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯方案
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            sm="7"
          >
            <!-- 名稱 -->
            <validation-provider
              #default="validationContext"
              name="方案名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      方案名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="planData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入方案名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            sm="5"
          >
            <b-form-group label-for="type">
              <template #label>
                <label class="mb-0">
                  代號
                  <span class="text-danger">*</span>
                </label>
              </template>

              <b-form-input
                id="type"
                v-model="planData.type"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- 說明 -->
        <div>
          <label>說明</label>
          <b-form-textarea
            v-model="planData.description"
            placeholder="請輸入說明"
            :state="planData.description.length <= maxChar ? null : false"
            rows="2"
          />
          <small
            class="textarea-counter-value float-right"
            :class="planData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ planData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>

        <div class="mt-1">
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">規格值</span>
            </div>
          </div>

          <hr class="mt-50">

          <draggable
            tag="ul"
            :list="planData.spec"
            class="list-group"
          >
            <li
              v-for="(element, index) in planData.spec"
              :key="`spec-${index}`"
              class="list-group-item"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                      class="cursor-pointer mr-1"
                    />

                    <div>
                      <!-- 名稱 -->
                      <div
                        class="font-weight-bold cursor-pointer"
                        @click="selectedEditSpec(element, index)"
                      >
                        {{ resolveDeviceTypeName(element.key, index) }}
                        <small v-if="element.key">( {{ element.key }} )</small>
                      </div>

                      <!-- 預設 -->
                      <div v-if="element.value">
                        <small class="text-muted d-flex">
                          <div class="item-description">{{ element.value }}</div>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex text-nowrap align-items-center">
                    <div
                      class="actions-link-btn mr-25"
                      @click="selectedEditSpec(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      class="actions-link-btn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="移除"
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </draggable>

          <!-- 新增預設值 -->
          <div class="text-center mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addNewItem"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>新增預設值</span>
            </b-button>
          </div>

          <!-- 規則設定 -->
          <plan-spec
            ref="planSpecModal"
            :plan-array="planData.spec"
            :device-type-options="deviceTypeOptions"
            :selected-index="selectedIndex"
            plan-spec-id="plan-spec-modal"
            @edit-rule="editPlanSpec"
          />
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BFormTextarea,
  BRow, BCol, BImg,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import PlanSpec from './components/planSpecs.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { usePlanList, usePlanSetting } from '../usePlan'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BImg,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    draggable,
    PlanSpec,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    planItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
      maxChar: 255,
      selectedIndex: null,
    }
  },
  methods: {
    // (獲取)裝置類型資料
    resolveDeviceTypeName(value, index) {
      const name = `項目 ${index + 1}`
      const resolveData = this.deviceTypeOptions.find(item => item.type === value)
      return resolveData ? resolveData.name : name
    },

    // (編輯)參數設定
    editPlanSpec(item) {
      const resolveData = this.syncObject(this.blankSetting, item)
      if (this.selectedIndex !== null) {
        this.$set(this.planData.spec, this.selectedIndex, resolveData)
        return
      }
      this.planData.spec.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditSpec(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.planSpecModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      this.selectedIndex = null
      this.$refs.planSpecModal.getData(resolveData)
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除規格',
        text: `你確定要刪除規格 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.planData.spec.splice(index, 1)
        }
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetPlanData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      if (this.planData.description.length > this.maxChar) {
        this.useAlertToast(false, '說明字數超過上限')
        return
      }

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '方案名稱',
        type: '分類代號',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.planData[Object.keys(requiredFields)[i]] === null || this.planData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const resolveSpec = {}
      this.planData.spec.forEach(item => {
        resolveSpec[item.key] = item.value
      })
      const resolveData = {
        id: this.planItem.id,
        data: {
          name: this.planData.name,
          spec: resolveSpec,
        },
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setPlanUpdate(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetPlanData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getDeviceTypeData()
      const resolveData = {
        ...this.syncObject(this.blankPlanPrice, this.planItem),
      }
      const resolveSpec = this.planData.spec ? Object.entries(this.planItem.spec).map(([key, value]) => ({ value, key })) : []
      resolveData.spec = resolveSpec
      this.planData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
      typeOptions,
    } = usePlanSetting()

    const {
      planData,
      deviceTypeOptions,
      searchType,
      isLoadingBusy,
      setPlanUpdate,
      getDeviceTypeData,
    } = usePlanList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPlanPrice = {
      id: null,
      name: null,
      type: null,
      description: '',
      spec: [],
    }

    const blankSetting = {
      value: null,
      key: null,
    }

    planData.value = JSON.parse(JSON.stringify(blankPlanPrice))

    const resetPlanData = () => {
      planData.value = JSON.parse(JSON.stringify(blankPlanPrice))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPlanData)

    return {
      planData,
      deviceTypeOptions,
      searchType,
      blankPlanPrice,
      blankSetting,
      isLoadingBusy,
      setPlanUpdate,
      getDeviceTypeData,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      typeOptions,

      resetPlanData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
