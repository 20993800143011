<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯商品
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 方案 -->
        <b-form-group label-for="plan_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                方案
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="productData.plan_id"
            :options="planTypeOptions"
            :reduce="option => option.id"
            :clearable="false"
            label="name"
            class="edit-col-select"
            placeholder="請選擇方案"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="font-weight-bold d-block text-nowrap mb-0">
                {{ option.name }}<small class="text-muted"> ( {{ resolvePlanMainType(option.type) }} )</small>
              </div>
            </template>

            <template #selected-option="option">
              <div class="font-weight-bold d-block text-nowrap mb-0">
                {{ option.name }}<small class="text-muted"> ( {{ resolvePlanMainType(option.type) }} )</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 名稱 -->
        <validation-provider
          #default="validationContext"
          name="商品名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  商品名稱
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>
            <b-form-input
              id="name"
              v-model="productData.name"
              :state="getValidationState(validationContext)"
              trim
              autofocus
              placeholder="請輸入商品名稱"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <!-- 上架狀態 -->
            <b-form-group
              label="上架狀態"
              label-for="display"
            >
              <v-select
                v-model="productData.display"
                :options="displayOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 身分驗證 -->
            <b-form-group
              label="身分驗證"
              label-for="require_kyc"
            >
              <v-select
                v-model="productData.require_kyc"
                :options="kycRequiredOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 售價 -->
            <validation-provider
              #default="validationContext"
              name="售價"
              rules="required"
            >
              <b-form-group label-for="price">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      售價
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="price"
                  v-model="productData.price"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  placeholder="請輸入商品售價"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 廣告區塊 -->
            <b-form-group
              label="廣告區塊"
              label-for="display_group"
            >
              <v-select
                v-model="productData.display_group"
                :options="displayGroupOptions"
                :reduce="option => option.key"
                label="name"
                class="edit-col-select"
                :disabled="!productData.branch_id"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useProductList, useProductSetting, useProductView } from '../../useProduct'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      isBusy: false,
      required,
    }
  },
  computed: {
    productItem() {
      return this.$store.state['admin-product'].productDataInfo
    },
    planTypeOptions() {
      return this.planOptions.filter(item => item.type === this.productItem.type)
    },
  },
  methods: {
    // (檢查)商品類型
    resolvePlanMainType(type) {
      const typeDictionary = {
        host: '實體主機',
        vps: '雲端主機',
        web: '虛擬主機',
      }
      if (typeDictionary[type]) return typeDictionary[type]
      return type
    },

    // (檢查)是否為主要商品類型
    isProductMainType(type) {
      return this.$store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetProductData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setProductUpdate({
              id: this.productItem.id,
              data: {
                ...this.productData,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetProductData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getPlanData()
      const resolveData = {
        ...this.syncObject(this.blankProductData, this.productItem),
      }
      const branchtId = this.productItem.branch_id
      if (branchtId) this.getDisplayGroupData(branchtId)
      this.productData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
      kycRequiredOptions,
      displayOptions,
    } = useProductSetting()

    const {
      productData,
      setProductUpdate,
      getDisplayGroupData,
      displayGroupOptions,
    } = useProductList()

    const {
      getPlanData,
      planOptions,
    } = useProductView()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankProductData = {
      name: null,
      price: 0,
      display: false,
      branch_id: null,
      plan_id: null,
      require_kyc: false,
      display_group: null, // 版塊
    }

    productData.value = JSON.parse(JSON.stringify(blankProductData))

    const resetProductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    return {
      productData,
      blankProductData,
      setProductUpdate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      kycRequiredOptions,
      displayOptions,
      displayGroupOptions,
      getDisplayGroupData,
      getPlanData,
      planOptions,

      resetProductData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.required-key {
  color: red;
  margin-top: 3px;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
