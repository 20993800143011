<template>
  <div>
    <b-modal
      :id="configValueId"
      no-close-on-backdrop
      ok-title="確認"
      centered
      ok-only
      @ok="handleOk"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ selectedIndex !== null ? '編輯' : '新增' }}參數
        </h4>
      </template>

      <div v-if="formatRuleData.name && formatRuleData.key">
        <b-row>
          <!-- 參數名稱 -->
          <b-col
            cols="12"
            lg="7"
          >
            <b-form-group
              :label="formatRuleData.name"
              label-for="config-label"
            >
              <b-form-input
                id="config-label"
                v-model="ruleData.name"
                name="config-label"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- 參數 -->
          <b-col
            cols="12"
            lg="5"
          >
            <b-form-group
              :label="formatRuleData.key"
              label-for="config-key"
            >
              <b-form-input
                id="config-key"
                v-model="ruleData.key"
                name="config-key"
                trim
                placeholder="key"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-for="(config, index) in filterConfigFormat()"
          :key="`config-${index + 1}`"
        >
          <b-form-group
            :label="config.label"
            :label-for="`config-${config.key}`"
          >
            <b-form-input
              :id="`config-${config.key}`"
              v-model="ruleData[config.key]"
              :name="`config-${config.key}`"
              trim
            />
          </b-form-group>
        </div>
      </div>

      <div v-else>
        <div
          v-for="(config, index) in configFormat"
          :key="`config-${index + 1}`"
        >
          <b-form-group
            :label="config.label"
            :label-for="`config-${config.key}`"
          >
            <b-form-input
              :id="`config-${config.key}`"
              v-model="ruleData[config.key]"
              :name="`config-${config.key}`"
              trim
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, VBTooltip, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { useAlert } from '@/libs/mixins/index'
import { useConfigSetting } from '../../useConfig'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    configValueId: {
      type: String,
      default: 'config-value-modal',
    },
    configFormat: {
      type: Array,
      default: () => [],
    },
    configArray: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  methods: {
    filterConfigFormat() {
      return this.configFormat.filter(item => item.key !== 'name' && item.key !== 'key')
    },
    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const requiredFields = {
        ...this.formatRuleData,
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.ruleData[Object.keys(requiredFields)[i]] === null || this.ruleData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.$nextTick(() => {
        if (this.selectedIndex === null && this.formatRuleData.key) {
          const sameKey = this.configArray.some(item => item.key === this.ruleData.key)
          if (sameKey) {
            this.useAlertToast(false, 'KEY值不可重複')
            return
          }
        } else if (this.formatRuleData.key) {
          const sameKey = this.configArray.some((item, index) => index !== this.selectedIndex && item.key === this.ruleData.key)
          if (sameKey) {
            this.useAlertToast(false, 'KEY值不可重複')
            return
          }
        }
        this.$emit('edit-rule', this.ruleData)
        this.$bvModal.hide(this.configValueId)
      })
    },

    // (取得)編輯資料
    getData(rule) {
      const resultBlank = {}
      const resultFormat = {}
      this.configFormat.forEach(({ label, key }) => {
        resultBlank[key] = null
        resultFormat[key] = label
      })

      this.blankRuleData = resultBlank
      this.formatRuleData = resultFormat

      const resolveData = {
        ...this.syncObject(this.blankRuleData, rule),
      }

      this.ruleData = resolveData
      this.$bvModal.show(this.configValueId)
    },
  },
  setup() {
    const blankRuleData = ref({})
    const formatRuleData = ref({})
    const ruleData = ref(JSON.parse(JSON.stringify(blankRuleData)))

    const resetRuleData = () => {
      ruleData.value = JSON.parse(JSON.stringify(blankRuleData))
    }

    const {
      syncObject,
    } = useConfigSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ruleData,
      blankRuleData,
      formatRuleData,
      resetRuleData,

      syncObject,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss" scoped>
.model-header {
  padding: 5px 0;
}
</style>
