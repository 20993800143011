<template>
  <div>
    <b-modal
      :id="propertiesSpecId"
      no-close-on-backdrop
      ok-title="確認"
      centered
      ok-only
      @ok="handleOk"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ selectedIndex !== null ? '編輯' : '新增' }}規格
        </h4>
      </template>

      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="類別"
              label-for="type"
            >
              <div class="d-flex align-items-center">
                <v-select
                  v-model="ruleData.type"
                  :options="!isSlot ?
                    [
                      { name: '元件', value: 'component' },
                      { name: '接點', value: 'slot' }] :
                    [
                      { name: '接點', value: 'slot' }
                    ]"
                  :reduce="option => option.value"
                  :clearable="false"
                  class="edit-col-select w-100 mr-1"
                  label="name"
                  placeholder="請選擇類型"
                  @input="() => {
                    ruleData.key = null
                    ruleData.label = null
                  }"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>

                <toggle-button
                  v-model="ruleData.required"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="75"
                  :height="30"
                  :labels="{checked: '必填', unchecked: '非必填'}"
                  :sync="true"
                />
              </div>
            </b-form-group>
          </b-col>

          <!-- 元件 -->
          <b-col cols="12">
            <b-form-group
              :label="ruleData.type === 'slot' ? '接點' : '元件'"
              label-for="key"
            >
              <v-select
                v-model="ruleData.key"
                :options="filiterDeviceTypeOptions"
                :reduce="option => option.type"
                :clearable="false"
                class="edit-col-select"
                label="name"
                :placeholder="`請選擇${ruleData.type === 'component' ? '元件' : '接點類型'}`"
                @input="selectedComponent"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div>
                    <div class="font-weight-bold d-block text-nowrap mb-0">
                      {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                    </div>
                  </div>
                </template>

                <template #selected-option="option">
                  <div>
                    <div class="font-weight-bold d-block text-nowrap mb-0">
                      {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 數量限制 -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="數量限制"
              label-for="limit"
            >
              <v-select
                v-model="ruleData.limit"
                :options="[
                  { name: '限制', value: true },
                  { name: '不限', value: false }
                ]"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
                label="name"
                placeholder="數量限制"
                @input="() => { ruleData.number = 0 }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 數量 -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="數量"
              label-for="number"
            >
              <b-form-input
                id="plan-value"
                v-model="ruleData.number"
                name="number"
                type="number"
                :disabled="!ruleData.limit"
                placeholder="限制數量"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, VBTooltip, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ToggleButton } from 'vue-js-toggle-button'
import { useAlert } from '@/libs/mixins/index'
import { useDeviceSetting } from '../../useDevice'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ToggleButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    propertiesSpecId: {
      type: String,
      default: 'properties-spec-modal',
    },
    deviceTypeOptions: {
      type: Array,
      default: () => [],
    },
    propertiesArray: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number || null,
      default: null,
    },
    isSlot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  computed: {
    filiterDeviceTypeOptions() {
      const options = []
      if (this.isSlot || this.ruleData.type === 'slot') {
        this.deviceTypeOptions.forEach(item => {
          if (item.slot) {
            options.push({
              id: item.id,
              type: item.type,
              name: item.name,
            })
          }
        })
      } else {
        this.deviceTypeOptions.forEach(item => {
          if (!item.slot) {
            options.push({
              id: item.id,
              type: item.type,
              name: item.name,
            })
          }
        })
      }
      return options
    },
  },
  methods: {
    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const requiredFields = {
        label: '名稱',
        key: '元件',
      }

      if (this.ruleData.limit) {
        requiredFields.number = '數量'
        if (!this.ruleData.number) {
          this.ruleData.number = 0
        }
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.ruleData[Object.keys(requiredFields)[i]] === null || this.ruleData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.$nextTick(() => {
        if (this.selectedIndex === null) {
          const sameKey = this.propertiesArray.some(item => item.key === this.ruleData.key && item.type === this.ruleData.type && item.required === this.ruleData.required)
          if (sameKey) {
            this.useAlertToast(false, '元件類型不可重複')
            return
          }
        } else {
          const sameKey = this.propertiesArray.some((item, index) => index !== this.selectedIndex && item.key === this.ruleData.key && item.type === this.ruleData.type && item.required === this.ruleData.required)
          if (sameKey) {
            this.useAlertToast(false, '元件類型不可重複')
            return
          }
        }
        this.$emit('edit-rule', this.ruleData)
        this.$bvModal.hide(this.propertiesSpecId)
      })
    },

    // (選擇)元件
    selectedComponent(type) {
      const selectedDeviceType = this.deviceTypeOptions.find(item => item.type === type)
      if (selectedDeviceType) {
        this.ruleData.label = selectedDeviceType.name
      }
    },

    // (取得)編輯資料
    getData(rule) {
      const resolveData = {
        ...this.syncObject(this.blankRuleData, rule),
      }

      this.ruleData = resolveData

      if (resolveData.key) {
        this.selectedComponent(resolveData.key)
      }
      this.$bvModal.show(this.propertiesSpecId)
    },
  },
  setup() {
    const blankRuleData = {
      type: 'component', // slot
      label: null,
      key: null,
      required: false,
      limit: false,
      number: 0,
    }

    const ruleData = ref(JSON.parse(JSON.stringify(blankRuleData)))

    const resetRuleData = () => {
      ruleData.value = JSON.parse(JSON.stringify(blankRuleData))
    }

    const {
      syncObject,
      getMetaListData,
    } = useDeviceSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ruleData,
      blankRuleData,
      resetRuleData,

      syncObject,
      getMetaListData,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  padding: 5px 0;
}
</style>
