import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const usePlanSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const typeOptions = [
    { label: '主要商品', value: 'main' },
    { label: '附加商品', value: 'sub' },
  ]

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  const ui = {}

  return {
    ui,
    typeOptions,
    getMetaListData,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const usePlanList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    getMetaListData,
  } = usePlanSetting()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    // {
    //   label: '類別', key: 'type', sortable: true, searchable: true, select: true,
    // },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '庫存總數', key: 'machine_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '商品總數', key: 'product_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const planData = ref(null)
  const searchType = ref(null)
  const deviceTypeOptions = ref([])
  const blankPlanData = {
    id: null,
    type: null,
    name: null,
    spec: {},
    machine_total: 0,
    product_total: 0,
    description: '',
    created_at: null,
    updated_at: null,
  }

  const blankDeviceTypeData = {
    id: null,
    name: null,
    type: null,
  }

  const setPlanDelete = (...arg) => store.dispatch('admin-plan/setPlanDelete', ...arg)
  const setPlanCreate = (...arg) => store.dispatch('admin-plan/setPlanCreate', ...arg)
  const setPlanUpdate = (...arg) => store.dispatch('admin-plan/setPlanUpdate', ...arg)

  const getDeviceTypeData = () => {
    getMetaListData({ key: 'deviceType', queryParams: { noPagination: true } }) //
      .then(response => {
        const { data } = response.data.data
        deviceTypeOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankDeviceTypeData, item),
          }
          return resolve
        })
      })
  }

  const getPlanListData = (ctx, callback) => {
    store.dispatch('admin-plan/getPlanList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankPlanData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankPlanData,
    refetchData,
    refetchTable,
    planData,
    searchType,
    deviceTypeOptions,

    setPlanDelete,
    setPlanCreate,
    setPlanUpdate,
    getPlanListData,
    getDeviceTypeData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
