<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererFolder from '@core/layouts/components/layout-content-renderer/LayoutContentRendererFolder.vue'
import LayoutContentRendererCustomer from '@core/layouts/components/layout-content-renderer/LayoutContentRendererCustomer.vue'
import LayoutContentRendererOpenAi from '@core/layouts/components/layout-content-renderer/LayoutContentRendererOpenAi.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
// import navigationList from '@/navigation'
import { clientSidebar, adminSidebar } from '@/navigation' // adminSidebar,
// import router from '@/router'
import store from '@/store'
import { usePublicData } from '@/libs/mixins/index'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererFolder,
    LayoutContentRendererCustomer,
    LayoutContentRendererOpenAi,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-openAi') return 'layout-content-renderer-open-ai'
      if (rendererType === 'sidebar-customer') return 'layout-content-renderer-customer'
      if (rendererType === 'sidebar-folder') return 'layout-content-renderer-folder'
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden, verticalSidebar,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      // visibilityStateHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)

    // visibilityStateHandler()
    // window.addEventListener('visibilitychange', visibilityStateHandler)
    // window.addEventListener('beforeunload', visibilityStateHandler('offline'))

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
      // window.removeEventListener('visibilitychange', visibilityStateHandler)
    })

    const headerGroup = {
      headerHome: {
        title: '後臺管理',
        auth: ['branch', 'customer', 'user', 'power', 'black'],
      },
      headerShop: {
        title: '商品設定',
        auth: ['payment', 'domain', 'stock', 'device', 'product', 'config'],
      },
      headerService: {
        title: '服務設定',
        auth: ['cart', 'order', 'service', 'quota'],
      },
      headerOther: {
        title: '其他設定',
        auth: ['api', 'tag', 'place', 'brand', 'meta', 'remark'],
      },
    }

    const groupKey = [
      'headerHome', 'branch', 'customer', 'user', 'power', 'black',
      'headerShop', 'payment', 'domain', 'stock', 'device', 'product', 'config',
      'headerService', 'cart', 'order', 'service', 'quota',
      'headerOther', 'api', 'tag', 'place', 'brand', 'meta', 'remark',
    ]

    const {
      home,
    } = clientSidebar()

    const sidebarData = adminSidebar()

    const adminSidebarData = groupKey.reduce((acc, key) => {
      const newResult = { ...acc }
      if (sidebarData[key] !== undefined) {
        newResult[key] = sidebarData[key]
      }
      return newResult
    }, {})

    const {
      routerGroupKey,
    } = usePublicData()

    // (是否)擁有權限
    const checkAuthAbility = (groupArray, name) => {
      // console.log('groupArray', groupArray, name)
      if (groupArray && groupArray.length <= 0) return false
      const route = groupArray.find(el => el.route_path === name)
      return route ? route.state : false
    }

    const initSidebar = () => {
      verticalSidebar.value = home
      store.dispatch('api/getTokenCheck')
        .then(response => {
          const { data } = response.data

          const resolveAuth = data.user_data.power
          const resolvePower = data.power_data

          const authArray = resolveAuth.split('.').map(id => Number(id))

          const powerOptions = resolvePower.map(item => {
            const resolve = {
              id: item.id,
              route_path: item.route_path,
              group: item.group,
              key: routerGroupKey[item.group] || null,
              state: authArray.includes(item.id),
            }
            return resolve
          })

          const groupPowerOptions = powerOptions.reduce((acc, item) => {
            if (!acc[item.key]) {
              acc[item.key] = []
            }
            acc[item.key].push(item)
            return acc
          }, {})

          const resolveAdminSidebar = []

          groupKey.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(headerGroup, key)) {
              let state = false
              headerGroup[key].auth.forEach(item => {
                const allFalse = Object.prototype.hasOwnProperty.call(groupPowerOptions, item)
                  ? groupPowerOptions[item].every(el => !el.state)
                  : true

                if (!allFalse) state = true
              })
              if (state) {
                resolveAdminSidebar.push({
                  header: headerGroup[key].title,
                })
              }
            } else if (adminSidebarData[key] && adminSidebarData[key].length > 0) {
              adminSidebarData[key].forEach(route => {
                if (route.key) {
                  const state = checkAuthAbility(Object.prototype.hasOwnProperty.call(groupPowerOptions, key) ? groupPowerOptions[key] : [], route.key)
                  if (state) resolveAdminSidebar.push(route)
                }
                if (route.children) {
                  const resolveRoute = {
                    ...route,
                    children: [],
                  }

                  route.children.forEach(link => {
                    if (link.key) {
                      const state = checkAuthAbility(Object.prototype.hasOwnProperty.call(groupPowerOptions, key) ? groupPowerOptions[key] : [], link.key)
                      if (state) resolveRoute.children.push(link)
                    } else resolveRoute.children.push(link)
                  })

                  if (resolveRoute.children.length > 0) resolveAdminSidebar.push(resolveRoute)
                }
              })
            }
          })

          if (resolveAdminSidebar.length > 0) {
            resolveAdminSidebar.forEach(item => {
              verticalSidebar.value.push(item)
            })
          }
        })
    }

    initSidebar()

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
