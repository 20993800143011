<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯機器
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- 名稱 -->
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="machineData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入機器名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- 機器類型 -->
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label-for="state">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    機器類型
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <v-select
                v-model="machineData.own"
                :options="ownOptions"
                :clearable="false"
                :reduce="option => option.value"
                disabled
                placeholder="請選擇機器類型"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 狀態 -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="state">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    狀態
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <v-select
                v-if="machineData.state !== 1"
                v-model="machineData.state"
                :options="stateOptions.filter(option => option.value !== 1)"
                :clearable="false"
                :reduce="option => option.value"
                placeholder="請選擇狀態"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>

              <v-select
                v-if="machineData.state === 1"
                v-model="machineData.state"
                :options="stateOptions"
                :clearable="false"
                :reduce="option => option.value"
                disabled
                placeholder="請選擇狀態"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 綁定機器 -->
          <b-col
            cols="12"
            lg="12"
          >
            <b-form-group label-for="main_device_id">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    機器綁定
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <div class="d-flex align-items-center w-100 border p-2 border-radius">
                <div class="mr-50">
                  <VueLoadImage
                    :use-loading="true"
                    :use-failed="true"
                    :blank-height="'32px'"
                    :loading-width="30"
                    :loading-height="30"
                    :failed-width="32"
                    failed-image="/dashboard/admin/images/deviceType/Component.svg"
                    class="mr-50"
                  >
                    <b-img
                      slot="image"
                      :src="`/dashboard/admin/images/deviceType/${machineData.own ? 'DedicatedServer' : 'ForeignServer'}.svg`"
                      height="32"
                      width="32"
                      class="icon-iamge"
                    />
                  </VueLoadImage>
                </div>
                <div
                  v-if="deviceData.id"
                  class="profile-user-info"
                >
                  <h6 class="mb-0">
                    <span class="mr-50">{{ deviceData.name }}</span>
                  </h6>

                  <small
                    class="text-muted"
                    style="word-break: break-all"
                  > {{ deviceData.serialNumber }}</small>
                </div>

                <div v-else>
                  <h6 class="mb-0">
                    <span class="mr-50">綁定機器</span>
                  </h6>
                </div>
              </div>

            </b-form-group>
          </b-col>

          <!-- 類別 -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="type">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    類別
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <v-select
                v-model="machineData.type"
                :options="[
                  { label: '實體主機', value: 'host' },
                  { label: '雲端主機', value: 'vps' },
                  { label: '虛擬主機', value: 'web' },
                ]"
                :clearable="false"
                :reduce="option => option.value"
                placeholder="請選擇類別"
                :disabled="machineData.state === 1"
                @input="() => { machineData.plan_id = null }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 方案 -->
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label-for="plan_id">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">方案</label>
                </div>
              </template>

              <v-select
                v-model="machineData.plan_id"
                :options="planOptions.filter(option => option.type === machineData.type)"
                :reduce="option => option.id"
                label="name"
                :disabled="machineData.state === 1"
                placeholder="請選擇方案"
                @input="changeBindPlan"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div
        v-if="machineData.plan_id && specArray.length > 0"
        class="mt-1"
      >
        <div class="d-flex align-items-center justify-content-between font-weight-bold">
          <div>
            <feather-icon
              icon="BoxIcon"
              class="mr-50"
              style="margin-top: -3px"
            />

            <span style="font-size: '16px'">方案規格</span>
          </div>
        </div>

        <hr class="mt-50">

        <div class="list-group">
          <div
            v-for="(element, index) in specArray"
            :key="`spec-${index}`"
            class="list-group-item"
          >
            <div>
              <!-- 名稱 -->
              <div class="font-weight-bold cursor-pointer">
                {{ resolveDeviceTypeName(element.key, index) }}
                <small v-if="element.key">( {{ element.key }} )</small>
              </div>

              <!-- 預設 -->
              <div v-if="element.value">
                <small class="text-muted d-flex">
                  <div class="item-description">{{ element.value }}</div>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BImg,
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import { useMachineSetting, useMachineList } from '../useMachine'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueLoadImage,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  props: {
    machineItem: {
      type: Object,
      required: true,
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
    deviceTypeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // (檢查)是否為主要商品類型
    isProductMainType(type) {
      return this.$store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)
    },

    // (檢查)主要商品類型
    resolvePlanMainType(type) {
      const isMain = this.isProductMainType(type)
      const typeDictionary = {
        host: '實體主機',
        vps: '雲端主機',
        web: '虛擬主機',
      }
      if (isMain) return typeDictionary[type]
      return '其他'
    },

    // (獲取)裝置類型資料
    resolveDeviceTypeName(value, index) {
      const name = `規格 ${index + 1}`
      const resolveData = this.deviceTypeOptions.find(item => item.type === value)
      return resolveData ? resolveData.name : name
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetMachineData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
        state: '狀態',
        type: '類別',
        own: '機器類型',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.machineData[Object.keys(requiredFields)[i]] === null || this.machineData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const resoloveData = {
        name: this.machineData.name,
      }

      if (this.machineData.state !== 1) {
        resoloveData.state = this.machineData.state
        resoloveData.plan_id = this.machineData.plan_id
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setMachineUpdate({
              id: this.machineItem.id,
              data: {
                ...resoloveData,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetMachineData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (切換)方案
    changeBindPlan(planId) {
      this.specArray = []
      const selectedPlan = this.planOptions.find(item => item.id === planId)
      if (selectedPlan && selectedPlan.spec) {
        this.specArray = Object.entries(selectedPlan.spec).map(([key, value]) => ({ value, key }))
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankMachineData, this.machineItem),
      }
      if (this.machineItem.plan_id) this.changeBindPlan(this.machineItem.plan_id)
      if (this.machineItem.main_device_id) {
        this.deviceData = this.syncObject(this.blankDeviceInfoData, this.machineItem.main_device_info)
      }
      this.machineData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      ui,
      syncObject,
      stateOptions,
      ownOptions,
    } = useMachineSetting()

    const {
      setMachineUpdate,
    } = useMachineList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const machineData = ref({})
    const deviceData = ref(null)
    const specArray = ref([])

    const blankMachineData = {
      name: null,
      state: 0,
      type: 'host',
      own: true,
      plan_id: null,
      main_device_id: null,
    }

    const blankSetting = {
      value: null,
      key: null,
    }

    const blankDeviceInfoData = {
      id: null,
      name: null,
      serialNumber: null,
    }

    machineData.value = JSON.parse(JSON.stringify(blankMachineData))

    const resetMachineData = () => {
      machineData.value = JSON.parse(JSON.stringify(blankMachineData))
      deviceData.value = JSON.parse(JSON.stringify(blankDeviceInfoData))
      specArray.value = []
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMachineData)

    return {
      machineData,
      deviceData,
      specArray,
      blankMachineData,
      blankDeviceInfoData,
      blankSetting,
      setMachineUpdate,
      useAlertToast,
      useHttpCodeAlert,

      resetMachineData,
      refFormObserver,
      getValidationState,
      resetForm,
      ui,
      stateOptions,
      ownOptions,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}
</style>
