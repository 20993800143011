import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useStockSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {}

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useStockList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  // const {
  //   getMetaListData,
  // } = useStockSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '類型', key: 'device_type', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '數量', key: 'quantity', sortable: true, searchable: true, select: true,
    },
    {
      label: '成本', key: 'cost', sortable: true, searchable: true, select: true,
    },
    {
      label: '入庫', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const searchState = ref(null)
  const searchDeviceType = ref(null)
  const deviceTypeOptions = ref([])
  const brandOptions = ref([])
  const placeOptions = ref([])

  const blankDeviceTypeInfoData = {
    id: null,
    name: null,
    type: null,
    slot: false,
  }

  const blankStockData = {
    id: null,
    name: null,
    serialNumber: null,
    device_type: null,
    quantity: 0,
    cost: 0,
    state: false, // 是否啟用
    description: '',
    created_at: null,
    updated_at: null,
    remark_display: false,
  }

  const blankBaseData = {
    id: null,
    name: null,
  }

  const setStockCreate = (...arg) => store.dispatch('admin-stock/setStockCreate', ...arg)
  const setStockUpdate = (...arg) => store.dispatch('admin-stock/setStockUpdate', ...arg)
  const setStockDelete = (...arg) => store.dispatch('admin-stock/setStockDelete', ...arg)
  const setStockToDevice = (...arg) => store.dispatch('admin-stock/setStockToDevice', ...arg)
  const setStockCancelDevice = (...arg) => store.dispatch('admin-stock/setStockCancelDevice', ...arg)

  const getStockListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value}`
    }

    if (searchDeviceType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};device_type: ${searchDeviceType.value}`
      } else resolveFilters = `device_type: ${searchDeviceType.value}`
    }
    store.dispatch('admin-stock/getStockList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankStockData, item),
          }
          return resolve
        })

        brandOptions.value = option.brand.map(item => {
          const resolve = {
            ...syncObject(blankBaseData, item),
          }
          return resolve
        })

        placeOptions.value = option.place.map(item => {
          const resolve = {
            ...syncObject(blankBaseData, item),
          }
          return resolve
        })

        deviceTypeOptions.value = option.deviceType.map(item => {
          const resolve = {
            ...syncObject(blankDeviceTypeInfoData, item),
          }
          return resolve
        }).filter(f => !f.slot)

        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchDeviceType, searchState], () => {
    refetchData()
  })

  return {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    searchState,
    searchDeviceType,
    deviceTypeOptions,
    placeOptions,
    brandOptions,
    blankStockData,
    blankDeviceTypeInfoData,
    refetchData,
    refetchTable,

    setStockCreate,
    setStockUpdate,
    setStockDelete,
    setStockToDevice,
    setStockCancelDevice,
    getStockListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
