<template>
  <div>
    <!-- 頁面: 商品列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('product', 'API.Admin.Product')">
      <!-- 新增商品 -->
      <product-list-add-modal
        ref="productAddModal"
        :plan-options="planOptions"
        :branch-options="branchOptions"
        @refetch-data="refetchData"
      />

      <!-- 網域商品編輯 -->
      <product-list-edit-modal
        v-if="selected"
        ref="productEditModal"
        :product-item="selected"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      />

      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="5"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>上架狀態</label>
              <v-select
                v-model="searchDisplay"
                :options="displayOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="7"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>方案</label>
              <v-select
                v-model="searchPlan"
                :options="planOptions"
                :reduce="option => option.id"
                label="name"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ resolvePlanMainType(option.type) }} )</small>
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ resolvePlanMainType(option.type) }} )</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="6"
              sm="12"
              class="mb-lg-0 mb-1"
            >
              <label>分站</label>
              <v-select
                v-model="searchBranch"
                :options="branchOptions"
                :reduce="option => option.id"
                label="name"
                class="edit-col-select"
                @input="value => {
                  getDisplayGroupData(value)
                  searchDisplayGroup = null
                }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div>
                    <div
                      v-if="option.name"
                      class="font-weight-bold d-block text-nowrap mb-0"
                    >
                      {{ option.name }}<small class="text-muted">( {{ option.branch }} )</small>
                    </div>

                    <div v-else>
                      {{ option.branch }}
                    </div>
                  </div>
                </template>

                <template #selected-option="option">
                  <div>
                    <div
                      v-if="option.name"
                      class="font-weight-bold d-block text-nowrap mb-0"
                    >
                      {{ option.name }}<small class="text-muted">( {{ option.branch }} )</small>
                    </div>

                    <div v-else>
                      {{ option.branch }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              class="mb-lg-0 mb-1"
            >
              <label>廣告區塊</label>
              <v-select
                v-model="searchDisplayGroup"
                :options="displayGroupOptions"
                :reduce="option => option.key"
                label="name"
                class="edit-col-select"
                :disabled="!searchBranch"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div>
                    <div
                      v-if="option.name"
                      class="font-weight-bold d-block text-nowrap mb-0"
                    >
                      {{ option.name }}<small class="text-muted"> ( {{ option.key }} )</small>
                    </div>

                    <div v-else>
                      {{ option.key }}
                    </div>
                  </div>
                </template>

                <template #selected-option="option">
                  <div>
                    <div
                      v-if="option.name"
                      class="font-weight-bold d-block text-nowrap mb-0"
                    >
                      {{ option.name }}<small class="text-muted"> ( {{ option.key }} )</small>
                    </div>

                    <div v-else>
                      {{ option.key }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <!-- 商品列表 -->
      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                商品列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('product', 'API.Admin.Product.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getProductListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 上架 -->
          <template #cell(display)="data">
            <div class="table-col">
              <div
                v-if="data.item.display"
                v-b-tooltip.hover.focus.v-secondary
                title="上架中"
                class="state-dot dot-green"
                @click="onSubmitUpdate(data.item)"
              />
              <div
                v-else
                v-b-tooltip.hover.focus.v-secondary
                title="未啟用"
                class="state-dot dot-gray"
                @click="onSubmitUpdate(data.item)"
              />
            </div>
          </template>

          <!-- 欄位: 分站 -->
          <template #cell(branch_id)="data">
            <div
              class="d-block text-nowrap show-text table-point-link"
              @click="linkBranchInfo(data.item)"
            >
              <span v-if="data.item.branch_info && data.item.branch_info.name">
                <div>{{ data.item.branch_info.name }}</div>
                <small class="text-muted">
                  {{ resolveDisplayGroupType(data.item) }}
                </small>
              </span>
              <span v-else>
                {{ data.item.branch_info.branch }}
              </span>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-col text-select">
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <div>
                  <b-link
                    class="font-weight-bold d-block text-nowrap show-text"
                    @click="onSubmitUpdate(data.item)"
                  >
                    {{ data.item.name }}
                  </b-link>

                </div>

                <b-link
                  v-if="checkAuthAbility('product', 'API.Admin.Product.Id')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="商品詳情"
                  class="selection-btn-icon-show"
                  @click="linkProductPriceSetting(data.item)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/external-link.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </b-link>
              </div>
            </div>
          </template>

          <!-- 欄位: 方案 -->
          <template #cell(plan)="data">
            <div class="d-block text-nowrap show-text">
              <div v-if="data.item.plan_info && data.item.plan_info.name">
                <div>{{ data.item.plan_info.name }}</div>
                <small class="text-muted">
                  {{ resolvePlanMainType(data.item.type) }}
                </small>
              </div>
              <div v-else>
                {{ data.item.plan_info.name }}
              </div>
            </div>
          </template>

          <!-- 欄位: 身分驗證 -->
          <template #cell(require_kyc)="data">
            <div class="table-col">
              <div class="kyc-state">
                <b-img
                  rounded
                  fluid
                  class="kyc-state-image"
                  :src="require('@/assets/images/pages/customer/id-card.svg')"
                />

                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  :title="ui.kycRequired[data.item.require_kyc]"
                  rounded
                  fluid
                  class="kyc-state-type"
                  :src="`/dashboard/admin/images/common/${data.item.require_kyc ? 'check' : 'cross'}.svg`"
                />
              </div>
            </div>
          </template>

          <!-- 欄位: 售價 -->
          <template #cell(price)="data">
            <div class="table-col text-nowrap">
              <span>{{ data.item.price ? `$ ${parseInt(data.item.price).toLocaleString()}` : '---' }}</span>
            </div>
          </template>

          <!-- 欄位: 庫存總數 -->
          <template #cell(machine_total)="data">
            <div class="table-col text-nowrap">
              <span>
                {{ data.item.plan_info.machine_total ? `${parseInt(data.item.plan_info.machine_total, 10).toLocaleString()}` : '---' }}
              </span>
            </div>
          </template>

          <!-- 欄位: 創建時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 更新時間 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.updated_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('product', 'API.Admin.Product.Create')"
                class="actions-link-btn mr-25"
                @click="onSubmitCopy(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="複製"
                  src="/dashboard/admin/images/table/copy.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('product', 'API.Admin.Product.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('product', 'API.Admin.Product.Delete')"
                class="actions-link-btn"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/dashboard/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('product', 'API.Admin.Product.Id')"
                class="actions-link-btn mr-25"
              >
                <b-link @click="linkProductPriceSetting(data.item)">
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="詳情"
                    src="/dashboard/admin/images/table/menu.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </b-link>
              </div>

              <!-- {{ data.item }} -->
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="m-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BLink, BInputGroupAppend, BAlert,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BCardHeader,
  BInputGroup, BFormGroup, BFormCheckbox, BCardBody, BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'

import router from '@/router'
import store from '@/store'
import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import { useProductList, useProductSetting } from '../useProduct'
import useStoreModule from '../useStoreModule'
import ProductListAddModal from './ProductListAddModal.vue'
import ProductListEditModal from './ProductListEditModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BCardHeader,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BCardBody,
    BSkeletonTable,
    BInputGroupAppend,

    vSelect,
    TableTimeRange,
    ProductListAddModal,
    ProductListEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      selected: null,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
    adminProductState() {
      return this.$store.state['admin-product']
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (前往)分站詳情
    linkBranchInfo(item) {
      const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-branch-view', params: { id: item.branch_id } })
    },

    // (前往)價格設定
    linkProductPriceSetting(item) {
      const auth = this.checkAuthAbility('product', 'API.Admin.Product.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-product-view', params: { id: item.id } })
    },

    // (檢查)是否為主要商品類型
    isProductMainType(type) {
      return this.$store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)
    },

    // (檢查)主要商品類型
    resolvePlanMainType(type) {
      const isMain = this.isProductMainType(type)
      const typeDictionary = {
        host: '實體主機',
        vps: '雲端主機',
        web: '虛擬主機',
      }
      if (isMain) return typeDictionary[type]
      return '其他'
    },

    // (檢查)廣告位置
    resolveDisplayGroupType(item) {
      if (item.branch_id && this.metaDisplayGroup[item.branch_id]) {
        const options = this.metaDisplayGroup[item.branch_id]
        if (options && options.length) {
          const displayGroup = options.find(f => f.key === item.display_group)
          return displayGroup ? displayGroup.name : ''
        }
        return item.display_group
      }
      return ''
    },

    // (觸發)編輯
    onSubmitCopy(item) {
      const auth = this.checkAuthAbility('product', 'API.Admin.Product.Create')
      if (!auth) return
      this.$refs.productAddModal.getData(item)
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        const auth = this.checkAuthAbility('product', 'API.Admin.Product.Create')
        if (!auth) return
        this.$refs.productAddModal.getData()
        return
      }
      const auth = this.checkAuthAbility('product', 'API.Admin.Product.Update')
      if (!auth) return
      this.selected = item
      setTimeout(() => { this.$refs.productEditModal.getData() }, 200)
    },

    // (刪除)商品
    onSubmitDelete(item) {
      const auth = this.checkAuthAbility('product', 'API.Admin.Product.Delete')
      if (!auth) return
      this.useSwalAlertWarning('刪除商品', `你確定要永久刪除商品 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setProductDelete({
              id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '商品已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const PRODUCT_ADMIN_STORE_MODULE_NAME = 'admin-product'

    if (!store.hasModule(PRODUCT_ADMIN_STORE_MODULE_NAME)) store.registerModule(PRODUCT_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(PRODUCT_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_ADMIN_STORE_MODULE_NAME)
    })

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      displayOptions,
    } = useProductSetting()

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      refetchData,
      refetchTable,

      searchBranch,
      searchDisplay,
      searchPlan,
      searchDisplayGroup,
      displayGroupOptions,
      planOptions,
      branchOptions,
      metaDisplayGroup,

      setProductDelete,
      getProductListData,
      getDisplayGroupData,
      useAlertToast,
      useHttpCodeAlert,
    } = useProductList()

    return {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      displayOptions,

      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      refetchData,
      refetchTable,

      searchBranch,
      searchDisplay,
      searchPlan,
      searchDisplayGroup,
      displayGroupOptions,
      planOptions,
      branchOptions,
      metaDisplayGroup,

      setProductDelete,
      getProductListData,
      getDisplayGroupData,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.table-col {
  min-width: 40px;
}

.table-title {
  min-width: 100px;
}
.server-name:hover {
  color: red !important;
  text-decoration: underline
}

.border-search {
  display: flex;
  flex-wrap: wrap;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

.fire-img {
  margin-top: -3px;
}

.nofire {
  opacity: 0.2;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.kyc-state {
  display: flex;
  align-items: center;
  .kyc-state-image {
    max-width: 24px;
    margin-right: 5px;
  }

  .kyc-state-type {
    max-width: 16px;
  }
}
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}

.alert-icon {
  position: relative;
  top: -2px;
  width: 20px;
  transform: scale(1.05);
  animation: btn-breathe .8s linear infinite;
}
</style>
