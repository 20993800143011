import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
import { useApiList } from '@/views/admin/api/mail/useApi'
// import router from '@/router'

export const useModalSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const isBusy = ref(false)

  const ui = {}

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    isBusy,

    getMetaListData,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useRemarkModal = () => {
  const ui = {}
  const {
    syncObject,
  } = useCommenSettings()

  const {
    totalNum,
    currentPage,
    perPage,
    searchQuery,
    timeRange,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const setRemarkCreate = (...arg) => store.dispatch('api/setRemarkCreate', ...arg)
  const setRemarkUpdateContent = (...arg) => store.dispatch('api/setRemarkUpdateContent', ...arg)
  const setRemarkUpdateDisplay = (...arg) => store.dispatch('api/setRemarkUpdateDisplay', ...arg)
  const setRemarkClearDisplay = (...arg) => store.dispatch('api/setRemarkClearDisplay', ...arg)
  const setRemarkDelete = (...arg) => store.dispatch('api/setRemarkDelete', ...arg)

  perPage.value = 10

  const inputRemarkTextarea = ref(null)
  const isBusy = ref(false)
  const isInitBusy = ref(true)
  const isLoadingBusy = ref(false)
  const inputRemark = ref('')
  const remarkList = ref([])
  const filterSelected = ref()
  const filterOptions = ref([])
  const keySelected = ref('all')
  const keyOptions = ref([])
  const propsKey = ref([])
  const searchSelf = ref(false)
  const searchDisplay = ref(false)
  const userData = ref(null)

  const transKey = {
    customer: '會員',
    order: '訂單',
    domain: '網域服務',
    vps: 'VPS',
    host: '實體主機',
    quota: '點數紀錄',
    stock: '庫存',
    device: '元件',
    machine: '機器',
  }

  const blankUserData = {
    id: null,
    ssid: null,
    account: null,
    image: null,
    name: null,
  }

  const blankRemarkData = {
    id: null,
    key: null,
    admin: null,
    content: '',
    ip: null,
    display: false,
    updated_at: null,
    created_at: null,
    deleted_at: null,
  }

  const resolveFilterType = type => {
    if (type === 'all') {
      return {
        label: '全部',
        value: type,
        color: '#2671c7',
      }
    }
    if (type === 'customer') {
      return {
        label: '會員',
        value: type,
        color: '#b46de3',
      }
    }
    if (type === 'order') {
      return {
        label: '訂單',
        value: type,
        color: '#2eb358',
      }
    }
    if (type === 'domain') {
      return {
        label: '網域服務',
        value: type,
        color: '#8bced6',
      }
    }
    if (type === 'vps') {
      return {
        label: 'VPS',
        value: type,
        color: '#e6c019',
      }
    }
    if (type === 'host') {
      return {
        label: '實體主機',
        value: type,
        color: '#cc0836',
      }
    }
    if (type === 'quota') {
      return {
        label: '點數紀錄',
        value: type,
        color: '#ff960d',
      }
    }
    if (type === 'stock') {
      return {
        label: '庫存',
        value: type,
        color: '#7bc196',
      }
    }
    if (type === 'device') {
      return {
        label: '元件',
        value: type,
        color: '#90d6ff',
      }
    }
    if (type === 'machine') {
      return {
        label: '機器',
        value: type,
        color: '#ad9ceb',
      }
    }
    return false
  }

  const getRemarkListData = () => {
    let resolveFilters = ''

    if (propsKey.value.length > 0) {
      const resolveKey = 'key: '
      if (keySelected.value === 'all') resolveFilters = resolveKey + propsKey.value.join(', ')
      else {
        const data = propsKey.value.find(item => item.startsWith(keySelected.value))
        resolveFilters = resolveKey + data
      }
    }

    if (timeRange.value !== null) {
      resolveFilters = `${resolveFilters};created_at: ${timeRange.value}`
    }

    if (searchSelf.value) {
      resolveFilters = `${resolveFilters};admin: ${userData.value.id}`
    }

    if (searchDisplay.value) {
      resolveFilters = `${resolveFilters};display: 1`
    }

    isLoadingBusy.value = true
    store.dispatch('api/getRemarkList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      range: timeRange.value,
      filters: resolveFilters,
    })
      .then(response => {
        const { data, total } = response.data.data
        const userInfo = JSON.parse(localStorage.getItem('userData'))
        userData.value = syncObject(blankUserData, userInfo)
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankRemarkData, item),
            admin_info: item.admin && item.admin_info ? syncObject(blankUserData, item.admin_info) : syncObject(blankUserData, {}),
          }
          const [key] = resolve.key.split('-')
          resolve.label = key
          return resolve
        })
        resolveData.forEach(el => {
          if (!remarkList.value.map(item => item.id).includes(el.id)) remarkList.value.push(el)
        })
        totalNum.value = total
        isLoadingBusy.value = false
        isInitBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isInitBusy.value = false
      })
  }

  watch([currentPage], () => {
    if (!isInitBusy.value) getRemarkListData()
  })

  watch([timeRange, searchQuery, searchSelf, searchDisplay, keySelected], () => {
    if (isInitBusy.value) return
    isInitBusy.value = true
    currentPage.value = 1
    remarkList.value = []
    getRemarkListData()
  })

  return {
    ui,
    useAlertToast,
    useHttpCodeAlert,
    blankRemarkData,
    blankUserData,
    totalNum,
    currentPage,
    perPage,
    searchQuery,
    timeRange,
    inputRemarkTextarea,
    isBusy,
    transKey,
    propsKey,
    isInitBusy,
    isLoadingBusy,
    filterSelected,
    filterOptions,
    keySelected,
    keyOptions,
    inputRemark,
    remarkList,
    searchSelf,
    searchDisplay,
    userData,
    resolveFilterType,
    getRemarkListData,
    setRemarkCreate,
    setRemarkUpdateContent,
    setRemarkUpdateDisplay,
    setRemarkClearDisplay,
    setRemarkDelete,
  }
}

export const useAdminApiMailModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    blankMailData,
  } = useApiList()

  const {
    syncObject,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setAPIMailDelete = (...arg) => store.dispatch('admin-api-mail-modal/setAPIMailDelete', ...arg)
  const setAPIMailCreate = (...arg) => store.dispatch('admin-api-mail-modal/setAPIMailCreate', ...arg)
  const setAPIMailUpdate = (...arg) => store.dispatch('admin-api-mail-modal/setAPIMailUpdate', ...arg)

  const getAPIMailListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-api-mail-modal/getAPIMailList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankMailData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getAPIMailListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getAPIMailListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setAPIMailDelete,
    setAPIMailCreate,
    setAPIMailUpdate,
    getAPIMailListData,
    useHttpCodeAlert,
  }
}

export const useAdminDomainDealerModal = () => {
  const tableData = ref([])
  const domainAgentOptions = ref([])
  const isTableBusy = ref(true)
  const searchAgent = ref([])

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankDomainDealerData = {
    id: null,
    name: null,
    domain_name_agent_id: null,
  }

  const blankDomainAgent = {
    id: null,
    agent: null,
  }

  const getDomainAgentData = callback => {
    getMetaListData({ key: 'domainNameAgent', queryParams: { noPagination: true } })
      .then(response => {
        const { data } = response.data.data
        domainAgentOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankDomainAgent, item),
          }
          return resolve
        })
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        callback()
      })
  }

  const getDomainDealerListData = () => {
    isTableBusy.value = true

    getDomainAgentData(() => {
      // 整理filters
      let resolveFilters = ''

      if (searchAgent.value.length > 0) {
        const resultIds = domainAgentOptions.value.filter(item => searchAgent.value.includes(item.agent)).map(item => item.id)
        resolveFilters = `domain_name_agent_id: ${resultIds}`
      }

      const resolveQueryParams = {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        filters: resolveFilters,
        _: Date.now(),
      }

      getMetaListData({ key: 'domainNameDealer', queryParams: { ...resolveQueryParams } })
        .then(response => {
          const { data, total } = response.data.data
          const resolveData = data.map(item => {
            const resolve = {
              ...syncObject(blankDomainDealerData, item),
            }
            return resolve
          })
          tableData.value = resolveData
          totalNum.value = total
          isTableBusy.value = false
        })
        .catch(error => {
          useHttpCodeAlert(error.response)
        })
    })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getDomainDealerListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getDomainDealerListData()
  })

  return {
    tableData,
    domainAgentOptions,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchAgent,
    refetchTable,
    getDomainDealerListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useAdminBranchModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankBranchData = {
    id: null,
    name: null,
    icon: null,
    branch: null,
    payment: null,
    mail_valid_switch: false,
    phone_valid_switch: false,
  }

  perPage.value = 8

  const getBranchListData = () => {
    isTableBusy.value = true
    getMetaListData({
      key: 'branch',
      queryParams: {
        pagination: true,
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        _: Date.now(),
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankBranchData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getBranchListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getBranchListData()
  })

  return {
    blankBranchData,
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,
    getBranchListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useAdminCustomerModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const searchBranch = ref(null)

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankCustomerData = {
    id: null,
    ssid: null,
    branch_id: null,
    image: null,
    family_name: null,
    name: null,
    account: null,
    email_state: 0,
    phone_state: 0,
    kyc_state: 0,
  }

  perPage.value = 8

  const getCustomerListData = () => {
    isTableBusy.value = true
    // 整理filters
    let resolveFilters = ''

    if (searchBranch.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};branch_id: ${searchBranch.value}`
      } else resolveFilters = `branch_id: ${searchBranch.value}`
    }

    getMetaListData({
      key: 'customer',
      queryParams: {
        pagination: true,
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        filters: resolveFilters,
        _: Date.now(),
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getCustomerListData()
  }

  watch([currentPage, perPage, searchQuery, searchBranch], () => {
    getCustomerListData()
  })

  return {
    blankCustomerData,
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    searchBranch,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,
    getCustomerListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useAdminPlanModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)
  const searchMain = ref(null)

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankPlanData = {
    id: null,
    name: null,
    type: null,
  }

  perPage.value = 8

  const getPlanListData = () => {
    isTableBusy.value = true

    const queryParams = {
      pagination: true,
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    }

    if (searchMain.value !== null) {
      queryParams.main = searchMain.value
    }

    getMetaListData({
      key: 'plan',
      queryParams,
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankPlanData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getPlanListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getPlanListData()
  })

  return {
    blankPlanData,
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchMain,
    refetchTable,
    getPlanListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useAdminDeviceModal = props => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedTableData,
    isSelectState,
    isSelectedAll,
    refetchData,
  } = useTableComponent()

  perPage.value = 10

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const blankChartData = {
    series: [0],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#24c9ff'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  const resolveChartDataColor = health => {
    if (health <= 10) return '#ff5b52'
    if (health <= 50) return '#ffbb53'
    if (health < 100) return '#24c9ff'
    if (health === 100) return '#37deb2'
    return '#24c9ff'
  }

  const ui = {
    stateList: [{
      0: '可使用', 1: '使用中', 2: '待處理', 3: '送修', 4: '淘汰',
    }, {
      0: 'light-success', 1: 'light-info', 2: 'light-warning', 3: 'light-danger', 4: 'light-secondary',
    }, {
      0: 'dot-green', 1: 'dot-blue ', 2: 'dot-yellow', 3: 'dot-red', 4: 'dot-gray',
    }, {
      0: 'dot-green-selected', 1: 'dot-blue-selected', 2: 'dot-yellow-selected', 3: 'dot-red-selected', 4: 'dot-gray',
    }],
  }

  const stateOptions = [
    { label: '可使用', value: 0 },
    { label: '使用中', value: 1 },
    { label: '待處理', value: 2 },
    { label: '送修', value: 3 },
    { label: '淘汰', value: 4 },
  ]

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '類別', key: 'device_type', sortable: true, searchable: true, select: true,
    },
    {
      label: '品牌', key: 'brand_id', sortable: true, searchable: true, select: false,
    },
    {
      label: '地點', key: 'place_id', sortable: false, searchable: false, select: false,
    },
    {
      label: '設定', key: 'use', searchable: true, select: true,
    },
  ]

  const searchState = ref(null)
  const searchPlace = ref(null)
  const searchBrand = ref(null)
  const searchDeviceType = ref(null)
  const brandOptions = ref([])
  const placeOptions = ref([])
  const deviceTypeOptions = ref([])
  const isTableBusy = ref(true)

  const blankDeviceData = {
    id: null,
    name: null,
    serialNumber: null,
    state: 0,
    brand_id: null,
    device_type: null,
    health: 100,
    stock_id: null,
    place_id: null,
    spec_data: {},
    parent_id: null,
    created_at: null,
    updated_at: null,
  }

  const blankDeviceTypeInfoData = {
    id: null,
    name: null,
    type: null,
  }

  const blankPlaceInfoData = {
    id: null,
    name: null,
    description: null,
  }

  const blankBrandInfoData = {
    id: null,
    name: null,
    image: null,
  }

  if (props.setSearchState.length > 0) searchState.value = props.setSearchState.join(', ')
  if (props.setSearchDeviceType.length > 0) searchDeviceType.value = props.setSearchDeviceType.join(', ')

  const getDeviceListData = (ctx, callback) => {
    isTableBusy.value = true
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value}`
    }

    if (searchPlace.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};place_id: ${searchPlace.value}`
      } else resolveFilters = `place_id: ${searchPlace.value}`
    }

    if (searchBrand.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};brand_id: ${searchBrand.value}`
      } else resolveFilters = `brand_id: ${searchBrand.value}`
    }

    if (searchDeviceType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};device_type: ${searchDeviceType.value}`
      } else resolveFilters = `device_type: ${searchDeviceType.value}`
    }

    store.dispatch('admin-device-modal/getDeviceList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDeviceData, item),
            place_info: item.place_id && item.place_info ? syncObject(blankPlaceInfoData, item.place_info) : syncObject(blankPlaceInfoData, {}),
            brand_info: item.brand_id && item.brand_info ? syncObject(blankBrandInfoData, item.brand_info) : syncObject(blankBrandInfoData, {}),
            device_type_info: item.device_type && item.device_type_info ? syncObject(blankDeviceTypeInfoData, item.device_type_info) : syncObject(blankDeviceTypeInfoData, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })

        brandOptions.value = option.brand.map(item => {
          const resolve = {
            ...syncObject(blankBrandInfoData, item),
          }
          return resolve
        })

        deviceTypeOptions.value = option.deviceType.map(item => {
          const resolve = {
            ...syncObject(blankDeviceTypeInfoData, item),
          }
          return resolve
        })

        placeOptions.value = option.place.map(item => {
          const resolve = {
            ...syncObject(blankPlaceInfoData, item),
          }
          return resolve
        })

        callback(resolveData)
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange, searchState, searchPlace, searchBrand, searchDeviceType], () => {
    refetchData()
  })

  return {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedTableData,
    isSelectState,
    isSelectedAll,
    refetchData,
    refetchTable,
    ui,
    stateOptions,
    searchState,
    searchPlace,
    searchBrand,
    searchDeviceType,
    brandOptions,
    placeOptions,
    deviceTypeOptions,
    blankDeviceData,
    isTableBusy,

    getDeviceListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
