<template>
  <div>
    <!-- 頁面: 網域列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <!-- 匯入網域 -->
    <domain-list-add-modal
      ref="domainAddModal"
      @refetch-data="refetchData"
    />

    <!-- 網域編輯 -->
    <domain-list-edit-modal
      ref="domainEditModal"
      @refetch-data="refetchData"
    />

    <!-- 紀錄編輯 -->
    <domain-action-edit-modal
      ref="domainActionModal"
      @refetch-data="refetchData"
    />

    <b-tabs
      v-if="checkAuthAbility('service', 'API.Admin.DomainName.Service') && checkAuthAbility('service', 'API.Admin.DomainName.History')"
      v-model="tabIndex"
      pills
      lazy
    >
      <b-tab title="網域服務">
        <DomainListService
          ref="DomainListService"
          @submit-update="onSubmitUpdate"
        />
      </b-tab>

      <b-tab title="交易紀錄">
        <DomainListAction
          ref="DomainListAction"
          :tab-index="1"
          @submit-update="onSubmitUpdate"
          @submit-action="onSubmitAction"
        />
      </b-tab>
    </b-tabs>

    <div v-else>
      <DomainListService
        v-if="checkAuthAbility('service', 'API.Admin.DomainName.Service')"
        ref="DomainListService"
        @submit-update="onSubmitUpdate"
      />

      <DomainListAction
        v-if="checkAuthAbility('service', 'API.Admin.DomainName.History')"
        ref="DomainListAction"
        :tab-index="1"
        @submit-update="onSubmitUpdate"
        @submit-action="onSubmitAction"
      />
    </div>

    <div v-if="!checkAuthAbility('service', 'API.Admin.DomainName.Service') && !checkAuthAbility('service', 'API.Admin.DomainName.History')">
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import moment from 'moment'
import { useRouter } from '@core/utils/utils'
import {
  BTabs, BTab, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import useStoreModule from '../useStoreModule'
import DomainListService from './DomainListService.vue'
import DomainListAction from './DomainListAction.vue'
import DomainListAddModal from './DomainListAddModal.vue'
import DomainListEditModal from './DomainListEditModal.vue'
import DomainActionEditModal from './DomainListActionEditModal.vue'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BAlert,
    BLink,

    DomainListService,
    DomainListAction,
    DomainListAddModal,
    DomainListEditModal,
    DomainActionEditModal,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      title: this.router.currentRoute.meta.pageTitle,
    }
  },
  methods: {
    moment,
    // (刷新列表)
    refetchData() {
      if (this.tabIndex === 0) this.$refs.DomainListService.refetchTable()
      if (this.tabIndex === 1) this.$refs.DomainListAction.refetchTable()
      if (this.tabIndex === 2) this.$refs.DomainListClose.refetchTable()
    },

    // (觸發)編輯
    onSubmitAction(item) {
      setTimeout(() => { this.$refs.domainActionModal.getData(item) }, 200)
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        this.$refs.domainAddModal.getData()
        return
      }
      setTimeout(() => { this.$refs.domainEditModal.getData(item) }, 200)
    },
  },
  setup() {
    const DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME = 'admin-service-domain'

    if (!store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)
    })

    const tabIndex = ref(0)
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const actionMap = { list: 0, log: 1 }

    tabIndex.value = actionMap[routeParams.value.action] ?? 0

    watch(tabIndex, newIndex => {
      const action = Object.keys(actionMap).find(key => actionMap[key] === newIndex)
      if (action) router.push({ name: 'admin-service-domain', params: { action } })
    })

    return {
      tabIndex,
      router,
      routeParams,
    }
  },
}
</script>
