<template>
  <div>
    <!-- 頁面: 庫存列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('stock', 'API.Admin.Stock')">
      <!-- 新增類別 -->
      <stock-add-modal
        ref="stockAddModal"
        :device-type-options="deviceTypeOptions"
        @refetch-data="refetchData"
      />

      <!-- 編輯類別 -->
      <stock-edit-modal
        v-if="selected"
        ref="stockEditModal"
        :stock-item="selected"
        :device-type-options="deviceTypeOptions"
        @refetch-data="refetchData"
      />

      <!-- 入庫 -->
      <stock-in-modal
        v-if="selected"
        ref="stockDeviceModal"
        :stock-item="selected"
        :brand-options="brandOptions"
        :place-options="placeOptions"
        @refetch-data="refetchData"
      />

      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              xl="2"
              lg="3"
              md="5"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchState"
                :options="[
                  { label: '未入庫', value: false },
                  { label: '已入庫', value: true, },
                ]"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
                <!-- <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template> -->
              </v-select>
            </b-col>

            <b-col
              cols="12"
              xl="3"
              lg="4"
              md="7"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>類別</label>
              <v-select
                v-model="searchDeviceType"
                :options="deviceTypeOptions"
                :reduce="option => option.type"
                label="name"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                庫存列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('stock', 'API.Admin.Stock.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getStockListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col p-50">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-col text-select">
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <div style="line-height: 1;">
                  <div class="d-block text-nowrap selection-group d-flex align-items-end">
                    <b-link
                      class="font-weight-bold d-block text-nowrap show-text"
                    >
                      {{ data.item.name }}
                    </b-link>

                    <div
                      v-if="data.item.remark_display"
                      @click="onSubmitRemark(data.item)"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/chat-remark.svg"
                        height="20"
                        width="20"
                        class="cursor-pointer ml-25 mb-25"
                      />
                    </div>
                  </div>

                  <small class="text-muted">
                    {{ data.item.serialNumber }}
                  </small>

                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 類別 -->
          <template #cell(device_type)="data">
            <div class="table-col">
              <div class="d-flex text-nowrap">
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                  class="mr-50"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${data.item.device_type}.svg`"
                    height="32"
                    width="32"
                    class="icon-iamge"
                  />
                </VueLoadImage>

                <div class="d-block text-nowrap show-text">
                  <div v-if="transStockDeviceType(data.item.device_type)">
                    <div>{{ transStockDeviceType(data.item.device_type) }}</div>
                    <small class="text-muted">
                      {{ data.item.device_type }}
                    </small>
                  </div>
                  <small v-else>
                    ---
                  </small>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 說明 -->
          <template #cell(description)="data">
            <div
              v-b-tooltip.hover.focus.v-secondary
              :title="data.item.description"
              class="table-col item-description"
            >
              {{ data.item.description ? data.item.description : '---' }}
            </div>
          </template>

          <!-- 欄位: 數量 -->
          <template #cell(quantity)="data">
            <div class="table-col text-nowrap">
              <span>{{ data.item.quantity ? parseInt(data.item.quantity).toLocaleString() : '---' }}</span>
            </div>
          </template>

          <!-- 欄位: 售價 -->
          <template #cell(cost)="data">
            <div class="table-col text-nowrap">
              <span>{{ data.item.cost ? `$ ${parseInt(data.item.cost).toLocaleString()}` : '---' }}</span>
            </div>
          </template>

          <!-- 欄位: 入庫 -->
          <template #cell(state)="data">
            <div class="table-col">
              <div class="stock-state">
                <b-img
                  v-if="data.item.state"
                  v-b-tooltip.hover.focus.v-secondary
                  title="已入庫"
                  rounded
                  fluid
                  class="stock-state-image"
                  :src="require('@/assets/images/pages/stock/stockIn.svg')"
                  @click="onSubmitOutStock(data.item)"
                />

                <b-img
                  v-if="!data.item.state"
                  v-b-tooltip.hover.focus.v-secondary
                  title="未入庫"
                  rounded
                  fluid
                  class="stock-state-image"
                  :src="require('@/assets/images/pages/stock/stockCancel.svg')"
                  @click="onSubmitInStock(data.item)"
                />
              </div>
            </div>
          </template>

          <!-- 欄位: 上次更新 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-remark-botton-alert actions-link-btn mr-25"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="備註留言"
                  src="/dashboard/admin/images/table/chat.svg"
                  class="actions-link-btn-image"
                  rounded
                />
                <div
                  v-if="data.item.remark_display"
                  class="state-dot dot-red"
                />
              </div>

              <div
                v-if="data.item.state && checkAuthAbility('stock', 'API.Admin.Stock.In.Cancel')"
                class="actions-link-btn mr-25"
                @click="onSubmitOutStock(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="取消入庫"
                  :src="'/dashboard/admin/images/table/product.svg'"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="!data.item.state && checkAuthAbility('stock', 'API.Admin.Stock.In')"
                class="actions-link-btn mr-25"
                @click="onSubmitInStock(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="入庫"
                  :src="'/dashboard/admin/images/table/product.svg'"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="!data.item.state && checkAuthAbility('stock', 'API.Admin.Stock.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="!data.item.state && checkAuthAbility('stock', 'API.Admin.Stock.Delete')"
                class="actions-link-btn"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/dashboard/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <input
          ref="fileInputModal"
          type="file"
          hidden
          accept=".svg"
          @input="handleImageUplode($event)"
        >

      </b-card>

      <remark-modal
        ref="stockRemarkExplorerModal"
        remark-explorer-id="stockRemarkExplorerModal"
        :key-array="keyArray"
        :is-hiden-emit="true"
        :use-callback-data="false"
        @emit-hidden-function="refetchTable"
        @call-back-data="() => {}"
      >
        <template v-slot:header-info>
          <div class="mb-1" />
        </template>
      </remark-modal>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BCardBody, BCardHeader,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

import { useStockList, useStockSetting } from '../useStock'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import StockAddModal from './StockListAddModal.vue'
import StockEditModal from './StockListEditModal.vue'
import StockInModal from './StockListInModal.vue'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BCardHeader,
    BCardBody,
    BAlert,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
    StockAddModal,
    StockEditModal,
    StockInModal,
    VueLoadImage,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
      isUploadBusy: true,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (轉換)元件類型
    transStockDeviceType(type) {
      const deviceTypeName = this.deviceTypeOptions.find(item => item.type === type)
      if (deviceTypeName) return deviceTypeName.name
      return '元件'
    },

    // (觸發)備註
    onSubmitRemark(item) {
      const resolveArray = []
      resolveArray.push(`stock-${item.id}`)
      this.keyArray = resolveArray
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      setTimeout(() => { this.$refs.stockRemarkExplorerModal.getData() }, 200)
    },

    // (觸發)入庫
    onSubmitInStock(item) {
      const auth = this.checkAuthAbility('stock', 'API.Admin.Stock.In')
      if (!auth) return
      if (item.state) {
        this.useAlertToast(false, '已入庫，無法再次入庫')
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.stockDeviceModal.getData() }, 200)
    },

    // (觸發)出庫
    onSubmitOutStock(item) {
      const auth = this.checkAuthAbility('stock', 'API.Admin.Stock.In.Cancel')
      if (!auth) return
      this.useSwalAlertWarning('取消入庫', '你確定要永久移除已入庫存元件嗎?')
        .then(result => {
          if (result.value) {
            this.setStockCancelDevice({
              id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '取消成功!', '庫存已出庫')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '取消失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        const auth = this.checkAuthAbility('stock', 'API.Admin.Stock.Create')
        if (!auth) return
        this.$refs.stockAddModal.getData()
        return
      }
      const auth = this.checkAuthAbility('stock', 'API.Admin.Stock.Update')
      if (!auth) return
      if (item.state) {
        this.useAlertToast(false, '已轉換成元件，無法編輯')
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.stockEditModal.getData() }, 200)
    },

    // (刪除)庫存
    onSubmitDelete(item) {
      const auth = this.checkAuthAbility('stock', 'API.Admin.Stock.Delete')
      if (!auth) return
      if (item.state) {
        this.useAlertToast(false, '已轉換成元件，無法刪除')
        return
      }
      this.useSwalAlertWarning('刪除庫存', `你確定要永久刪除庫存 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setStockDelete({
              id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '庫存已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const STOCK_ADMIN_STORE_MODULE_NAME = 'admin-stock'

    if (!store.hasModule(STOCK_ADMIN_STORE_MODULE_NAME)) store.registerModule(STOCK_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(STOCK_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(STOCK_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      searchState,
      searchDeviceType,
      deviceTypeOptions,
      brandOptions,
      placeOptions,
      blankStockData,
      blankDeviceTypeInfoData,
      refetchData,
      refetchTable,

      setStockDelete,
      getStockListData,
      setStockToDevice,
      setStockCancelDevice,
    } = useStockList()

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useStockSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,

      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      searchState,
      searchDeviceType,
      deviceTypeOptions,
      brandOptions,
      placeOptions,
      blankStockData,
      blankDeviceTypeInfoData,
      refetchData,
      refetchTable,

      setStockDelete,
      getStockListData,
      setStockToDevice,
      setStockCancelDevice,
      dateConfig2,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.stock-state {
  .stock-state-image {
    &:hover {
      cursor: pointer;
      transition: all 0.3s ease;
      transform: scale(1.2);
    }
    max-width: 30px;
  }
}
</style>
