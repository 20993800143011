<template>
  <div>
    形象廣告
  </div>
</template>

<script>
export default {
  setup() {
  },
}
</script>
