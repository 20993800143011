<template>
  <div>
    <b-modal
      :id="remarkExplorerId"
      no-close-on-backdrop
      no-close-on-esc
      centered
      size="lg"
      header-bg-variant="primary"
      body-class="p-0"
      hide-footer
      @change="chgModalState"
      @hidden="handleHide"
      @close="handleHide"
    >
      <!-- hide-footer -->
      <!-- @change="chgModalState" -->
      <template #modal-title>
        <h4 class="m-0 model-header">
          備註留言
          <!-- {{ remarkExplorerId }} -->
        </h4>
      </template>

      <div>
        <!-- (詳情) -->
        <slot name="header-info" />

        <!-- (備註) -->
        <div class="remark-container">
          <!-- 輸入框 -->
          <b-card-body class="py-0 mb-50">
            <div class="remark-textarea">
              <div
                v-if="filterOptions.length > 1"
                class="remark-textarea-type"
              >
                <div
                  v-for="item in filterOptions"
                  :key="item.value"
                  :style="{ 'background-color': resolveFilterType(item.value).color }"
                  class="selected-option"
                  :class="{ 'selected-option-active': filterSelected == item.value }"
                  @click="() => filterSelected = item.value"
                >
                  {{ resolveFilterType(item.value).label }}
                </div>
              </div>

              <textarea
                ref="inputRemarkTextarea"
                v-model="inputRemark"
                class="w-100 site-input-area"
                :disabled="isBusy"
                placeholder="輸入備註..."
                :style="{
                  'color': !isDark ? '#000000' : '#d0d2d6',
                  'border-color': !isDark ? '#d0d2d6' : '#4b5c7c',
                }"
                @keydown="handleKeyDown"
              />
            </div>
          </b-card-body>

          <!-- 篩選器 -->
          <b-card-body class="py-0">
            <div class="remark-filter">
              <b-row>
                <!-- 顯示篩選 -->
                <b-col
                  cols="12"
                  sm="4"
                  class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
                >
                  <v-select
                    v-if="filterOptions.length > 1"
                    v-model="keySelected"
                    :options="keyOptions"
                    class="b-form-select w-100"
                    :reduce="option => option.value"
                    :clearable="false"
                    placeholder="請選擇備註類型"
                    @input="(option) => {
                      if (option !== 'all') filterSelected = option
                    }"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無類型資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.label }}
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div
                        class="selected-option d-block text-nowrap mb-0"
                        :style="{ 'color': resolveFilterType(option.value).color }"
                      >
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                </b-col>

                <!-- 搜尋框 -->
                <b-col
                  cols="12"
                  sm="8"
                >
                  <div class="text-nowrap d-flex justify-content-end">
                    <div
                      v-b-tooltip.hover.focus.v-secondary
                      title="重新整理"
                      class="d-flex align-items-center actions-link-btn ml-25"
                      @click="getData"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/refresh.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      v-if="keySelected !== 'all' && filterSelected"
                      v-b-tooltip.hover.focus.v-secondary
                      title="一鍵關閉標記"
                      class="d-flex align-items-center actions-link-btn ml-25"
                      @click="onSubmitClearDisplay"
                    >
                      <b-img
                        :src="require('@/assets/images/component/remark/completed.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />

                    </div>

                    <div
                      v-b-tooltip.hover.focus.v-secondary
                      title="只顯示標記"
                      class="d-flex align-items-center actions-link-btn ml-25"
                      :class="searchDisplay ? 'actions-link-btn-active' : null"
                      @click="searchDisplay = !searchDisplay"
                    >
                      <b-img
                        :src="require('@/assets/images/component/remark/remarkOnly.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      v-b-tooltip.hover.focus.v-secondary
                      title="只顯示自己"
                      class="d-flex align-items-center actions-link-btn ml-25"
                      :class="searchSelf ? 'actions-link-btn-active' : null"
                      @click="searchSelf = !searchSelf"
                    >
                      <b-img
                        :src="require('@/assets/images/component/remark/showSelf.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      v-b-tooltip.hover.focus.v-secondary
                      title="篩選器"
                      class="d-flex align-items-center actions-link-btn ml-25"
                    >
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        right
                      >
                        <template #button-content>
                          <b-img
                            src="/dashboard/admin/images/table/filter.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </template>

                        <b-dropdown-form style="min-width: 300px">
                          <h6>篩選器</h6>
                          <b-form-group>
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                v-model="searchQuery"
                                debounce="500"
                                class="d-inline-block"
                                placeholder="搜尋..."
                              />
                              <b-input-group-append
                                v-if="searchQuery"
                                is-text
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="cursor-pointer text-muted"
                                  @click="searchQuery = null"
                                />
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>

                          <!-- 只顯示自己 -->
                          <b-form-group>
                            <div class="d-flex justify-content-between align-items-center mt-50">
                              <label class="mb-0">只顯示自己</label>

                              <toggle-button
                                v-model="searchSelf"
                                :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                                :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                                :width="60"
                                :height="24"
                                class="m-25 mb-50"
                                :labels="{checked: '開啟', unchecked: '關閉'}"
                                :sync="true"
                              />
                            </div>
                          </b-form-group>

                          <!-- 只顯示標記 -->
                          <b-form-group>
                            <div class="d-flex justify-content-between align-items-center mt-50">
                              <label class="mb-0">只顯示標記</label>

                              <toggle-button
                                v-model="searchDisplay"
                                :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                                :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                                :width="60"
                                :height="24"
                                class="m-25 mb-50"
                                :labels="{checked: '開啟', unchecked: '關閉'}"
                                :sync="true"
                              />
                            </div>
                          </b-form-group>
                        </b-dropdown-form>
                      </b-dropdown>
                    </div>

                    <div
                      v-b-tooltip.hover.focus.v-secondary
                      title="創建日期"
                      class="d-flex align-items-center actions-link-btn ml-25"
                      :class="timeRange ? 'actions-link-btn-active' : null"
                      @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/calendar.svg"
                        class="actions-link-btn-image"
                        rounded
                      />

                      <TableTimeRange
                        ref="TableTimeRange"
                        table-explorer-id="TableTimeRange"
                        @call-back-data="time => timeRange = time"
                      />
                    </div>
                  </div>
                </b-col>

              </b-row>
            </div>
          </b-card-body>

          <!-- 備註列表 -->
          <div
            ref="remarkListContent"
            class="remark-list"
          >
            <b-card-body class="pb-0 pt-50">
              <div
                v-if="!isInitBusy"
                class="animate__animated animate__fadeIn"
              >
                <div
                  v-if="remarkList.length > 0"
                  class="media-list media-bordered"
                >
                  <b-media
                    v-for="remark in remarkList"
                    :key="remark.id"
                    vertical-align="top"
                    no-body
                  >
                    <b-media-aside>
                      <VueLoadImage
                        v-if="remark.admin_info.image"
                        :use-loading="true"
                        :use-failed="true"
                        :blank-height="'45px'"
                        :loading-width="45"
                        :loading-height="45"
                        :failed-width="45"
                      >
                        <b-img
                          slot="image"
                          :src="remark.admin_info.image"
                          alt="留言"
                          blank-color="#ccc"
                          rounded="circle"
                          width="45"
                        />
                      </VueLoadImage>

                      <b-avatar
                        v-else
                        size="45"
                        rounded="circle"
                        variant="light-primary"
                        class="mb-2"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="20"
                        />
                      </b-avatar>
                    </b-media-aside>

                    <b-media-body class="ml-75">

                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <h5 class="font-weight-bolder mb-0 mr-25">
                            {{ remark.admin_info.name }}
                            <!-- / {{ remark.id }} -->
                          </h5>

                          <span
                            :style="{ 'color': resolveFilterType(remark.label).color }"
                            class="remark-list-tag"
                          >
                            [ {{ resolveFilterType(remark.label).label }} ]
                          </span>
                        </div>

                        <div class="remark-list-action">
                          <small
                            v-b-tooltip.hover.focus.v-secondary
                            :title="`${moment(remark.created_at).format('HH:mm:ss')}`"
                            variant="gradient-primary"
                          >
                            <span class="text-muted align-middle">{{ updateOnline(remark.created_at, refonlineTime) }}</span>
                          </small>
                        </div>
                      </div>

                      <div
                        v-if="editCol !== remark.id"
                        class="remark-list-content mt-50"
                      >
                        <div class="show-text">
                          <span>{{ remark.content }}</span>
                          <!-- {{ remark }} -->
                        </div>

                        <div class="remark-list-action">
                          <div class="d-flex justify-content-end">
                            <div
                              class="actions-link-btn mr-25"
                              :class="{ 'remark-action-hidden': !remark.display }"
                              @click="onSubmitChangeDisplay(remark)"
                            >
                              <b-img
                                v-b-tooltip.hover.focus.v-secondary
                                :title="remark.display ? '關閉標記' : '重點標記'"
                                :src="require('@/assets/images/component/remark/remark.svg')"
                                class="actions-link-btn-image"
                                rounded
                              />
                            </div>

                            <div
                              v-if="remark.admin === userData.id"
                              class="actions-link-btn mr-25"
                              @click="onSubmitUpdate(remark)"
                            >
                              <b-img
                                v-b-tooltip.hover.focus.v-secondary
                                title="編輯"
                                :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                                class="actions-link-btn-image"
                                rounded
                              />
                            </div>

                            <div
                              v-if="remark.admin === userData.id"
                              class="actions-link-btn"
                              @click="onSubmitDelete(remark)"
                            >
                              <b-img
                                v-b-tooltip.hover.focus.v-secondary
                                title="刪除"
                                :src="require('@/assets/images/pages/ui/blue-line/trash-bin.svg')"
                                class="actions-link-btn-image"
                                rounded
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="editCol === remark.id && !isBusy">
                        <div class="text-right mt-50">
                          <div
                            v-show="!isBusy"
                            class="mb-50"
                          >
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </div>
                        <b-form-textarea
                          v-model="editColData"
                          :placeholder="remark.content ? remark.content : '請輸入備註...'"
                        />
                      </div>
                    </b-media-body>
                    <!-- {{ remark }} -->
                  </b-media>
                </div>

                <div
                  v-if="isLoadingBusy"
                  class="text-center p-2"
                >
                  <b-img
                    :src="$store.state.app.themeImages.loadingImg"
                    rounded
                    height="30"
                    width="30"
                  />
                </div>

                <div
                  v-if="!isLoadingBusy && !isAll && remarkList.length !== 0"
                  class="text-center p-2"
                >
                  <span>沒有更多資料了</span>
                </div>

                <div
                  v-if="remarkList.length === 0"
                  class="text-center my-5 animate__animated animate__fadeIn"
                >
                  <b-img
                    :src="$store.state.app.themeImages.notFoundImg"
                    fluid
                    width="480"
                    alt="查無資料"
                  />
                </div>
              </div>

              <div
                v-show="isInitBusy"
                class="remark-loading"
              >
                <b-img
                  :src="$store.state.app.themeImages.loadingImg"
                  rounded
                  height="60"
                  width="60"
                />
              </div>
            </b-card-body>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BModal, VBTooltip, BCardBody, BImg, BMediaAside, BMedia, BMediaBody, BRow, BCol, BDropdown, BDropdownForm,
  BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BInputGroupAppend, BFormTextarea, BAvatar,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useModalSetting, useRemarkModal } from '../useModal'
import { useSwalToast } from '@/libs/mixins/index'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BModal,
    BAvatar,
    BCardBody,
    BMediaAside,
    BMedia,
    BMediaBody,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
    TableTimeRange,
    VueLoadImage,
    ToggleButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    keyArray: {
      type: Array,
      required: true,
    },
    isHidenEmit: {
      type: Boolean,
      default: false,
    },
    useCallbackData: {
      type: Boolean,
      default: false,
    },
    remarkExplorerId: {
      type: String,
      default: 'remark-explorer-modal',
    },
  },
  data() {
    return {
      selected: null,
      editCol: null,
      editColData: null,
      isRefresh: false,
    }
  },
  computed: {
    isAll() {
      const num = this.currentPage * this.perPage
      if (num > this.totalNum) return false
      return true
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (切換)彈窗狀態
    chgModalState(bvModalEvt) {
      if (bvModalEvt) this.$refs.remarkListContent.addEventListener('scroll', this.isScrollBottom)
    },

    // (觸發)創建
    onSubmitCreate() {
      if (this.isBusy) return
      const keyValue = this.filterOptions.find(item => item.value === this.filterSelected)?.keyId
      const createKey = `${this.filterSelected}-${keyValue}`

      const resolveData = {
        ...this.syncObject(this.blankRemarkData, {}),
        admin_info: this.syncObject(this.blankUserData, this.userData),
        label: this.filterSelected,
      }

      resolveData.key = createKey
      resolveData.content = this.inputRemark
      resolveData.created_at = new Date()
      resolveData.display = true

      this.isBusy = true

      const isMatch = this.keySelected === this.filterSelected || this.keySelected === 'all'
      if (isMatch) this.remarkList.unshift(resolveData)

      this.setRemarkCreate({
        key: createKey,
        content: this.inputRemark,
        display: true,
      })
        .then(response => {
          const { data } = response.data
          if (isMatch) {
            this.remarkList[0] = {
              ...this.remarkList[0],
              ...data,
            }
          }
          this.inputRemark = ''
          this.$refs.inputRemarkTextarea.focus()
          this.isBusy = false
          this.isRefresh = true
          setTimeout(() => { this.$refs.inputRemarkTextarea.focus() }, 200)
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.remarkList = this.remarkList.filter(item => item.id)
          this.isBusy = false
        })
    },

    // (觸發)編輯
    onSubmitUpdate(remark) {
      this.editCol = remark.id
      this.editColData = JSON.parse(JSON.stringify(remark.content))
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.editCol = null
      this.editColData = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      if (this.editColData.length === 0) {
        this.useAlertToast(false, '備註內容不得為空')
        return
      }
      if (this.isBusy) return
      this.isBusy = true

      this.setRemarkUpdateContent({
        remark_id: this.editCol,
        data: {
          content: this.editColData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          const index = this.remarkList.findIndex(item => item.id === this.editCol)
          if (index !== -1) {
            this.remarkList[index].content = this.editColData
          }
          this.cancelEditCol()
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (觸發)刪除
    onSubmitDelete(remark) {
      if (this.isBusy) return

      this.isBusy = true

      this.useSwalAlertWarning('刪除備註', '你確定要永久刪除當前備註嗎?')
        .then(result => {
          if (result.value) {
            this.setRemarkDelete({ remark_id: remark.id })
              .then(() => {
                this.remarkList = this.remarkList.filter(item => item.id !== remark.id)
                this.useSwalAlertCenter(true, '刪除成功!', '備註已移除')
                this.isRefresh = true
                this.isBusy = false
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
                this.isBusy = false
              })
          } else this.isBusy = false
        })
    },

    // (觸發)變更標記
    onSubmitChangeDisplay(remark) {
      if (this.isBusy) return
      this.isBusy = true

      this.useSwalAlertWarning(`${remark.display ? '關閉' : '開啟'}標記`, `你確定要${remark.display ? '關閉' : '開啟'}當前備註標記嗎?`)
        .then(result => {
          if (result.value) {
            this.setRemarkUpdateDisplay({
              remark_id: remark.id,
              data: {
                display: !remark.display,
              },
            })
              .then(() => {
                this.useSwalAlertCenter(true, '觸發成功!', `備註標記已${remark.display ? '開啟' : '關閉'}`)
                const index = this.remarkList.findIndex(item => item.id === remark.id)
                if (index !== -1) {
                  this.remarkList[index].display = !remark.display
                }
                this.isRefresh = true
                this.isBusy = false
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '觸發失敗!', error.response.data.message)
                this.isBusy = false
              })
          } else this.isBusy = false
        })
    },

    // (觸發)關閉所有標記
    onSubmitClearDisplay() {
      if (this.isBusy) return
      const keyValue = this.filterOptions.find(item => item.value === this.filterSelected)?.keyId
      const createKey = `${this.filterSelected}-${keyValue}`

      this.isBusy = true

      this.useSwalAlertWarning('關閉標記', `你確定要一鍵關閉當前 ${this.transKey[this.filterSelected]} 所有備註標記嗎?`)
        .then(result => {
          if (result.value) {
            this.setRemarkClearDisplay({ key: createKey })
              .then(() => {
                this.useSwalAlertCenter(true, '觸發成功!', '備註標記已關閉')
                this.isRefresh = true
                this.isBusy = false
                this.getData()
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '觸發失敗!', error.response.data.message)
                this.isBusy = false
              })
          } else this.isBusy = false
        })
    },

    // (觸發)輸入欄位按鈕
    handleKeyDown(event) {
      if (event.shiftKey && event.key === 'Enter') return
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.onSubmitCreate()
      }
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.remarkList = []
      this.keyOptions = []
      this.filterOptions = []
      this.cancelEditCol()
      this.$nextTick(() => {
        this.$bvModal.hide(this.remarkExplorerId)
        if (this.isHidenEmit && this.isRefresh) this.$emit('emit-hidden-function')
        this.isRefresh = false
      })
    },

    // (自動)加載資料
    isScrollBottom() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.remarkListContent
      if ((scrollTop + clientHeight + 1 >= scrollHeight) && this.isAll) {
        this.currentPage += 1
      }
    },

    // (獲取資料)
    getData() {
      this.isInitBusy = true
      this.remarkList = []
      this.keyOptions = []
      this.filterOptions = []
      this.currentPage = 1

      const resolveKey = []
      this.keyArray.forEach(item => {
        const parts = item.split(/-(.+)/)
        const key = parts[0]
        const keyId = parts[1]
        const resolve = {
          label: this.transKey[key],
          value: key,
          keyId,
        }
        const exists = resolveKey.some(existingItem => existingItem.value === key)

        if (!exists && key && keyId) {
          resolveKey.push(resolve)
        }
      })

      this.keyOptions.push({ label: '全部', value: 'all', keyId: null })
      resolveKey.forEach(item => this.keyOptions.push(item))
      this.filterOptions = resolveKey

      if (this.keyOptions.length > 0) this.keySelected = this.keyOptions[1].value
      if (this.filterOptions.length > 0) this.filterSelected = this.filterOptions[0].value
      this.$bvModal.show(this.remarkExplorerId)
      this.propsKey = this.keyArray
      this.getRemarkListData()
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      ui,
      useAlertToast,
      useHttpCodeAlert,
      blankRemarkData,
      blankUserData,
      totalNum,
      currentPage,
      perPage,
      searchQuery,
      timeRange,
      inputRemarkTextarea,
      isBusy,
      transKey,
      propsKey,
      resolveFilterType,
      isInitBusy,
      isLoadingBusy,
      filterSelected,
      filterOptions,
      keySelected,
      keyOptions,
      inputRemark,
      remarkList,
      searchSelf,
      searchDisplay,
      userData,
      getRemarkListData,
      setRemarkCreate,
      setRemarkUpdateContent,
      setRemarkUpdateDisplay,
      setRemarkClearDisplay,
      setRemarkDelete,
    } = useRemarkModal()

    const {
      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useModalSetting()

    return {
      isDark,
      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,

      ui,
      blankRemarkData,
      blankUserData,
      useAlertToast,
      useHttpCodeAlert,
      totalNum,
      currentPage,
      perPage,
      searchQuery,
      timeRange,
      inputRemarkTextarea,
      isBusy,
      transKey,
      propsKey,
      resolveFilterType,
      isInitBusy,
      isLoadingBusy,
      filterSelected,
      filterOptions,
      keySelected,
      keyOptions,
      inputRemark,
      remarkList,
      searchSelf,
      searchDisplay,
      userData,
      getRemarkListData,
      setRemarkCreate,
      setRemarkUpdateContent,
      setRemarkUpdateDisplay,
      setRemarkClearDisplay,
      setRemarkDelete,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.remark-filter {
  .b-form-select {
    font-weight: bolder;
    font-size: 16px;
  }
}
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.remark-container {
  position: relative;
  min-height: 10vh;

  .remark-filter {
    position: relative;
  }

  .remark-textarea {
    .remark-textarea-type {
      display: flex;
      margin: 0 5px;
      .selected-option {
        padding: 0.3rem 10px;
        border-radius: 5px 5px 0 0;
        font-size: 12px;
        color: white;
        letter-spacing: 1px;
        opacity: 0.3;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        letter-spacing: 1px;
      }

      .selected-option-active {
        padding: 0.3rem 20px;
        opacity: 1;
        font-weight: bolder;
      }
    }
    .site-input-area {
      border: none;
      background: transparent;
      border-radius: 0;
      resize: none;
      height: 100px;
      color: #d0d2d6;
      padding: 10px;
      border: 1px #d0d2d6 solid;
      border-radius: 5px;
      &:focus {
        box-shadow: none;
        border-color: #719ECE;
      }
      &:focus-visible {
        outline: none;
      }
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  .remark-list {
    height: 50vh;
    overflow-y:auto;
    position: relative;

    .remark-list-action {
      min-width: 65px;
      text-align: right;
    }

    .remark-list-tag {
      padding: 1px 5px;
      color: white;
      font-size: 12px;
      border-radius: 5px;
    }

    .remark-list-content {
      display: flex;
      justify-content: space-between;
    }

    .remark-action-hidden {
      opacity: 0.2;
    }

    .remark-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

@media (max-width: 480px) {
  .remark-list-content {
    flex-direction: column-reverse;
  }
}
</style>
