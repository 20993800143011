<template>
  <div>
    <b-row class="mb-2 mb-sm-0">
      <b-col
        cols="12"
        xl="9"
        lg="9"
      >
        <b-overlay
          :show="adminProductState.isBusyLoading"
          variant="transparent"
          opacity="0.9"
          rounded="sm"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                lg="7"
                md="6"
                class="mb-1"
              >
                <div class="d-flex align-items-center mb-50">
                  <div
                    v-if="adminProductState.productDataInfo.display"
                    v-b-tooltip.hover.focus.v-secondary
                    title="上架中"
                    class="state-dot dot-green mr-1"
                    @click="onSubmitUpdate(adminProductState.productDataInfo)"
                  />
                  <div
                    v-else
                    v-b-tooltip.hover.focus.v-secondary
                    title="未啟用"
                    class="state-dot dot-gray mr-1"
                    @click="onSubmitUpdate(adminProductState.productDataInfo)"
                  />

                  <h3 class="font-weight-bolder mb-0">
                    <span class="server-name">{{ adminProductState.productDataInfo.name }}</span>
                  </h3>
                </div>
                <!-- SSID -->
                <b-form-group
                  label="SSID"
                  label-for="ssid"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    {{ adminProductState.productDataInfo.ssid ? adminProductState.productDataInfo.ssid : '---' }}
                  </div>
                </b-form-group>

                <!-- 分站 -->
                <b-form-group
                  label="分站"
                  label-for="branch"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    <span v-if="adminProductState.productDataInfo.branch_info && adminProductState.productDataInfo.branch_info.name">{{ adminProductState.productDataInfo.branch_info.name }}</span>
                    <span v-else>{{ adminProductState.productDataInfo.branch_info.branch }}</span>
                  </div>
                </b-form-group>

                <!-- 廣告位置 -->
                <b-form-group
                  label="廣告位置"
                  label-for="ssid"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div
                    class="edit-col-label"
                    @click="onSubmitUpdate"
                  >
                    <span>
                      {{ adminProductState.productDataInfo.display_group ? resolveDisplayGroupType(adminProductState.productDataInfo) : '---' }}
                    </span>

                    <span class="selection-btn-icon">
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        height="15"
                        width="15"
                        class="cursor-pointer"
                      />
                    </span>
                  </div>
                </b-form-group>

                <!-- 方案 -->
                <b-form-group
                  label="方案"
                  label-for="plan"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div
                    class="edit-col-label"
                    @click="onSubmitUpdate"
                  >
                    <span>
                      {{ adminProductState.productDataInfo.plan_info.name }}
                    </span>

                    <span class="selection-btn-icon">
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        height="15"
                        width="15"
                        class="cursor-pointer"
                      />
                    </span>
                  </div>
                </b-form-group>

                <!-- 售價 -->
                <b-form-group
                  label="售價"
                  label-for="price"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div
                    class="edit-col-label"
                    @click="onSubmitUpdate"
                  >
                    <span>
                      {{ adminProductState.productDataInfo.price ? `$ ${parseInt(adminProductState.productDataInfo.price).toLocaleString()}` : '---' }}
                    </span>

                    <span class="selection-btn-icon">
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        height="15"
                        width="15"
                        class="cursor-pointer"
                      />
                    </span>
                  </div>

                  <!-- <div class="mx-50">
                    {{ adminProductState.productDataInfo.price ? `$ ${parseInt(adminProductState.productDataInfo.price).toLocaleString()}` : '---' }}
                  </div> -->
                </b-form-group>

                <!-- 上次更新 -->
                <b-form-group
                  label="上次更新"
                  label-for="updated_at"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    {{ adminProductState.productDataInfo.updated_at ? moment(adminProductState.productDataInfo.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>

                <!-- 創建時間 -->
                <b-form-group
                  label="創建時間"
                  label-for="created_at"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    {{ adminProductState.productDataInfo.created_at ? moment(adminProductState.productDataInfo.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="5"
                md="6"
              >
                <h4 class="mb-0">
                  目前統計
                </h4>
                <vue-apex-charts
                  type="radialBar"
                  height="220"
                  class="mt-2"
                  :options="goalOverviewRadialBar"
                  :series="[percentage]"
                />
                <b-row class="text-center mx-0">
                  <b-col
                    cols="6"
                    class="border-right d-flex align-items-between flex-column pt-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      目標
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ adminProductState.productDataInfo.target ? parseInt(adminProductState.productDataInfo.target).toLocaleString() : 0 }}
                    </h3>
                  </b-col>

                  <b-col
                    cols="6"
                    class="d-flex align-items-between flex-column pt-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      目前擁有數量
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ adminProductState.productDataInfo.total ? parseInt(adminProductState.productDataInfo.total, 10).toLocaleString() : 0 }}
                    </h3>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        lg="3"
      >
        <div class="mb-1">
          <b-card>
            <div class="link-button-container">
              <div class="link-botton text-center">
                <b-img
                  :src="require('@/assets/images/icon/history.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  歷史紀錄
                </h5>
              </div>

              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-botton text-center"
              >
                <!-- @click="onSubmitRemark()" -->
                <div class="link-botton-alert">
                  <b-img
                    :src="require('@/assets/images/icon/remark.svg')"
                    class="link-botton-image"
                    fluid
                    rounded
                  />
                  <!-- <div
                    v-if="adminOrderState.orderInfo.remark_display"
                    class="state-dot dot-red"
                  /> -->
                </div>

                <h5 class="text-body-heading mb-0">
                  留言備註
                </h5>
              </div>

              <div
                class="link-botton text-center"
                @click="onSubmitUpdate"
              >
                <b-img
                  :src="require('@/assets/images/icon/editSet.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  編輯
                </h5>
              </div>

              <div
                class="link-botton text-center"
                @click="$emit('refresh-product-info')"
              >
                <b-img
                  :src="require('@/assets/images/icon/refresh.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  重新整理
                </h5>
              </div>
            </div>
          </b-card>

          <!-- <b-row>
            <b-col
              lg="12"
              sm="6"
            >
              <b-card no-body>
                <b-card-body class="px-50">
                  <div class="d-flex justify-content-between flex-row w-100">
                    <div class="px-50">
                      <b-img
                        :src="require('@/assets/images/pages/shop/domain/trade.svg')"
                        height="38"
                        rounded
                      />
                    </div>

                    <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                      <div>
                        <h5 class="font-weight-bolder mr-25 mb-25">
                          上架狀態
                        </h5>

                        <small>
                          啟用後可在前台展示
                        </small>
                      </div>

                      <div class="text-right">
                        <toggle-button
                          v-model="domainData.display"
                          :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                          :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                          :width="65"
                          :height="30"
                          class="m-25 mb-50"
                          :labels="{checked: '開啟', unchecked: '關閉'}"
                          :sync="true"
                          :disabled="!checkAuthAbility('product', 'API.Admin.Product.Update')"
                          @input="() => {
                            selectEditCol('display')
                            confirmEditCol()
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <b-col
              lg="12"
              sm="6"
            >
              <b-card no-body>
                <b-card-body class="px-50">
                  <div class="d-flex justify-content-between flex-row w-100">
                    <div class="px-50">
                      <b-img
                        :src="require('@/assets/images/pages/customer/id-card.svg')"
                        height="38"
                        rounded
                      />
                    </div>

                    <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                      <div>
                        <h5 class="font-weight-bolder mr-25 mb-25">
                          身分驗證
                        </h5>

                        <small>
                          實名購買、保障交易安全
                        </small>
                      </div>

                      <div class="text-right">
                        <toggle-button
                          v-model="domainData.require_kyc"
                          :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                          :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                          :width="65"
                          :height="30"
                          class="m-25 mb-50"
                          :labels="{checked: '開啟', unchecked: '關閉'}"
                          :sync="true"
                          :disabled="!checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                          @input="() => {
                            selectEditCol('require_kyc')
                            confirmEditCol()
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row> -->
        </div>
      </b-col>
    </b-row>

    <!-- 商品編輯 -->
    <product-edit-modal
      ref="productEditModal"
      @refetch-data="$emit('refresh-product-info')"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BOverlay, BCardText, BImg, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import { useProductView, useProductSetting } from '../useProduct'
import ProductEditModal from './components/ProductEditModal.vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BOverlay,
    BCardText,
    VueApexCharts,
    ProductEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  computed: {
    adminProductState() {
      return this.$store.state['admin-product']
    },
    percentage() {
      return 0
      // if (Math.round((this.adminProductState.productDataInfo.total / this.adminProductState.productDataInfo.target) * 100) > 100) return 100
      // return Math.round((this.adminProductState.productDataInfo.total / this.adminProductState.productDataInfo.target) * 100)
    },
  },
  methods: {
    moment,
    // (檢查)是否為主要商品類型
    isProductMainType(type) {
      return this.$store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)
    },

    // (檢查)廣告位置
    resolveDisplayGroupType(item) {
      if (item.branch_id && this.metaDisplayGroup[item.branch_id]) {
        const options = this.metaDisplayGroup[item.branch_id]
        if (options && options.length) {
          const displayGroup = options.find(f => f.key === item.display_group)
          return displayGroup ? displayGroup.name : ''
        }
        return item.display_group
      }
      return '---'
    },

    // (觸發)編輯
    onSubmitUpdate() {
      const auth = this.checkAuthAbility('product', 'API.Admin.Product.Update')
      if (!auth) return
      setTimeout(() => { this.$refs.productEditModal.getData() }, 200)
    },
  },
  setup() {
    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      goalOverviewRadialBar,
      getMetaSystemConfigAll,
      metaDisplayGroup,
    } = useProductView()

    const {
      ui,
      kycRequiredOptions,
      displayOptions,
      syncObject,
    } = useProductSetting()

    getMetaSystemConfigAll()

    return {
      useAlertToast,
      useHttpCodeAlert,

      ui,
      kycRequiredOptions,
      displayOptions,
      syncObject,
      goalOverviewRadialBar,
      getMetaSystemConfigAll,
      metaDisplayGroup,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
// .dark-layout {}

.link-button-container {
  display: flex;
  flex-wrap: wrap;
  .link-botton {
    width: calc(50% - 10px);
    margin: 5px;
    padding: 20px 10px;
    border: 1px solid #d5d2dc1a;
    background-color: #b8b7bb3b;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: transform ease-out 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .link-botton-image {
      max-width: 30px;
      max-height: 30px;
      margin-bottom: 10px;
    }

    .text-body-heading {
      font-size: 14px;
    }

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      animation: btn-breathe .8s linear infinite;
      background-color: #c9ebff59;
    }
  }

  .link-botton-alert {
    position: relative;
    width: 100%;
    max-width: 30px;
    .state-dot {
      position: absolute;
      right: -5px;
      bottom: 8px;
    }
  }
}

.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    width: calc(100% - 80px);
  }
}

.server-name {
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: red !important;
    text-decoration: underline
  }
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
