<template>
  <b-modal
    id="in-stock-modal"
    ref="in-stock-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    body-class="p-0"
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        入庫元件
      </h4>
    </template>

    <div class="pt-1 px-2">
      <!-- 品牌 -->
      <b-form-group label-for="plan_id">
        <template #label>
          <div class="d-flex justify-content-between align-items-center">
            <label class="mb-0">
              品牌
              <span class="text-danger">*</span>
            </label>
          </div>
        </template>

        <v-select
          v-model="stockData.brand_id"
          :options="brandOptions"
          :reduce="option => option.id"
          :clearable="false"
          label="name"
          class="edit-col-select"
          placeholder="請選擇品牌"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              沒有<em>{{ search }}</em> 的相關結果
            </template>

            <div v-else>
              <small>暫無資料</small>
            </div>
          </template>
        </v-select>
      </b-form-group>

      <!-- 地點 -->
      <b-form-group label-for="plan_id">
        <template #label>
          <div class="d-flex justify-content-between align-items-center">
            <label class="mb-0">
              地點
              <span class="text-danger">*</span>
            </label>
          </div>
        </template>

        <v-select
          v-model="stockData.place_id"
          :options="placeOptions"
          :reduce="option => option.id"
          :clearable="false"
          label="name"
          class="edit-col-select"
          placeholder="請選擇地點"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              沒有<em>{{ search }}</em> 的相關結果
            </template>

            <div v-else>
              <small>暫無資料</small>
            </div>
          </template>
        </v-select>
      </b-form-group>

      <!-- 自動產生序號 -->
      <div>
        <div class="d-flex align-items-center justify-content-between mb-1">
          <label>是否自動生成SN值</label>

          <toggle-button
            v-model="autoType"
            :color="{checked: '#20c997', unchecked: '#8f8f8f'}"
            :switch-color="{checked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
            :width="75"
            :height="30"
            class="mt-50"
            :labels="{checked: '自動', unchecked: '手動'}"
            :sync="true"
            @input="generateSerialNumbers"
          />
        </div>

        <b-row v-if="autoType">
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              label-for="numberLength"
              label="序號長度"
            >
              <b-form-input
                v-model="numberLength"
                placeholder="序號長度"
                type="number"
                @input="generateSerialNumbers"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            sm="5"
          >
            <b-form-group
              label-for="endText"
              label="序號前綴"
            >
              <b-form-input
                v-model="preText"
                placeholder="序號前綴"
                @input="generateSerialNumbers"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group
              label-for="endText"
              label="序號後綴"
            >
              <b-form-input
                v-model="endText"
                placeholder="序號後綴"
                @input="generateSerialNumbers"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div class="mt-2">
        <div class="d-flex align-items-center justify-content-between font-weight-bold">
          <div>
            <feather-icon
              icon="BoxIcon"
              class="mr-50"
              style="margin-top: -3px"
            />

            <span style="font-size: '16px'">元件名稱</span>
          </div>
        </div>

        <hr class="mt-50">

        <b-row>
          <b-col cols="2" />

          <b-col cols="5">
            <h5>名稱</h5>
          </b-col>

          <b-col cols="5">
            <h5>SN值</h5>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="scroll-area">
      <div class="px-2">
        <div
          v-for="(device, index) in stockData.devices"
          :key="index"
          class="mb-1"
        >
          <b-row>
            <b-col cols="2">
              <div class="mt-50">
                {{ index + 1 }}
              </div>
            </b-col>

            <b-col cols="5">
              <b-form-input
                id="name"
                v-model="device.name"
                trim
                placeholder="請輸入名稱"
              />
            </b-col>

            <b-col cols="5">
              <b-form-input
                id="serialNumber"
                v-model="device.serialNumber"
                trim
                placeholder="請輸入SN值"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import {
  BButton, VBTooltip, BFormGroup, BFormInput, BSpinner,
  BRow, BCol,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useStockList, useStockSetting } from '../useStock'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    ToggleButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    stockItem: {
      type: Object,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    placeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
      // -------------------------------
      autoType: false, // 使用自動生成序號
      preText: 'SN-', // 自動生成序號前綴
      numberLength: 3, // 序號長度
      endText: '', // 自動生成序號後綴
    }
  },
  methods: {
    // (自動)產生序號
    generateSerialNumbers() {
      if (!this.autoType) return
      const serialNumbers = []

      for (let i = 1; i <= this.stockData.devices.length; i += 1) {
        let numberPart = i.toString()

        if (this.numberLength > 0) {
          numberPart = numberPart.padStart(this.numberLength, '0')
        }

        if (this.fixedNumberLength && this.numberLength > 0) {
          numberPart = numberPart.slice(-this.numberLength)
        }

        let serialNumber = this.preText

        serialNumber += numberPart
        serialNumber += this.endText || ''
        serialNumbers.push(serialNumber)
      }
      this.stockData.devices.forEach((device, index) => {
        Object.assign(device, { serialNumber: serialNumbers[index] })
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetStockData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('in-stock-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // autoType

      // 判斷必填欄位是否填寫
      const requiredFields = {
        brand_id: '品牌',
        place_id: '地點',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.stockData[Object.keys(requiredFields)[i]] === null || this.stockData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      // (檢查)元件名稱 或 SN值
      let stateCheck = true

      this.stockData.devices.forEach(device => {
        if (device.name === '' || device.serialNumber === '') {
          stateCheck = false
        }
      })

      if (!stateCheck) {
        this.useAlertToast(false, '元件名稱 或 SN值 未填寫')
        return
      }

      this.isBusy = true
      this.setStockToDevice({
        id: this.stockItem.id,
        data: {
          ...this.stockData,
        },
      })
        .then(response => {
          this.useAlertToast(response.data.success, response.data.message)
          this.isBusy = false
          this.$emit('refetch-data')
          this.resetStockData()
          this.$nextTick(() => {
            this.$bvModal.hide('in-stock-modal')
          })
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)資料
    getData() {
      this.resetStockData()
      const deviceNumber = parseInt(this.stockItem.quantity, 10)
      for (let i = 0; i < deviceNumber; i += 1) {
        this.stockData.devices.push({
          name: this.stockItem.name,
          serialNumber: null,
        })
      }
      const generateTenDigitTimestamp = () => Math.floor(new Date().getTime() / 1000)
      this.preText = `SN${generateTenDigitTimestamp()}-`
      this.numberLength = 3
      this.generateSerialNumbers()
      this.$bvModal.show('in-stock-modal')
    },
  },
  setup() {
    const {
      syncObject,
    } = useStockSetting()

    const {
      setStockToDevice,
    } = useStockList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankStockData = {
      brand_id: null,
      place_id: null,
      devices: [],
    }

    const stockData = ref(null)
    stockData.value = JSON.parse(JSON.stringify(blankStockData))

    const resetStockData = () => {
      stockData.value = JSON.parse(JSON.stringify(blankStockData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetStockData)

    return {
      stockData,
      blankStockData,
      setStockToDevice,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,

      resetStockData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.scroll-area {
  max-height: 50vh;
  overflow-y: auto;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.required-key {
  color: red;
  margin-top: 3px;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
