<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="8"
      >
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <h4 class="mb-0 font-weight-bolder">
                附加商品
              </h4>

              <b-img
                v-if="isExtraProductUpdate"
                v-b-tooltip.hover.focus.v-secondary
                title="尚未儲存"
                class="alert-icon"
                :src="require('@/assets/images/commen/warning-yellow.svg')"
              />
            </div>

            <div
              v-if="checkAuthAbility('product', 'API.Admin.Product.Update')"
              class="text-nowrap d-flex justify-content-end"
            >
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUpdate({})"
              >
                <b-img
                  src="/dashboard/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="initExtraArray"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="儲存"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="submitExtraProductUpdate"
              >
                <b-img
                  src="/dashboard/admin/images/table/save.svg"
                  class="actions-link-btn-image"
                />
              </div>
            </div>
          </b-card-header>

          <b-card-body v-if="!isLoadingBusy">
            <draggable
              tag="ul"
              :list="productData"
              class="list-group"
              @update="updateProductSort"
            >
              <li
                v-for="(product, index) in productData"
                :key="`product-${index}`"
                class="list-group-item"
              >
                <div class="d-flex">
                  <div>
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                      class="cursor-pointer mr-1 mt-25"
                    />
                  </div>

                  <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div class="font-weight-bold cursor-pointer">
                        {{ resolvePlanName(product.planId) }}
                      </div>

                      <div
                        v-if="checkAuthAbility('product', 'API.Admin.Product.Update')"
                        class="d-flex text-nowrap align-items-center"
                      >
                        <div
                          class="actions-link-btn mr-25"
                          @click="onSubmitUpdate(product)"
                        >
                          <b-img
                            v-b-tooltip.hover.focus.v-secondary
                            title="編輯"
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>

                        <div
                          class="actions-link-btn"
                          @click="onSubmitRemove(product)"
                        >
                          <b-img
                            v-b-tooltip.hover.focus.v-secondary
                            title="移除"
                            :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mt-50">
                      <b-row>
                        <b-col
                          v-for="(option, optionIndex) in productOptionsFilter(product.planId)"
                          :key="`option-${optionIndex}`"
                          cols="12"
                          xl="4"
                          lg="6"
                          md="6"
                        >
                          <label
                            class="checkbox-label mr-25"
                            @click="onSubmitUpdate(product)"
                          >
                            <div class="d-flex align-items-center">
                              <b-form-checkbox
                                name="table-column"
                                :checked="product.options.includes(option.id)"
                                disabled
                                class="extra-checkbox"
                              />

                              <div class="w-100">
                                <div
                                  class="d-block show-text"
                                >
                                  {{ option.name }}
                                </div>
                                <small class="text-muted">
                                  <div
                                    v-if="option.price"
                                    class="email-text"
                                  >
                                    +$ {{ parseInt(option.price, 10).toLocaleString() }}
                                  </div>
                                </small>
                              </div>
                            </div>
                          </label>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </li>

              <div
                v-if="productData.length === 0"
                class="loading-area border"
              >
                <div class="text-center my-5 animate__animated animate__fadeIn">
                  <b-img
                    :src="$store.state.app.themeImages.notFoundImg"
                    fluid
                    width="480"
                    alt="查無資料"
                  />
                </div>
              </div>
            </draggable>
          </b-card-body>

          <b-card-body v-else>
            <div class="loading-area border">
              <b-img
                :src="$store.state.app.themeImages.loadingImg"
                rounded
                height="60"
                width="60"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="4"
      >
        <b-card title="預覽">
          <b-form-group
            v-for="(item, index) in previewExtraArray"
            :key="`preview-${index + 1}`"
          >
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  {{ item.plan_info.name }}
                  <span
                    v-if="item.required"
                    class="text-danger"
                  >*</span>
                </label>

                <div>
                  <div
                    v-if="item.muti"
                    class="preview-badge muti-badge"
                    :style="{ backgroundColor: '#20c997' }"
                  >
                    多選
                  </div>

                  <div
                    v-else
                    class="preview-badge single-badge"
                    :style="{ backgroundColor: '#4683bb' }"
                  >
                    單選
                  </div>
                </div>
              </div>
            </template>

            <v-select
              v-model="item.value"
              :options="item.options"
              :clearable="item.required"
              :reduce="option => option.id"
              label="name"
              :multiple="item.muti"
              :placeholder="`請選擇 ${item.plan_info.name}`"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <!-- 更新附加商品 -->
    <update-extra-modal
      v-if="selected"
      ref="updateExtraModal"
      :extra-data-item="selected"
      :extra-product-options="productData"
      :product-options="productOptions"
      :plan-options="planOptions"
      @refetch-data="initExtraArray"
      @edit-extra-product="editExtraProduct"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BImg, VBTooltip, BCardHeader, BCardBody, BFormCheckbox, BFormGroup, // BOverlay,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import store from '@/store'
import UpdateExtraModal from './components/ExtraUpdateModal.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import { useProductView, useProductSetting } from '../useProduct'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BFormGroup,
    BCardHeader,
    BCardBody,
    draggable,
    vSelect,
    BFormCheckbox,
    UpdateExtraModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    // (轉換)方案名稱
    resolvePlanName(planId) {
      if (!planId) return '方案不存在'
      const planInfo = this.planOptions.find(el => el.id === planId)
      return planInfo ? planInfo.name : planId
    },

    // (過濾)方案商品
    productOptionsFilter(planId) {
      if (!planId) return []
      return this.productOptions.filter(f => f.plan_id === planId)
    },

    // (更新)排序
    updateProductSort() {
      this.previewExtraProduct()
      this.isExtraProductUpdate = true
    },

    // (觸發)新增/編輯附加商品
    onSubmitUpdate(item) {
      const auth = this.checkAuthAbility('product', 'API.Admin.Product.Update')
      if (!auth) return
      if (!item.id) {
        this.selected = {
          ...item,
        }
        setTimeout(() => { this.$refs.updateExtraModal.getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.updateExtraModal.getData() }, 200)
    },

    // (刪除)附加商品
    onSubmitRemove(item) {
      const selectIndex = this.productData.findIndex(el => el.planId === item.planId)
      if (selectIndex === -1) return

      const productItem = this.productData[selectIndex]

      this.$swal({
        title: '移除附加商品方案',
        text: `你確定要移除附加商品方案 ${this.resolvePlanName(productItem.planId)} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.productData.splice(selectIndex, 1)
          this.previewExtraProduct()
          this.isExtraProductUpdate = true
        }
      })
    },

    // (更新)單一附加商品
    editExtraProduct(item) {
      const selectIndex = this.productData.findIndex(el => el.planId === item.planId)
      if (selectIndex === -1) {
        this.productData.push(item)
        this.previewExtraProduct()
        this.isExtraProductUpdate = true
        return
      }
      this.$set(this.productData, selectIndex, item)
      this.previewExtraProduct()
      this.isExtraProductUpdate = true
    },

    // (更新)附加商品
    submitExtraProductUpdate() {
      if (!this.checkAuthAbility('product', 'API.Admin.Product.Update')) return
      if (this.isLoadingBusy) return

      const resolveData = this.productData.map(item => {
        const resolve = {
          ...item,
          options: [],
        }
        resolve.options = item.options.filter(id => {
          const matchingOption = this.productOptions.find(el => el.id === id)
          return matchingOption && matchingOption.plan_id === item.planId
        })
        return resolve
      })

      const productItem = store.state['admin-product'].productDataInfo

      this.setProductUpdate({
        id: productItem.id,
        data: {
          name: productItem.name,
          display: productItem.display,
          require_kyc: productItem.require_kyc,
          price: productItem.price,
          plan_id: productItem.plan_id,
          extra_product: resolveData,
        },
      })
        .then(response => {
          this.useAlertToast(response.data.success, response.data.message)
          this.isBusy = false

          const newStoreData = {
            ...productItem,
            extra_product: resolveData,
          }
          store.commit('admin-product/UPDATE_PRODUCT_DATA_INFO_STATE', newStoreData)
          this.initExtraArray()
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
  },
  setup() {
    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      planOptions,
      blankPlanInfoData,
      setProductUpdate,
    } = useProductView()

    const {
      syncObject,
      getMetaListData,
    } = useProductSetting()

    const productData = ref([])
    const productOptions = ref([])
    const previewExtraArray = ref([])
    const isExtraProductUpdate = ref(false)
    const isLoadingBusy = ref(true)
    const isBusy = ref(false)

    const blankProductData = {
      id: null,
      ssid: null,
      name: null,
      price: 0,
      type: null,
      display: false,
      branch_id: null,
      plan_id: null,
    }

    // (檢查)是否為主要商品類型
    const isProductMainType = type => store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)

    // (獲取)方案
    const getPlanData = callback => {
      planOptions.value = []
      getMetaListData({ key: 'plan', queryParams: { noPagination: true } })
        .then(response => {
          const { data } = response.data.data
          data.forEach(item => {
            const resolve = {
              ...syncObject({ id: null, name: null, type: 'host' }, item),
            }
            const isMainType = isProductMainType(resolve.type)
            if (!isMainType) {
              planOptions.value.push(resolve)
            }
          })
          callback()
        })
    }

    // (獲取)分站商品
    const getProductListData = callback => {
      const productItem = store.state['admin-product'].productDataInfo
      getMetaListData({ key: 'product', queryParams: { noPagination: true } })
        .then(response => {
          const { data } = response.data.data
          const resolveData = data.map(item => {
            const resolve = {
              ...syncObject(blankProductData, item),
              plan_info: item.plan_id && item.plan_info ? syncObject(blankPlanInfoData, item.plan_info) : syncObject(blankPlanInfoData, {}),
            }
            return resolve
          })
          productOptions.value = resolveData.filter(item => item.branch_id === productItem.branch_id && item.display)
          callback()
        })
    }

    // (加載)顯示預覽
    const previewExtraProduct = () => {
      previewExtraArray.value = productData.value.map(item => {
        const planInfo = planOptions.value.find(el => el.id === item.planId)

        const resolveProductOptions = item.options
          .map(id => productOptions.value.find(el => el.id === id))
          .filter(Boolean)

        const resolve = {
          plan_info: planInfo,
          options: resolveProductOptions,
          required: item.required,
          muti: item.muti,
          value: null,
        }

        return resolve
      })
    }

    // (檢查)附加商品
    const checkExtraProduct = () => {
      const productItem = store.state['admin-product'].productDataInfo
      productData.value = productItem.extra_product.map(item => {
        const resolve = {
          ...item,
          options: [],
        }
        resolve.options = item.options.filter(id => {
          const matchingOption = productOptions.value.find(el => el.id === id)
          return matchingOption && matchingOption.plan_id === item.planId
        })
        return resolve
      })
    }

    // (初始化)附加商品
    const initExtraArray = () => {
      isLoadingBusy.value = true
      isExtraProductUpdate.value = false
      getPlanData(() => {
        getProductListData(() => {
          checkExtraProduct()
          previewExtraProduct()
          isLoadingBusy.value = false
        })
      })
    }

    initExtraArray()

    return {
      productData,
      previewExtraArray,
      isBusy,
      isLoadingBusy,
      isExtraProductUpdate,
      planOptions,
      productOptions,
      useAlertToast,
      useHttpCodeAlert,
      syncObject,

      isProductMainType,
      getPlanData,
      setProductUpdate,
      initExtraArray,
      previewExtraProduct,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.extra-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgba(65, 159, 217, 1);
}
</style>

<style lang="scss" scoped>
.checkbox-label {
  font-size: 14px;
  cursor: pointer;
}
.list-group-item {
  &:hover {
    background: transparent !important;
  }
}

.loading-area {
  border-radius: 5px;
}

.alert-icon {
  position: relative;
  top: -2px;
  width: 24px;
  transform: scale(1.05);
  animation: btn-breathe .8s linear infinite;
}

.preview-badge {
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

</style>
