<template>
  <div>
    <!-- 頁面: 訂單列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('order', 'API.Admin.Order')">
      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="3"
              sm="6"
              class="mb-md-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchStateType"
                :options="stateTypeOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                訂單列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('order', 'API.Admin.Order.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="linkOrderCreate"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getOrderListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 訂單編號 -->
          <template #cell(ssid)="data">
            <div class="d-flex align-items-end">
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                @click="linkOrderView(data.item)"
              >
                {{ data.item.ssid }}
              </b-link>

              <div
                v-if="data.item.remark_display"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  src="/dashboard/admin/images/table/chat-remark.svg"
                  height="20"
                  width="20"
                  class="cursor-pointer mb-25 ml-50"
                />
              </div>
            </div>
          </template>

          <!-- 欄位: 備註 -->
          <template #cell(content)="data">
            <div
              class="table-col item-description"
              @click="onSubmitShowContent(data.item)"
            >
              {{ data.item.content ? data.item.content : '---' }}
            </div>
          </template>

          <!-- 欄位: 會員名稱 -->
          <template #cell(customer)="data">
            <div class="table-col">
              <b-media
                v-if="data.item.customer && data.item.customer_info"
                vertical-align="center"
              >
                <template #aside>
                  <b-link @click="linkCustomerInfo(data.item.customer_info)">
                    <b-avatar
                      size="32"
                      :src="data.item.customer_info.image"
                      :text="avatarText(data.item.customer_info.name)"
                      variant="light-primary"
                    />
                  </b-link>
                </template>
                <div class="d-block text-nowrap selection-group d-flex align-items-center">
                  <b-link
                    class="font-weight-bold d-block text-nowrap show-text"
                    @click="onSubmitRemark(data.item)"
                  >
                    {{ data.item.customer_info.name }} {{ data.item.customer_info.family_name }}
                  </b-link>
                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="篩選"
                    class="selection-btn-icon-show"
                    @click="searchCol({
                      name: '會員SSID',
                      key: 'search_query',
                      value: data.item.customer,
                    })"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/filter2.svg"
                      height="14"
                      width="14"
                      class="cursor-pointer mb-25"
                    />
                  </span>

                  <b-link
                    v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')"
                    v-b-tooltip.hover.focus.v-secondary
                    title="會員詳情"
                    class="selection-btn-icon-show"
                    @click="linkCustomerInfo(data.item.customer_info)"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/external-link.svg"
                      height="14"
                      width="14"
                      class="cursor-pointer mb-25"
                    />
                  </b-link>
                </div>
                <div
                  class="text-muted item-description-email"
                >
                  {{ data.item.customer_info.account }}
                </div>
              </b-media>

              <div
                v-else
                class="text-nowrap"
                @click="onSubmitServiceEdit(data.item)"
              >
                <b-avatar
                  size="32"
                  variant="light-primary"
                />
                <small class="text-muted ml-50">暫無資料</small>
              </div>
            </div>
          </template>

          <!-- 欄位: 狀態 -->
          <template #cell(state)="data">
            <div class="table-col">
              <b-badge
                v-if="data.item.state === 0 && data.item.price === 0"
                variant="light-secondary"
              >
                待確認
              </b-badge>

              <b-badge
                v-else
                :variant="ui.stateType[2][data.item.state]"
              >
                {{ ui.stateType[0][data.item.state] }}
              </b-badge>
            </div>
          </template>

          <!-- 欄位: 繳費方式 -->
          <template #cell(payment_id)="data">
            <div
              v-if="data.item.payment_id === null"
              class="table-col payment-type"
            >
              <p class="text-muted">
                尚未設置
              </p>
            </div>

            <div
              v-else
              class="table-col"
            >
              <div
                v-if="!data.item.payment_type"
                class="payment-type"
              >
                <!-- <img
                  src="/dashboard/admin/images/sideIcon/order-list.svg"
                  height="22"
                  width="30"
                > -->
                <p>系統</p>
              </div>

              <div
                v-else
                class="payment-type"
              >
                <p>{{ ui.paymentType[data.item.payment_type] }}</p>
              </div>
            </div>
          </template>

          <!-- 欄位: 金額 -->
          <template #cell(price)="data">
            <div class="table-col payment-price text-nowrap">
              $ {{ parseInt(data.item.price, 10).toLocaleString() }}
            </div>
          </template>

          <!-- 欄位: 上次更新 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-remark-botton-alert actions-link-btn mr-25"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="備註留言"
                  src="/dashboard/admin/images/table/chat.svg"
                  class="actions-link-btn-image"
                  rounded
                />
                <div
                  v-if="data.item.remark_display"
                  class="state-dot dot-red"
                />
              </div>

              <div
                v-if="checkAuthAbility('order', 'API.Admin.Order.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitShowContent(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="快速編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('order', 'API.Admin.Order.Id')"
                class="actions-link-btn mr-25"
                @click="linkOrderView(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="訂單詳情"
                  src="/dashboard/admin/images/table/menu.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>

      <remark-modal
        v-if="selected"
        ref="orderRemarkExplorerModal"
        remark-explorer-id="orderRemarkExplorerModal"
        :key-array="keyArray"
        :is-hiden-emit="true"
        :use-callback-data="false"
        @emit-hidden-function="refetchTable"
        @call-back-data="() => {}"
      >
        <template v-slot:header-info>
          <div
            v-if="selected"
            class="p-2"
          >
            <b-row>
              <b-col
                cols="21"
                lg="6"
                class="d-flex justify-content-between flex-column mb-1"
              >
                <div class="d-flex justify-content-start">
                  <div class="profile-image">
                    <b-avatar
                      size="9rem"
                      rounded
                      :src="selected.customer_info.image"
                      variant="light-secondary"
                    />
                  </div>

                  <div class="d-flex flex-column ml-1">
                    <div class="mb-50">
                      <h2 class="mb-0">
                        {{ selected.customer_info.name }} {{ selected.customer_info.family_name }}
                      </h2>
                      <span class="show-text">{{ selected.customer_info.account }}</span>
                    </div>

                    <div class="text-nowrap">
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="信箱驗證"
                        rounded
                        fluid
                        class="user-state-icon"
                        :style="{ 'opacity': selected.customer_info.email_state === 2 ? 1 : 0.3 }"
                        :src="require('@/assets/images/pages/customer/email.svg')"
                      />

                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="電話驗證"
                        rounded
                        fluid
                        class="user-state-icon"
                        :style="{ 'opacity': selected.customer_info.phone_state === 2 ? 1 : 0.3 }"
                        width="28"
                        :src="require('@/assets/images/pages/customer/security.svg')"
                      />

                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="身分驗證"
                        rounded
                        fluid
                        class="user-state-icon"
                        :style="{ 'opacity': selected.customer_info.kyc_state === 2 ? 1 : 0.3 }"
                        :src="require('@/assets/images/pages/customer/id-card.svg')"
                      />

                    </div>
                  </div>
                </div>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="訂單編號"
                  class="m-0"
                >
                  <div class="mt-50">
                    <b-link
                      class="font-weight-bold d-block text-nowrap show-text"
                      :to="{ name: 'admin-order-view', params: { id: selected.id } }"
                    >
                      {{ selected.ssid }}
                    </b-link>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="上次登入"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ selected.customer_info.last_login_at ? moment(selected.customer_info.last_login_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="上次更新"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ selected.customer_info.updated_at ? moment(selected.customer_info.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div
            v-else
            class="mb-1"
          />
        </template>
      </remark-modal>

      <b-modal
        v-if="selected"
        id="description-modal"
        centered
        @hidden="handleHide"
        @close="handleHide"
      >
        <template #modal-title>
          <h4 class="m-0">
            訂單備註
          </h4>
        </template>

        <div class="modal-content-area">
          <!-- 標籤 -->
          <div>
            <div class="font-weight-bold mb-1 d-flex justify-content-between w-100 align-items-center">
              <h5 class="mb-0">
                標籤
              </h5>
            </div>
          </div>

          <hr>

          <!-- 備註 -->
          <div>
            <div class="font-weight-bold mb-1 d-flex justify-content-between w-100 align-items-center">
              <h5 class="mb-0">
                備註
              </h5>

              <div
                v-if="editCol === 'content'"
                class="mb-50"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                  width="18"
                  class="mr-75 cursor-pointer"
                  @click="confirmEditCol"
                />

                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                  width="18"
                  class="cursor-pointer"
                  @click="cancelEditCol"
                />
              </div>

              <div v-else>
                <div
                  class="actions-link-btn mr-25"
                  @click="selectEditCol('content')"
                >
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="編輯"
                    src="/dashboard/admin/images/table/edit.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>

            <div v-if="editCol === 'content'">

              <b-form-textarea
                v-model="editColData"
                rows="5"
                no-resize
                :placeholder="selected.content ? selected.content : '感謝您的支持及購買，如有任何疑問，請隨時聯絡我們。'"
              />
            </div>

            <div v-else>
              <span
                v-if="selected.content"
                class="font-weight-bold"
              >
                {{ selected.content }}
              </span>

              <span
                v-else
                class="text-muted"
              >
                該訂單無任何備註
              </span>
            </div>
          </div>
        </div>

        <template #modal-footer>
          <div>
            <b-button
              variant="primary"
              :disabled="isTableBusy"
              @click="handleOk"
            >
              <span v-if="!isTableBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BAvatar, BMedia, BBadge,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BModal, BFormTextarea, BSpinner, BButton, BCardBody, BAlert, BCardHeader,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'
import { useOrderList, useOrderSetting } from '../useOrder'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BBadge,
    BCard,
    BMedia,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BAvatar,
    BModal,
    BCardHeader,
    BFormInput,
    BFormTextarea,
    BSpinner,
    BCardBody,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
      editCol: null,
      editColData: null,
      isTableBusy: false,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark(item) {
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      const resolveArray = []
      resolveArray.push(`order-${item.id}`)
      if (item.customer_info) resolveArray.push(`customer-${item.customer_info.id}`)
      this.keyArray = resolveArray
      this.selected = JSON.parse(JSON.stringify(item))
      setTimeout(() => { this.$refs.orderRemarkExplorerModal.getData() }, 200)
    },

    // (顯示)備註
    onSubmitShowContent(item) {
      this.selected = JSON.parse(JSON.stringify(item))
      setTimeout(() => { this.$bvModal.show('description-modal') }, 200)
    },

    // (選取)編輯欄位
    selectEditCol(type) {
      this.editCol = type
      this.editColData = JSON.parse(JSON.stringify(this.selected[type]))
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      this.selected[this.editCol] = this.editColData
      this.editCol = null
    },

    // (關閉)確認編輯
    handleHide() {
      this.editCol = null
      this.$bvModal.hide('description-modal')
    },

    // (訂單)確認編輯
    handleOk() {
      if (this.isTableBusy) return
      this.isTableBusy = true
      this.setOrderUpdate({
        order_id: this.selected.id,
        content: this.editColData,
        tag_id: this.selected.tag_id || [],
      })
        .then(response => {
          this.editCol = null
          this.refetchData()
          this.useAlertToast(true, response.data.message)
          this.$bvModal.hide('description-modal')
          this.isTableBusy = false
        })
        .catch(error => {
          this.useAlertToast(false, error.response.data.message)
          this.isTableBusy = false
        })
    },

    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
      }
    },

    // (前往)訂單詳情
    linkOrderView(item) {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Id')
      if (!auth) return
      this.$router.push({
        name: 'admin-order-view',
        params: {
          id: item.id,
        },
      })
    },

    // (前往)訂單創建
    linkOrderCreate() {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Create')
      if (!auth) return
      this.$router.push({ name: 'admin-order-add' })
    },

    // (前往)會員詳情
    linkCustomerInfo(item) {
      const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-customer-view', params: { id: item.id } })
    },
  },
  setup() {
    const ORDER_ADMIN_STORE_MODULE_NAME = 'admin-order'

    if (!store.hasModule(ORDER_ADMIN_STORE_MODULE_NAME)) store.registerModule(ORDER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(ORDER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(ORDER_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,
      searchStateType,

      getOrderListData,
    } = useOrderList()

    const {
      ui,
      stateTypeOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      setOrderUpdate,
    } = useOrderSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      stateTypeOptions,
      avatarText,
      useAlertToast,
      dateConfig2,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,
      searchStateType,

      getOrderListData,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      setOrderUpdate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}

.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}

.payment-type {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    white-space: nowrap;
  }
  img {
    margin-right: 5px;
  }
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.modal-content-area {
  min-height: 200px;
  .description-title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .description-content {
    white-space: pre-wrap;
  }
}
.user-state-icon {
  max-width: 30px;
  margin-right: 10px;
}

.user-state-icon-hidden {
  opacity: 0 !important;
}
</style>
