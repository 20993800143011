<template>
  <div>
    <div class="text-right mb-50">
      <toggle-button
        v-model="showSidebar"
        :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
        :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
        :width="75"
        :height="30"
        :labels="{checked: '全部', unchecked: '配置圖'}"
        :sync="true"
        @input="onSubmitCloseUpdate"
      />
    </div>

    <b-row class="mb-2 mb-sm-0">
      <b-col
        cols="12"
        :xl="showSidebar ? 9 : 12"
      >
        <b-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <h4 class="mb-0 font-weight-bolder">
                配置圖
              </h4>

              <b-img
                v-if="isSpecUpdate"
                v-b-tooltip.hover.focus.v-secondary
                title="尚未儲存"
                class="alert-icon"
                :src="require('@/assets/images/commen/warning-yellow.svg')"
              />
            </div>

            <div
              v-if="!adminDeviceState.isBusyLoading"
              class="text-nowrap d-flex justify-content-end"
            >

              <div
                v-if="checkAuthAbility('device', 'API.Admin.Device.Update')"
                v-b-tooltip.hover.focus.v-secondary
                title="新增元件"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitAddSettingModal"
              >
                <b-img
                  src="/dashboard/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="initDeviceSpecData(true)"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="清空"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="submitClearAllSpecData"
              >
                <b-img
                  src="/dashboard/admin/images/table/delete-all.svg"
                  class="actions-link-btn-image"
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="儲存"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="submitDeviceUpdate"
              >
                <b-img
                  src="/dashboard/admin/images/table/save.svg"
                  class="actions-link-btn-image"
                />
              </div>
            </div>
          </div>

          <div
            v-if="!isSpecBusy && !adminDeviceState.isBusyLoading"
            class="animate__animated animate__fadeIn mt-1"
          >
            <grid-layout
              v-if="specDataDeploy.length > 0"
              :layout.sync="specDataDeploy"
              :col-num="12"
              :row-height="30"
              :is-draggable="true"
              :is-resizable="true"
              :vertical-compact="true"
              :use-css-transforms="true"
            >
              <grid-item
                v-for="(item, index) in specDataDeploy"
                :key="item.i"
                :static="item.static"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :class="[
                  `item-color-${item.type}`,
                  { 'item-type-required': item.required }
                ]"
                :style="{
                  touchAction: 'none',
                  zIndex: selectedIndex === item.i ? 10 : 0,
                }"
              >
                <div class="d-flex align-items-center justify-content-between py-25 px-50">
                  <div class="mt-25 d-flex align-items-center">
                    <div
                      class="header-title"
                      :class="{ 'text-danger': item.error}"
                    >
                      {{ item.label }}
                    </div>

                    <b-img
                      v-if="item.error"
                      v-b-tooltip.hover.focus.v-secondary
                      :title="errorLog[item.error]"
                      class="alert-icon"
                      height="20"
                      :src="require('@/assets/images/commen/warning-yellow.svg')"
                    />
                  </div>

                  <b-dropdown
                    variant="link"
                    no-caret
                    right
                    class="dropdown-action-btn"
                    @toggle="selectedIndex = item.i"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="onSubmitEditDevice(item, index)"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/edit.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>編輯</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="item.type !== 'blank' && item.static === false"
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="onSubmitBindDevice(item, index)"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/bind.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>綁定</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="onSubmitCopyDevice(item)"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>複製</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="(item.type === 'component' && item.value) || (item.type === 'slot' && item.bind)"
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="onSubmitCheckLinkDevice(item)"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/external-link.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>前往元件</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="item.static === false"
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="onSubmitRemoveDevice(item, index)"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/delete.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>移除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <div class="px-1">
                  <div class="area-grid-item">
                    <div
                      v-if="!item.busy"
                      class="area-grid-item-content"
                    >
                      <div class="item-image">
                        <VueLoadImage
                          v-if="item.type === 'slot' || item.type === 'component'"
                          :use-loading="true"
                          :use-failed="true"
                          :blank-height="'32px'"
                          :loading-width="30"
                          :loading-height="30"
                          :failed-width="60"
                          :failed-image="transDeviceErrorImage(item.type)"
                        >
                          <b-img
                            slot="image"
                            :src="`/dashboard/admin/images/deviceType/${item.key}.svg`"
                          />
                        </VueLoadImage>
                      </div>

                      <div class="item-title">
                        <div
                          v-if="item.type === 'slot' || item.type === 'component'"
                          class="mt-50"
                        >
                          <div
                            v-if="item.value || item.bind"
                            class="content-title"
                          >
                            {{ resolveDeviceIdInfo(item).name }}
                          </div>
                          <div
                            v-else
                            class="text-muted"
                          >尚未{{ item.type === 'component' ? '綁定元件' : '連接' }}
                          </div>

                          <div class="content-serialNumber">
                            <small>{{ resolveDeviceIdInfo(item).serialNumber }}</small>
                          </div>
                        </div>

                        <div
                          v-else
                          class="mt-50 content-title-empty"
                        />
                      </div>
                    </div>

                    <div
                      v-else
                      class="area-grid-item-content"
                    >
                      <div class="text-center">
                        <div>
                          <VueLoadImage
                            :use-loading="true"
                            :use-failed="true"
                            :blank-height="'32px'"
                            :loading-width="30"
                            :loading-height="30"
                            :failed-width="60"
                            :failed-image="transDeviceErrorImage(item.type)"
                          >
                            <b-img
                              slot="image"
                              :src="$store.state.app.themeImages.loadingImg"
                              :width="(item.w >= 2 && item.h > 1) ? 60 : 30"
                              :height="(item.w >= 2 && item.h > 1) ? 60 : 30"
                            />
                          </VueLoadImage>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="lock-icon">
                  <div
                    v-if="item.static"
                    v-b-tooltip.hover.v-secondary
                    title="鎖定中"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.lockImg"
                      width="18"
                      alt="鎖定"
                    />
                  </div>
                </div>
              </grid-item>
            </grid-layout>

            <div
              v-else
              class="text-center animate__animated animate__fadeIn empty-area"
            >
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
                class="empty-area-image"
              />
            </div>
          </div>

          <div
            v-else
            class="loading-area"
          >
            <b-img
              :src="$store.state.app.themeImages.loadingImg"
              rounded
              height="60"
              width="60"
            />
          </div>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :xl="showSidebar ? 3 : 0"
      >
        <b-card v-if="showSidebar && (isLargeScreen || isNotLargeScreen)">
          <h4 class="mb-0 font-weight-bolder">
            類別規格
          </h4>

          <div
            v-if="!isSpecBusy && !adminDeviceState.isBusyLoading"
            class="list-group mt-2"
          >
            <div
              v-for="(rule, index) in adminDeviceState.deviceDataInfo.device_type_info.properties"
              :key="index"
              class="list-group-item"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex py-50 align-items-center">
                  <div :style="{ width: '50px' }">
                    <VueLoadImage
                      :use-loading="true"
                      :use-failed="true"
                      :blank-height="'32px'"
                      :loading-width="30"
                      :loading-height="30"
                      :failed-width="32"
                      :failed-image="transDeviceErrorImage(rule.type)"
                      class="mr-50"
                    >
                      <b-img
                        slot="image"
                        :src="`/dashboard/admin/images/deviceType/${rule.key}.svg`"
                        height="40"
                        width="40"
                        class="icon-iamge"
                      />
                    </VueLoadImage>
                  </div>

                  <div class="d-block show-text">
                    <div>
                      <div class="mb-25">
                        <b-badge
                          :variant="rule.type === 'component' ? 'info' : 'warning'"
                          class="mr-25"
                        >
                          {{ rule.type === 'component' ? '元件' : '接點' }}
                        </b-badge>
                        <span class="font-weight-bold">{{ rule.label }} </span>
                        <small v-if="rule.key">( {{ rule.key }} )</small>
                      </div>
                      <div class="ml-25">
                        <small>
                          <span :class="rule.required ? 'text-danger' : ''">{{ rule.required ? '必填' : '選填' }}</span>,
                          數量{{ rule.limit ? `限制 ${rule.number ? rule.number : 0} 個` : '不限' }}
                        </small>
                      </div>
                    </div>
                  </div>

                </div>

                <div
                  v-if="checkAuthAbility('device', 'API.Admin.Device.Update')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增元件"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitAddDevice(rule.key, rule.required)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>
          </div>
        </b-card>

        <b-card v-if="showSidebar && selectedDevice && $store.getters['app/currentBreakPoint'] === 'xl'">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <h4 class="mb-0 font-weight-bolder">
                元件詳情
              </h4>

            </div>

            <div class="text-nowrap d-flex justify-content-end">
              <div
                class="actions-link-btn"
                @click="selectedDevice = null"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="返回"
                  src="/dashboard/admin/images/table/arrow.svg"
                  class="actions-link-btn-image"
                  rounded
                  height="30"
                  width="30"
                />
              </div>
            </div>
          </div>

          <div class="mt-2">
            <div v-show="!specDataDeploy[selectedIndex].busy">
              <device-setting-edit
                ref="refDeviceSettingEdit"
                :device-data="selectedDevice"
                :device-index="selectedIndex"
                :children-device-list="childrenDeviceList"
                :is-modal="false"
                @submit-bind-device="onSubmitBindDevice"
                @submit-update-device="onSubmitUpdateDevice"
                @submit-remove-device="onSubmitRemoveDevice"
              />
            </div>

            <div
              v-show="specDataDeploy[selectedIndex].busy"
              class="loading-area"
            >
              <b-img
                :src="$store.state.app.themeImages.loadingImg"
                rounded
                height="60"
                width="60"
              />
            </div>
          </div>
        </b-card>

        <div>
          <b-modal
            id="update-device-setting-modal"
            no-close-on-esc
            no-close-on-backdrop
            ok-title="確認"
            header-bg-variant="primary"
            ok-only
            centered
            hide-footer
            body-class="p-0"
          >
            <template #modal-title>
              <h4 class="m-0 model-header">
                更新元件
              </h4>
            </template>

            <div>
              <device-setting-edit
                ref="refDeviceSettingEdit"
                :device-data="selectedDevice"
                :device-index="selectedIndex"
                :children-device-list="childrenDeviceList"
                :is-modal="true"
                @submit-bind-device="onSubmitBindDevice"
                @submit-update-device="onSubmitUpdateDevice"
                @submit-remove-device="onSubmitRemoveDevice"
              />
            </div>
          </b-modal>
        </div>
      </b-col>
    </b-row>

    <admin-device-modal
      ref="adminDeviceExplorerModal"
      device-explorer-id="adminDeviceExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      :data-key="['id', 'name', 'serialNumber', 'device_type']"
      :set-search-device-type="searchDeviceType"
      :set-search-state="searchDeviceState"
      @emit-hidden-function="() => {}"
      @call-back-data="callbackDeviceExplorer"
    />

    <!-- 新增元件 -->
    <b-modal
      id="add-device-setting-modal"
      no-close-on-esc
      no-close-on-backdrop
      ok-title="確認"
      header-bg-variant="primary"
      ok-only
      centered
      hide-footer
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          新增元件
        </h4>
      </template>

      <div>
        <!-- 元件 -->
        <div v-if="deviceTypeGroup.components.length > 0">
          <div class="mb-1">
            <b-badge variant="info">
              <h4 class="text-light mb-0">
                元件
              </h4>
            </b-badge>
          </div>

          <b-row>
            <b-col
              v-for="(item, index) in deviceTypeGroup.components"
              :key="index"
              cols="12"
              lg="4"
              md="6"
              class="mb-2"
            >
              <div
                v-if="item.components.length > 1"
                class="button-mode"
              >
                <div class="button-type">
                  <div class="mb-1">
                    <VueLoadImage
                      :use-loading="true"
                      :use-failed="true"
                      :blank-height="'32px'"
                      :loading-width="30"
                      :loading-height="30"
                      :failed-width="40"
                      :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                    >
                      <b-img
                        slot="image"
                        :src="`/dashboard/admin/images/deviceType/${item.key}.svg`"
                        height="40"
                        width="40"
                      />
                    </VueLoadImage>
                  </div>

                  <h5 class="text-body-heading mb-25">
                    <span class=" text-primary">{{ item.label }}</span>
                  </h5>

                  <small>{{ item.key }}</small>
                </div>
                <div class="big-button">
                  <span
                    class="small-button"
                    @click="onSubmitAddDevice(item.key, true)"
                  >
                    <div class="button-small-type">
                      <h5 class="text-body-small-heading mb-0 text-danger">
                        必填
                      </h5>
                    </div>
                  </span>

                  <span
                    class="small-button"
                    @click="onSubmitAddDevice(item.key, false)"
                  >
                    <div class="button-small-type">
                      <h5 class="text-body-small-heading mb-0">
                        選填
                      </h5>
                    </div>
                  </span>
                </div>
              </div>

              <div
                v-else
                class="link-card text-center"
                @click="onSubmitAddDevice(item.key, item.components[0].required)"
              >
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${item.key}.svg`"
                    class="link-card-image"
                  />
                </VueLoadImage>

                <h5 class="text-body-heading mb-25">
                  <span class="">{{ item.label }}</span>
                </h5>

                <small>{{ item.key }}</small>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- 接點 -->
        <div v-if="deviceTypeGroup.slots.length > 0">
          <div class="mb-1">
            <b-badge variant="warning">
              <h4 class="text-light mb-0">
                接點
              </h4>
            </b-badge>
          </div>

          <b-row>
            <b-col
              v-for="(item, index) in deviceTypeGroup.slots"
              :key="index"
              cols="12"
              lg="4"
              md="6"
              class="mb-2"
            >
              <div
                v-if="item.components.length > 1"
                class="button-mode"
              >
                <div class="button-type">
                  <div class="mb-1">
                    <VueLoadImage
                      :use-loading="true"
                      :use-failed="true"
                      :blank-height="'32px'"
                      :loading-width="30"
                      :loading-height="30"
                      :failed-width="40"
                      :failed-image="'/dashboard/admin/images/deviceType/ZPlug.svg'"
                    >
                      <b-img
                        slot="image"
                        :src="`/dashboard/admin/images/deviceType/${item.key}.svg`"
                        height="40"
                        width="40"
                      />
                    </VueLoadImage>
                  </div>

                  <h5 class="text-body-heading mb-25">
                    <span class=" text-primary">{{ item.label }}</span>
                  </h5>

                  <small>{{ item.key }}</small>
                </div>
                <div class="big-button">
                  <span
                    class="small-button"
                    @click="onSubmitAddDevice(item.key, true)"
                  >
                    <div class="button-small-type">
                      <h5 class="text-body-small-heading mb-0 text-danger">
                        必填
                      </h5>
                    </div>
                  </span>

                  <span
                    class="small-button"
                    @click="onSubmitAddDevice(item.key, false)"
                  >
                    <div class="button-small-type">
                      <h5 class="text-body-small-heading mb-0">
                        選填
                      </h5>
                    </div>
                  </span>
                </div>
              </div>

              <div
                v-else
                class="link-card text-center"
                @click="onSubmitAddDevice(item.key, item.components[0].required)"
              >
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${item.key}.svg`"
                    class="link-card-image"
                  />
                </VueLoadImage>

                <h5 class="text-body-heading mb-25">
                  <span class="">{{ item.label }}</span>
                </h5>

                <small>{{ item.key }}</small>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- 空元件 -->
        <div>
          <div class="mb-1">
            <b-badge variant="secondary">
              <h4 class="text-light mb-0">
                空元件
              </h4>
            </b-badge>
          </div>

          <b-row>
            <b-col
              cols="12"
              lg="4"
              md="6"
              class="mb-2"
            >
              <div
                class="link-card text-center"
                @click="onSubmitAddDevice('blank', false)"
              >
                <h5 class="text-body-heading mb-25">
                  <span class="">空元件</span>
                </h5>

                <small>Blank</small>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <!-- 指定接點-->
    <b-modal
      id="device-slot-type-modal"
      no-close-on-esc
      no-close-on-backdrop
      ok-title="確認"
      header-bg-variant="primary"
      ok-only
      centered
      hide-footer
      body-class="p-0"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          選擇接點類型
        </h4>
      </template>

      <div>
        <b-table
          :items="deviceSlotType"
          :fields="[
            { label: '序號', key: 'index' },
            { label: '類別', key: 'device_type' },
            { label: '設定', key: 'use' },
          ]"
          responsive
          striped
          show-empty
          class="position-relative"
        >
          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 序號 -->
          <template #cell(index)="data">
            <div class="table-col">
              <span>{{ data.index + 1 }}</span>
            </div>
          </template>

          <!-- 欄位: 類別 -->
          <template #cell(device_type)="data">
            <div class="table-col">
              <div class="d-flex text-nowrap">
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/ZPlug.svg'"
                  class="mr-50"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${data.item.key}.svg`"
                    height="32"
                    width="32"
                    class="icon-iamge"
                  />
                </VueLoadImage>

                <div class="d-block text-nowrap show-text">
                  <div>
                    <div>{{ data.item.label }}</div>
                    <small class="text-muted">
                      {{ data.item.key }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 目前設定 -->
          <template #cell(use)="data">
            <div>
              <b-button
                variant="outline-primary"
                size="sm"
                class="text-nowrap"
                @click="callbackSlotTypeExplorer(data.item)"
              >選擇</b-button>
            </div>
          </template>

        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, VBTooltip, BCard, BImg, BBadge, BDropdown, BDropdownItem, BTable, BButton,
} from 'bootstrap-vue'
import VueGridLayout from 'vue-grid-layout'
import { ToggleButton } from 'vue-js-toggle-button'
// import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import { useDeviceView, useDeviceSetting } from '../useDevice'
import store from '@/store'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import AdminDeviceModal from '@/layouts/components/Modal/admin-device-modal/deviceModal.vue'
import DeviceSettingEdit from './components/DeviceSettingEdit.vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BBadge,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    VueLoadImage,
    AdminDeviceModal,
    ToggleButton,
    DeviceSettingEdit,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      selectedIndex: null,
      tempDeviceData: null,
      selectedDevice: null,
    }
  },
  computed: {
    adminDeviceState() {
      return this.$store.state['admin-device']
    },
    deviceTypeGroup() {
      const propertiesArray = this.adminDeviceState.deviceDataInfo.device_type_info.properties

      const groupedProperties = propertiesArray.reduce((acc, curr) => {
        const category = curr.type === 'component' ? 'components' : 'slots'

        const existing = acc[category].find(item => item.key === curr.key)

        const componentData = {
          required: curr.required,
          limit: curr.limit,
          number: curr.number,
        }

        if (existing) {
          existing.components.push(componentData)
        } else {
          acc[category].push({
            label: curr.label,
            key: curr.key,
            components: [componentData],
          })
        }

        return acc
      }, { components: [], slots: [] })
      return groupedProperties
    },
    isLargeScreen() {
      return !this.selectedDevice && this.$store.getters['app/currentBreakPoint'] === 'xl'
    },
    isNotLargeScreen() {
      return this.$store.getters['app/currentBreakPoint'] !== 'xl'
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },
  watch: {
    currentBreakPoint(newVal, oldVal) {
      if (newVal === 'xl' || oldVal !== 'xl') {
        this.onSubmitCloseUpdate()
      }
    },
  },
  methods: {
    moment,
    // (轉換)組件圖片
    transDeviceErrorImage(type) {
      if (type === 'slot') return '/dashboard/admin/images/deviceType/ZPlug.svg'
      return '/dashboard/admin/images/deviceType/Component.svg'
    },

    // (獲取)元件名稱
    resolveDeviceIdInfo(item) {
      if (item.type === 'component') {
        const deviceInfo = this.childrenDeviceList.find(f => f.id === item.value)
        if (deviceInfo) return deviceInfo
        return {
          id: item.value,
          name: '元件遺失',
          serialNumber: null,
        }
      }
      if (item.type === 'slot') {
        const deviceInfo = this.childrenDeviceList.find(f => f.id === item.bind)
        if (deviceInfo) return deviceInfo
        return {
          id: item.bind,
          name: '元件遺失',
          serialNumber: null,
        }
      }
      return {
        id: null,
        name: '空元件',
        serialNumber: null,
      }
    },

    // (關閉)設定
    onSubmitCloseUpdate() {
      this.$bvModal.hide('update-device-setting-modal')
      this.selectedDevice = null
      this.selectedIndex = null
    },

    // (觸發)綁定
    onSubmitBindDevice(item, index) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) {
        this.useAlertToast(false, '權限不足')
        return
      }
      this.selectedIndex = index
      if (item.type === 'slot') {
        this.searchDeviceState = [0, 1, 2, 3, 4]

        // 找尋可用接點
        const slotItem = this.deviceTypeOptions.find(f => f.type === item.key)
        if (!slotItem) {
          this.useAlertToast(false, '元件類別不存在')
          return
        }
        const slotProperties = slotItem.properties
        const foundTypes = []

        for (let i = 0; i < this.deviceTypeOptions.length; i += 1) {
          const device = this.deviceTypeOptions[i]

          if (device.type === item.key) break

          if (device.properties && device.properties.length > 0) {
            for (let j = 0; j < slotProperties.length; j += 1) {
              const property = slotProperties[j]

              for (let k = 0; k < device.properties.length; k += 1) {
                const devProperty = device.properties[k]

                if (devProperty.key === property.key) {
                  foundTypes.push(device.type)
                  break
                }
              }
            }
          }
        }
        this.searchDeviceType = foundTypes
      } else {
        this.searchDeviceType = [item.key]
        this.searchDeviceState = [0, 2]
      }
      setTimeout(() => { this.$refs.adminDeviceExplorerModal.getData() }, 200)
    },

    // (觸發)編輯
    onSubmitEditDevice(item, index) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) {
        this.useAlertToast(false, '權限不足')
        return
      }

      this.selectedDevice = item
      this.selectedIndex = index

      const webSize = this.$store.getters['app/currentBreakPoint']
      if (!this.showSidebar || webSize !== 'xl') {
        this.$bvModal.show('update-device-setting-modal')
      }

      setTimeout(() => {
        this.$refs.refDeviceSettingEdit.getData()
      }, 200)
    },

    // (觸發)複製
    onSubmitCopyDevice(item) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) {
        this.useAlertToast(false, '權限不足')
        return
      }

      const resolveAreaData = {
        label: item.label,
        type: item.type,
        key: item.key,
        value: null,
        required: item.required,
        error: null,
        x: 0,
        y: 0,
        w: item.w,
        h: item.h,
        i: this.specDataDeploy.length,
        static: false,
      }
      this.specDataDeploy.push(resolveAreaData)
      this.checkPropertiesRule()
      this.isSpecUpdate = true
    },

    // (觸發)新增
    onSubmitAddDevice(key, required) {
      const resolveData = {
        key,
        label: null,
        type: null,
        value: null,
        required,
        error: null,
      }

      if (key === 'blank') {
        resolveData.type = 'blank'
        resolveData.label = '空元件'
      }

      if (key !== 'blank') {
        const deviceProperties = store.state['admin-device'].deviceDataInfo.device_type_info.properties
        const propertiesRule = deviceProperties.find(f => f.key === key && f.required === required)
        if (!propertiesRule) {
          this.useAlertToast(false, '元件不存在')
          return
        }
        resolveData.label = propertiesRule.label
        resolveData.type = propertiesRule.type

        if (propertiesRule.limit === true) {
          const number = parseInt(propertiesRule.number, 10)
          const counter = this.specDataDeploy.filter(f => f.key === key && f.required === required).length
          if (counter >= number) {
            resolveData.error = 2
          }
        }
      }

      // (新增)配置圖元件
      const resolveAreaData = {
        label: resolveData.label,
        type: resolveData.type,
        key: resolveData.key,
        value: resolveData.value,
        bind: null,
        required: resolveData.required,
        error: resolveData.error,
        x: 0,
        y: 0,
        w: 4,
        h: 3,
        i: this.specDataDeploy.length,
        static: false,
        busy: false,
      }
      this.specDataDeploy.push(resolveAreaData)
      this.checkPropertiesRule()
      this.isSpecUpdate = true
      this.$bvModal.hide('add-device-setting-modal')
    },

    // (觸發)更新
    onSubmitUpdateDevice(item, index) {
      this.$set(this.specDataDeploy[index], 'busy', true)
      this.$set(this.specDataDeploy[index], 'static', item.static)
      this.$set(this.specDataDeploy[index], 'value', item.value)
      this.$set(this.specDataDeploy[index], 'bind', item.bind)
      this.refreshSpecDataDeployChildren()
      this.onSubmitCloseUpdate()
    },

    // (觸發)移除
    onSubmitRemoveDevice(item, index) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) {
        this.useAlertToast(false, '權限不足')
        return
      }

      if (item.value) {
        this.$swal({
          title: '移除元件',
          text: `你確定要移除元件 ${item.label} 嗎?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.specDataDeploy.splice(index, 1)
            this.isSpecUpdate = true
            if (this.selectedIndex === index) {
              this.checkPropertiesRule()
              this.onSubmitCloseUpdate()
            }
          }
        })
      } else {
        this.specDataDeploy.splice(index, 1)
        this.isSpecUpdate = true
        if (this.selectedIndex === index) {
          this.checkPropertiesRule()
          this.onSubmitCloseUpdate()
        }
      }
    },

    // (觸發)前往元件
    onSubmitCheckLinkDevice(item) {
      if (item.type === 'component' && item.value) {
        this.onSubmitLinkDevice(item.value)
      }
      if (item.type === 'slot' && item.bind) {
        this.onSubmitLinkDevice(item.bind)
      }
    },

    // (觸發)前往元件
    onSubmitLinkDevice(bindId) {
      if (this.isSpecUpdate) {
        this.$swal({
          title: '尚未儲存',
          text: '即將跳轉，是否先儲存配置圖設定',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: '立即儲存',
          cancelButtonText: '取消',
          denyButtonText: '直接前往',
          customClass: {
            confirmButton: 'btn btn-primary',
            denyButton: 'btn btn-outline-info ml-1',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.isConfirmed) {
              this.submitDeviceUpdate()
            } else if (result.isDenied) {
              this.$router.push({ name: 'admin-device-view', params: { id: bindId } })
            }
          })
      } else this.$router.push({ name: 'admin-device-view', params: { id: bindId } })
    },

    // (重新)獲取配置圖綁定元件
    refreshSpecDataDeployChildren() {
      const idArray = this.specDataDeploy.map(item => {
        if (item.type === 'slot') return item.bind
        return item.value
      }).filter(f => f)

      this.specDataDeploy.forEach(item => {
        let deviceId = null
        if (item.type === 'slot') deviceId = item.bind
        else deviceId = item.value
        if (deviceId) {
          const isInChildren = this.childrenDeviceList.find(f => f.id === deviceId)
          if (!isInChildren) {
            this.$set(this.specDataDeploy[item.i], 'busy', true)
          } else {
            this.$set(this.specDataDeploy[item.i], 'busy', false)
          }
        }
      })

      this.getDeviceInfoList(idArray, array => {
        this.childrenDeviceList = array
        this.checkPropertiesRule()
        this.specDataDeploy.forEach(item => {
          this.$set(this.specDataDeploy[item.i], 'busy', false)
        })
      })
    },
    // -------------------------------------------------------------------------------------------------
    // (觸發)新增元件談窗
    onSubmitAddSettingModal() {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) {
        this.useAlertToast(false, '權限不足')
        return
      }
      this.$bvModal.show('add-device-setting-modal')
    },

    // (獲取)元件資料
    callbackDeviceExplorer(device) {
      const deviceType = this.specDataDeploy[this.selectedIndex].type
      this.specDataDeploy[this.selectedIndex].error = null
      if (deviceType === 'component') {
        this.specDataDeploy[this.selectedIndex].value = device.id
        this.isSpecUpdate = true
        this.refreshSpecDataDeployChildren()
        this.onSubmitCloseUpdate()
      } else if (deviceType === 'slot') {
        this.onSubmitSlotTypeModal(device)
      }
    },
    // -------------------------------------------------------------------------------------------------
    // (選擇)接點類型
    onSubmitSlotTypeModal(item) {
      this.tempDeviceData = item
      const deviceType = this.deviceTypeOptions.find(f => f.type === item.device_type)
      if (!deviceType) {
        this.useAlertToast(false, '元件類別不存在')
        return
      }
      if (deviceType.properties.length > 1) {
        this.deviceSlotType = deviceType.properties.filter(f => f.type === 'slot')
        this.$bvModal.show('device-slot-type-modal')
      } else if (deviceType.properties.length === 1) {
        this.callbackSlotTypeExplorer(deviceType.properties[0])
      }
    },

    // (選擇)接點類型
    callbackSlotTypeExplorer(slotType) {
      this.isSpecUpdate = true
      const deviceItem = this.specDataDeploy[this.selectedIndex]
      deviceItem.value = null
      deviceItem.bind = this.tempDeviceData.id
      this.deviceSlotBind[deviceItem.i] = slotType
      this.refreshSpecDataDeployChildren()
      this.onSubmitCloseUpdate()
      this.$bvModal.hide('device-slot-type-modal')
    },
    // -------------------------------------------------------------------------------------------------
    // (清空)配置圖
    submitClearAllSpecData() {
      this.specDataDeploy = []
      this.isSpecUpdate = true
    },

    // (觸發)設定更新
    submitDeviceUpdate() {
      // 處理元件設定
      const childrenComponentIds = []
      const childrenSlotIds = []
      const childrenSlotArray = []
      const errorAlert = []

      this.specDataDeploy.forEach(item => {
        if (item.type === 'component' && item.value) childrenComponentIds.push(item.value)
        if (item.type === 'slot' && item.bind) childrenSlotIds.push(item.value)
        if (item.type === 'slot' && item.bind) childrenSlotArray.push(item)
      })

      // 判斷元件是否重複綁定
      const hasDuplicates = array => {
        const sortedArray = array.slice().sort()
        for (let i = 0; i < sortedArray.length - 1; i += 1) {
          if (sortedArray[i] === sortedArray[i + 1]) {
            return true
          }
        }
        return false
      }
      const isDuplicates = hasDuplicates(childrenComponentIds)
      if (isDuplicates) {
        this.useAlertToast(false, '綁定元件重複')
        return
      }

      // API更新元件設定
      this.isSpecBusy = true
      let successNum = 0
      let errorNum = 0

      // 完成
      const finishSetting = () => {
        if (errorNum !== 0) {
          const errorAlertText = `<div style="color: #f27474; font-size: 12px">( ${errorAlert.join('、')} )</div>`
          this.$swal({
            title: '綁定失敗',
            html: `
              元件綁定或解除失敗，是否先儲存配置圖設定?<br>
              ${errorAlertText}
            `,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            customClass: {
              confirmButton: 'btn btn-warning',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.submitSaveSpecUpdate()
            } else this.isSpecBusy = false
          })
        } else {
          this.submitSaveSpecUpdate()
        }
      }

      // (結束)返回
      const endFunction = () => {
        successNum += 1
        if (successNum + errorNum === 4) finishSetting()
      }

      // (等待)返回
      const waitFunctionBack = state => {
        if (state) {
          successNum += 1
          if (successNum + errorNum === 4) finishSetting()
        } else {
          errorNum += 1
          if (successNum + errorNum === 4) finishSetting()
        }
      }

      // -------------------------------------------------------------------------------------------------
      // API綁定接點
      const childSlotBindList = []

      childrenSlotArray.forEach((item, index) => {
        const currentId = this.adminDeviceState.deviceDataInfo.id
        const slotType = this.deviceSlotBind[item.i]
        if (slotType) {
          const resolveBindSlot = {
            serialNumber: `SNS${new Date().getTime()}-${index + 1}`,
            name: item.label,
            device_type: item.key,
            parent_id: currentId,
            connect_serialNumber: `SNC${new Date().getTime()}-${index + 1}`,
            connect_name: slotType.label,
            connect_device_type: slotType.key,
            connect_parent_id: item.bind,
            i: item.i,
          }
          childSlotBindList.push(resolveBindSlot)
        }
      })

      const updateMutiBindSlot = callback => {
        this.setDeviceMutiSlotBind({
          slots: childSlotBindList,
        })
          .then(response => {
            const successSlots = response.data.data.success
            const failCount = parseInt(response.data.data.fail_count, 10)

            successSlots.forEach(item => {
              const resolveSlots = {
                slot_id: item.slot_id,
                serialNumber: item.slot_info ? item.slot_info.serialNumber : null,
              }

              if (resolveSlots.serialNumber) {
                const specData = childSlotBindList.find(f => f.serialNumber === resolveSlots.serialNumber)
                this.$set(this.specDataDeploy[specData.i], 'value', resolveSlots.slot_id)
              }
            })

            // 清空接點綁定類型
            this.deviceSlotBind = {}

            if (failCount > 0) {
              errorAlert.push(`接點綁定失敗 ${failCount} 個`)
              callback(false)
            } else callback(true)
          })
          .catch(() => {
            errorAlert.push('接點綁定失敗')
            callback(false)
          })
      }

      // API解除接點綁定
      const childSlotUnbindIds = []

      const updateMutiUnbindSlot = () => {
        const slotList = []
        this.adminDeviceState.deviceDataInfo.child_info.forEach(item => {
          if (item.slot && item.connect_id) slotList.push({ id: item.id, connect_id: item.connect_id })
        })

        slotList.forEach(item => {
          if (!childrenSlotIds.includes(item.id)) childSlotUnbindIds.push(item.id)
        })

        if (childSlotUnbindIds.length > 0) {
          const mutiData = {
            slots: childSlotUnbindIds,
          }

          this.setDeviceMutiSlotUnbind(mutiData)
            .then(() => {
              waitFunctionBack(true)
            })
            .catch(() => {
              errorAlert.push('接點解除綁定失敗')
              waitFunctionBack(false)
            })
        } else endFunction()
      }

      // API綁定子元件
      const updateMutiBindDevice = callback => {
        const mutiData = {
          devices: childrenComponentIds,
          parent_id: this.adminDeviceState.deviceDataInfo.id,
        }

        this.setDeviceMutiBind(mutiData)
          .then(() => {
            callback(true)
          })
          .catch(() => {
            errorAlert.push('子元件綁定失敗')
            callback(false)
          })
      }

      // API解除綁定子元件
      const childList = []
      this.adminDeviceState.deviceDataInfo.child_info.forEach(item => {
        if (!item.slot) childList.push(item.id)
      })
      const childrenComponentUnbindIds = childList.filter(f => !childrenComponentIds.includes(f))

      const updateMutiUnbindDevice = callback => {
        const mutiData = {
          devices: childrenComponentUnbindIds,
        }

        this.setDeviceMutiUnbind(mutiData)
          .then(() => {
            callback(true)
          })
          .catch(() => {
            errorAlert.push('子元件解除綁定失敗')
            callback(false)
          })
      }

      if (childrenComponentIds.length > 0) {
        updateMutiBindDevice(response => waitFunctionBack(response))
      } else endFunction()

      if (childrenComponentUnbindIds.length > 0) {
        updateMutiUnbindDevice(response => waitFunctionBack(response))
      } else endFunction()

      if (childSlotBindList.length > 0) {
        updateMutiBindSlot(response => {
          waitFunctionBack(response)
          updateMutiUnbindSlot()
        })
      } else {
        endFunction()
        updateMutiUnbindSlot()
      }
    },

    // (觸發)更新配置圖
    submitSaveSpecUpdate() {
      const resolveSpecData = this.specDataDeploy.map(item => {
        const resolveData = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          static: item.static,
          type: item.type,
          label: item.label,
          key: item.key,
          required: item.required,
          value: item.value,
          bind: item.bind,
        }
        return resolveData
      })

      const resolveBaseData = this.syncObject({
        id: null,
        name: null,
        serialNumber: null,
        state: 0,
        brand_id: null,
        health: 100,
        place_id: null,
      }, this.adminDeviceState.deviceDataInfo)

      this.setDeviceUpdate({
        id: this.adminDeviceState.deviceDataInfo.id,
        data: {
          ...resolveBaseData,
          spec_data: resolveSpecData,
        },
      })
        .then(response => {
          const { data } = response.data
          const responseSpecData = data.spec_data
          if (responseSpecData) {
            const remainDeviceData = {
              ...store.state['admin-device'].deviceDataInfo,
            }
            remainDeviceData.spec_data = responseSpecData
            remainDeviceData.child_info = data.child_info ? data.child_info.map(item => {
              const child = {
                ...this.syncObject(this.blankChildrenInfo, item),
                slot: item.device_type_info ? item.device_type_info.slot : false,
                connect_id: item.connect_info ? item.connect_info.id : null,
              }

              // 處理綁定資料
              if (child.slot && child.connect_id) {
                const connectInfo = item.connect_info
                if (connectInfo.parent_id && connectInfo.parent_info) {
                  const bindDevice = {
                    ...this.syncObject(this.blankChildrenInfo, connectInfo.parent_info),
                    connect_id: child.id,
                  }
                  remainDeviceData.slot_list.push(bindDevice)
                  child.connect_id = bindDevice.id
                }
              }
              return child
            }) : []
            store.commit('admin-device/UPDATE_DEVICE_DATA_INFO_STATE', remainDeviceData)
          }
          this.isSpecUpdate = false
          this.isSpecBusy = false
          this.initDeviceSpecData()
          this.useAlertToast('success', '設定更新成功')
        })
        .catch(error => {
          this.isSpecBusy = false
          this.useAlertToast(false, error.response && error.response.data ? error.response.data.message : '設定更新失敗')
        })
    },
  },
  setup() {
    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      deviceTypeOptions,
      blankDeviceTypeData,
      blankSetting,
      blankSpecData,
      blankChildrenInfo,

      getMetaListData,
      getDeviceInfoList,
      setDeviceUpdate,
      setDeviceMutiBind,
      setDeviceMutiUnbind,
      setDeviceMutiSlotBind,
      setDeviceMutiSlotUnbind,
    } = useDeviceView()

    const {
      ui,
      syncObject,
    } = useDeviceSetting()

    const childrenDeviceList = ref([])
    const searchDeviceType = ref([])
    const searchDeviceState = ref([0, 2])

    const isSpecBusy = ref(false)
    const isSpecUpdate = ref(false)
    const specDataDeploy = ref([])
    const deviceSlotType = ref([])
    const deviceSlotBind = ref({})

    const showSidebar = ref(true)

    const errorLog = {
      1: '元件類別不存在',
      2: '超過設定數量上限',
      3: '元件不存在',
      4: '元件類型不相符',
      5: '連結斷開',
    }

    // (檢查)錯誤碼
    const checkPropertiesRule = () => {
      const deviceDataItem = store.state['admin-device'].deviceDataInfo

      deviceDataItem.device_type_info.properties.forEach(rule => {
        if (rule.required) {
          if (rule.limit) {
            const number = parseInt(rule.number, 10)
            let counter = 1
            specDataDeploy.value.forEach(item => {
              if (item.key === rule.key && item.required === rule.required) {
                if (counter > number) {
                  Object.assign(item, { error: 2 })
                }
                counter += 1
              }
            })
          }
        }
        if (!rule.required) {
          if (rule.limit) {
            const number = parseInt(rule.number, 10)
            let counter = 1
            specDataDeploy.value.forEach(item => {
              if (item.key === rule.key && item.required === rule.required) {
                if (counter > number) {
                  Object.assign(item, { error: 2 })
                }
                counter += 1
              }
            })
          }
        }
        specDataDeploy.value.forEach((item, index) => {
          Object.assign(item, { i: index })
          if (item.error) return

          if (item.value) {
            const itemInfo = deviceDataItem.child_info.find(f => f.id === item.value)

            if (!itemInfo) {
              if (item.type === 'component') {
                const newDevice = childrenDeviceList.value.find(el => el.id === item.value)
                if (!newDevice) Object.assign(item, { error: 3 })
              }
              if (item.type === 'slot') Object.assign(item, { value: null, bind: null })
            }

            if (itemInfo && item.type !== 'blank') {
              const filiterDeviceType = deviceTypeOptions.value.find(f => f.type === itemInfo.device_type)
              if (!filiterDeviceType) Object.assign(item, { error: 1 })
              if (filiterDeviceType) {
                const isDeviceTypeSlot = filiterDeviceType.slot ? 'slot' : 'component'
                if (item.type !== isDeviceTypeSlot) Object.assign(item, { error: 4, type: isDeviceTypeSlot })
              }
            }
          }
        })
      })
    }

    // (初始化)元件設定
    const initDeviceSpecData = state => {
      if (isSpecBusy.value && state) return
      isSpecBusy.value = true

      const deviceDataItem = store.state['admin-device'].deviceDataInfo

      specDataDeploy.value = []
      deviceSlotBind.value = {}

      getMetaListData({ key: 'deviceType', queryParams: { noPagination: true } })
        .then(response => {
          const { data } = response.data.data
          // (整理)元件類別規格
          deviceTypeOptions.value = data.map(item => {
            const resolve = {
              ...syncObject(blankDeviceTypeData, item),
            }

            resolve.properties = item.properties ? item.properties.map(el => syncObject(blankSetting, el)) || [] : []
            return resolve
          })

          store.commit('admin-device/UPDATE_DEVICE_TYPE_ARRAY_STATE', deviceTypeOptions.value)

          isSpecUpdate.value = false

          // 已設定
          if (deviceDataItem.spec_data.length > 0) {
            const { spec_data } = deviceDataItem

            spec_data.forEach((item, index) => {
              const resolveAreaData = {
                error: null,
                busy: false,
                ...item,
                i: index,
              }
              const itemInfo = deviceDataItem.child_info.find(f => f.id === item.value)

              if (itemInfo && item.type !== 'blank') {
                resolveAreaData.key = itemInfo.device_type
                resolveAreaData.bind = itemInfo.connect_id

                if (item.type === 'slot' && !item.bind) resolveAreaData.error = 5
              }

              if (item.type === 'blank') resolveAreaData.label = '空元件'

              specDataDeploy.value.push(resolveAreaData)
            })
          }

          // 未被綁定 - 加載預設
          if (deviceDataItem.spec_data.length === 0 && deviceDataItem.child_info.length === 0) {
            deviceDataItem.device_type_info.properties.forEach(rule => {
              if (rule.required) {
                if (rule.limit) {
                  const number = parseInt(rule.number, 10)
                  for (let i = 0; i < number; i += 1) {
                    const resolveAreaData = {
                      ...blankSpecData,
                      i: specDataDeploy.value.length,
                      error: null,
                      busy: false,
                    }
                    resolveAreaData.type = rule.type
                    resolveAreaData.key = rule.key
                    resolveAreaData.label = rule.label
                    resolveAreaData.required = rule.required
                    isSpecUpdate.value = true
                    specDataDeploy.value.push(resolveAreaData)
                  }
                }

                if (!rule.limit) {
                  const resolveAreaData = {
                    ...blankSpecData,
                    i: specDataDeploy.value.length,
                    error: null,
                  }
                  resolveAreaData.type = rule.type
                  resolveAreaData.key = rule.key
                  resolveAreaData.label = rule.label
                  resolveAreaData.required = rule.required
                  isSpecUpdate.value = true
                  specDataDeploy.value.push(resolveAreaData)
                }
              }
            })
          }

          // 被綁定 - 載入綁定
          deviceDataItem.child_info.forEach(item => {
            const resolveAreaData = {
              error: null,
              busy: false,
              ...blankSpecData, // x, y, w, h, static, type, label, key, required, value, bind
              i: specDataDeploy.value.length,
            }

            resolveAreaData.label = item.name
            resolveAreaData.key = item.device_type
            resolveAreaData.value = item.id
            resolveAreaData.bind = item.connect_id
            resolveAreaData.type = item.slot ? 'slot' : 'component'
            if (item.slot && item.connect_id) {
              const inInArray = specDataDeploy.value.find(f => f.value === item.id)
              if (!inInArray) {
                isSpecUpdate.value = true
                specDataDeploy.value.push(resolveAreaData)
              }
            }
            if (!item.slot && item.id) {
              const inInArray = specDataDeploy.value.find(f => f.value === item.id)
              if (!inInArray) {
                isSpecUpdate.value = true
                specDataDeploy.value.push(resolveAreaData)
              }
            }
          })

          // (獲取)當前元件資訊
          const idArray = specDataDeploy.value.map(item => {
            if (item.type === 'slot') return item.bind
            return item.value
          }).filter(f => f)

          getDeviceInfoList(idArray, array => {
            childrenDeviceList.value = array
            checkPropertiesRule()
            isSpecBusy.value = false
          })
        })
    }

    initDeviceSpecData(true)

    return {
      useAlertToast,
      useHttpCodeAlert,
      blankChildrenInfo,

      deviceTypeOptions,
      childrenDeviceList,
      searchDeviceType,
      searchDeviceState,
      errorLog,

      isSpecBusy,
      isSpecUpdate,
      specDataDeploy,
      deviceSlotType,
      deviceSlotBind,
      showSidebar,

      ui,
      syncObject,
      initDeviceSpecData,
      checkPropertiesRule,
      setDeviceUpdate,
      setDeviceMutiBind,
      setDeviceMutiUnbind,
      setDeviceMutiSlotBind,
      setDeviceMutiSlotUnbind,
      getDeviceInfoList,
    }
  },
}
</script>

<style>
.dropdown-action-btn button {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.dark-layout {
  .item-color-slot {
    border-color: #ffbb53 !important;
    .header-title {
      color: #ffbb53;
    }
  }

  .item-color-blank {
    border-color: #bbbbbb !important;
    .header-title {
      color: #e4e5eb;
    }
  }

  .item-color-component {
    border-color: #65d8ff !important;
    .header-title {
      color: #65d8ff;
    }
  }

  .vue-grid-item {
    .resizing {
      opacity: 0.5;
    }

    .area-grid-item {
      .item-title {
        .content-title {
          color: #e4e5eb;
        }

        .content-serialNumber {
          color: #b4b4b4;
        }
      }
    }
  }
}

.vue-grid-layout, .empty-area {
  background: #b8b7bb3b;
  min-height: 50vh;
  border-radius: 5px;
}

.empty-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  border: 4px dashed black;
  border-radius: 5px;
  color: #000000;
  .content-title-empty {
    height: 50px;
  }

  .header-title {
    height: 20px;
    overflow: hidden;
  }
}

.item-type-required {
  border-style: solid !important;
}

.item-color-slot {
  border-color: #d37a13 !important;
  .header-title {
    color: #d37a13;
  }
}

.item-color-blank {
  border-color: #9c9c9c !important;
  .header-title {
    color: #5e5873;
  }
}

.item-color-component {
  border-color: #209ede !important;
  .header-title {
    color: #209ede;
  }
}

.vue-grid-item {
  // display: flex;
  // flex-direction: column; /* 垂直排列子元素 */
  // height: 100vh; /* 滿版高度 */
  box-sizing: border-box; /* 包括 padding 在內計算高度 */
  .resizing {
    opacity: 0.9;
  }

  .static {
    background: rgb(55, 55, 211);
  }

  .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  .no-drag {
    height: 100%;
    width: 100%;
  }

  .minMax {
    font-size: 12px;
  }

  .add {
    cursor: pointer;
  }

  .lock-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 8px 8px 0;
  }

  .area-grid-item {
    // background-color: #fff;
    padding: 5px;
    position: absolute;
    top: 30px;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: calc(100% - 30px);
    // transform: translate(-50%, -50%);
    .area-grid-item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
    .item-image {
      position: relative;
      width: 80%;
      height: 80%;
      max-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .item-title {
      height: 50px;
      text-align: center;
      .content-title {
        font-size: 1.3rem;
        font-weight: bolder;
        height: 20px;
        color: #303030;
        overflow: hidden;
        height: 20px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // max-width: 120px !important;
      }

      .content-serialNumber {
        color: #5c5c5c;
        font-size: 1rem;
      }
    }
  }
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  /* background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat; */
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // background-color: red;
  // transform: translate3d(0, 18px, 0px);
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
.list-group-item {
  &:hover {
    background: transparent !important;
  }
}

.object-device-setting {
  border-top: none;
  border-radius: 0 0 5px 5px !important;
}

.device-bind-info {
  border-radius: 5px;
}

.device-bind-info-error {
  border-style: dashed !important;
  border-color: rgb(236, 134, 134) !important;
}

.alert-icon {
  position: relative;
  // top: -2px;
  width: 26px;
  transform: scale(1.05);
  animation: btn-breathe .8s linear infinite;
}

.link-card {
  padding: 30px;
  border: 1px solid #d5d2dc1a;
  background-color: rgba(184, 183, 187, 0.113);
  // margin-bottom: 20px;
  border-radius: 10px;
  transition: transform ease-out 200ms;
  .link-card-image {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 10px;
  }

  .text-body-heading {
    margin-top: 5px;
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    .link-card-image {
      animation: breathe .8s linear infinite;
    }
  }
}

.button-mode {
  position: relative;
  height: 140px;
  border: 1px solid #d5d2dc1a;
  background-color: rgba(184, 183, 187, 0.113);
  margin-bottom: 10px;
  border-radius: 10px;
  transition: transform ease-out 200ms;
  min-height: 100%;
  .link-card-image {
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 10px;
  }

  .text-body-heading {
    margin-top: 5px;
    font-size: 16px;
  }

  .button-type {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.small-button {
  position: absolute;
  width: calc(100% - 17px);
  height: calc(50% - 17px);
  border: none;
  border-radius: 10px;
  background-color: rgba(184, 183, 187, 0.278);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  .button-small-type {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .link-card-small-image {
      max-width: 30px;
      max-height: 30px;
      margin-bottom: 5px;
    }

    .text-body-small-heading {
      font-size: 14px;
    }
  }
}

/* 当鼠标悬停在大按钮上时显示小按钮 */
.button-mode:hover {
  .small-button {
    cursor: pointer;
    opacity: 1;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      .link-card-small-image {
        animation: breathe .8s linear infinite;
      }
    }
  }
  .button-type {
    opacity: 0;
  }
}

/* 这里只是简单地定义了四个小按钮的位置 */
.big-button {
  .small-button:nth-child(1) {
    top: 10px;
    left: 10px;
  }
  .small-button:nth-child(2) {
    bottom: 10px;
    right: 10px;
  }
}
</style>
