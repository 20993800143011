<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯元件
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <!-- 名稱 -->
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="deviceData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入元件名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="7"
          >
            <!-- SN序號 -->
            <validation-provider
              #default="validationContext"
              name="SN序號"
              rules="required"
            >
              <b-form-group label-for="type">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      SN序號
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="deviceData.serialNumber"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入SN序號"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="5"
          >
            <b-form-group
              label-for="device_type"
              class="vs-disabled-show"
            >
              <!-- <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    狀態
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template> -->

              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    狀態
                    <span class="text-danger">*</span>
                  </label>

                  <div
                    v-if="deviceItem.state === 1"
                    v-b-tooltip.hover.v-secondary
                    title="元件使用中，無法修改"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.lockImg"
                      width="18"
                      alt="說明"
                    />
                  </div>
                </div>
              </template>

              <v-select
                v-model="deviceData.state"
                :options="deviceItem.state === 1 ? [
                  { label: '使用中', value: 1 },
                ] : [
                  { label: '可使用', value: 0 },
                  { label: '鎖定', value: 5 },
                  { label: '待處理', value: 2 },
                  { label: '送修', value: 3 },
                  { label: '淘汰', value: 4 },
                ]"
                class="w-100"
                :reduce="option => option.value"
                :clearable="false"
                :disabled="deviceItem.state === 1"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 健康度 -->
          <b-col cols="12">
            <b-form-group label-for="health">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    健康度
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <vue-slider
                v-model="deviceData.health"
              />

            </b-form-group>
          </b-col>

          <!-- 地點 -->
          <b-col cols="12">
            <b-form-group
              label-for="device_type"
              class="vs-disabled-show"
            >
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    地點
                    <span class="text-danger">*</span>
                  </label>

                  <div
                    v-if="deviceItem.parent_id"
                    v-b-tooltip.hover.v-secondary
                    title="已裝設在其他元件上，無法修改"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.lockImg"
                      width="18"
                      alt="說明"
                    />
                  </div>
                </div>
              </template>

              <v-select
                v-model="deviceData.place_id"
                :options="placeOptions"
                class="w-100"
                :reduce="option => option.id"
                :clearable="false"
                label="name"
                placeholder="請選擇地點"
                :disabled="deviceItem.parent_id !== null"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    {{ option.name }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- 品牌 -->
          <b-col cols="12">
            <b-form-group label-for="brand_id">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    品牌
                    <span class="text-danger">*</span>
                  </label>

                  <div
                    v-if="deviceItem.stock_id"
                    v-b-tooltip.hover.v-secondary
                    title="元件屬於庫存，無法修改"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.lockImg"
                      width="18"
                      alt="說明"
                    />
                  </div>
                </div>
              </template>

              <v-select
                v-model="deviceData.brand_id"
                :options="brandOptions"
                class="w-100 vs-disabled-show"
                :reduce="option => option.id"
                :clearable="false"
                label="name"
                placeholder="請選擇品牌"
                :disabled="deviceItem.stock_id !== null"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
  BRow, BCol, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDeviceSetting, useDeviceList } from '../../useDevice'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueSlider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
    }
  },
  computed: {
    deviceItem() {
      return this.$store.state['admin-device'].deviceDataInfo
    },
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDeviceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
        serialNumber: 'SN序號',
        state: '狀態',
        device_type: '類別',
        place_id: '地點',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.deviceData[Object.keys(requiredFields)[i]] === null || this.deviceData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setDeviceUpdate({
              id: this.deviceItem.id,
              data: { ...this.deviceData },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDeviceData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getBrandData()
      this.getPlaceData()
      const resolveData = {
        ...this.syncObject(this.blankDeviceData, this.deviceItem),
      }
      this.deviceData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      ui,
      syncObject,
      getMetaListData,
    } = useDeviceSetting()

    const {
      setDeviceUpdate,
    } = useDeviceList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const deviceData = ref(null)
    const brandOptions = ref([])
    const placeOptions = ref([])

    const blankDeviceData = {
      name: null,
      serialNumber: null,
      state: 0,
      brand_id: null,
      device_type: null,
      place_id: null,
      health: 100,
    }

    const blankBrandInfoData = {
      id: null,
      name: null,
      image: null,
    }

    deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))

    const resetDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDeviceData)

    const getBrandData = () => {
      getMetaListData({ key: 'brand', queryParams: { noPagination: true } })
        .then(response => {
          const { data } = response.data.data
          brandOptions.value = data.map(item => {
            const resolve = {
              ...syncObject(blankBrandInfoData, item),
            }
            return resolve
          })
        })
    }

    const getPlaceData = () => {
      getMetaListData({ key: 'place', queryParams: { noPagination: true } })
        .then(response => {
          const { data } = response.data.data
          placeOptions.value = data.map(item => {
            const resolve = {
              ...syncObject({ id: null, name: null }, item),
            }
            return resolve
          })
        })
    }

    return {
      ui,
      deviceData,
      brandOptions,
      placeOptions,
      blankDeviceData,
      setDeviceUpdate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,

      resetDeviceData,
      refFormObserver,
      getValidationState,
      resetForm,
      getBrandData,
      getPlaceData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}
.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}
.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}
</style>
