<template>
  <div>
    <!-- 匯入網域 -->
    <domain-view-dns-add-modal
      ref="domainAddModal"
      :table-type="type"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <!-- 匯入網域 -->
    <domain-view-dns-edit-modal
      ref="domainEditModal"
      :table-type="type"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <b-overlay
      :show="isCardBusy"
      variant="transparent"
      opacity="0.85"
      rounded="sm"
      blur="5px"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                {{ ui.tableType[type] }}
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.DNS.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </b-card-body>

        <!-- DNS列表 -->
        <b-table
          ref="refDataListTable"
          responsive
          :items="getDomainDNSList"
          :fields="tableColumns"
          striped
          hover
          show-empty
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumns.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-col">
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <h4 class="m-0 py-50">
                  <span
                    class="cursor-pointer"
                    @click="onSubmitUpdate(data.item)"
                  >
                    {{ data.item.name === '@' ? routerUrl : data.item.name }}
                  </span>
                </h4>

                <span
                  v-b-tooltip.hover.focus.v-secondary
                  title="複製"
                  class="selection-btn-icon-show ml-50"
                  @click="onSubmitCopyDNSName(data.item)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/copy.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </span>
              </div>

            </div>
          </template>

          <!-- 欄位: 內容 -->
          <template #cell(contentTxt)="data">
            <div
              class="m-0 py-50 cursor-pointer item-description"
              @click="onSubmitUpdate(data.item)"
            >
              {{ data.item.contentTxt }}
            </div>
          </template>

          <!-- 欄位: TTL -->
          <template #cell(ttl)="data">
            <span
              class="m-0 py-50 cursor-pointer text-nowrap"
              @click="onSubmitUpdate(data.item)"
            >
              {{ data.item.ttl == 1 ? '自動' : data.item.ttl }}
            </span>
          </template>

          <!-- 欄位: Proxy -->
          <template #cell(proxy)="data">
            <div @click="onSubmitUpdate(data.item)">
              <b-form-checkbox
                :checked="data.item.proxy"
                name="check-button"
                switch
                inline
                class="mt-50"
                disabled
              >
                <span class="text-nowrap">開啟</span>
              </b-form-checkbox>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(show_details)="data">
            <div class="d-flex text-nowrap">
              {{ data.item.show_details }}
              <div
                v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.DNS.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.DNS.Delete')"
                class="actions-link-btn mr-25"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/dashboard/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BImg, BRow, BCol, VBTooltip, BTable, BSkeletonTable, BPagination, BFormCheckbox, BCardBody, BOverlay,
} from 'bootstrap-vue'

import moment from 'moment'

import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import { useDomainDNSTable, useDomainSetting } from '../../useDomain'
import DomainViewDnsAddModal from './DomainViewDnsAddModal.vue'
import DomainViewDnsEditModal from './DomainViewDnsEditModal.vue'

export default {
  components: {
    BCard,
    BImg,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BCardBody,
    BSkeletonTable,
    BPagination,
    BFormCheckbox,
    DomainViewDnsAddModal,
    DomainViewDnsEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    moment,
    onSubmitCopyDNSName(item) {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const copyData = JSON.parse(JSON.stringify(item.name))
      if (!copyData) return
      navigator.clipboard.writeText(copyData)
        .then(() => {
          this.useAlertToast(true, '複製成功!', 'DNS名稱已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },
    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        if (this.$store.state['admin-service-domain'].domainInfo.reverse_proxy_id) {
          this.useSwalAlertWarning('新增DNS設定', '此網域已經反向代理，若直接新增可能會造成代理失效，是否執行新增')
            .then(result => {
              if (result.value) {
                this.$refs.domainAddModal.getData()
              }
            })
          return
        }
        this.$refs.domainAddModal.getData()
      } else setTimeout(() => { this.$refs.domainEditModal.getData(item) }, 200)
    },

    // (刪除)DNS
    onSubmitDelete(item) {
      if (this.isCardBusy) return
      this.useSwalAlertWarning('刪除DNS', `你確定要永久刪除 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.isCardBusy = true
            this.setDomainDNSAction({
              action: 'delete',
              data: {
                id: item.id,
                source: this.type,
                type: item.type,
              },
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', 'DNS已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
                this.isCardBusy = false
              })
          }
        })
    },
  },
  setup(props) {
    const {
      routerUrl,
      isCardBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchType,
      typeOptions,

      useAlertToast,
      useHttpCodeAlert,

      getDomainDNSList,
      setDomainDNSAction,
    } = useDomainDNSTable(props.type)

    const {
      ui,
    } = useDomainSetting()

    return {
      ui,

      routerUrl,
      isCardBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchType,
      typeOptions,

      useAlertToast,
      useHttpCodeAlert,

      getDomainDNSList,
      setDomainDNSAction,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-switch {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: rgba(70, 131, 187, 1) !important;
  }
  .custom-control-label {
    color: #495057 !important;
  }
}
</style>

<style lang="scss" scoped>

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }

  &:hover {
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
</style>
