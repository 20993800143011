import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useMachineSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = [{
    label: '未使用', value: 0,
  }, {
    label: '上架', value: 2,
  }, {
    label: '使用中', value: 1,
  }]

  const ownOptions = [{
    label: '自有機器', value: true,
  }, {
    label: '海外', value: false,
  }]

  const ui = {
    stateList: [{
      0: '未使用', 2: '上架', 1: '使用中',
    }, {
      0: 'light-secondary', 2: 'light-success', 1: 'light-info',
    }, {
      0: 'dot-gray', 2: 'dot-green', 1: 'dot-blue',
    }, {
      0: 'dot-gray', 2: 'dot-green-selected', 1: 'dot-blue-selected',
    }],
  }

  const resolveMachineType = type => {
    const lowerType = type.toLowerCase()
    switch (lowerType) {
      case 'domain':
        return {
          label: '網域',
          value: lowerType,
          color: '#8bced6',
        }
      case 'vps':
        return {
          label: 'VPS',
          value: lowerType,
          color: '#fab834',
        }
      case 'host':
        return {
          label: '實體',
          value: lowerType,
          color: '#cc0836',
        }
      case 'web':
        return {
          label: '虛擬',
          value: lowerType,
          color: '#2b5fba',
        }
      default:
        return false
    }
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    stateOptions,
    ownOptions,
    resolveMachineType,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useMachineList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedTableData,
    compareTableData,
    isSelectState,
    isSelectedAll,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  // const {
  //   getMetaListData,
  // } = useMachineSetting()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '', key: 'selected', sortable: false, searchable: false, select: true,
    },
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '類別', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '方案', key: 'plan', sortable: true, searchable: true, select: true,
    },
    {
      label: '機器綁定', key: 'own', sortable: true, searchable: true, select: true,
    },
    {
      label: '機櫃', key: 'rack_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '地點', key: 'place', sortable: false, searchable: false, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const searchState = ref(null)
  const searchOwn = ref(null)
  const searchType = ref(null)
  const searchPlan = ref(null)
  const planOptions = ref([])
  const placeOptions = ref([])
  const deviceTypeOptions = ref([])

  const blankMachineData = {
    id: null,
    name: null,
    state: 0,
    type: null,
    own: true,
    plan_id: null,
    rack_id: null,
    main_device_id: null,
    created_at: null,
    updated_at: null,
    remark_display: false,
  }

  const blankPlanInfoData = {
    id: null,
    name: null,
    type: null,
    description: null,
    spec: {},
  }

  const blankPlaceInfoData = {
    id: null,
    name: null,
    description: null,
  }

  const blankDeviceInfoData = {
    id: null,
    name: null,
    place_id: null,
    serialNumber: null,
  }

  const blankDeviceTypeInfoData = {
    id: null,
    name: null,
    type: null,
  }

  const setMachineDelete = (...arg) => store.dispatch('admin-machine/setMachineDelete', ...arg)
  const setMachineCreate = (...arg) => store.dispatch('admin-machine/setMachineCreate', ...arg)
  const setMachineUpdate = (...arg) => store.dispatch('admin-machine/setMachineUpdate', ...arg)
  const setMachineMutiUpdate = (...arg) => store.dispatch('admin-machine/setMachineMutiUpdate', ...arg)

  const getMachineListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value}`
    }

    if (searchType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};type: ${searchType.value}`
      } else resolveFilters = `type: ${searchType.value}`
    }

    if (searchPlan.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};plan_id: ${searchPlan.value}`
      } else resolveFilters = `plan_id: ${searchPlan.value}`
    }

    if (searchOwn.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};own: ${searchOwn.value}`
      } else resolveFilters = `own: ${searchOwn.value}`
    }

    store.dispatch('admin-machine/getMachineList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankMachineData, item),
            plan_info: item.plan_id && item.plan_info ? syncObject(blankPlanInfoData, item.plan_info) : syncObject(blankPlanInfoData, {}),
            main_device_info: item.main_device_id && item.main_device_info ? syncObject(blankDeviceInfoData, item.main_device_info) : syncObject(blankDeviceInfoData, {}),
            rack_info: item.rack_id && item.rack_info ? syncObject(blankDeviceInfoData, item.rack_info) : syncObject(blankDeviceInfoData, {}),
          }
          return resolve
        })
        compareTableData.value = resolveData
        planOptions.value = option.plan.map(item => {
          const resolve = {
            ...syncObject(blankPlanInfoData, item),
          }
          return resolve
        })

        placeOptions.value = option.place.map(item => {
          const resolve = {
            ...syncObject(blankPlaceInfoData, item),
          }
          return resolve
        })

        deviceTypeOptions.value = option.deviceType.map(item => {
          const resolve = {
            ...syncObject(blankDeviceTypeInfoData, item),
          }
          return resolve
        })

        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange, searchOwn, searchState, searchType, searchPlan], () => {
    refetchData()
  })

  return {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedTableData,
    compareTableData,
    isSelectState,
    isSelectedAll,
    refetchData,
    refetchTable,
    searchState,
    searchOwn,
    searchType,
    searchPlan,
    planOptions,
    placeOptions,
    deviceTypeOptions,
    blankDeviceInfoData,

    getMachineListData,
    setMachineDelete,
    setMachineCreate,
    setMachineUpdate,
    setMachineMutiUpdate,
    useAlertToast,
    useHttpCodeAlert,
  }
}
