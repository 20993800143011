import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
import router from '@/router'

export const useDomainSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    stateType: [{
      0: '未啟用', 1: '啟用中', 2: '待處理', 3: '關閉', 4: '逾期',
    }, {
      0: 'light-secondary', 1: 'light-success', 2: 'light-info', 3: 'light-danger', 4: 'light-warning',
    }],
    logStateType: [{
      0: '購買中', 1: '完成', 2: '失敗', 3: '已退款',
    }, {
      0: 'light-secondary', 1: 'light-success', 2: 'light-danger', 3: 'light-primary',
    }],
    actionType: [{
      0: '免費', 1: '購買託管', 2: '攜入', 3: '續約', 4: '匯入',
    }, {
      0: 'light-secondary', 1: 'light-primary', 2: 'light-warning', 3: 'light-success', 4: 'light-info',
    }],
    tabOptions: {
      0: '服務網域', 1: '服務紀錄',
    },
    tableType: {
      original: '原經銷商', reverse: '反向代理',
    },
    ttlList: {
      1: '自動',
      60: '1分鐘',
      120: '2分鐘',
      300: '5分鐘',
      600: '10分鐘',
      900: '15分鐘',
      1800: '30分鐘',
      3600: '1小時',
      7200: '2小時',
      18000: '5小時',
      43200: '12小時',
      86400: '1天',
    },
    dnsLogType: [{
      admin: '管理者', client: '會員',
    }, {
      admin: 'light-primary', client: 'light-warning',
    }],
  }

  const domainActionOptions = [
    { label: '網域購買託管', value: 1 },
    { label: '網域攜入', value: 2 },
    { label: '網域續約', value: 3 },
    { label: '免費網址', value: 0 },
    { label: '手動匯入', value: 4 },
  ]

  const ttlOptions = [
    { label: '尊重現有標頭', value: 0 },
    { label: '30秒', value: 30 },
    { label: '1分鐘', value: 60 },
    { label: '2分鐘', value: 120 },
    { label: '5分鐘', value: 300 },
    { label: '20分鐘', value: 1200 },
    { label: '30分鐘', value: 1800 },
    { label: '1小時', value: 3600 },
    { label: '2小時', value: 7200 },
    { label: '3小時', value: 10800 },
    { label: '4小時', value: 14400 },
    { label: '5小時', value: 18000 },
    { label: '8小時', value: 28800 },
    { label: '12小時', value: 43200 },
    { label: '16小時', value: 57600 },
    { label: '20小時', value: 72000 },
    { label: '1天', value: 86400 },
    { label: '2天', value: 172800 },
    { label: '3天', value: 259200 },
    { label: '4天', value: 345600 },
    { label: '5天', value: 432000 },
    { label: '8天', value: 691200 },
    { label: '16天', value: 1382400 },
    { label: '24天', value: 2073600 },
    { label: '1個月', value: 2678400 },
    { label: '2個月', value: 5356800 },
    { label: '6個月', value: 16070400 },
    { label: '1年', value: 31536000 },
  ]

  const ttlListOptions = [
    1, 60, 120, 300, 600, 900, 1800, 3600, 7200, 18000, 43200, 86400,
  ]

  const sslOptions = [
    { text: '關閉 (不安全)', value: 'off' },
    { text: '彈性', value: 'flexible' },
    { text: '完整', value: 'full' },
    { text: '完整 (嚴格)', value: 'strict' },
  ]

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    domainActionOptions,
    ttlOptions,
    ttlListOptions,
    sslOptions,
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useDomainServiceList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '網域', key: 'url', sortable: true, searchable: true, select: true,
    },
    {
      label: '姓名', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '約期 (年)', key: 'quantity', sortable: true, searchable: true, select: true,
    },
    {
      label: '開始時間', key: 'startTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '到期時間', key: 'endTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchAction = ref(null)

  const blankServiceDomainData = {
    id: null,
    ssid: null,
    suffix: null,
    url: null,
    state: 0,
    start_time: null,
    end_time: null,
    system_order_id: null,
    action: null,
    content: '',
    customer: null,
    prefix: null,
    quantity: 1,
    transKey: null,
    branch_id: null,
    domain_name_dealer_id: null,
    domain_name_agent_id: null,
    reverse_proxy_id: null,
    reverse_proxy_agent_id: null,
    remark_display: false,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  }

  const blankDealerInfoData = {
    id: null,
    name: null,
  }

  const blankDomainAgent = {
    id: null,
    agent: null,
  }

  const blankCustomerData = {
    id: null,
    image: null,
    account: null,
    name: null,
    family_name: null,
    branch_id: null,
    last_login_at: null,
    email_state: 0,
    phone_state: 0,
    kyc_state: 0,
    updated_at: null,
  }

  const setDomainServiceAdd = (...arg) => store.dispatch('admin-service-domain/setDomainServiceAdd', ...arg)
  const setDomainServiceUpdate = (...arg) => store.dispatch('admin-service-domain/setDomainServiceUpdate', ...arg)
  const setDomainActionUpdate = (...arg) => store.dispatch('admin-service-domain/setDomainActionUpdate', ...arg)

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchAction.value !== null) {
      resolveFilters = `action: ${searchAction.value}`
    }

    const queryParams = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    }

    store.dispatch('admin-service-domain/getDomainService', queryParams)
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankServiceDomainData, item),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: item.customer && item.customer_info ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null }, item.order) : syncObject({ id: null }, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchAction], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchAction,
    timeRange,
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
    refetchData,
    refetchTable,
    getDomainListData,
    setDomainServiceAdd,
    setDomainServiceUpdate,
    setDomainActionUpdate,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDomainActionList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
  } = useDomainServiceList()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '網域', key: 'url', sortable: true, searchable: true, select: true,
    },
    {
      label: '訂單', key: 'order', sortable: true, searchable: true, select: true,
    },
    {
      label: '姓名', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '約期 (年)', key: 'quantity', sortable: true, searchable: true, select: false,
    },
    {
      label: '開始時間', key: 'startTime', sortable: true, searchable: true, select: false,
    },
    {
      label: '到期時間', key: 'endTime', sortable: true, searchable: true, select: false,
    },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchAction = ref(null)
  const searchService = ref(1)

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchAction.value !== null) {
      resolveFilters = `action: ${searchAction.value}`
    }

    const queryParams = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    }
    if (searchService.value === 2) queryParams.onlyTrashed = true

    store.dispatch('admin-service-domain/getDomainList', queryParams)
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankServiceDomainData, item),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: item.customer && item.customer_info ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null, state: 0 }, item.order) : syncObject({ id: null, state: 0 }, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchAction], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchService,
    searchAction,
    timeRange,
    blankServiceDomainData,
    blankDealerInfoData,
    refetchData,
    refetchTable,

    getDomainListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDomainServiceView = () => {
  const {
    // useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
  } = useDomainServiceList()

  const blankBranchData = {
    id: null,
    name: null,
    icon: null,
    mail_valid_switch: false,
    phone_valid_switch: false,
  }

  const isServiceBusy = ref(false)
  const cloudflareSetting = ref(null)
  const isSaveCheck = ref(false)
  const setOrderServiceDomain = (...arg) => store.dispatch('admin-service-domain/setOrderServiceDomain', ...arg)
  const getOrderProductSearch = (...arg) => store.dispatch('admin-service-domain/getOrderProductSearch', ...arg)
  const setDomainReverseProxy = (...arg) => store.dispatch('admin-service-domain/setDomainServiceDNSReverseProxy', ...arg)
  const setDomainReverseProxyDelete = (...arg) => store.dispatch('admin-service-domain/setDomainServiceDNSReverseProxyDelete', ...arg)
  const setDomainLockUpdate = (...arg) => store.dispatch('admin-service-domain/setDomainLockUpdate', ...arg)

  const getDomainInfoData = (url, callback) => {
    if (!url) {
      router.push({ name: 'admin-service-domain' })
      return
    }

    store.dispatch('admin-service-domain/getDomainServiceInfo', { url })
      .then(response => {
        const { data } = response.data

        const resolveData = {
          ...syncObject(blankServiceDomainData, data),
          domain_name_dealer_info: data.domain_name_dealer_id && data.domain_name_dealer_info ? syncObject(blankDealerInfoData, data.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
          customer_info: data.customer && data.customer_info ? syncObject(blankCustomerData, data.customer_info) : syncObject(blankCustomerData, {}),
          branch_info: data.branch_id && data.branch_info ? syncObject(blankBranchData, data.branch_info) : syncObject(blankBranchData, {}),
          domain_name_agent_info: data.domain_name_agent_id && data.domain_name_agent_info ? syncObject(blankDomainAgent, data.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
          reverse_proxy_info: data.reverse_proxy_id && data.reverse_proxy_info ? syncObject(blankDealerInfoData, data.reverse_proxy_info) : syncObject(blankDealerInfoData, {}),
          reverse_proxy_agent_info: data.reverse_proxy_agent_id && data.reverse_proxy_agent_info ? syncObject(blankDomainAgent, data.reverse_proxy_agent_info) : syncObject(blankDomainAgent, {}),
        }
        store.commit('admin-service-domain/UPDATE_DOMAIN_INFO_STATE', resolveData)
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const checkDomainInfoState = callback => {
    // 整理filters
    const url = router.currentRoute.params.id
    const resolveFilters = `url: ${url}`

    store.dispatch('admin-service-domain/getDomainList', {
      filters: resolveFilters,
      noPagination: true,
      withTrashed: true,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        if (total <= 0) {
          callback(false)
          return
        }

        // (搜尋是否有啟用中的服務)
        const activatingService = data.find(item => item.state === 1)

        // (是否有啟用中的服務-否)
        if (!activatingService) {
          const firstService = data[0]
          if (firstService.state !== 0) {
            callback(firstService)
            return
          }
          callback(firstService)
          return
        }

        callback(activatingService)
      })
      .catch(() => callback(null))
  }

  const getDomainServiceSetting = url => {
    isServiceBusy.value = true
    isSaveCheck.value = false

    const blankCFSettingData = {
      browser_cache_ttl: 0,
      ipv6: false,
      ssl: 'off',
    }

    const blankForward301Data = {
      state: false,
      url: null,
    }

    store.dispatch('admin-service-domain/getDomainServiceSetting', { url })
      .then(response => {
        const { data } = response.data

        const resolveData = {
          ...syncObject(blankCFSettingData, data),
          forward301: data.forward301 ? syncObject(blankForward301Data, data.forward301) : syncObject(blankForward301Data, {}),
        }
        if (data.ipv6 === 'on') resolveData.ipv6 = true
        else resolveData.ipv6 = false

        cloudflareSetting.value = resolveData
        isServiceBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    isServiceBusy,
    isSaveCheck,
    cloudflareSetting,
    setOrderServiceDomain,
    getOrderProductSearch,
    checkDomainInfoState,
    getDomainInfoData,
    setDomainLockUpdate,
    setDomainReverseProxy,
    setDomainReverseProxyDelete,
    getDomainServiceSetting,
  }
}

// 服務紀錄
export const useDomainServiceInfoList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    searchQuery,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankServiceDomainData,
    blankDealerInfoData,
    blankDomainAgent,
    blankCustomerData,
  } = useDomainServiceList()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '訂單', key: 'order', sortable: true, searchable: true, select: false,
    },
    {
      label: '約期 (年)', key: 'quantity', sortable: true, searchable: true, select: true,
    },
    {
      label: '開始時間', key: 'startTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '到期時間', key: 'endTime', sortable: true, searchable: true, select: true,
    },
    {
      label: '姓名', key: 'customer', sortable: true, searchable: true, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: false,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', sortable: false, searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchAction = ref(null)

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    const url = router.currentRoute.params.id
    let resolveFilters = `url: ${url}`

    if (searchAction.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};action: ${searchAction.value}`
      } else resolveFilters = `action: ${searchAction.value}`
    }

    const queryParams = {
      search: searchQuery.value,
      filters: resolveFilters,
      noPagination: true,
      withTrashed: true,
      _: Date.now(),
    }

    store.dispatch('admin-service-domain/getDomainList', queryParams)
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankServiceDomainData, item),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
            customer_info: item.customer && item.customer_info ? syncObject(blankCustomerData, item.customer_info) : syncObject(blankCustomerData, {}),
            domain_name_agent_info: item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {}),
            order_info: item.system_order_id && item.order ? syncObject({ id: null, state: 0 }, item.order) : syncObject({ id: null, state: 0 }, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  watch([searchQuery, timeRange, searchAction], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    searchQuery,
    tableColumns,
    dataMeta,
    searchAction,
    timeRange,
    refetchData,

    getDomainListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

// 名稱服務器
export const useDomainNameServer = () => {
  const isTableBusy = ref(true)
  const isCardBusy = ref(false)
  const serviceNameServer = ref([])

  const {
    tableColumns,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '序號', key: 'index' },
    { label: '標題', key: 'nameserver' },
  ]

  const url = router.currentRoute.params.id

  const setDomainNameServerUpdate = data => store.dispatch('admin-service-domain/setDomainServiceNameServerUpdate', { url, data })

  const getDomainNameServer = () => {
    isTableBusy.value = true

    store.dispatch('admin-service-domain/getDomainServiceNameServer', { url })
      .then(response => {
        const { data } = response.data

        serviceNameServer.value = data || []
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isTableBusy.value = false
      })
  }

  return {
    isTableBusy,
    isCardBusy,
    serviceNameServer,

    setDomainNameServerUpdate,
    getDomainNameServer,

    useAlertToast,
    useHttpCodeAlert,
  }
}

// DNS設定
export const useDomainDNSTable = tableType => {
  const routerUrl = router.currentRoute.params.id

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const isBusy = ref(false)
  const isCardBusy = ref(true)
  const typeOptions = ref([])
  const searchType = ref(null)
  const sourceType = ref(null)

  const blankDnsRecordData = {
    id: null,
    type: null,
    name: null,
    contentTxt: null,
    ttl: null,
    proxy: false,
  }

  tableColumns.value = [
    { label: '種類', key: 'type' },
    { label: '名稱', key: 'name' },
    { label: '內容', key: 'contentTxt' },
    { label: 'TTL', key: 'ttl' },
    { label: '動作', key: 'show_details' },
  ]

  const setDomainDNSAction = data => store.dispatch('admin-service-domain/setDomainServiceDNSAction', { url: routerUrl, ...data })

  const getDomainDNSList = (ctx, callback) => {
    isCardBusy.value = true
    const queryParams = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      source: tableType,
      _: Date.now(),
    }

    store.dispatch('admin-service-domain/getDomainServiceDNSList', { url: routerUrl, queryParams })
      .then(response => {
        const { dnsRecord, type, total } = response.data.data
        const resolveData = dnsRecord.map(item => {
          const resolve = {
            ...syncObject(blankDnsRecordData, item),
          }
          return resolve
        })
        typeOptions.value = type
        sourceType.value = response.data.data.sourceType

        if (sourceType.value === 'CloudFlare') {
          tableColumns.value = [
            { label: '種類', key: 'type' },
            { label: '名稱', key: 'name' },
            { label: '內容', key: 'contentTxt' },
            { label: 'TTL', key: 'ttl' },
            { label: 'Proxy狀態', key: 'proxy' },
            { label: '動作', key: 'show_details' },
          ]
        } else {
          tableColumns.value = [
            { label: '種類', key: 'type' },
            { label: '名稱', key: 'name' },
            { label: '內容', key: 'contentTxt' },
            { label: 'TTL', key: 'ttl' },
            { label: '動作', key: 'show_details' },
          ]
        }

        callback(resolveData)
        totalNum.value = total
        isCardBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        callback([])
        isCardBusy.value = false
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  return {
    isBusy,
    isCardBusy,
    routerUrl,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
    refetchTable,
    searchType,
    typeOptions,

    useAlertToast,
    useHttpCodeAlert,

    getDomainDNSList,
    setDomainDNSAction,
  }
}

// 操作紀錄
export const useDomainLog = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankCustomerData,
  } = useDomainServiceList()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類型', key: 'identity', sortable: true, searchable: true, select: true,
    },
    {
      label: '標題', key: 'title', sortable: true, searchable: true, select: true,
    },
    {
      label: '內容', key: 'content', sortable: true, searchable: true, select: true,
    },
    {
      label: 'IP', key: 'ip', sortable: true, searchable: true, select: true,
    },
    {
      label: '裝置', key: 'device', sortable: true, searchable: true, select: true,
    },
    {
      label: '修改者', key: 'user', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
  ]

  const searchIdentity = ref(null)

  const blankLogData = {
    id: null,
    identity: 'admin',
    ip: null,
    device: null,
    user: null,
    title: null,
    content: '',
    domain: null,
    agent: null,
    created_at: null,
    updated_at: null,
  }

  const getDomainListLogData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchIdentity.value !== null) {
      resolveFilters = `identity: ${searchIdentity.value}`
    }

    const queryParams = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    }

    store.dispatch('admin-service-domain/getDomainServiceLog', {
      url: router.currentRoute.params.id,
      queryParams,
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankLogData, item),
            user_info: item.user && item.user_info ? syncObject(blankCustomerData, item.user_info) : syncObject(blankCustomerData, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchIdentity], () => {
    refetchData()
  })

  return {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchIdentity,
    timeRange,
    syncObject,
    refetchData,
    refetchTable,
    getDomainListLogData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
