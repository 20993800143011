<template>
  <div>
    <!-- 頁面: 類別列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('device', 'API.Admin.Device.Type')">
      <!-- 新增類別 -->
      <device-type-add-modal
        ref="deviceAddModal"
        @refetch-data="refetchData"
      />

      <!-- 編輯類別 -->
      <device-type-edit-modal
        v-if="selected"
        ref="deviceEditModal"
        :device-item="selected"
        @refetch-data="refetchData"
      />

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                元件類別
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('device', 'API.Admin.Device.Type.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getDeviceTypeListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col p-50">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 圖標 -->
          <template #cell(icon)="data">
            <div
              class="table-col d-flex py-50"
              @click="onSubmitUplode(data.item)"
            >
              <VueLoadImage
                :use-loading="true"
                :use-failed="true"
                :blank-height="'32px'"
                :loading-width="30"
                :loading-height="30"
                :failed-width="32"
                :failed-image="transDeviceErrorImage(data.item.slot)"
              >
                <b-img
                  slot="image"
                  :src="`/dashboard/admin/images/deviceType/${data.item.type}.svg`"
                  height="32"
                  width="32"
                  class="icon-iamge"
                />
              </VueLoadImage>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-col">
              <b-link
                v-if="data.item.name"
                class="font-weight-bold d-block text-nowrap show-text"
                @click="onSubmitUpdate(data.item)"
              >
                {{ data.item.name }}
              </b-link>

              <span
                v-else
                class="text-muted"
              >
                尚未設定
              </span>
            </div>
          </template>

          <!-- 欄位: KEY -->
          <template #cell(type)="data">
            <div>
              <span
                class="font-weight-bold"
                @click="onSubmitUpdate(data.item)"
              >
                {{ data.item.type }}
              </span>
            </div>
          </template>

          <!-- 欄位: 接點 -->
          <template #cell(slot)="data">
            <div class="table-col">
              <b-badge
                :variant="ui.slotType[1][data.item.slot]"
                class="mr-25"
              >
                {{ ui.slotType[0][data.item.slot] }}
              </b-badge>
            </div>
          </template>

          <!-- 欄位: 上次更新 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('device', 'API.Admin.Device.Type.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <input
          ref="fileInputModal"
          type="file"
          hidden
          accept=".svg"
          @input="handleImageUplode($event)"
        >

      </b-card>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BAlert, BBadge,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

import { useDeviceTypeList, useDeviceSetting } from '../useDevice'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import DeviceTypeAddModal from './DeviceTypeAddModal.vue'
import DeviceTypeEditModal from './DeviceTypeEditModal.vue'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
    DeviceTypeAddModal,
    DeviceTypeEditModal,
    VueLoadImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
      isUploadBusy: true,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (轉換)組件圖片
    transDeviceErrorImage(type) {
      if (type) return '/dashboard/admin/images/deviceType/ZPlug.svg'
      return '/dashboard/admin/images/deviceType/Component.svg'
    },

    // (觸發)上傳
    onSubmitUplode(item) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Type.Icon')
      if (!auth) return
      this.selected = item
      this.$refs.fileInputModal.click()
    },

    // (上傳)圖片
    handleImageUplode(e) {
      const { files } = e.target
      const file = files[0]
      const formData = new FormData()
      formData.append('data', file)

      this.isUploadBusy = true

      this.setDeviceTypeImageUpload({
        id: this.selected.id,
        data: formData,
      })
        .then(response => {
          this.refetchData()
          this.useAlertToast(response.data.success, response.data.message)

          const fileInput = this.$refs.fileInputModal
          if (fileInput) {
            fileInput.value = ''
          }
          this.isUploadBusy = false
        })
        .catch(() => {
          this.useAlertToast(false, '上傳失敗')
          const fileInput = this.$refs.fileInputModal
          if (fileInput) {
            fileInput.value = ''
          }
          this.isUploadBusy = false
        })
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        const auth = this.checkAuthAbility('device', 'API.Admin.Device.Type.Create')
        if (!auth) return
        this.$refs.deviceAddModal.getData()
        return
      }
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Type.Update')
      if (!auth) return
      this.selected = item
      setTimeout(() => { this.$refs.deviceEditModal.getData() }, 200)
    },
  },
  setup() {
    const DEVICE_ADMIN_STORE_MODULE_NAME = 'admin-device'

    if (!store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchState,
      timeRange,

      getDeviceTypeListData,
      setDeviceTypeImageUpload,
    } = useDeviceTypeList()

    const {
      ui,
      stateOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useDeviceSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      useAlertToast,
      dateConfig2,
      stateOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchState,
      timeRange,

      getDeviceTypeListData,
      setDeviceTypeImageUpload,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>
