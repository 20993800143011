<template>
  <div>
    <!-- 頁面: 元件列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('device', 'API.Admin.Device')">
      <!-- 新增元件 -->
      <device-list-add-modal
        ref="deviceAddModal"
        :brand-options="brandOptions"
        :place-options="placeOptions"
        :device-type-options="deviceTypeOptions"
        @refetch-data="refetchData"
      />

      <!-- 編輯元件 -->
      <device-list-edit-modal
        v-if="selected"
        ref="deviceEditModal"
        :device-item="selected"
        :brand-options="brandOptions"
        :place-options="placeOptions"
        :device-type-options="deviceTypeOptions"
        @refetch-data="refetchData"
      />

      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="5"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchState"
                :options="[
                  { label: '可使用', value: 0 },
                  { label: '使用中', value: 1, },
                  { label: '鎖定', value: 5, },
                  { label: '待處理', value: 2 },
                  { label: '送修', value: 3 },
                  { label: '淘汰', value: 4 },
                ]"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.stateList[3][option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="7"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>類別</label>
              <v-select
                v-model="searchDeviceType"
                :options="deviceTypeOptions"
                :reduce="option => option.type"
                label="name"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="2"
              md="5"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>地點</label>
              <v-select
                v-model="searchPlace"
                :options="placeOptions"
                class="w-100"
                :reduce="option => option.id"
                label="name"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    {{ option.name }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="2"
              md="5"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>品牌</label>
              <v-select
                v-model="searchBrand"
                :options="brandOptions"
                class="w-100"
                :reduce="option => option.id"
                label="name"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                元件列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('device', 'API.Admin.Device.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="多選"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="onSubmitDelete(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/select-all.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>

                <div
                  v-if="isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="一鍵刪除"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitMutiDelete"
                >
                  <b-img
                    src="/dashboard/admin/images/table/delete-all.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="取消"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitTableCancel"
                >
                  <b-img
                    src="/dashboard/admin/images/table/cancel.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getDeviceListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          primary-key="id"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          select-mode="multi"
          selectable
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
          @row-selected="onRowSelected"
        >
          <template #head(selected)>
            <span
              class="text-primary cursor-pointer"
              @click.stop="!isSelectedAll ? selectAllRows() : clearSelected()"
            >
              {{ isSelectedAll ? '取消' : '全選' }}
            </span>
          </template>

          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 選取 -->
          <template #cell(selected)="data">
            <div class="table-col">
              <!-- <b-form-checkbox
                v-model="data.rowSelected"
                name="table-column"
                disabled
              /> -->

              <b-form-checkbox
                v-if="data.item.state !== 1 && data.item.state !== 5 && !data.item.stock_id && !data.item.parent_id"
                v-model="data.rowSelected"
                name="table-column"
                disabled
                class="machine-checkbox"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 狀態 -->
          <template #cell(state)="data">
            <div class="table-col">
              <div
                v-b-tooltip.hover.focus.v-secondary
                :title="ui.stateList[0][data.item.state]"
                class="state-dot"
                :class="ui.stateList[2][data.item.state]"
                @click="onSubmitServiceEdit(data.item)"
              />
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-col">
              <div class="d-flex text-nowrap align-items-center">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  :title="data.item.device_type_info.name"
                >
                  <VueLoadImage
                    :use-loading="true"
                    :use-failed="true"
                    :blank-height="'32px'"
                    :loading-width="30"
                    :loading-height="30"
                    :failed-width="32"
                    :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                    class="mr-50"
                  >
                    <b-img
                      slot="image"
                      :src="`/dashboard/admin/images/deviceType/${data.item.device_type}.svg`"
                      height="32"
                      width="32"
                      class="icon-iamge"
                    />
                  </VueLoadImage>
                </div>

                <div class="table-col text-select">
                  <div class="d-block text-nowrap selection-group d-flex align-items-center">
                    <div style="line-height: 1">
                      <div class="text-nowrap d-flex align-items-center">
                        <div class="d-flex align-items-end">
                          <b-link
                            class="d-block text-nowrap show-text item-description-email font-weight-bold"
                            @click="onSubmitUpdate(data.item)"
                          >
                            {{ data.item.name }}
                            <!-- <div
                              class="item-description-email font-weight-bold "
                            >
                              {{ data.item.name }}
                            </div> -->
                          </b-link>

                          <div
                            v-if="data.item.remark_display"
                            @click="onSubmitRemark(data.item)"
                          >
                            <b-img
                              src="/dashboard/admin/images/table/chat-remark.svg"
                              height="20"
                              width="20"
                              class="cursor-pointer ml-25 mb-25"
                            />
                          </div>
                        </div>

                        <b-link
                          v-if="checkAuthAbility('device', 'API.Admin.Device.Id')"
                          v-b-tooltip.hover.focus.v-secondary
                          title="元件詳情"
                          class="selection-btn-icon-show"
                          @click="onSubmitDeviceLink(data.item)"
                        >
                          <b-img
                            src="/dashboard/admin/images/table/external-link.svg"
                            height="14"
                            width="14"
                            class="cursor-pointer mb-25"
                          />
                        </b-link>
                      </div>

                      <small class="text-muted">
                        {{ data.item.serialNumber }}
                      </small>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <!-- <template #cell(name)="data">
            <div class="table-col text-select">
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <div>
                  <div class="text-nowrap d-flex align-items-center">
                    <div class="d-flex align-items-end">
                      <b-link
                        class="font-weight-bold d-block text-nowrap show-text"
                        @click="onSubmitUpdate(data.item)"
                      >
                        {{ data.item.name }}
                      </b-link>

                      <div
                        v-if="data.item.remark_display"
                        @click="onSubmitRemark(data.item)"
                      >
                        <b-img
                          src="/dashboard/admin/images/table/chat-remark.svg"
                          height="20"
                          width="20"
                          class="cursor-pointer ml-50"
                        />
                      </div>
                    </div>

                    <b-link
                      v-if="checkAuthAbility('device', 'API.Admin.Device.Id')"
                      v-b-tooltip.hover.focus.v-secondary
                      title="元件詳情"
                      class="selection-btn-icon-show"
                      @click="onSubmitDeviceLink(data.item)"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/external-link.svg"
                        height="14"
                        width="14"
                        class="cursor-pointer mb-25"
                      />
                    </b-link>
                  </div>

                  <small class="text-muted">
                    {{ data.item.serialNumber }}
                  </small>

                </div>
              </div>
            </div>
          </template> -->

          <!-- 欄位: 類別 -->
          <!-- <template #cell(device_type)="data">
            <div class="table-col">
              <div class="d-flex text-nowrap">
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                  class="mr-50"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${data.item.device_type}.svg`"
                    height="32"
                    width="32"
                    class="icon-iamge"
                  />
                </VueLoadImage>

                <div class="d-block text-nowrap show-text">
                  <div v-if="data.item.device_type_info && data.item.device_type_info.name">
                    <div>{{ data.item.device_type_info.name }}</div>
                    <small class="text-muted">
                      {{ data.item.device_type }}
                    </small>
                  </div>
                  <small v-else>
                    ---
                  </small>
                </div>
              </div>
            </div>
          </template> -->

          <!-- 欄位: 健康度 -->
          <template #cell(health)="data">
            <div class="d-flex align-items-center table-col">
              <span class="text-body-heading mr-50 text-nowrap health-text">{{ data.item.health }} %</span>
              <vue-apex-charts
                v-b-tooltip.hover.focus.v-secondary
                :title="`${data.item.health} / 100`"
                type="radialBar"
                height="30"
                width="30"
                :options="data.item.chartDataAll.options"
                :series="data.item.chartDataAll.series"
              />
            </div>
          </template>

          <!-- 欄位: 地點 -->
          <template #cell(place_id)="data">
            <div class="table-title">
              <div v-if="data.item.place_id">
                <span>{{ data.item.place_info.name }}</span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 品牌 -->
          <template #cell(brand_id)="data">
            <div class="table-title">
              <div
                v-if="data.item.brand_id && data.item.brand_info && data.item.brand_info.image"
                v-b-tooltip.hover.focus.v-secondary
                :title="`${data.item.brand_info.name}`"
                class="table-title-image"
                @click="showImgs(data.item.brand_info.image)"
              >
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'30px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="50"
                >
                  <b-img
                    slot="image"
                    :src="data.item.brand_info.image"
                    class="title-image"
                    :alt="data.item.brand_info.name"
                  />
                </VueLoadImage>
              </div>

              <div
                v-else
                class="text-center"
              >
                <div v-if="data.item.brand_id && data.item.brand_info.name">
                  <span>{{ data.item.brand_info.name }}</span>
                </div>

                <div
                  v-else
                  class="table-title-image"
                >
                  <b-img
                    v-if="data.item.brand_id"
                    v-b-tooltip.hover.focus.v-secondary
                    title="品牌不存在"
                    :src="$store.state.app.themeImages.missImg"
                    height="50"
                    width="50"
                    class="title-image"
                  />

                  <span v-else>---</span>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 進貨號 -->
          <template #cell(stock_id)="data">
            <div class="table-col">
              <small
                v-if="data.item.stock_id && data.item.stock_info"
                v-b-tooltip.hover.focus.v-secondary
                :title="data.item.stock_info.description ? data.item.stock_info.description : null"
                class="text-nowrap"
              >
                {{ data.item.stock_info.serialNumber }}
              </small>

              <div v-else>
                ---
              </div>
            </div>
          </template>

          <!-- 欄位: 綁定 -->
          <template #cell(parent_id)="data">
            <div class="table-col">
              <div
                v-if="data.item.parent_id && data.item.parent_info"
                class="d-flex text-nowrap"
                @click="onSubmitDeviceLink(data.item.parent_info)"
              >
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                  class="mr-50"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${data.item.parent_info.device_type}.svg`"
                    height="32"
                    width="32"
                    class="icon-iamge"
                  />
                </VueLoadImage>

                <div class="d-block text-nowrap show-text">
                  <div v-if="data.item.parent_info.name">
                    <div
                      v-b-tooltip.hover.focus.v-secondary
                      :title="data.item.parent_info.name"
                      class="item-description-email"
                    >
                      {{ data.item.parent_info.name }}
                    </div>
                    <small class="text-muted">
                      {{ data.item.parent_info.serialNumber }}
                    </small>
                  </div>
                </div>
              </div>

              <div v-else>
                ---
              </div>
            </div>
          </template>

          <!-- 欄位: 上次更新 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 詳細 -->
          <template #row-details>
            <!-- ="data" -->
            <!-- <div class="table-toggle-detail border-top p-1">
              <b-card
                no-body
                class="m-0 border animate__animated animate__fadeIn"
              >
                <div>

                </div>
              </b-card>
            </div> -->
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-remark-botton-alert actions-link-btn"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="備註留言"
                  src="/dashboard/admin/images/table/chat.svg"
                  class="actions-link-btn-image"
                  rounded
                />
                <div
                  v-if="data.item.remark_display"
                  class="state-dot dot-red"
                />
              </div>

              <div
                v-if="checkAuthAbility('device', 'API.Admin.Device.Update')"
                class="actions-link-btn"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('device', 'API.Admin.Device.Id')"
                class="actions-link-btn mr-25"
              >
                <b-link @click="onSubmitDeviceLink(data.item)">
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="詳情"
                    src="/dashboard/admin/images/table/menu.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </b-link>
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>

      <remark-modal
        v-if="selected"
        ref="deviceRemarkExplorerModal"
        remark-explorer-id="deviceRemarkExplorerModal"
        :key-array="keyArray"
        :is-hiden-emit="true"
        :use-callback-data="false"
        @emit-hidden-function="refetchTable"
        @call-back-data="() => {}"
      >
        <template v-slot:header-info>
          <div
            v-if="selected"
            class="p-2"
          >
            <b-row>
              <b-col
                cols="21"
                lg="6"
                class="d-flex justify-content-between flex-column mb-1"
              >
                <div class="d-flex justify-content-start">
                  <div class="profile-image">
                    <VueLoadImage
                      :use-loading="true"
                      :use-failed="true"
                      :blank-height="'32px'"
                      :loading-width="30"
                      :loading-height="30"
                      :failed-width="32"
                      :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                      class="mr-50"
                    >
                      <b-img
                        slot="image"
                        :src="`/dashboard/admin/images/deviceType/${selected.device_type}.svg`"
                        height="90"
                        width="90"
                        class="icon-iamge"
                      />
                    </VueLoadImage>
                  </div>

                  <div class="d-flex flex-column mt-25 ml-50">
                    <div class="mb-50">
                      <h3 class="mb-0">
                        {{ selected.name }}
                      </h3>
                      <span class="show-text text-muted">{{ selected.serialNumber }}</span>
                    </div>
                  </div>
                </div>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="品牌"
                  class="m-0"
                >
                  <div class="mt-50">
                    <div v-if="selected.brand_id && selected.brand_info.name">
                      {{ selected.brand_info.name }}
                    </div>

                    <span v-else>---</span>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="地點"
                  class="m-0"
                >
                  <div class="mt-50">
                    <div v-if="selected.place_id && selected.place_info.name">
                      {{ selected.place_info.name }}
                    </div>

                    <span v-else>---</span>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="庫存編號"
                  class="m-0"
                >
                  <div class="mt-50">
                    <b-link
                      v-if="selected.stock_id && selected.stock_info"
                      class="font-weight-bold d-block text-nowrap show-text"
                    >
                      {{ selected.stock_info.serialNumber }}
                    </b-link>

                    <span v-else>---</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div
            v-else
            class="mb-1"
          />
        </template>
      </remark-modal>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BCardBody,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BCardHeader, BAlert,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'
import { useDeviceList, useDeviceSetting } from '../useDevice'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'
import DeviceListAddModal from './DeviceListAddModal.vue'
import DeviceListEditModal from './DeviceListEditModal.vue'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BFormInput,
    BCardBody,
    BCardHeader,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
    VueLoadImage,
    VueApexCharts,
    remarkModal,
    DeviceListAddModal,
    DeviceListEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      if (!this.isSelectState) return this.tableColumns.filter(f => f.select).filter(f => f.key !== 'selected')
      return this.tableColumns.filter(f => f.select)
    },
    tableColumnsSearchable() {
      if (!this.isSelectState) return this.tableColumns.filter(f => f.searchable).filter(f => f.key !== 'selected')
      return this.tableColumns.filter(f => f.searchable)
      // return this.tableColumns.filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)圖片顯示
    showImgs(iamge) {
      this.$viewerApi({
        images: [iamge],
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (觸發)備註
    onSubmitRemark(item) {
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      const resolveArray = []
      resolveArray.push(`device-${item.id}`)
      if (item.stock_id) resolveArray.push(`stock-${item.stock_id}`)
      this.keyArray = resolveArray
      this.selected = JSON.parse(JSON.stringify(item))
      setTimeout(() => { this.$refs.deviceRemarkExplorerModal.getData() }, 200)
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        const auth = this.checkAuthAbility('device', 'API.Admin.Device.Create')
        if (!auth) return
        this.$refs.deviceAddModal.getData()
        return
      }
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) return
      this.selected = item
      setTimeout(() => { this.$refs.deviceEditModal.getData() }, 200)
    },

    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
      }
    },

    // (前往)元件詳情
    onSubmitDeviceLink(item) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Id')
      if (!auth) return
      this.$router.push({
        name: 'admin-device-view',
        params: {
          id: item.id,
        },
      })
    },

    // (觸發選取)刪除列表物品
    onSubmitDelete(index) {
      this.isSelectState = true
      if (index !== null) this.selectRow(index)
    },

    // (選擇)列表物品
    onRowSelected(items) {
      if (this.isSelectState) {
        this.selectedTableData = items
      } else this.clearSelected()
    },

    // (觸發)列表取消
    submitTableCancel() {
      this.isSelectState = false
      this.clearSelected()
      this.selectedTableData = []
    },

    // (刪除)多選物品
    submitMutiDelete() {
      const items = []

      this.selectedTableData.forEach(item => {
        if (item.stock_id) return
        if (item.parent_id) return
        if (item.state === 1 || item.state === 5) return
        items.push(item.id)
      })

      if (items.length === 0) {
        this.useSwalAlertCenter(false, '刪除失敗!', '請選擇可刪除的元件!')
        return
      }

      const errorAlertText = '<div style="font-size: 12px">( 包含進貨號 或 綁定狀態元件不可刪除 )</div>'
      this.$swal({
        title: '刪除元件',
        html: `
          是否要刪除所選擇到的 ${items.length} 個元件?<br>
          ${errorAlertText}
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.setDeviceMutiDelete({
            devices: items,
          }).then(response => {
            this.refetchData()
            this.useSwalAlertCenter(response.data.success, `刪除${response.data.success ? '成功' : '失敗'}!`, response.data.message)
          })
            .catch(error => {
              this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
            })
        }
      })
    },
  },
  setup() {
    const DEVICE_ADMIN_STORE_MODULE_NAME = 'admin-device'

    if (!store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      selectedTableData,
      isSelectState,
      isSelectedAll,
      refetchData,
      refetchTable,
      searchState,
      searchPlace,
      searchBrand,
      searchDeviceType,
      brandOptions,
      placeOptions,
      deviceTypeOptions,

      getDeviceListData,
      setDeviceDelete,
      setDeviceCreate,
      setDeviceUpdate,
      setDeviceMutiUpdate,
      setDeviceMutiDelete,
      useAlertToast,
      useHttpCodeAlert,
    } = useDeviceList()

    const {
      ui,
      stateOptions,

      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDeviceSetting()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    const selectAllRows = () => {
      isSelectedAll.value = true
      refDataListTable.value.selectAllRows()
    }

    const clearSelected = () => {
      isSelectedAll.value = false
      refDataListTable.value.clearSelected()
    }

    const selectRow = index => {
      refDataListTable.value.selectRow(index)
    }

    const unselectRow = index => {
      refDataListTable.value.unselectRow(index)
    }

    return {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      selectedTableData,
      isSelectState,
      isSelectedAll,
      refetchData,
      refetchTable,
      searchState,
      searchPlace,
      searchBrand,
      searchDeviceType,
      brandOptions,
      placeOptions,
      deviceTypeOptions,

      getDeviceListData,
      setDeviceDelete,
      setDeviceCreate,
      setDeviceUpdate,
      setDeviceMutiUpdate,
      setDeviceMutiDelete,
      useAlertToast,
      useHttpCodeAlert,

      ui,
      stateOptions,

      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
      dateConfig2,
      selectAllRows,
      clearSelected,
      selectRow,
      unselectRow,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.machine-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(65, 159, 217, 1);
}
</style>

<style lang="scss" scoped>
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}
.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}
.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}

.table-title-image {
  text-align: center;
  .title-image {
    max-width: 100px;
    max-height: 40px;
    text-align: center;
  }
}

.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}

.user-state-icon {
  max-width: 30px;
  margin-right: 10px;
}

.user-state-icon-hidden {
  opacity: 0 !important;
}

.health-text {
  min-width: 3em;
  text-align: right;
}
</style>
