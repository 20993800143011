<template>
  <!-- 頁面: 商品詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
  <div>
    <div v-if="checkAuthAbility('product', 'API.Admin.Product.Id')">
      <div v-if="adminProductState.productDataInfo">
        <product-view-info
          @refresh-product-info="refreshProductInfoData"
        />

        <b-tabs
          v-if="isProductMainType(adminProductState.productDataInfo.type)"
          pills
          lazy
        >
          <b-tab
            title="附加商品"
            active
          >
            <product-view-extra />
          </b-tab>

          <b-tab title="形象廣告">
            <product-view-ad />
          </b-tab>
        </b-tabs>

        <div v-else>
          <product-view-extra />
        </div>
      </div>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import { BTabs, BTab, BAlert } from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import ProductViewInfo from './ProductViewInfo.vue'
import ProductViewExtra from './ProductViewExtra.vue'
import ProductViewAd from './ProductViewAd.vue'
import { useProductView } from '../useProduct'
import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BAlert,
    ProductViewInfo,
    ProductViewExtra,
    ProductViewAd,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    adminProductState() {
      return this.$store.state['admin-product']
    },
  },
  beforeDestroy() {
    this.$store.commit('admin-product/UPDATE_PRODUCT_DATA_INFO_STATE', null)
  },
  methods: {
    // (檢查)是否為主要商品類型
    isProductMainType(type) {
      return this.$store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)
    },
  },
  setup() {
    const PRODUCT_ADMIN_STORE_MODULE_NAME = 'admin-product'

    if (!store.hasModule(PRODUCT_ADMIN_STORE_MODULE_NAME)) store.registerModule(PRODUCT_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(PRODUCT_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_ADMIN_STORE_MODULE_NAME)
    })

    const productID = router.currentRoute.params.id

    const {
      getProductInfoData,
    } = useProductView()

    const refreshProductInfoData = () => {
      store.commit('admin-product/UPDATE_BUSY_LOADING_STATE', true)
      getProductInfoData({ id: productID })
        .then(() => {
          store.commit('admin-product/UPDATE_BUSY_LOADING_STATE', false)
        })
        .catch(() => {
          router.replace({ name: 'admin-product' })
        })
    }

    refreshProductInfoData()

    return {
      productID,
      refreshProductInfoData,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
