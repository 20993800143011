import { ref, watch } from '@vue/composition-api'
import { $themeColors } from '@themeConfig'
import {
  useTableComponent, useCommenSettings, useAlert, usePublicData,
} from '@/libs/mixins/index'
import store from '@/store'
import router from '@/router'

export const useDomainSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ruleTypeOptions = [
    {
      label: '文字',
      value: 'TextType',
      inputType: 'text',
      options: {
        min: null,
        max: null,
        default: null,
      },
    },
    {
      label: '數字',
      value: 'NumericType',
      inputType: 'number',
      options: {
        min: null,
        max: null,
        default: null,
      },
    },
    {
      label: '布林',
      value: 'BooleanType',
      inputType: 'number',
      options: {
        default: false,
      },
    },
    {
      label: '日期',
      value: 'DateType',
      inputType: 'date',
      options: {
        format: null,
        input: null,
      },
    },
    {
      label: '電話',
      value: 'PhoneType',
      inputType: 'number',
      options: {
        dialCode: null,
        iso2: null,
        default: null,
      },
    },
    {
      label: '國家',
      value: 'CountryType',
      inputType: 'number',
      options: {
        iso2: null,
        default: null,
      },
    },
    {
      label: '下拉選單',
      value: 'DropdownType',
      inputType: 'text',
      options: {
        items: [],
        default: null,
      },
    },
    {
      label: '多選下拉選單',
      value: 'DropdownMutiType',
      inputType: 'text',
      options: {
        items: [],
        default: null,
        delimiter: ',',
      },
    },
  ]

  const ui = {
    displayList: [{
      false: '未啟用', true: '上架中',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
    isFireList: {
      false: '一般', true: '熱銷',
    },
    kycRequired: {
      false: '不需驗證', true: '需驗證',
    },
    priceType: {
      price_new: { name: '新註冊', cost: 'cost_new' },
      price_transfer: { name: '攜入(轉移)', cost: 'cost_transfer' },
      price_continue: { name: '續約', cost: 'cost_continue' },
    },
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)
  const getExchangeRate = (...arg) => store.dispatch('api/getExchangeRate', ...arg)
  const getDomainDealerCost = (...arg) => store.dispatch('admin-domain/getDomainDealerCost', ...arg)
  const getDomainDealerBalance = (...arg) => store.dispatch('admin-domain/getDomainDealerBalance', ...arg)

  // (搜尋)規則
  const resolveRuleMode = rule => {
    const foundItem = ruleTypeOptions.find(item => item.value === rule)
    if (foundItem) return foundItem
    return {
      label: '錯誤', value: 'error', inputType: 'text', options: {},
    }
  }

  const timeFormatOptions = [
    'Y-m-d H:i:s',
    'Y/m/d H:i:s',
    'Ymd',
    'Y-m-d H:i:s:u',
    'Y/m/d',
    'm/d',
    'unix(s)',
    'unix(ms)',
    '自訂時間',
  ]

  const kycRequiredOptions = [
    { label: '不需驗證', value: false },
    { label: '需驗證', value: true },
  ]

  const displayOptions = [
    { label: '未啟用', value: false },
    { label: '上架中', value: true },
  ]

  const isFireOptions = [
    { label: '一般', value: false },
    { label: '熱銷', value: true },
  ]

  const isFreeOptions = [
    { label: '付費網域', value: false },
    { label: '免費網域', value: true },
  ]

  return {
    ui,
    kycRequiredOptions,
    displayOptions,
    isFireOptions,
    isFreeOptions,
    ruleTypeOptions,
    resolveRuleMode,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
    getExchangeRate,
    getDomainDealerCost,
    getDomainDealerBalance,

    timeFormatOptions,
  }
}

export const useDomainAgent = () => {
  const {
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    timeRange,
    refDataListTable,
    totalNum,
    dateDefaluteConfig,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const domainAgent = ref(null)
  const initBusy = ref(false)

  const blankDomainAgent = {
    id: null,
    agent: null,
    number_of_dealer: null,
    rules: [],
    updated_at: null,
    created_at: null,
  }

  const blankSetting = {
    key: null,
    name: null,
    required: false,
    type: 'TextType',
    options: {},
  }

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'agent', sortable: true, searchable: true, select: true,
    },
    {
      label: '總數', key: 'number_of_dealer', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const getDomainAgent = (...args) => store.dispatch('admin-domain/getDomainAgent', ...args)
  const setDomainAgentCreate = (...args) => store.dispatch('admin-domain/setDomainAgentCreate', ...args)
  const setDomainAgentUpdate = (...args) => store.dispatch('admin-domain/setDomainAgentUpdate', ...args)

  const getDomainAgentsData = (ctx, callback) => {
    store.dispatch('admin-domain/getDomainAgentList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDomainAgent, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    refDataListTable,
    dateDefaluteConfig,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    timeRange,
    refetchData,
    refetchTable,

    domainAgent,
    initBusy,
    blankDomainAgent,
    blankSetting,
    totalNum,

    getDomainAgent,
    getDomainAgentsData,
    setDomainAgentCreate,
    setDomainAgentUpdate,

    useAlertToast,
    useHttpCodeAlert,
    syncObject,
  }
}

export const useDomainDealer = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    allCountries,
  } = usePublicData()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '網域商', key: 'domain_name_agent_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '商品數量', key: 'amount', sortable: true, searchable: true, select: true,
    },
    {
      label: '餘額查詢', key: 'balance', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const domainData = ref(null)
  const searchAgentId = ref(null)

  const blankDomainDealerData = {
    id: null,
    name: null,
    domain_name_agent_id: null,
    rules: [],
    amount: 0,
    created_at: null,
    updated_at: null,
  }

  const blankDomainAgent = {
    id: null,
    agent: null,
  }

  const getDomainDealer = (...arg) => store.dispatch('admin-domain/getDomainDealer', ...arg)
  const setDomainDealerDelete = (...arg) => store.dispatch('admin-domain/setDomainDealerDelete', ...arg)
  const setDomainDealerCreate = (...arg) => store.dispatch('admin-domain/setDomainDealerCreate', ...arg)
  const setDomainDealerUpdate = (...arg) => store.dispatch('admin-domain/setDomainDealerUpdate', ...arg)

  const resolveCountriesCode = code => {
    const country = allCountries.find(([, , dialCode]) => dialCode === code)
    return country ? country[1] : null
  }

  const getDomainDealerData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchAgentId.value !== null) {
      resolveFilters = `domain_name_agent_id: ${searchAgentId.value}`
    }

    store.dispatch('admin-domain/getDomainDealerList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDomainDealerData, item),
          }
          return resolve
        })

        const domainAgentOptions = option.map(item => {
          const resolve = {
            ...syncObject(blankDomainAgent, item),
          }
          return resolve
        })
        store.commit('admin-domain/UPDATE_DOMAIN_AGENT_LIST_STATE', domainAgentOptions)
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchAgentId, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchAgentId,
    timeRange,
    blankDomainDealerData,
    blankDomainAgent,
    domainData,
    refetchData,
    refetchTable,
    resolveCountriesCode,

    getDomainDealer,
    setDomainDealerDelete,
    setDomainDealerCreate,
    setDomainDealerUpdate,
    getDomainDealerData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDomainPrice = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    getMetaListData,
  } = useDomainSetting()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankDomainAgent,
  } = useDomainDealer()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '上架', key: 'display', sortable: true, searchable: true, select: true,
    },
    {
      label: '後綴', key: 'suffix', sortable: true, searchable: true, select: true,
    },
    {
      label: '分站', key: 'branch_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '身分驗證', key: 'require_kyc', sortable: true, searchable: true, select: true,
    },
    {
      label: '註冊最低價格(元/年)', key: 'price_new', sortable: true, searchable: true, select: true,
    },
    {
      label: '轉移最低價格(元/年)', key: 'price_transfer', sortable: true, searchable: true, select: false,
    },
    {
      label: '轉移費用', key: 'transfer', sortable: true, searchable: true, select: false,
    },
    {
      label: '經銷商', key: 'dealer', sortable: true, searchable: true, select: true,
    },
    {
      label: '網域總數', key: 'total', sortable: true, searchable: true, select: true,
    },
    {
      label: '熱銷', key: 'isFire', sortable: true, searchable: true, select: false,
    },
    {
      label: '排序', key: 'rank', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const isRuleBusy = ref(false)
  const domainData = ref(null)
  const searchBranch = ref(null)
  const searchAgentId = ref(null)
  const searchDisplay = ref(null)
  const searchIsFree = ref(0)
  const searchIsFire = ref(null)
  const searchDealerId = ref(null)
  const isLoadingBusy = ref(false)
  const dealerOptions = ref([])
  const branchOptions = ref([])
  const isPriceUpdate = ref({
    price_new: false,
    price_transfer: false,
    price_continue: false,
  })
  const isRuleUpdate = ref(false)
  const exchangeRate = ref(null)

  const setDomainDelete = (...arg) => store.dispatch('admin-domain/setDomainDelete', ...arg)
  const setDomainCreate = (...arg) => store.dispatch('admin-domain/setDomainCreate', ...arg)
  const setDomainUpdate = (...arg) => store.dispatch('admin-domain/setDomainUpdate', ...arg)

  const blankDomainPriceData = {
    id: null,
    ssid: null,
    suffix: null,
    display: false,
    is_free: false,
    is_fire: false,
    require_kyc: false,
    cost_state: false,
    price_new: [],
    cost_new: [],
    price_transfer: [],
    cost_transfer: [],
    price_continue: [],
    cost_continue: [],
    cost_currency: 'USD',
    transfer: 0,
    total: 0,
    rank: '',
    extra_rules: [],
    branch_id: null,
    domain_name_dealer_id: null,
    domain_name_agent_id: null,
    created_at: null,
    updated_at: null,
  }

  const blankDealerInfoData = {
    id: null,
    name: null,
    domain_name_agent_id: null,
    domain_name_agent_info: null,
  }

  const blankBranchInfoData = {
    id: null,
    name: null,
    branch: null,
  }

  const getDomainDealerData = () => {
    isLoadingBusy.value = true
    // 整理filters
    let resolveFilters = ''

    if (searchAgentId.value !== null) {
      resolveFilters = `domain_name_agent_id: ${searchAgentId.value}`
    }

    getMetaListData({ key: 'domainNameDealer', queryParams: { filters: resolveFilters, noPagination: true } }) //
      .then(response => {
        const { data } = response.data.data
        dealerOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankDealerInfoData, item),
          }
          return resolve
        })
        isLoadingBusy.value = false
      })
  }

  const getBranchData = () => {
    getMetaListData({ key: 'branch', queryParams: { noPagination: true } }) //
      .then(response => {
        const { data } = response.data.data
        branchOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankBranchInfoData, item),
          }
          return resolve
        })
      })
  }

  const getDomainListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchBranch.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};branch_id: ${searchBranch.value}`
      } else resolveFilters = `branch_id: ${searchBranch.value}`
    }

    if (searchDealerId.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};domain_name_dealer_id: ${searchDealerId.value}`
      } else resolveFilters = `domain_name_dealer_id: ${searchDealerId.value}`
    }

    if (searchDisplay.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};display: ${searchDisplay.value ? 1 : 0}`
      } else resolveFilters = `display: ${searchDisplay.value ? 1 : 0}`
    }

    if (searchIsFire.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};is_fire: ${searchIsFire.value ? 1 : 0}`
      } else resolveFilters = `is_fire: ${searchIsFire.value ? 1 : 0}`
    }

    store.dispatch('admin-domain/getDomainListData', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      is_free: searchIsFree.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDomainPriceData, item),
            branch_info: item.branch_id && item.branch_info ? syncObject(blankBranchInfoData, item.branch_info) : syncObject(blankBranchInfoData, {}),
            domain_name_dealer_info: item.domain_name_dealer_id && item.domain_name_dealer_info ? syncObject(blankDealerInfoData, item.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
          }
          resolve.domain_name_agent_info = item.domain_name_agent_id && item.domain_name_agent_info ? syncObject(blankDomainAgent, item.domain_name_agent_info) : syncObject(blankDomainAgent, {})
          return resolve
        })
        dealerOptions.value = option.dealer.map(item => {
          const resolve = {
            ...syncObject(blankDealerInfoData, item),
          }
          return resolve
        })

        const domainAgentOptions = option.agent.map(item => {
          const resolve = {
            ...syncObject(blankDomainAgent, item),
          }
          return resolve
        })

        branchOptions.value = option.branch.map(item => {
          const resolve = {
            ...syncObject(blankBranchInfoData, item),
          }
          return resolve
        })
        store.commit('admin-domain/UPDATE_DOMAIN_AGENT_LIST_STATE', domainAgentOptions)
        callback(resolveData)
        totalNum.value = total
        isLoadingBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const setDomainPriceUpdate = (...arg) => store.dispatch('admin-domain/setDomainUpdate', ...arg)
  const getDomainPriceData = (updateData, callback) => {
    store.dispatch('admin-domain/getDomainPriceData', { ...updateData })
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankDomainPriceData, data),
          branch_info: data.branch_id && data.branch_info ? syncObject(blankBranchInfoData, data.branch_info) : syncObject(blankDealerInfoData, {}),
          domain_name_dealer_info: data.domain_name_dealer_id && data.domain_name_dealer_info ? syncObject(blankDealerInfoData, data.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
        }
        resolveData.domain_name_agent_info = data.domain_name_agent_id && data.domain_name_agent_info ? syncObject(blankDomainAgent, data.domain_name_agent_info) : syncObject(blankDomainAgent, {})
        const fields = ['price_new', 'price_transfer', 'price_continue', 'cost_new', 'cost_transfer', 'cost_continue']
        Object.keys(resolveData).forEach(key => {
          if (fields.includes(key) && resolveData[key] === '[]') {
            resolveData[key] = []
          }
        })

        store.commit('admin-domain/UPDATE_DOMAIN_PRICE_INFO_STATE', resolveData)
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        router.replace({ name: 'admin-domain-price' })
      })
  }

  const getDomainPriceResetData = (updateData, callback, error) => {
    store.dispatch('admin-domain/getDomainPriceData', { ...updateData })
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankDomainPriceData, data),
          branch_info: data.branch_id && data.branch_info ? syncObject(blankBranchInfoData, data.branch_info) : syncObject(blankDealerInfoData, {}),
          domain_name_dealer_info: data.domain_name_dealer_id && data.domain_name_dealer_info ? syncObject(blankDealerInfoData, data.domain_name_dealer_info) : syncObject(blankDealerInfoData, {}),
        }
        resolveData.domain_name_agent_info = data.domain_name_agent_id && data.domain_name_agent_info ? syncObject(blankDomainAgent, data.domain_name_agent_info) : syncObject(blankDomainAgent, {})
        const fields = ['price_new', 'price_transfer', 'price_continue', 'cost_new', 'cost_transfer', 'cost_continue']
        Object.keys(resolveData).forEach(key => {
          if (fields.includes(key) && resolveData[key] === '[]') {
            resolveData[key] = []
          }
        })
        callback(resolveData)
      })
      .catch(() => error())
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchDisplay, searchIsFree, searchIsFire, searchDealerId, searchBranch], () => {
    refetchData()
  })

  const $strokeColor = '#ebe9f1'
  const $textHeadingColor = '#5e5873'
  const $goalStrokeColor2 = '#51e5a8'
  const goalOverviewRadialBar = {
    chart: {
      height: 200,
      type: 'radialBar',
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        blur: 3,
        left: 1,
        top: 1,
        opacity: 0.1,
      },
    },
    colors: [$goalStrokeColor2],
    plotOptions: {
      radialBar: {
        offsetY: -10,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: '77%',
        },
        track: {
          background: $strokeColor,
          strokeWidth: '50%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            color: $textHeadingColor,
            fontSize: '2.86rem',
            fontWeight: '600',
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [$themeColors.success],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    grid: {
      padding: {
        bottom: 30,
      },
    },
  }

  return {
    isBusy,
    isRuleBusy,
    isLoadingBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchBranch,
    searchAgentId,
    searchIsFree,
    searchIsFire,
    searchDisplay,
    searchDealerId,
    timeRange,
    blankDomainPriceData,
    blankDealerInfoData,
    domainData,
    goalOverviewRadialBar,
    dealerOptions,
    branchOptions,
    isPriceUpdate,
    isRuleUpdate,
    exchangeRate,
    refetchData,
    refetchTable,
    setDomainDelete,
    setDomainCreate,
    setDomainUpdate,

    getDomainPriceData,
    getDomainPriceResetData,
    setDomainPriceUpdate,
    getDomainListData,
    getDomainDealerData,
    getBranchData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
