<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="9"
      >
        <b-overlay
          :show="adminDeviceState.isBusyLoading"
          variant="transparent"
          opacity="0.9"
          rounded="sm"
        >
          <b-card>
            <div class="d-flex align-items-center mb-1 d-md-none">
              <div
                v-b-tooltip.hover.focus.v-secondary
                :title="ui.stateList[0][adminDeviceState.deviceDataInfo.state]"
                class="state-dot mr-75"
                :class="ui.stateList[2][adminDeviceState.deviceDataInfo.state]"
              />

              <h3 class="font-weight-bolder mb-0">
                <span class="server-name">{{ adminDeviceState.deviceDataInfo.name }}</span>
              </h3>
            </div>

            <b-row>
              <b-col
                cols="12"
                lg="7"
                md="6"
                class="mb-1"
                order="2"
                order-md="1"
              >
                <div class="d-none d-md-flex align-items-center mb-1">
                  <div
                    v-b-tooltip.hover.focus.v-secondary
                    :title="ui.stateList[0][adminDeviceState.deviceDataInfo.state]"
                    class="state-dot mr-75"
                    :class="ui.stateList[2][adminDeviceState.deviceDataInfo.state]"
                  />

                  <h3 class="font-weight-bolder mb-0">
                    <span class="server-name">{{ adminDeviceState.deviceDataInfo.name }}</span>
                  </h3>
                </div>

                <div class="pl-2">
                  <!-- SN序號 -->
                  <b-form-group
                    label="SN序號"
                    label-for="serialNumber"
                    label-cols="4"
                    label-cols-lg="5"
                    label-cols-xl="4"
                    class="edit-col-from-group"
                  >
                    <div
                      class="edit-col-label"
                      @click="onSubmitUpdate"
                    >
                      <span>
                        {{ adminDeviceState.deviceDataInfo.serialNumber ? adminDeviceState.deviceDataInfo.serialNumber : '---' }}
                      </span>

                      <span class="selection-btn-icon">
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                          height="15"
                          width="15"
                          class="cursor-pointer"
                        />
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label="地點"
                    label-for="place_id"
                    label-cols="4"
                    label-cols-lg="5"
                    label-cols-xl="4"
                    class="edit-col-from-group"
                  >
                    <div
                      class="edit-col-label"
                      @click="onSubmitUpdate"
                    >
                      <div v-if="adminDeviceState.deviceDataInfo.place_id">
                        <span>{{ adminDeviceState.deviceDataInfo.place_info.name }}</span>
                      </div>

                      <div v-else>
                        <span>---</span>
                      </div>

                      <span class="selection-btn-icon">
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                          height="15"
                          width="15"
                          class="cursor-pointer"
                        />
                      </span>
                    </div>
                  </b-form-group>

                  <!-- 進貨號 -->
                  <b-form-group
                    label="進貨號"
                    label-for="stock_id"
                    label-cols="4"
                    label-cols-lg="5"
                    label-cols-xl="4"
                    class="edit-col-from-group"
                  >
                    <div class="mx-50">
                      <div
                        v-if="adminDeviceState.deviceDataInfo.stock_id && adminDeviceState.deviceDataInfo.stock_info"
                        v-b-tooltip.hover.focus.v-secondary
                        :title="adminDeviceState.deviceDataInfo.stock_info.description ? adminDeviceState.deviceDataInfo.stock_info.description : null"
                        class="text-nowrap text-primary"
                      >
                        {{ adminDeviceState.deviceDataInfo.stock_info.serialNumber }}
                      </div>

                      <div v-else>
                        ---
                      </div>
                    </div>
                  </b-form-group>

                  <!-- 裝設 -->
                  <b-form-group
                    label="裝設"
                    label-for="parent_id"
                    label-cols="4"
                    label-cols-lg="5"
                    label-cols-xl="4"
                    class="edit-col-from-group"
                  >
                    <div class="mx-50">
                      <div v-if="adminDeviceState.deviceDataInfo.parent_id">
                        <div class="d-block text-nowrap selection-group d-flex align-items-start">
                          <div>
                            <b-link
                              class="font-weight-bold d-block text-nowrap show-text"
                              @click="onSubmitLinkDevice(adminDeviceState.deviceDataInfo.parent_id)"
                            >
                              {{ adminDeviceState.deviceDataInfo.parent_info.name }}
                            </b-link>
                          </div>

                          <b-link
                            v-b-tooltip.hover.focus.v-secondary
                            title="前往元件"
                            class="selection-btn-icon-show"
                            @click="onSubmitLinkDevice(adminDeviceState.deviceDataInfo.parent_id)"
                          >
                            <b-img
                              src="/dashboard/admin/images/table/external-link.svg"
                              height="14"
                              width="14"
                              class="cursor-pointer mb-25"
                            />
                          </b-link>
                        </div>
                      </div>

                      <span v-else>---</span>
                      <!-- {{ adminDeviceState.deviceDataInfo.parent_id ? adminDeviceState.deviceDataInfo.parent_id : '---' }} -->
                    </div>
                  </b-form-group>

                  <!-- 上次更新 -->
                  <b-form-group
                    label="上次更新"
                    label-for="updated_at"
                    label-cols="4"
                    label-cols-lg="5"
                    label-cols-xl="4"
                    class="edit-col-from-group"
                  >
                    <div class="mx-50">
                      {{ adminDeviceState.deviceDataInfo.updated_at ? moment(adminDeviceState.deviceDataInfo.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                    </div>
                  </b-form-group>

                  <!-- 創建時間 -->
                  <b-form-group
                    label="創建時間"
                    label-for="created_at"
                    label-cols="4"
                    label-cols-lg="5"
                    label-cols-xl="4"
                    class="edit-col-from-group"
                  >
                    <div class="mx-50">
                      {{ adminDeviceState.deviceDataInfo.created_at ? moment(adminDeviceState.deviceDataInfo.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
                    </div>
                  </b-form-group>
                </div>
              </b-col>

              <b-col
                cols="12"
                lg="5"
                md="6"
                order="1"
                order-md="2"
                class="mb-1 mb-md-0"
              >
                <div style="min-height: 190px">
                  <vue-apex-charts
                    type="radialBar"
                    height="220"
                    class="mt-1"
                    :options="goalOverviewRadialBar"
                    :series="[percentage]"
                  />
                </div>

                <b-row class="text-center mx-0">
                  <b-col
                    cols="6"
                    class="border-right"
                  >
                    <b-card-text class="text-muted mb-0">
                      類別
                    </b-card-text>

                    <div class="d-flex justify-content-center mt-1">
                      <div class="d-flex text-nowrap align-items-center">
                        <VueLoadImage
                          :use-loading="true"
                          :use-failed="true"
                          :blank-height="'32px'"
                          :loading-width="30"
                          :loading-height="30"
                          :failed-width="32"
                          :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                          class="mr-50"
                        >
                          <b-img
                            slot="image"
                            :src="`/dashboard/admin/images/deviceType/${adminDeviceState.deviceDataInfo.device_type}.svg`"
                            height="32"
                            width="32"
                            class="icon-iamge"
                          />
                        </VueLoadImage>

                        <div class="d-block text-nowrap show-text text-left">
                          <div v-if="adminDeviceState.deviceDataInfo.device_type_info && adminDeviceState.deviceDataInfo.device_type_info.name">
                            <div>{{ adminDeviceState.deviceDataInfo.device_type_info.name }}</div>
                            <small class="text-muted">
                              {{ adminDeviceState.deviceDataInfo.device_type }}
                            </small>
                          </div>
                          <small v-else>
                            ---
                          </small>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="6">
                    <b-card-text class="text-muted mb-0">
                      品牌
                    </b-card-text>

                    <div class="mt-1">
                      <div
                        v-if="adminDeviceState.deviceDataInfo.brand_id && adminDeviceState.deviceDataInfo.brand_info && adminDeviceState.deviceDataInfo.brand_info.image"
                        v-b-tooltip.hover.focus.v-secondary
                        :title="`${adminDeviceState.deviceDataInfo.brand_info.name}`"
                        class="table-title-image"
                        @click="showImgs(adminDeviceState.deviceDataInfo.brand_info.image)"
                      >
                        <VueLoadImage
                          :use-loading="true"
                          :use-failed="true"
                          :blank-height="'30px'"
                          :loading-width="30"
                          :loading-height="30"
                          :failed-width="50"
                        >
                          <b-img
                            slot="image"
                            :src="adminDeviceState.deviceDataInfo.brand_info.image"
                            class="title-image"
                            :alt="adminDeviceState.deviceDataInfo.brand_info.name"
                          />
                        </VueLoadImage>
                      </div>

                      <div v-else>
                        <div v-if="adminDeviceState.deviceDataInfo.brand_id && adminDeviceState.deviceDataInfo.brand_info.name">
                          <h4 class="font-weight-bolder mb-0 mt-1">
                            {{ adminDeviceState.deviceDataInfo.brand_info.name }}
                          </h4>
                        </div>

                        <div
                          v-else
                          class="table-title-image"
                        >
                          <b-img
                            v-if="adminDeviceState.deviceDataInfo.brand_id"
                            v-b-tooltip.hover.focus.v-secondary
                            title="品牌不存在"
                            :src="$store.state.app.themeImages.missImg"
                            height="50"
                            width="50"
                            class="title-image"
                          />

                          <span
                            v-else
                            class="text-muted"
                          >尚未設定品牌</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        lg="3"
      >
        <div class="mb-1">
          <b-card>
            <div class="link-button-container">
              <!-- <div class="link-botton text-center">
                <b-img
                  :src="require('@/assets/images/icon/history.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  歷史紀錄
                </h5>
              </div> -->

              <div
                class="link-botton text-center"
                @click="$emit('click-image-device-explorer')"
              >
                <b-img
                  :src="require('@/assets/images/icon/images.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  圖片總管
                </h5>
              </div>

              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-botton text-center"
              >
                <!-- @click="onSubmitRemark()" -->
                <div class="link-botton-alert">
                  <b-img
                    :src="require('@/assets/images/icon/remark.svg')"
                    class="link-botton-image"
                    fluid
                    rounded
                  />
                  <!-- <div
                    v-if="adminOrderState.orderInfo.remark_display"
                    class="state-dot dot-red"
                  /> -->
                </div>

                <h5 class="text-body-heading mb-0">
                  留言備註
                </h5>
              </div>

              <div
                class="link-botton text-center"
                @click="onSubmitUpdate"
              >
                <b-img
                  :src="require('@/assets/images/icon/editSet.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  編輯
                </h5>
              </div>

              <div
                class="link-botton text-center"
                @click="$emit('refresh-device-info')"
              >
                <b-img
                  :src="require('@/assets/images/icon/refresh.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  重新整理
                </h5>
              </div>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- 商品編輯 -->
    <device-edit-modal
      ref="deviceEditModal"
      @refetch-data="$emit('refresh-device-info')"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BOverlay, BImg, VBTooltip, BCardText, BLink,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import { useDeviceView, useDeviceSetting } from '../useDevice'
import DeviceEditModal from './components/DevicesEditModal.vue'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BLink,
    BFormGroup,
    BOverlay,
    BCardText,
    VueApexCharts,
    DeviceEditModal,
    VueLoadImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  computed: {
    adminDeviceState() {
      return this.$store.state['admin-device']
    },
    percentage() {
      return 0
      // if (Math.round((this.adminDeviceState.deviceDataInfo.total / this.adminDeviceState.deviceDataInfo.target) * 100) > 100) return 100
      // return Math.round((this.adminDeviceState.deviceDataInfo.total / this.adminDeviceState.deviceDataInfo.target) * 100)
    },
  },
  methods: {
    moment,
    // (觸發)圖片顯示
    showImgs(iamge) {
      this.$viewerApi({
        images: [iamge],
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (觸發)前往元件
    onSubmitLinkDevice(bindId) {
      this.$router.push({ name: 'admin-device-view', params: { id: bindId } })
    },

    // (檢查)是否為主要商品類型
    isDeviceMainType(type) {
      return this.$store.getters['appConfig/mainDeviceServerType'].map(item => item.key).includes(type)
    },

    // (檢查)廣告位置
    resolveDisplayGroupType(item) {
      if (item.branch_id && this.metaDisplayGroup[item.branch_id]) {
        const options = this.metaDisplayGroup[item.branch_id]
        if (options && options.length) {
          const displayGroup = options.find(f => f.key === item.display_group)
          return displayGroup ? displayGroup.name : ''
        }
        return item.display_group
      }
      return '---'
    },

    // (觸發)編輯
    onSubmitUpdate() {
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Update')
      if (!auth) return
      setTimeout(() => { this.$refs.deviceEditModal.getData() }, 200)
    },
  },
  setup() {
    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      goalOverviewRadialBar,
    } = useDeviceView()

    const {
      ui,
      syncObject,
    } = useDeviceSetting()

    // getMetaSystemConfigAll()

    return {
      useAlertToast,
      useHttpCodeAlert,

      ui,
      syncObject,

      goalOverviewRadialBar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
// .dark-layout {}

.link-button-container {
  display: flex;
  flex-wrap: wrap;
  .link-botton {
    width: calc(50% - 10px);
    margin: 5px;
    padding: 20px 10px;
    border: 1px solid #d5d2dc1a;
    background-color: #b8b7bb3b;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: transform ease-out 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .link-botton-image {
      max-width: 30px;
      max-height: 30px;
      margin-bottom: 10px;
    }

    .text-body-heading {
      font-size: 14px;
    }

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      animation: btn-breathe .8s linear infinite;
      background-color: #c9ebff59;
    }
  }

  .link-botton-alert {
    position: relative;
    width: 100%;
    max-width: 30px;
    .state-dot {
      position: absolute;
      right: -5px;
      bottom: 8px;
    }
  }
}

.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    width: calc(100% - 80px);
  }
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}
.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}
.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}

.server-name {
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: red !important;
    text-decoration: underline
  }
}

.table-title-image {
  text-align: center;
  .title-image {
    max-width: 100px;
    max-height: 40px;
    text-align: center;
  }
}

.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
</style>
