import { ref, watch } from '@vue/composition-api'
import { $themeColors } from '@themeConfig'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useDeviceSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    stateList: [{
      0: '可使用', 1: '使用中', 2: '待處理', 3: '送修', 4: '淘汰', 5: '鎖定',
    }, {
      0: 'light-success', 1: 'light-info', 2: 'light-warning', 3: 'light-danger', 4: 'light-secondary', 5: 'light-info',
    }, {
      0: 'dot-green', 1: 'dot-blue', 2: 'dot-yellow', 3: 'dot-red', 4: 'dot-gray', 5: 'dot-blue',
    }, {
      0: 'dot-green-selected', 1: 'dot-blue-selected', 2: 'dot-yellow-selected', 3: 'dot-red-selected', 4: 'dot-gray', 5: 'dot-blue-selected',
    }],
    slotType: [
      { false: '元件', true: '接點' },
      { false: 'info', true: 'warning' },
    ],
  }

  const stateOptions = [
    { label: '可使用', value: 0 },
    { label: '使用中', value: 1 },
    { label: '鎖定', value: 5 },
    { label: '待處理', value: 2 },
    { label: '送修', value: 3 },
    { label: '淘汰', value: 4 },
  ]

  const slotOptions = [
    { label: '元件', value: false },
    { label: '接點', value: true },
  ]

  const blankChartData = {
    series: [0],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#24c9ff'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  const resolveChartDataColor = health => {
    if (health <= 10) return '#ff5b52'
    if (health <= 50) return '#ffbb53'
    if (health < 100) return '#24c9ff'
    if (health === 100) return '#37deb2'
    return '#24c9ff'
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    stateOptions,
    slotOptions,
    blankChartData,
    resolveChartDataColor,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useDeviceTypeList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    getMetaListData,
  } = useDeviceSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '圖標', key: 'icon', sortable: false, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '驅動', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '類型', key: 'slot', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const deviceData = ref(null)
  const deviceTypeOptions = ref([])

  const blankDeviceTypeData = {
    id: null,
    name: null,
    type: null,
    slot: false,
    properties: [],
    created_at: null,
    updated_at: null,
  }

  const setDeviceTypeCreate = (...arg) => store.dispatch('admin-device/setDeviceTypeCreate', ...arg)
  const setDeviceTypeUpdate = (...arg) => store.dispatch('admin-device/setDeviceTypeUpdate', ...arg)
  const setDeviceTypeImageUpload = (...arg) => store.dispatch('admin-device/setDeviceTypeImageUpload', ...arg)

  const getDeviceTypeData = () => {
    getMetaListData({ key: 'deviceType', queryParams: { noPagination: true } }) //
      .then(response => {
        const { data } = response.data.data
        const blankSimpleData = {
          id: null,
          name: null,
          type: null,
          slot: false,
        }
        deviceTypeOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankSimpleData, item),
            // slot: item.slot ? 1 : 0,
          }
          return resolve
        })
      })
  }

  const getDeviceTypeListData = (ctx, callback) => {
    store.dispatch('admin-device/getDeviceTypeList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDeviceTypeData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    deviceData,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankDeviceTypeData,
    refetchData,
    refetchTable,
    getDeviceTypeData,
    deviceTypeOptions,

    setDeviceTypeCreate,
    setDeviceTypeUpdate,
    getDeviceTypeListData,
    setDeviceTypeImageUpload,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDeviceList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedTableData,
    isSelectState,
    isSelectedAll,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    // getMetaListData,
    blankChartData,
    resolveChartDataColor,
  } = useDeviceSetting()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '', key: 'selected', sortable: false, searchable: false, select: true,
    },
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '類別', key: 'device_type', sortable: true, searchable: true, select: true,
    // },
    {
      label: '品牌', key: 'brand_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '地點', key: 'place_id', sortable: false, searchable: false, select: true,
    },
    {
      label: '健康度', key: 'health', sortable: true, searchable: true, select: true,
    },
    {
      label: '進貨號', key: 'stock_id', sortable: false, searchable: false, select: true,
    },
    {
      label: '綁定', key: 'parent_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const searchState = ref(null) // '0, 1, 2, 3'
  const searchPlace = ref(null)
  const searchBrand = ref(null)
  const searchDeviceType = ref(null)
  const brandOptions = ref([])
  const placeOptions = ref([])
  const deviceTypeOptions = ref([])

  const blankDeviceData = {
    id: null,
    name: null,
    serialNumber: null,
    state: 0,
    brand_id: null,
    device_type: null,
    health: 100,
    stock_id: null,
    place_id: null,
    parent_id: null,
    spec_data: {},
    created_at: null,
    updated_at: null,
    remark_display: false,
  }

  const blankDeviceTypeInfoData = {
    id: null,
    name: null,
    type: null,
    slot: false,
  }

  const blankPlaceInfoData = {
    id: null,
    name: null,
    description: null,
  }

  const blankBrandInfoData = {
    id: null,
    name: null,
    image: null,
  }

  const blankStockInfoData = {
    id: null,
    name: null,
    serialNumber: null,
    description: null,
  }

  const setDeviceDelete = (...arg) => store.dispatch('admin-device/setDeviceDelete', ...arg)
  const setDeviceCreate = (...arg) => store.dispatch('admin-device/setDeviceCreate', ...arg)
  const setDeviceUpdate = (...arg) => store.dispatch('admin-device/setDeviceUpdate', ...arg)
  const setDeviceMutiUpdate = (...arg) => store.dispatch('admin-device/setDeviceMutiUpdate', ...arg)
  const setDeviceMutiDelete = (...arg) => store.dispatch('admin-device/setDeviceMutiDelete', ...arg)
  const setDeviceMutiBind = (...arg) => store.dispatch('admin-device/setDeviceMutiBind', ...arg)
  const setDeviceMutiUnbind = (...arg) => store.dispatch('admin-device/setDeviceMutiUnbind', ...arg)
  const setDeviceMutiSlotBind = (...arg) => store.dispatch('admin-device/setDeviceMutiSlotBind', ...arg)
  const setDeviceMutiSlotUnbind = (...arg) => store.dispatch('admin-device/setDeviceMutiSlotUnbind', ...arg)

  const getDeviceListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value}`
    }

    if (searchPlace.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};place_id: ${searchPlace.value}`
      } else resolveFilters = `place_id: ${searchPlace.value}`
    }

    if (searchBrand.value !== null) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};brand_id: ${searchBrand.value}`
      } else resolveFilters = `brand_id: ${searchBrand.value}`
    }

    if (searchDeviceType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};device_type: ${searchDeviceType.value}`
      } else resolveFilters = `device_type: ${searchDeviceType.value}`
    }

    store.dispatch('admin-device/getDeviceList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDeviceData, item),
            place_info: item.place_id && item.place_info ? syncObject(blankPlaceInfoData, item.place_info) : syncObject(blankPlaceInfoData, {}),
            brand_info: item.brand_id && item.brand_info ? syncObject(blankBrandInfoData, item.brand_info) : syncObject(blankBrandInfoData, {}),
            parent_info: item.parent_id && item.parent_info ? syncObject({
              id: null,
              device_type: null,
              name: null,
              serialNumber: null,
            }, item.parent_info) : syncObject(blankDeviceData, {}),
            stock_info: item.stock_id && item.stock_info ? syncObject(blankStockInfoData, item.stock_info) : syncObject(blankStockInfoData, {}),
            device_type_info: item.device_type && item.device_type_info ? syncObject(blankDeviceTypeInfoData, item.device_type_info) : syncObject(blankDeviceTypeInfoData, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })

        brandOptions.value = option.brand.map(item => {
          const resolve = {
            ...syncObject(blankBrandInfoData, item),
          }
          return resolve
        })

        deviceTypeOptions.value = option.deviceType.map(item => {
          const resolve = {
            ...syncObject(blankDeviceTypeInfoData, item),
          }
          return resolve
        }).filter(f => !f.slot)

        placeOptions.value = option.place.map(item => {
          const resolve = {
            ...syncObject(blankPlaceInfoData, item),
          }
          return resolve
        })

        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange, searchState, searchPlace, searchBrand, searchDeviceType], () => {
    refetchData()
  })

  return {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedTableData,
    isSelectState,
    isSelectedAll,
    refetchData,
    refetchTable,
    searchState,
    searchPlace,
    searchBrand,
    searchDeviceType,
    brandOptions,
    placeOptions,
    deviceTypeOptions,
    blankDeviceTypeInfoData,
    blankBrandInfoData,
    blankPlaceInfoData,
    blankStockInfoData,

    getDeviceListData,
    setDeviceDelete,
    setDeviceCreate,
    setDeviceUpdate,
    setDeviceMutiUpdate,
    setDeviceMutiDelete,
    setDeviceMutiBind,
    setDeviceMutiUnbind,
    setDeviceMutiSlotBind,
    setDeviceMutiSlotUnbind,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useDeviceView = () => {
  const {
    blankDeviceTypeInfoData,
    blankBrandInfoData,
    blankPlaceInfoData,
    blankStockInfoData,
    deviceTypeOptions,

    setDeviceUpdate,
    setDeviceMutiBind,
    setDeviceMutiUnbind,
    setDeviceMutiSlotBind,
    setDeviceMutiSlotUnbind,
  } = useDeviceList()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    getMetaListData,
  } = useDeviceSetting()

  const $strokeColor = '#ebe9f1'
  const $textHeadingColor = '#5e5873'
  const $goalStrokeColor2 = '#51e5a8'
  const goalOverviewRadialBar = {
    chart: {
      height: 180,
      type: 'radialBar',
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        blur: 3,
        left: 1,
        top: 1,
        opacity: 0.1,
      },
    },
    colors: [$goalStrokeColor2],
    plotOptions: {
      radialBar: {
        offsetY: -10,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: '77%',
        },
        track: {
          background: $strokeColor,
          strokeWidth: '50%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            color: $textHeadingColor,
            fontSize: '2.5rem',
            fontWeight: '600',
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [$themeColors.success],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    grid: {
      padding: {
        bottom: 30,
      },
    },
  }

  const blankDeviceData = {
    id: null,
    name: null,
    serialNumber: null,
    state: 0,
    brand_id: null,
    device_type: null,
    health: 100,
    stock_id: null,
    place_id: null,
    spec_data: [],
    child_info: [],
    parent_id: null,
    created_at: null,
    updated_at: null,
  }

  const blankDeviceTypeData = {
    id: null,
    name: null,
    type: null,
    slot: false,
    properties: [], // blankSetting
  }

  const blankSetting = {
    type: 'component', // slot
    label: null,
    key: null,
    required: false,
    limit: false,
    number: 0,
  }

  const blankChildrenInfo = {
    id: null,
    name: null,
    serialNumber: null,
    device_type: null,
  }

  const blankSpecData = {
    x: 0,
    y: 0,
    w: 4,
    h: 3,
    i: null,
    static: false,
    type: 'component',
    label: null,
    key: null,
    required: false,
    value: null,
    bind: null,
  }

  const getDeviceInfoList = (ids, callback) => {
    const resolveFilters = `id: ${ids.join(',')}`

    if (!ids.length) {
      callback([])
      return
    }

    store.dispatch('admin-device/getDeviceList', {
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDeviceData, item),
          }
          return resolve
        })

        callback(resolveData)
      })
  }

  const getDeviceInfoData = updateData => new Promise((resolve, reject) => {
    store.dispatch('admin-device/getDeviceData', { ...updateData })
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankDeviceData, data),
          place_info: data.place_id && data.place_info ? syncObject(blankPlaceInfoData, data.place_info) : syncObject(blankPlaceInfoData, {}),
          brand_info: data.brand_id && data.brand_info ? syncObject(blankBrandInfoData, data.brand_info) : syncObject(blankBrandInfoData, {}),
          device_type_info: data.device_type && data.device_type_info ? syncObject(blankDeviceTypeInfoData, data.device_type_info) : syncObject(blankDeviceTypeInfoData, {}),
          parent_info: data.parent_id && data.parent_info ? syncObject(blankChildrenInfo, data.parent_info) : syncObject(blankChildrenInfo, {}),
          stock_info: data.stock_id && data.stock_info ? syncObject(blankStockInfoData, data.stock_info) : syncObject(blankStockInfoData, {}),
          spec_data: [],
          slot_list: [],
          // chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
        }
        if (Array.isArray(data.spec_data)) {
          resolveData.spec_data = data.spec_data ? data.spec_data.map(item => syncObject(blankSpecData, item)) : []
        }
        resolveData.device_type_info.properties = data.device_type && data.device_type_info && data.device_type_info.properties ? data.device_type_info.properties.map(el => syncObject(blankSetting, el)) : []
        resolveData.child_info = data.child_info ? data.child_info.map(item => {
          const child = {
            ...syncObject(blankChildrenInfo, item),
            slot: item.device_type_info ? item.device_type_info.slot : false,
            connect_id: item.connect_info ? item.connect_info.id : null,
          }

          // 處理綁定資料
          if (child.slot && child.connect_id) {
            const connectInfo = item.connect_info
            if (connectInfo.parent_id && connectInfo.parent_info) {
              const bindDevice = {
                ...syncObject(blankChildrenInfo, connectInfo.parent_info),
                connect_id: child.id,
              }
              resolveData.slot_list.push(bindDevice)
              child.connect_id = bindDevice.id
            }
          }
          return child
        }) : []

        store.commit('admin-device/UPDATE_DEVICE_DATA_INFO_STATE', resolveData)
        resolve()
      })
      .catch(error => reject(error))
  })

  return {
    useAlertToast,
    useHttpCodeAlert,
    syncObject,
    getMetaListData,

    goalOverviewRadialBar,
    blankDeviceData,
    blankDeviceTypeInfoData,
    blankDeviceTypeData,
    blankBrandInfoData,
    blankPlaceInfoData,
    blankSetting,
    blankSpecData,
    blankChildrenInfo,
    deviceTypeOptions,
    getDeviceInfoData,
    getDeviceInfoList,
    setDeviceUpdate,
    setDeviceMutiBind,
    setDeviceMutiUnbind,
    setDeviceMutiSlotBind,
    setDeviceMutiSlotUnbind,
  }
}
