import { ref, watch } from '@vue/composition-api' // , computed
import { useTableComponent, useAlert } from '@/libs/mixins/index' // , useCommenSettings
import store from '@/store'

export const useImageSetting = () => {}

export const useImageResize = props => {
  const { deviceId } = props

  const {
    totalNum,
    currentPage,
    perPage,
  } = useTableComponent()

  const {
    useAlertToast,
  } = useAlert()

  perPage.value = 15
  totalNum.value = null

  const isBusy = ref(false)
  const isTableBusy = ref(true)
  const isBusyLoading = ref(false)

  const uploadNumber = ref(0)
  const randomString = ref([])
  const deductNumber = ref(0)

  const imageFileList = ref([])

  const setImageDelete = (...arg) => store.dispatch('admin-device-modal/deleteImageExplorer', ...arg)
  const setImageUpdate = (...arg) => store.dispatch('admin-device-modal/updateImageExplorer', ...arg)

  const getImageExplorer = () => {
    store.dispatch('admin-device-modal/getDeviceImages', {})
    if (imageFileList.value.length > 0) isBusyLoading.value = true
    store.dispatch('admin-device-modal/getDeviceImages', {
      limit: perPage.value,
      page: currentPage.value,
      order: 'desc',
      filter: `id: ${deviceId}`,
    })
      .then(response => {
        isTableBusy.value = false
        isBusyLoading.value = false
        const { data } = response.data.data
        data.forEach(el => {
          if (deductNumber.value > 0) {
            deductNumber.value -= 1
            return
          }
          const resolve = {
            id: el.id,
            name: el.name,
            url: el.path,
            device_id: el.device_id,
            image: null,
            error: null,
          }
          imageFileList.value.push(resolve)
        })
        totalNum.value = response.data.data.total
      })
  }

  const clearDataBack = (rId, response) => {
    const index = imageFileList.value.findIndex(obj => obj.rId === rId)
    randomString.value = randomString.value.filter(item => item !== rId)
    uploadNumber.value -= 1
    imageFileList.value[index].rId = null
    if (!response.success) {
      totalNum.value -= 1
      imageFileList.value[index].error = response.message
      imageFileList.value[index].image = null
      return
    }
    imageFileList.value[index].image = null
    imageFileList.value[index].id = response.data.id
    imageFileList.value[index].url = response.data.path
    imageFileList.value[index].device_id = response.data.device_id
    imageFileList.value[index].error = null
  }

  const setImageUpload = (image => {
    const formData = new FormData()
    totalNum.value += 1

    formData.append('data', image.image)
    formData.append('device_id', deviceId)
    formData.append('name', image.name)
    store.dispatch('admin-device-modal/uploadImageExplorer', {
      data: formData,
    })
      .then(response => {
        clearDataBack(image.rId, response.data)
        if (uploadNumber.value === 0) {
          isBusy.value = false
        }
      })
      .catch(() => {
        clearDataBack(image.rId, { success: false, message: '上傳時發生錯誤' })
        totalNum.value -= 1
        if (uploadNumber.value === 0) isBusy.value = false
        useAlertToast(false, '圖片上傳時發生錯誤')
      })
  })

  watch([currentPage], () => {
    getImageExplorer()
  })

  return {
    isBusy,
    totalNum,
    currentPage,
    perPage,
    isTableBusy,
    isBusyLoading,
    uploadNumber,
    randomString,
    deductNumber,
    setImageUpload,
    setImageDelete,
    setImageUpdate,
    getImageExplorer,

    imageFileList,
    useAlertToast,
  }
}
