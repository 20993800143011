<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯類別
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            lg="7"
          >
            <!-- 名稱 -->
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="deviceData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入商品名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="5"
          >
            <!-- 驅動 -->
            <b-form-group label-for="type">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    驅動
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>
              <b-form-input
                id="name"
                v-model="deviceData.type"
                trim
                placeholder="請輸入元件驅動"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div
          class="mt-1"
        >
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">規格規則</span>
            </div>
          </div>

          <hr class="mt-50">

          <draggable
            tag="ul"
            :list="deviceData.properties"
            class="list-group"
          >
            <li
              v-for="(element, index) in deviceData.properties"
              :key="`properties-${index}`"
              class="list-group-item"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                      class="cursor-pointer mr-1"
                    />

                    <div>
                      <div class="d-flex py-50 align-items-center">
                        <div :style="{ width: '50px' }">
                          <VueLoadImage
                            :use-loading="true"
                            :use-failed="true"
                            :blank-height="'32px'"
                            :loading-width="30"
                            :loading-height="30"
                            :failed-width="32"
                            :failed-image="transDeviceErrorImage(element.type === 'slot')"
                            class="mr-50"
                          >
                            <b-img
                              slot="image"
                              :src="`/dashboard/admin/images/deviceType/${element.key}.svg`"
                              height="40"
                              width="40"
                              class="icon-iamge"
                            />
                          </VueLoadImage>
                        </div>

                        <div class="d-block show-text">
                          <div>
                            <div class="mb-25">
                              <b-badge
                                :variant="element.type === 'component' ? 'info' : 'warning'"
                                class="mr-25"
                              >
                                {{ element.type === 'component' ? '元件' : '接點' }}
                              </b-badge>
                              <span class="font-weight-bold">{{ element.label }} </span>
                              <small v-if="element.key">( {{ element.key }} )</small>
                            </div>
                            <div class="ml-25">
                              <small>
                                <span :class="element.required ? 'text-danger' : ''">{{ element.required ? '必填' : '選填' }}</span>,
                                數量{{ element.limit ? `限制 ${element.number ? element.number : 0} 個` : '不限' }}
                              </small>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="d-flex text-nowrap align-items-center">
                    <div
                      class="actions-link-btn mr-25"
                      @click="selectedEditProperties(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      class="actions-link-btn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="移除"
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </draggable>

          <!-- 新增規格 -->
          <div class="text-center mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addNewProperties"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>新增規格</span>
            </b-button>
          </div>

          <!-- 規則設定 -->
          <properties-setting
            ref="propertiesModal"
            :properties-array="deviceData.properties"
            :selected-index="selectedIndex"
            :is-slot="deviceData.slot"
            :device-type-options="deviceTypeOptions"
            properties-spec-id="properties-modal"
            @edit-rule="editProperties"
          />
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
  BRow, BCol, BImg, BBadge,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import PropertiesSetting from './components/DeviceTypeProperties.vue'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDeviceSetting, useDeviceTypeList } from '../useDevice'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    draggable,
    PropertiesSetting,
    VueLoadImage,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    deviceItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
      maxChar: 255,
      selectedIndex: null,
    }
  },
  computed: {
    adminDomainState() {
      return this.$store.state['admin-domain']
    },
  },
  methods: {
    // (轉換)組件圖片
    transDeviceErrorImage(type) {
      if (type) return '/dashboard/admin/images/deviceType/ZPlug.svg'
      return '/dashboard/admin/images/deviceType/Component.svg'
    },

    // (編輯)參數設定
    editProperties(item) {
      const resolveData = this.syncObject(this.blankSetting, item)
      if (this.selectedIndex !== null) {
        this.$set(this.deviceData.properties, this.selectedIndex, resolveData)
        return
      }
      this.deviceData.properties.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditProperties(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.propertiesModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewProperties() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      if (this.deviceData.slot) {
        resolveData.type = 'slot'
      }
      this.selectedIndex = null
      setTimeout(() => { this.$refs.propertiesModal.getData(resolveData) }, 200)
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除規格',
        text: `你確定要刪除規格 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deviceData.properties.splice(index, 1)
        }
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDeviceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.deviceData[Object.keys(requiredFields)[i]] === null || this.deviceData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setDeviceTypeUpdate({
              id: this.deviceItem.id,
              data: {
                ...this.deviceData,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDeviceData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getDeviceTypeData()
      const resolveData = {
        ...this.syncObject(this.blankDeviceData, this.deviceItem),
      }
      this.deviceData.properties = this.deviceItem.properties.map(item => this.syncObject(this.blankSetting, item)) || []
      this.deviceData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useDeviceSetting()

    const {
      deviceData,
      setDeviceTypeUpdate,
      getDeviceTypeData,
      deviceTypeOptions,
    } = useDeviceTypeList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankDeviceData = {
      name: null,
      type: null,
      slot: false,
      properties: [],
    }

    const blankSetting = {
      type: 'component', // slot
      label: null,
      key: null,
      required: false,
      limit: false,
      number: 0,
    }

    deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))

    const resetDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDeviceData)

    return {
      deviceData,
      blankDeviceData,
      blankSetting,
      setDeviceTypeUpdate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      getDeviceTypeData,
      deviceTypeOptions,

      resetDeviceData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.required-key {
  color: red;
  margin-top: 3px;
}
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
