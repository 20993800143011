<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ brandDataItem.id ? '編輯品牌' : '新增品牌' }}
      </h4>
    </template>

    <validation-observer
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent
        @reset.prevent="resetForm"
      >
        <!-- 名稱 -->
        <validation-provider
          #default="{ errors }"
          name="名稱"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="brandData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="brandDataItem.name ? brandDataItem.name : '請輸入品牌名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 圖片 -->
        <div
          v-if="brandDataItem.id && checkAuthAbility('brand', 'API.Admin.Brand.Image')"
          class="mb-1"
        >
          <label class="mb-0">圖片</label>

          <div class="profile-img border cursor-pointer">

            <VueLoadImage
              v-if="brandData.image"
              :use-loading="true"
              :use-failed="true"
              :blank-height="'120px'"
              :loading-width="30"
              :loading-height="30"
              :failed-width="60"
            >
              <b-img
                slot="image"
                :src="brandData.image"
                rounded
                height="120"
                alt="品牌圖標"
                class="brand-iamge"
              />
            </VueLoadImage>

            <b-img
              v-else
              :src="$store.state.app.themeImages.emptyImg"
              rounded
              height="50"
              width="50"
              class="icon-iamge"
              alt="沒有品牌"
            />

            <div
              class="animate__animated animate__fadeIn"
              :class="{ 'profile-image-mask': checkAuthAbility('brand', 'API.Admin.Brand.Image')}"
            >
              <div
                v-if="checkAuthAbility('brand', 'API.Admin.Brand.Image')"
                class="profile-icon profile-image-edit"
                @click="submitUploadImage"
              >
                <b-img
                  src="/dashboard/admin/images/table/edit-w.svg"
                  width="30"
                  height="30"
                />
              </div>
              <div
                v-if="brandData.image && checkAuthAbility('brand', 'API.Admin.Brand.Image.Clear')"
                class="profile-icon profile-image-reset"
                @click="removeImage"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh-w.svg"
                  width="16"
                  height="16"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 說明 -->
        <div>
          <label>說明</label>
          <b-form-textarea
            v-model="brandData.description"
            :placeholder="brandDataItem.description ? brandDataItem.description : '請輸入說明'"
            :state="brandData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="brandData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ brandData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>

      <!-- 上傳圖片 -->
      <b-form-file
        ref="refInputEl"
        accept=".jpg, .png"
        :hidden="true"
        plain
        @change="loadImage($event)"
      />

      <!-- 圖片裁剪 -->
      <b-modal
        id="base-image-modal"
        centered
        :no-close-on-backdrop="close"
        :no-close-on-esc="close"
      >

        <template #modal-header>
          <h4>圖片裁剪</h4>
        </template>

        <div class="upload-example">
          <cropper
            ref="cropper"
            :src="image"
            :canvas="{
              minHeight: 0,
              minWidth: 0,
              maxHeight: 1920,
              maxWidth: 1920,
            }"
            :auto-zoom="false"
            :debounce="false"
            @change="onChange"
          />
        </div>

        <template #modal-footer>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="uploadImage"
          >
            上傳
          </b-button>
        </template>
      </b-modal>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, VBTooltip,
  BFormTextarea, BImg, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import formValidation from '@core/comp-functions/forms/form-validation'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useBrandList, useBrandSetting } from '../useBrand'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    // BImg,
    BForm,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BImg,
    BFormFile,

    ValidationProvider,
    ValidationObserver,
    VueLoadImage,
    Cropper,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  props: {
    brandDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
    }
  },
  methods: {
    // (觸發)圖片
    submitUploadImage() {
      const auth = this.checkAuthAbility('brand', 'API.Admin.Brand.Image')
      if (!auth) return
      this.$refs.refInputEl.$el.click()
    },

    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (!['JPG', 'PNG'].includes(type.toUpperCase())) return
      this.$bvModal.show('base-image-modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('data', blob)
          this.setBrandImageUpload({
            brand_id: this.brandDataItem.id,
            type: 'image',
            data: formData,
          })
            .then(response => {
              const image = response.data.data
              this.brandData.image = image
              this.$emit('refetch-data')
              this.useAlertToast(response.data.success, response.data.message)
            })
            .catch(error => {
              this.useSwalAlertCenter(false, '上傳失敗!', error.response.data.message)
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('base-image-modal')
      this.result.coordinates = null
    },

    // (刪除)圖片
    removeImage() {
      const auth = this.checkAuthAbility('brand', 'API.Admin.Brand.Image.Clear')
      if (!auth) return

      this.setBrandImageDelete({
        brand_id: this.brandData.id,
        type: 'image',
      })
        .then(response => {
          this.brandData.image = null
          this.useAlertToast(response.data.success, response.data.message)
          this.$emit('refetch-data')
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
        })
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('base-image-modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetbrandData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.brandData.description.length > this.maxChar) {
        this.useAlertToast(false, '說明字數超過上限')
        return
      }

      this.isBusy = true

      if (this.brandDataItem.id) {
        this.setBrandUpdate({
          id: this.brandDataItem.id,
          data: {
            name: this.brandData.name,
            description: this.brandData.description,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetbrandData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setBrandCreate({
          name: this.brandData.name,
          description: this.brandData.description,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetbrandData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankbrandData, this.brandDataItem),
      }
      this.brandData = resolveData
      this.$bvModal.show('update-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setBrandCreate,
      setBrandUpdate,
      setBrandImageUpload,
      setBrandImageDelete,
    } = useBrandList()

    const {
      stateOptions,
      getMetaListData,
      departmentsOptions,
      syncObject,
    } = useBrandSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankbrandData = {
      id: null,
      name: null,
      image: null,
      description: '',
    }

    const brandData = ref(JSON.parse(JSON.stringify(blankbrandData)))

    const resetbrandData = () => {
      brandData.value = JSON.parse(JSON.stringify(blankbrandData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetbrandData)

    return {
      isBusy,

      brandData,
      stateOptions,
      departmentsOptions,
      blankbrandData,
      setBrandCreate,
      setBrandUpdate,
      setBrandImageUpload,
      setBrandImageDelete,
      getMetaListData,

      resetbrandData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.profile-img {
    position: relative;
    min-height: 80px;
    border-radius: 5px;
    .icon-iamge {
      position: absolute;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .brand-iamge {
      // max-height: 120px;
      max-width: 100%;
      position: absolute;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
    }
    .profile-image-mask {
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #00000080;
      user-select: none;
      .profile-icon {
        cursor: pointer;
      }
      .profile-image-reset {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #69bdf2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:hover {
      .profile-image-mask {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
