<template>
  <div>
    <!-- 搜尋欄 -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>篩選器</h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            lg="3"
            md="4"
            class="mb-md-0 mb-1"
          >
            <label>類型</label>
            <v-select
              v-model="searchAction"
              :options="domainActionOptions"
              class="w-100"
              :reduce="option => option.value"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <!-- 網域列表 -->
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              交易紀錄
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-if="checkAuthAbility('service', 'API.Admin.DomainName.Import')"
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitServiceAdd"
              >
                <b-img
                  src="/dashboard/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDomainListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            hide-header
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 類別 -->
        <template #cell(type)="data">
          <div class="table-col">
            <b-badge :variant="ui.actionType[1][data.item.action]">
              {{ ui.actionType[0][data.item.action] }}
            </b-badge>
          </div>
        </template>

        <!-- 欄位: 網域 -->
        <template #cell(url)="data">
          <div class="table-col text-select">
            <div class="d-block text-nowrap selection-group d-flex align-items-center">
              <div class="d-flex align-items-end">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text"
                  @click="linkServiceView(data.item)"
                >
                  <h5 class="mb-0">
                    {{ data.item.prefix }}{{ data.item.suffix }}
                  </h5>
                </b-link>

                <div
                  v-if="data.item.remark_display && checkAuthAbility('remark', 'API.Admin.Remark')"
                  @click="onSubmitRemark(data.item)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/chat-remark.svg"
                    height="20"
                    width="20"
                    class="cursor-pointer mb-25 ml-50"
                  />
                </div>
              </div>
              <span
                v-if="checkAuthAbility('service', 'API.Admin.DomainName.Service.Url')"
                v-b-tooltip.hover.focus.v-secondary
                title="網域詳情"
                class="selection-btn-icon-show"
                @click="linkServiceView(data.item)"
              >
                <b-img
                  src="/dashboard/admin/images/table/external-link.svg"
                  height="14"
                  width="14"
                  class="cursor-pointer mb-25"
                />
              </span>
            </div>
            <small class="text-muted ">{{ data.item.url }}</small>
          </div>
        </template>

        <!-- 欄位: 訂單 -->
        <template #cell(order)="data">
          <div class="table-col">
            <div
              v-if="data.item.system_order_id && data.item.order_info.id"
              class="d-block text-nowrap selection-group d-flex align-items-center"
            >
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                @click="onSubmitRemark(data.item)"
              >
                {{ data.item.system_order_id }}
              </b-link>

              <b-link
                v-if="checkAuthAbility('order', 'API.Admin.Order.Id')"
                v-b-tooltip.hover.focus.v-secondary
                title="前往訂單"
                class="selection-btn-icon-show"
                @click="linkOrderView(data.item)"
              >
                <b-img
                  src="/dashboard/admin/images/table/external-link.svg"
                  height="14"
                  width="14"
                  class="cursor-pointer mb-25"
                />
              </b-link>
            </div>

            <div
              v-else
              class="text-nowrap"
            >
              <small class="text-muted">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 約期 (年) -->
        <template #cell(quantity)="data">
          <div class="table-col">
            <span>{{ data.item.quantity }}</span>
          </div>
        </template>

        <!-- 欄位: 會員名稱 -->
        <template #cell(customer)="data">
          <div class="table-col">
            <b-media
              v-if="data.item.customer && data.item.customer_info"
              vertical-align="center"
            >
              <template #aside>
                <b-link @click="linkCustomerInfo(data.item.customer_info)">
                  <b-avatar
                    size="32"
                    :src="data.item.customer_info.image"
                    :text="avatarText(data.item.customer_info.name)"
                    variant="light-primary"
                  />
                </b-link>
              </template>
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text"
                  @click="onSubmitRemark(data.item)"
                >
                  {{ data.item.customer_info.name }} {{ data.item.customer_info.family_name }}
                </b-link>
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選"
                  class="selection-btn-icon-show"
                  @click="searchCol({
                    name: '會員SSID',
                    key: 'search_query',
                    value: data.item.customer,
                  })"
                >
                  <b-img
                    src="/dashboard/admin/images/table/filter2.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </span>
                <b-link
                  v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="會員詳情"
                  class="selection-btn-icon-show"
                  @click="linkCustomerInfo(data.item.customer_info)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/external-link.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </b-link>
              </div>
              <div class="text-muted item-description-email">
                {{ data.item.customer_info.account }}
              </div>
            </b-media>

            <div
              v-else
              class="text-nowrap"
            >
              <b-avatar
                size="32"
                variant="light-primary"
              />
              <small class="text-muted ml-50">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 開始時間 -->
        <template #cell(startTime)="data">
          <div
            v-b-tooltip.hover.focus.v-secondary
            :title="data.item.start_time ? `${moment(data.item.start_time).format('HH:mm:ss')}` : null"
            variant="gradient-primary"
          >
            <span class="text-nowrap">
              {{ data.item.start_time ? moment(data.item.start_time).format("YYYY-MM-DD") : '---' }}
            </span>
          </div>
        </template>

        <!-- 欄位: 結束時間 -->
        <template #cell(endTime)="data">
          <div
            v-b-tooltip.hover.focus.v-secondary
            :title="data.item.end_time ? `${moment(data.item.end_time).format('HH:mm:ss')}` : null"
            variant="gradient-primary"
          >
            <span class="text-nowrap">
              {{ data.item.end_time ? moment(data.item.end_time).format("YYYY-MM-DD") : '---' }}
            </span>
          </div>
        </template>

        <!-- 欄位: 經銷商 / 網域商 -->
        <template #cell(dealer)="data">
          <div class="table-col">
            <div class="w-100">
              <span
                class="font-weight-bold d-block text-nowrap show-text"
              >
                {{ data.item.domain_name_dealer_info.name }}
              </span>
              <small class="text-muted">
                <div class="email-text">{{ data.item.domain_name_agent_info.agent }}</div>
              </small>
            </div>
          </div>
        </template>

        <!-- 欄位: 狀態 -->
        <template #cell(state)="data">
          <div class="table-col">
            <b-badge
              v-if="!isServiceBusy.includes(data.item.id)"
              :variant="ui.logStateType[1][data.item.state]"
              @click="alertStateContent(data.item)"
            >
              {{ ui.logStateType[0][data.item.state] }}
            </b-badge>

            <b-badge
              v-else
              variant="light-secondary"
            >
              處理中
            </b-badge>
          </div>
        </template>

        <!-- 欄位: 創建時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 更新時間 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex actions-container">
            <div
              v-if="checkAuthAbility('service', 'API.Admin.DomainName.EPP')"
              class="actions-link-btn"
              :class="{ 'actions-link-btn-hiden': (data.item.state !== 2 && data.item.action === 2) || isServiceBusy.includes(data.item.id) }"
              :style="{ 'opacity': data.item.state === 2 && !isServiceBusy.includes(data.item.id) && data.item.action === 2 ? 1 :0 }"
              style="width: 30px"
              @click="onSubmitEPPSetting(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="快速編輯"
                src="/dashboard/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-if="checkAuthAbility('service', 'API.Admin.DomainName.Action')"
              class="actions-link-btn"
              :class="{ 'actions-link-btn-hiden': data.item.state !== 2 || isServiceBusy.includes(data.item.id) }"
              :style="{ 'opacity': data.item.state === 2 && !isServiceBusy.includes(data.item.id) ? 1 :0 }"
              style="width: 30px"
              @click="onSubmitActivate(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="手動開通"
                src="/dashboard/admin/images/table/product.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
              class="actions-link-btn"
              @click="onSubmitRemark(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="備註留言"
                src="/dashboard/admin/images/table/chat.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-if="checkAuthAbility('service', 'API.Admin.DomainName.Service.Url')"
              class="actions-link-btn mr-25"
              @click="linkServiceView(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="網域詳情"
                src="/dashboard/admin/images/table/menu.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="m-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <remark-modal
      ref="domainActionRemarkExplorerModal"
      remark-explorer-id="domainActionRemarkExplorerModal"
      :key-array="keyArray"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="refetchTable"
      @call-back-data="() => {}"
    >
      <template v-slot:header-info>
        <div
          v-if="remarkHeaderInfo"
          class="p-2"
        >
          <b-row>
            <b-col
              cols="21"
              lg="6"
              class="d-flex justify-content-between flex-column mb-1"
            >
              <div class="d-flex justify-content-start">
                <div class="profile-image">
                  <b-avatar
                    size="9rem"
                    rounded
                    :src="remarkHeaderInfo.customer_info.image"
                    variant="light-secondary"
                  />
                </div>

                <div class="d-flex flex-column ml-1">
                  <div class="mb-50">
                    <h2 class="mb-0">
                      {{ remarkHeaderInfo.customer_info.name }} {{ remarkHeaderInfo.customer_info.family_name }}
                    </h2>
                    <span class="show-text">{{ remarkHeaderInfo.customer_info.account }}</span>
                  </div>

                  <div class="text-nowrap">
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="信箱驗證"
                      rounded
                      fluid
                      class="user-state-icon"
                      :style="{ 'opacity': remarkHeaderInfo.customer_info.email_state === 2 ? 1 : 0.3 }"
                      :src="require('@/assets/images/pages/customer/email.svg')"
                    />

                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="電話驗證"
                      rounded
                      fluid
                      class="user-state-icon"
                      :style="{ 'opacity': remarkHeaderInfo.customer_info.phone_state === 2 ? 1 : 0.3 }"
                      width="28"
                      :src="require('@/assets/images/pages/customer/security.svg')"
                    />

                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="身分驗證"
                      rounded
                      fluid
                      class="user-state-icon"
                      :style="{ 'opacity': remarkHeaderInfo.customer_info.kyc_state === 2 ? 1 : 0.3 }"
                      :src="require('@/assets/images/pages/customer/id-card.svg')"
                    />

                  </div>
                </div>
              </div>
            </b-col>

            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label-cols="4"
                label="當前網域"
                class="m-0"
              >
                <div class="mt-50 font-weight-bolder">
                  {{ remarkHeaderInfo.prefix }}{{ remarkHeaderInfo.suffix }}
                </div>
              </b-form-group>

              <b-form-group
                v-if="remarkHeaderInfo.system_order_id"
                label-cols="4"
                label="紀錄訂單"
                class="m-0"
              >
                <div class="mt-50">
                  {{ remarkHeaderInfo.system_order_id ? remarkHeaderInfo.system_order_id : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="上次登入"
                class="m-0"
              >
                <div class="mt-50">
                  {{ remarkHeaderInfo.customer_info.last_login_at ? moment(remarkHeaderInfo.customer_info.last_login_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="上次更新"
                class="m-0"
              >
                <div class="mt-50">
                  {{ remarkHeaderInfo.customer_info.updated_at ? moment(remarkHeaderInfo.customer_info.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div
          v-else
          class="mb-1"
        />
      </template>
    </remark-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BInputGroupAppend, BBadge,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BMedia, BLink, BAvatar,
  BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import { useDomainActionList, useDomainServiceView, useDomainSetting } from '../useDomain'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    BCardHeader,
    BCardBody,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,
    BInputGroupAppend,

    vSelect,
    TableTimeRange,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      isServiceBusy: [],
      keyArray: [],
      remarkHeaderInfo: null,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark(item) {
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      const resolveArray = []
      resolveArray.push(`domain-${item.url}`)
      if (item.order_info && item.order_info.id) resolveArray.push(`order-${item.order_info.id}`)
      if (item.customer_info && item.customer_info.id) resolveArray.push(`customer-${item.customer_info.id}`)
      if (resolveArray.length <= 0) return
      this.keyArray = resolveArray
      this.remarkHeaderInfo = JSON.parse(JSON.stringify(item))
      setTimeout(() => { this.$refs.domainActionRemarkExplorerModal.getData() }, 200)
    },

    // (觸發)編輯EPP密碼
    onSubmitEPPSetting(item) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.EPP')
      if (!auth) return
      this.$emit('submit-action', item)
    },

    // (觸發)狀態顯示
    alertStateContent(item) {
      if (item.state !== 2) return
      this.useSwalAlertCommon('error', '失敗原因', item.content)
    },

    // (匯入)網域
    onSubmitServiceAdd() {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Create')
      if (!auth) return
      this.$emit('submit-update', null)
    },

    // (編輯)網域
    onSubmitServiceEdit(item) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Update')
      if (!auth) return
      this.$emit('submit-update', item)
    },

    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
      }
    },

    // (觸發)服務執行
    onSubmitActivate(service) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Action')
      if (!auth) return
      if (service.state !== 2) return
      if (service.order_info.state !== 4) return
      if (this.isServiceBusy.includes(service.id)) return
      const serviceDataInfo = `${this.ui.actionType[0][service.action]} ${service.prefix}${service.suffix} / ${service.quantity}年`

      this.useSwalAlertWarning('網域串接', `你確定要手動觸發 ${serviceDataInfo} 的串接服務嗎? ( 該動作包含網域購買流程及服務新增，請先確認網域商餘額，以免購買失敗 )`)
        .then(result => {
          if (result.value) {
            if (!this.isServiceBusy.includes(service.id)) {
              this.isServiceBusy.push(service.id)
            }
            this.setOrderServiceDomain({
              domain_name_id: service.id,
            }).then(response => {
              this.isServiceBusy = this.isServiceBusy.filter(id => id !== service.id)
              this.useSwalAlertCenter(true, '串接成功', response.data.message)
              this.refetchData()
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '觸發失敗', error.response.data.message)
                this.isServiceBusy = this.isServiceBusy.filter(id => id !== service.id)
              })
          }
        })
    },

    // (前往)服務詳情
    linkServiceView(item) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Service.Url')
      if (!auth) return
      if (!item.url) return
      this.$router.push({
        name: 'admin-service-domain-view',
        params: {
          id: item.url,
        },
      })
    },

    // (前往)會員詳情
    linkCustomerInfo(item) {
      const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-customer-view', params: { id: item.id } })
    },

    // (前往)訂單詳情
    linkOrderView(item) {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Id')
      if (!auth) return
      this.$router.push({
        name: 'admin-order-view',
        params: {
          id: item.id,
        },
      })
    },
  },
  setup() {
    const {
      ui,
      domainActionOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDomainSetting()

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchAction,
      timeRange,
      blankDomainDealerData,
      domainData,
      refetchData,
      refetchTable,

      getDomainListData,
      useAlertToast,
      useHttpCodeAlert,
    } = useDomainActionList()

    const {
      setOrderServiceDomain,
    } = useDomainServiceView()

    return {
      ui,
      domainActionOptions,
      avatarText,
      refonlineTime,
      onlineTime,
      updateOnline,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchAction,
      timeRange,
      blankDomainDealerData,
      domainData,
      refetchData,
      refetchTable,

      getDomainListData,
      useAlertToast,
      useHttpCodeAlert,

      setOrderServiceDomain,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.actions-container {
  display: flex;
}

.user-state-icon {
  max-width: 30px;
  margin-right: 10px;
}

.user-state-icon-hidden {
  opacity: 0 !important;
}

@media (max-width: 576px) {
  .actions-container {
    justify-content: start;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
