<template>
  <div>
    <b-modal
      :id="deviceExplorerId"
      no-close-on-backdrop
      no-close-on-esc
      centered
      header-bg-variant="primary"
      body-class="p-0"
      hide-footer
      size="lg"
      @hidden="handleHide"
      @close="handleHide"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          元件設定 {{ setSearchDeviceType }}
        </h4>
      </template>

      <div>
        <div class="m-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="text-nowrap d-flex justify-content-end align-items-center mt-50">

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選器"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/filter.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form style="min-width: 300px">
                      <b-form-group>
                        <label class="mb-50">
                          搜尋
                        </label>

                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="searchQuery"
                            debounce="500"
                            class="d-inline-block"
                            placeholder="搜尋..."
                          />
                          <b-input-group-append
                            v-if="searchQuery"
                            is-text
                          >
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer text-muted"
                              @click="searchQuery = null"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getDeviceListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          primary-key="id"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          select-mode="multi"
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-col text-select d-flex">
              <div
                v-b-tooltip.hover.focus.v-secondary
                :title="ui.stateList[0][data.item.state]"
                class="state-dot mt-25 mr-50"
                :class="ui.stateList[2][data.item.state]"
              />

              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <div>
                  <b-link class="font-weight-bold d-block text-nowrap show-text">
                    {{ data.item.name }}
                  </b-link>

                  <small class="text-muted">
                    {{ data.item.serialNumber }}
                  </small>

                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 類別 -->
          <template #cell(device_type)="data">
            <div class="table-col">
              <div class="d-flex text-nowrap">
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                  class="mr-50"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${data.item.device_type}.svg`"
                    height="32"
                    width="32"
                    class="icon-iamge"
                  />
                </VueLoadImage>

                <div class="d-block text-nowrap show-text">
                  <div v-if="data.item.device_type_info && data.item.device_type_info.name">
                    <div>{{ data.item.device_type_info.name }}</div>
                    <small class="text-muted">
                      {{ data.item.device_type }}
                    </small>
                  </div>
                  <small v-else>
                    ---
                  </small>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 地點 -->
          <template #cell(place_id)="data">
            <div class="table-title">
              <div v-if="data.item.place_id">
                <span>{{ data.item.place_info.name }}</span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 品牌 -->
          <template #cell(brand_id)="data">
            <div class="table-title">
              <div
                v-if="data.item.brand_id && data.item.brand_info && data.item.brand_info.image"
                v-b-tooltip.hover.focus.v-secondary
                :title="`${data.item.brand_info.name}`"
                class="table-title-image"
                @click="showImgs(data.item.brand_info.image)"
              >
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'30px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="50"
                >
                  <b-img
                    slot="image"
                    :src="data.item.brand_info.image"
                    class="title-image"
                    :alt="data.item.brand_info.name"
                  />
                </VueLoadImage>
              </div>

              <div v-else>
                <div v-if="data.item.brand_id && data.item.brand_info.name">
                  <span>{{ data.item.brand_info.name }}</span>
                </div>

                <div
                  v-else
                  class="table-title-image"
                >
                  <b-img
                    v-if="data.item.brand_id"
                    v-b-tooltip.hover.focus.v-secondary
                    title="品牌不存在"
                    :src="$store.state.app.themeImages.missImg"
                    height="50"
                    width="50"
                    class="title-image"
                  />

                  <span v-else>---</span>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 目前設定 -->
          <template #cell(use)="data">
            <div>
              <b-button
                variant="outline-primary"
                size="sm"
                class="text-nowrap"
                @click="() => {
                  selectedComponent(data.item)
                  handleOk()
                }"
              >選擇</b-button>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 my-1">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            v-if="useCallbackData"
            variant="primary"
            :disabled="isTableBusy"
            @click="handleOk"
          >
            <!--  || (botInfo.id === null && selectBotData.id === null) -->
            <span v-if="!isTableBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BDropdownForm, BFormInput,
  BInputGroupPrepend, BInputGroup, BFormGroup, BSkeletonTable, BInputGroupAppend, BModal, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useAdminDeviceModal, useModalSetting } from '../useModal'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BModal,
    BImg,
    BRow,
    BCol,
    BLink,
    BTable,
    BButton,
    BPagination,
    BSkeletonTable,
    BSpinner,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    VueLoadImage,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    dataKey: {
      type: Array,
      default: () => ['id', 'name', 'serialNumber'],
    },
    setSearchDeviceType: {
      type: Array,
      default: () => [],
    },
    setSearchState: {
      type: Array,
      default: () => [],
    },
    isHidenEmit: {
      type: Boolean,
      default: false,
    },
    useCallbackData: {
      type: Boolean,
      default: false,
    },
    deviceExplorerId: {
      type: String,
      default: 'device-explorer-modal',
    },
  },
  data() {
    return {
      selectDevice: {},
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns.filter(f => f.select)
    },
  },
  methods: {
    selectedComponent(data) {
      this.dataKey.forEach(key => {
        if (key in data) {
          this.selectDevice[key] = data[key]
        }
      })
    },

    // 按下彈窗選擇
    handleOk() {
      if (this.isTableBusy) return
      if (this.selectDevice.id === null) {
        this.useAlertToast(false, '元件不得為空')
        return
      }

      if (this.useCallbackData) this.$emit('call-back-data', this.selectDevice)
      this.$nextTick(() => {
        this.handleHide()
      })
    },

    // 按下彈窗關閉
    handleHide() {
      this.$nextTick(() => {
        this.$bvModal.hide(this.deviceExplorerId)
        if (this.isHidenEmit) this.$emit('emit-hidden-function')
        this.searchQuery = null
      })
    },

    // (獲取資料)
    getData(item) {
      if (item) {
        const resolveData = {
          ...this.syncObject(this.blankDeviceData, item),
        }
        this.selectDevice = resolveData
      }
      if (this.setSearchDeviceType.length > 0) this.searchDeviceType = this.setSearchDeviceType.join(', ')
      else this.searchDeviceType = null

      if (this.setSearchState.length > 0) this.searchState = this.setSearchState.join(', ')
      else this.searchState = null

      this.$bvModal.show(this.deviceExplorerId)
    },
  },
  setup(props) {
    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      selectedTableData,
      isSelectState,
      isSelectedAll,
      refetchData,
      refetchTable,
      ui,
      stateOptions,
      searchState,
      searchPlace,
      searchBrand,
      searchDeviceType,
      brandOptions,
      placeOptions,
      deviceTypeOptions,
      blankDeviceData,
      isTableBusy,

      getDeviceListData,
      useAlertToast,
      useHttpCodeAlert,
    } = useAdminDeviceModal(props)

    const {
      syncObject,
    } = useModalSetting()

    return {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      selectedTableData,
      isSelectState,
      isSelectedAll,
      refetchData,
      refetchTable,
      ui,
      stateOptions,
      searchState,
      searchPlace,
      searchBrand,
      searchDeviceType,
      brandOptions,
      placeOptions,
      deviceTypeOptions,
      blankDeviceData,
      isTableBusy,

      getDeviceListData,
      useAlertToast,
      useHttpCodeAlert,

      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}
.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}
.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}

.table-title-image {
  text-align: center;
  .title-image {
    max-width: 100px;
    max-height: 40px;
    text-align: center;
  }
}

.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
</style>
