<template>
  <b-modal
    :id="imageExplorerId"
    no-close-on-backdrop
    no-close-on-esc
    centered
    header-bg-variant="primary"
    size="lg"
    body-class="p-0"
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        圖片總管
      </h4>
    </template>

    <div ref="uploadCardContent">
      <div
        v-if="!isTableBusy"
        ref="uploadCard"
        no-body
        class="image-explorer-contain upload-card"
        @dragenter="dragEnter"
        @dragover="preventDefault"
        @dragleave="preventDefault"
        @drop="dropFile"
      >
        <div
          ref="dropArea"
          class="dropArea hidden"
          @dragenter="dragAreaEnter"
          @dragleave="dragLeave"
          @dragover="preventDefault"
          @drop="dropFile"
        >
          <div class="upload-label">
            <span class="label-title">
              把圖片拖放到這裡
            </span>
            <b-img
              :src="require('@/assets/images/icon/images.svg')"
              width="60"
              fluid
            />
          </div>
        </div>

        <div
          class="border m-1 rounded"
        >
          <!-- class="d-block d-sm-none" -->
          <label class="upload-label-text">
            <span>
              把圖片拖放到這裡或者 <strong class="cursor-pointer text-primary">點擊這裡</strong> 選擇檔案
            </span>
            <input
              type="file"
              multiple
              accept=".jpg, .png, .gif"
              hidden
              @input="resolveFile($event.target.files)"
            >
          </label>

        </div>

        <!-- 圖片模式 -->
        <div class="container upload-card-content">
          <b-row
            class="match-height image-view-container"
          >
            <b-col
              v-for="(image, index) in imageFileList"
              :key="index"
              cols="12"
              xl="4"
              lg="6"
              class="mb-1 d-flex align-items-center"
              @drop="() => {}"
            >
              <div
                class="image-view"
                :class="!isDark ? 'image-light' : 'image-view-dark'"
              >
                <div class="image-view-header">
                  <span
                    v-if="!image.image && !image.error"
                    class="image-view-select cursor-pointer"
                    :class="{ 'd-none': editCol === index }"
                    :style="{opacity: selected.includes(image) ? 1 : 0}"
                  >
                    <b-form-checkbox
                      v-model="selected"
                      :value="image"
                      name="muti-selected"
                    />
                    <!-- {{ selected.includes(image) }} -->
                  </span>

                  <b-input-group
                    v-if="editCol === index"
                    class="input-group-merge mx-25 mb-25"
                  >
                    <b-form-input
                      id="name"
                      v-model="image.name"
                      placeholder="圖片名稱"
                    />

                    <b-input-group-append is-text>
                      <div>
                        <span
                          class="cursor-pointer"
                          @click="confirmEditCol(image, index)"
                        >
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                            width="18"
                            class="mr-75 disable-drag"
                          />
                        </span>

                        <span
                          class="cursor-pointer"
                          @click="cancelEditCol(index)"
                        >
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                            width="18"
                            class="disable-drag"
                          />
                        </span>
                      </div>
                    </b-input-group-append>
                  </b-input-group>

                  <div
                    v-else
                    class="image-view-title cursor-pointer"
                    @click="selectEditCol(index)"
                  >
                    <span class="image-view-title-text">{{ image.name }}</span>
                  </div>
                </div>

                <div class="image-view-body">
                  <span
                    v-if="!image.image || image.error"
                    class="image-view-remove cursor-pointer"
                    @click="removeImage(image, index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="remove-icon "
                    />
                  </span>

                  <div
                    class="w-100 h-100 d-flex align-items-center justify-content-center"
                    @click="showImgs(index)"
                  >
                    <b-img-lazy
                      :src="image.url"
                      fluid
                      :alt="image.name"
                    />
                  </div>

                  <div
                    v-if="image.image"
                    class="image-upload-mask"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      height="60"
                    />
                  </div>

                  <div
                    v-if="image.error"
                    class="image-upload-error-mask"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.errorImg"
                      height="60"
                    />
                  </div>
                </div>

                <div class="image-view-footer">
                  <div>
                    <div v-if="image.image">
                      <button class="download-btn">
                        <b-img
                          :src="$store.state.app.themeImages.loadingImg"
                          height="30"
                          class="disable-drag"
                        />
                      </button>
                      <span>圖片上傳中</span>
                    </div>

                    <div
                      v-else
                      class="mt-1 px-50"
                    >
                      {{ image.error }}
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <div
            v-if="imageFileList.length === 0"
            class="text-center my-5 animate__animated animate__fadeIn"
          >
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </div>

        <div
          v-if="isBusyLoading"
          class="text-center p-2"
        >
          <b-img
            :src="$store.state.app.themeImages.loadingImg"
            rounded
            height="30"
            width="30"
          />
        </div>
      </div>

      <div
        v-else
        class="upload-card-content"
      >
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-img
            :src="$store.state.app.themeImages.loadingImg"
            rounded
            height="80"
            width="80"
          />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-between align-items-center w-100 flex-sm-row flex-column">
        <div class="mb-sm-0 mb-50">
          <div v-if="selected.length > 0">
            已選擇: {{ selected.length }}張
          </div>
        </div>
        <div
          v-if="!isBusyLoading"
          class="mb-sm-0 mb-50"
        >
          圖片數量 {{ totalNum }}
        </div>

        <div class="mb-sm-0 mb-50">
          <b-button
            v-if="totalNum !== 0"
            variant="outline-primary"
            size="sm"
            @click="targetSelected"
          >
            {{ selectedAll ? '取消' : '全選' }}
          </b-button>

          <b-button
            v-if="selected.length > 0"
            variant="outline-danger"
            size="sm"
            class="ml-1"
            @click="removeMutiImage"
          >
            刪除
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BModal, BImg, BRow, BCol, VBTooltip, BInputGroup, BInputGroupAppend, BFormInput, BFormCheckbox, BButton,
  BImgLazy,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { usePublicFunction } from '@/libs/mixins/index'
import { useImageResize } from './useImage'

export default {
  components: {
    BModal,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BImgLazy,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [usePublicFunction],
  props: {
    isHidenRefresh: {
      type: Boolean,
      default: false,
    },
    deviceId: {
      type: String,
      required: true,
    },
    useCallbackUrl: {
      type: Boolean,
      default: false,
    },
    imageExplorerId: {
      type: String,
      default: 'image-explorer-modal',
    },
  },
  data() {
    return {
      selectedAll: false,
      selected: [],
      elementNode: null,
      editCol: null,
      editColData: null,
    }
  },
  computed: {
    isAll() {
      const num = this.currentPage * this.perPage
      if (num > this.totalNum) return false
      return true
    },
  },
  methods: {
    // (全選)
    targetSelected() {
      if (this.selectedAll) {
        this.selected = []
        this.selectedAll = !this.selectedAll
        return
      }
      this.selected = this.imageFileList.filter(f => !f.error)
      this.selectedAll = !this.selectedAll
    },

    // (取消)編輯欄位
    cancelEditCol(index) {
      this.imageFileList[index].name = this.editColData
      this.editCol = ''
    },

    // (選取)編輯欄位
    selectEditCol(index) {
      if (!this.imageFileList[index].id) return
      this.editCol = index
      this.editColData = this.imageFileList[index].name
    },

    // (確認)編輯欄位
    confirmEditCol(image, index) {
      if (!this.imageFileList[index].name) return
      this.onSubmitUpdate(image, index)
    },

    // (觸發)圖片顯示
    showImgs(index) {
      const newArray = this.imageFileList.slice(index).concat(this.imageFileList.slice(0, index)).map(item => item.url)
      this.$viewerApi({
        images: newArray,
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (更新圖片)
    onSubmitUpdate(item, index) {
      this.setImageUpdate({
        serial: this.deviceId,
        id: item.id,
        data: {
          name: item.name,
        },
      }).then(response => {
        this.useAlertToast(response.data.success, response.data.message)
        if (response.data.success) {
          this.editColData = ''
          this.editCol = ''
        } else {
          this.cancelEditCol(index)
        }
      })
    },

    // (移除)圖片
    removeImage(item, index) {
      this.$swal({
        title: '刪除圖片',
        text: `你確定要刪除圖片 ${item.name} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (!item.id) {
            this.imageFileList.splice(index, 1)
            return
          }
          this.setImageDelete({
            id: [item.id],
          }).then(response => {
            if (response.data.success) {
              this.imageFileList.splice(index, 1)
              this.totalNum -= 1
              this.useSwalAlertCenter(false, '刪除成功!', '圖片已移除!')
            } else {
              this.useSwalAlertCenter(false, '刪除失敗!', response.data.message)
            }
          })
        }
      })
    },

    // (移除)圖片
    removeMutiImage() {
      this.$swal({
        title: '刪除圖片',
        text: `你確定要刪除所選擇的 ${this.selected.length} 張圖片嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const imageId = this.selected.map(item => item.id)
        if (result.value) {
          this.setImageDelete({
            id: imageId,
          }).then(response => {
            if (response.data.success) {
              imageId.forEach(item => {
                const index = this.imageFileList.findIndex(obj => obj.id === item)
                this.imageFileList.splice(index, 1)
                this.totalNum -= 1
              })
              this.selected = []
              this.useSwalAlertCenter(false, '刪除成功!', '圖片已移除')
            } else {
              this.useSwalAlertCenter(false, '刪除失敗!', response.data.message)
            }
          })
        }
      })
    },

    // (匯入)圖片
    resolveFile(files) {
      const fileArray = Array.from(files)
      if (fileArray.length === 0 || !fileArray[0].type) return
      fileArray.forEach(file => {
        const image = new Image()
        const resolveFile = {}
        const objectURL = window.URL.createObjectURL(file)
        image.src = objectURL

        resolveFile.name = file.name
        resolveFile.image = file
        resolveFile.url = objectURL
        resolveFile.rId = this.generateRandomString()
        resolveFile.error = null
        resolveFile.id = null

        const typeOption = ['image/gif', 'image/png', 'image/jpeg', 'image/apng', 'image/x-icon']

        const canUpload = typeOption.includes(file.type)

        if (canUpload) {
          this.uploadNumber += 1
          this.imageFileList.unshift(resolveFile)
        }
      })

      if (this.uploadNumber <= 0) return

      this.isBusy = true
      this.uploadImage()
    },

    // (上傳)圖片
    uploadImage() {
      this.imageFileList.forEach(item => {
        if (item.image) {
          if (this.isAll) this.deductNumber += 1
          this.setImageUpload(item, this.deviceId)
        }
      })
    },

    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation() // 終止事件傳導
      e.preventDefault() // 終止預設行為
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.$refs.dropArea.classList.remove('hidden')
    },

    dragAreaEnter(e) {
      this.elementNode = e.target
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      const { files } = e.dataTransfer
      this.resolveFile(files)
      this.$refs.dropArea.classList.add('hidden')
    },

    // 產生亂數雜錯
    generateRandomString() {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let randomString = ''

      do {
        for (let i = 0; i < 5; i += 1) {
          const randomIndex = Math.floor(Math.random() * characters.length)
          randomString += characters[randomIndex]
        }
      } while (this.randomString.includes(randomString))

      this.randomString.push(randomString)
      return randomString
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.editCol = ''
      this.isTableBusy = true
      this.currentPage = 1
      this.deductNumber = 0
      this.imageFileList = []
      this.selected = []
      this.selectedAll = false
      this.$nextTick(() => {
        this.$bvModal.hide(this.imageExplorerId)
        if (this.isHidenRefresh) this.$emit('reset-data')
      })
    },

    // (獲取資料)
    getData() {
      this.isTableBusy = true
      this.currentPage = 1
      this.imageFileList = []
      this.deductNumber = 0
      this.$bvModal.show(this.imageExplorerId)
      this.getImageExplorer()
    },
  },
  setup(props) {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      totalNum,
      currentPage,
      perPage,
      isBusy,
      isTableBusy,
      isBusyLoading,
      uploadNumber,
      randomString,
      setImageUpload,
      setImageDelete,
      setImageUpdate,
      getImageExplorer,

      imageFileList,
      deductNumber,
      useAlertToast,
    } = useImageResize(props)

    return {
      isBusyLoading,
      isTableBusy,
      isBusy,
      uploadNumber,
      randomString,
      setImageUpload,
      setImageUpdate,
      imageFileList,
      isDark,
      getImageExplorer,
      setImageDelete,
      totalNum,
      currentPage,
      perPage,
      deductNumber,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.upload-card-content {
  height: 60vh;
  min-height: 300px;
  // padding: 10px;
  overflow-y:auto;
  overflow-x: hidden;
}

.upload-card-content-table {
  height: 50vh;
  overflow: hidden;
  .b-table-sticky-header {
    max-height: 50vh;
  }

  .item-description {
    // background-color: red;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    // font-size: 1.4rem;
}

  .image-view {
    max-height: 40px;
    max-width: 80px;
  }

  .title-name:hover {
    color: rgb(56, 172, 255) !important;
    // text-decoration: underline
  }

  .download-btn {
    border: none;
    background: transparent;
    width: 50px;
    height: 50px;
    &:hover {
      filter: brightness(120%);
    }
  }
}

.upload-card {
  // height: 60vh;
  // min-height: 300px;
  // padding: 10px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  .dropArea {
    display: block;
    min-height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 30px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #282828;
    opacity: .9;
    // backdrop-filter: blur(10px);
    text-align: center;
    color: #fff;
    // text-shadow: 0em 0.01em #333, 0em 0.02em #333, 0em 0.02em 0.03em #333;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}

.image-view-container {
  margin-top: 10px;
  .image-view {
    position: relative;
    padding: 5px;
    width: 100%;
    .image-view-header {
      display: flex;
      justify-content: center;
      .image-view-select {
        position: absolute;
        top: 15px;
        left: 10px;
        opacity: 0;
      }

      .image-view-info{
        position: absolute;
        top: 15px;
        right: 10px;
      }

      .image-view-title {
        padding: 10px 0;
        font-size: 16px;
        font-weight: bold;
        // word-break: break-all;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // max-width: 140px;
        display: flex;
        .image-view-title-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
        }
      }
    }

    .image-view-body {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // flex-direction: column;
      height: 180px;
      padding: 10px;
      border-radius: 10px;
      img {
        max-height: 160px;
        cursor: pointer;
      }

      .image-view-remove {
        position: absolute;
        top: -10px;
        right: -6px;
        color: white;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        text-align: center;
        opacity: 0;
        &:hover {
          background-color: #c40c0cb3;
        }
      }

      .image-upload-mask {
        position: absolute;
        top: 15px;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .image-upload-error-mask {
        background-color: rgba(0, 0, 0, 0.361);
        position: absolute;
        top: 15px;
        bottom: 10px;
        right: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .image-view-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .download-btn {
        border: none;
        background: transparent;
        width: 50px;
        height: 50px;
        &:hover {
          filter: brightness(120%);
        }
      }

      .choice-btn {
        .btn-class-name {
          --c-color: 70, 131, 187;
          border-radius: .5em;
          transition: .3s;
          color: rgb(var(--c-color));
          fill: rgb(var(--c-color));
          font-weight: bolder;
          font-size: 16px;
          letter-spacing: 2px;
          cursor: pointer;
          border: 1px solid rgb(var(--c-color));
          outline: none;
          display: flex;
          align-items: center;
          padding: 3px 5px;
          background-color: transparent;
          span {
            transform: scale(.8);
            transition: .3s;
          }
          svg {
            font-size: 0;
            transform: scale(0.5) translateX(0%) rotate(-180deg);
            transition: .3s;
          }
          &:hover {
            background-color: rgb(var(--c-color));
            span {
              color: white;
            }
            svg {
              font-size: 12px;
              font-weight: bolder;
              transform: scale(1) translateX(20%) rotate(0deg);
              fill: white;
            }
          }

          &:active {
            transition: 0s;
          }

          &:disabled {
            border: 1px solid rgb(var(--c-color), 0.8);
            span {
              color: rgb(var(--c-color), 0.8);
            }
            svg {
              font-size: 0;
            }
          }

          &:disabled:hover {
            background-color: transparent;
            span {
              color: rgb(var(--c-color), 0.8);
            }
          }
        }
      }
    }

    .disable-drag {
      pointer-events: none;
      // user-drag: none;
      // -webkit-user-drag: none; /* 適用於老版本的 WebKit 瀏覽器 */
    }

    &:hover {
      border-radius: 10px;
      border: #4683bb 1px solid;
      margin: -1px;

      .image-view-header {
        .image-view-select {
          opacity: 1 !important;
        }
      }

      .image-view-body {
        .image-view-remove {
          opacity: 1;
        }
      }
    }
  }

  .image-light {
    .image-view-body {
      background-color:#00000010;
      .image-view-remove {
        background-color: #3c3c3cb3;
      }
    }
  }

  .image-view-dark {
    .image-view-body {
      background-color:#ffffff10;
      .image-view-remove {
        background-color: #898989;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.image-card {
  position: relative;
  height: 300px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.08);
}
.image-card:hover {
  border: 1px solid #000;
  .remove-btn {
    opacity: 1;
  }
}
.image-action {
  position: absolute;
  top: 15px;
  right: 15px;
  align-self: end;
  button {
    width: 28px;
    height: 28px;
    padding: 3px;
  }
  .remove-btn {
    opacity: 0;
  }
}
.image-canvas {
  height: 60%;
  width: 98%;
  .image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.image-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -3px;
}
</style>
