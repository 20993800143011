<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯庫存
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 名稱 -->
        <validation-provider
          #default="validationContext"
          name="庫存名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  庫存名稱
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>
            <b-form-input
              id="name"
              v-model="stockData.name"
              :state="getValidationState(validationContext)"
              trim
              autofocus
              placeholder="請輸入庫存名稱"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 序號 -->
        <validation-provider
          #default="validationContext"
          name="序號"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  序號
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>
            <b-form-input
              id="name"
              v-model="stockData.serialNumber"
              :state="getValidationState(validationContext)"
              trim
              placeholder="請輸入序號"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 類別 -->
        <b-form-group label-for="plan_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                類別
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="stockData.device_type"
            :options="deviceTypeOptions"
            :reduce="option => option.type"
            :clearable="false"
            label="name"
            class="edit-col-select"
            placeholder="請選擇類別"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="font-weight-bold d-block text-nowrap mb-0">
                {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
              </div>
            </template>

            <template #selected-option="option">
              <div class="font-weight-bold d-block text-nowrap mb-0">
                {{ option.name }}<small class="text-muted"> ( {{ option.type }} )</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <!-- 數量 -->
            <validation-provider
              #default="validationContext"
              name="數量"
              rules="required"
            >
              <b-form-group label-for="price">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      數量
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="price"
                  v-model="stockData.quantity"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  placeholder="請輸入元件數量"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!-- 成本 -->
            <validation-provider
              #default="validationContext"
              name="成本"
              rules="required"
            >
              <b-form-group label-for="price">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      成本
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="price"
                  v-model="stockData.cost"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入購買成本"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div>
          <label>說明</label>
          <b-form-textarea
            v-model="stockData.description"
            :placeholder="stockData.description ? stockData.description : '請輸入說明'"
            :state="stockData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="stockData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ stockData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BFormTextarea,
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useStockList, useStockSetting } from '../useStock'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    stockItem: {
      type: Object,
      required: true,
    },
    deviceTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetStockData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
        serialNumber: '序號',
        device_type: '類別',
        quantity: '數量',
        cost: '成本',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.stockData[Object.keys(requiredFields)[i]] === null || this.stockData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      if (this.stockData.quantity <= 0) {
        this.useAlertToast(false, '數量不得小於 0')
        return
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setStockUpdate({
              id: this.stockItem.id,
              data: {
                ...this.stockData,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetStockData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankStockData, this.stockItem),
      }
      this.stockData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useStockSetting()

    const {
      setStockUpdate,
    } = useStockList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankStockData = {
      name: null,
      serialNumber: null,
      device_type: null,
      quantity: 1,
      cost: 0,
      description: '',
    }

    const stockData = ref(null)
    stockData.value = JSON.parse(JSON.stringify(blankStockData))

    const resetStockData = () => {
      stockData.value = JSON.parse(JSON.stringify(blankStockData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetStockData)

    return {
      stockData,
      blankStockData,
      setStockUpdate,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,

      resetStockData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.required-key {
  color: red;
  margin-top: 3px;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
