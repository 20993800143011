export const adminSidebar = () => {
  const user = [{
    title: '管理者列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/users.svg',
    route: { name: 'admin-user' },
    key: 'API.Admin.User',
  }]

  const branch = [{
    title: '分站管理列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/brand-admin.svg',
    route: { name: 'admin-branch' },
    key: 'API.Admin.Branch',
  }]

  const customer = [{
    title: '會員列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/customer.svg',
    route: { name: 'admin-customer' },
    key: 'API.Admin.Customer',
  }]

  const power = [{
    title: '權限列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/power.svg',
    route: { name: 'admin-power' },
    key: 'API.Admin.Power',
  }]

  const black = [{
    title: '黑名單列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/blacklist.svg',
    route: { name: 'admin-black' },
    key: 'API.Admin.Ban',
  }]

  const brand = [{
    title: '品牌列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/brandlist.svg',
    route: { name: 'admin-brand' },
    key: 'API.Admin.Brand',
  }]

  const place = [{
    title: '地點列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/place.svg',
    route: { name: 'admin-place' },
    key: 'API.Admin.Place',
  }]

  const tag = [{
    title: '標籤列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/tag.svg',
    route: { name: 'admin-tag' },
    key: 'API.Admin.Tag',
  }]

  const payment = [{
    title: '金流列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/credit-card.svg',
    children: [
      {
        title: '金流商管理',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/settings.svg',
        route: { name: 'admin-payment-type' },
        key: 'API.Admin.ThirdPartyPayment',
      }, {
        title: '金流列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/list.svg',
        route: { name: 'admin-payment' },
        key: 'API.Admin.Payment',
      },
    ],
  }]

  const domain = [{
    title: '網域管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/domain.svg',
    children: [
      {
        title: '網域商列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/domain-agent.svg',
        route: { name: 'admin-domain-agent' },
        key: 'API.Admin.DomainNameAgent',
      }, {
        title: '經銷商列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/domain-dealer.svg',
        route: { name: 'admin-domain-dealer' },
        key: 'API.Admin.DomainNameDealer',
      }, {
        title: '網域商品',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/price.svg',
        route: { name: 'admin-domain-price' },
        key: 'API.Admin.DomainNamePrice',
      },
    ],
  }]

  const stock = [{
    title: '庫存列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/stock.svg',
    route: { name: 'admin-stock' },
    key: 'API.Admin.Stock',
  }]

  const device = [{
    title: '設備管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/host.svg',
    children: [
      {
        title: '元件類別',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/device-type.svg',
        route: { name: 'admin-device-type' },
        key: 'API.Admin.Device.Type',
      }, {
        title: '元件列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/device-list.svg',
        route: { name: 'admin-device' },
        key: 'API.Admin.Device',
      }, {
        title: '機器列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/host-machine.svg',
        route: { name: 'admin-machine' },
      },
    ],
  }]

  const product = [{
    title: '方案列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/plan.svg',
    route: { name: 'admin-plan' },
    key: 'API.Admin.Plan',
  }, {
    title: '商品列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/price.svg',
    route: { name: 'admin-product' },
    key: 'API.Admin.Product',
  }]

  const config = [{
    title: '設定',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/settings.svg',
    children: [
      {
        title: '系統規則',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/list.svg',
        route: { name: 'admin-config' },
      },
    ],
  }]

  const cart = [{
    title: '購物車列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/cart-list.svg',
    route: { name: 'admin-cart' },
    key: 'API.Admin.Cart',
  }]

  const api = [{
    title: 'API管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/api.svg',
    children: [
      {
        title: '信箱API列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/api-mail.svg',
        route: { name: 'admin-api-mail' },
        key: 'API.Admin.MailApi',
      },
    ],
  }]

  const meta = []

  const order = [{
    title: '訂單列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/order-list.svg',
    route: { name: 'admin-order' },
    key: 'API.Admin.Order',
  }]

  const service = [{
    title: '服務管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/service.svg',
    children: [
      {
        title: '網域列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/domain.svg',
        route: { name: 'admin-service-domain', params: { action: 'list' } },
        key: 'API.Admin.DomainName.Service',
      },
    ],
  }]

  const remark = []

  const quota = [{
    title: '點數紀錄',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/point.svg',
    route: { name: 'admin-quota' },
    key: 'API.Admin.Quota',
  }]

  return {
    user,
    branch,
    customer,
    power,
    black,
    brand,
    place,
    tag,
    payment,
    domain,
    device,
    stock,
    product,
    config,
    cart,
    api,
    meta,
    order,
    service,
    remark,
    quota,
  }
}

export const clientSidebar = () => {
  const home = [{
    title: '後台首頁',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/home.svg',
    route: { name: 'admin-home' },
  }, {
    title: '個人資料',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/profile.svg',
    route: { name: 'profile' },
  }]

  return {
    home,
  }
}
