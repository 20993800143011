<template>
  <div>
    <!-- 頁面: 機器列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('device', 'API.Admin.Machine')">
      <!-- 新增機器 -->
      <machine-list-add-modal
        ref="machineAddModal"
        :plan-options="planOptions"
        :device-type-options="deviceTypeOptions"
        @refetch-data="refetchData"
      />

      <!-- 編輯機器 -->
      <machine-list-edit-modal
        v-if="selected"
        ref="machineEditModal"
        :plan-options="planOptions"
        :machine-item="selected"
        :device-type-options="deviceTypeOptions"
        @refetch-data="refetchData"
      />

      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="5"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchState"
                :options="stateOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      v-if="option.value === 1"
                      class="mr-50 border state-dot dot-blue-selected"
                    />
                    <span
                      v-else-if="option.value === 2"
                      class="mr-50 border state-dot dot-green-selected"
                    />

                    <span
                      v-else
                      class="mr-50 border state-dot dot-gray"
                    />
                    {{ option.label }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      v-if="option.value === 1"
                      class="mr-50 border state-dot dot-blue-selected"
                    />
                    <span
                      v-else-if="option.value === 2"
                      class="mr-50 border state-dot dot-green-selected"
                    />

                    <span
                      v-else
                      class="mr-50 border state-dot dot-gray"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="7"
              sm="6"
              class="mb-lg-0 mb-1"
            >
              <label>方案</label>
              <v-select
                v-model="searchPlan"
                :options="planOptions"
                :reduce="option => option.id"
                label="name"
                class="edit-col-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ resolvePlanMainType(option.type) }} )</small>
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="font-weight-bold d-block text-nowrap mb-0">
                    {{ option.name }}<small class="text-muted"> ( {{ resolvePlanMainType(option.type) }} )</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="2"
              md="3"
              sm="6"
              class="mb-md-0 mb-1"
            >
              <label>類別</label>
              <v-select
                v-model="searchType"
                :options="[
                  { label: '實體主機', value: 'host' },
                  { label: '雲端主機', value: 'vps' },
                  { label: '虛擬主機', value: 'web' },
                ]"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                機器列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div
                v-if="!isSelectState"
                class="text-nowrap d-flex justify-content-end"
              >
                <div
                  v-if="checkAuthAbility('device', 'API.Admin.Machine.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="多選"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="onSubmitMutiSelected(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/select-all.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-if="!isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>

              <div
                v-if="isSelectState"
                class="text-nowrap d-flex justify-content-end"
              >
                <div
                  v-if="checkAuthAbility('device', 'API.Admin.Machine.Update')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="指定方案"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitMutiPlan"
                >
                  <b-img
                    src="/dashboard/admin/images/sideIcon/plan.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <!-- <div
                  v-if="checkAuthAbility('device', 'API.Admin.Machine.Delete')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="一鍵刪除"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitMutiDelete"
                >
                  <b-img
                    src="/dashboard/admin/images/table/delete-all.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div> -->

                <div
                  v-if="isSelectState"
                  v-b-tooltip.hover.focus.v-secondary
                  title="取消"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitTableCancel"
                >
                  <b-img
                    src="/dashboard/admin/images/table/cancel.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getMachineListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          primary-key="id"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          select-mode="multi"
          selectable
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
          @row-selected="onRowSelected"
          @row-clicked="onRowClicked"
        >
          <template #head(selected)>
            <span
              class="text-primary cursor-pointer"
              @click.stop="!isSelectedAll ? selectAllRows() : clearSelected()"
            >
              {{ isSelectedAll ? '取消' : '全選' }}
            </span>
          </template>

          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 選取 -->
          <template #cell(selected)="data">
            <div class="table-col">
              <b-form-checkbox
                v-if="data.item.state !== 1"
                v-model="data.rowSelected"
                name="table-column"
                disabled
                class="machine-checkbox"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 狀態 -->
          <template #cell(state)="data">
            <div class="table-col">
              <div
                v-if="data.item.state === 1"
                v-b-tooltip.hover.focus.v-secondary
                title="使用中"
                class="state-dot dot-blue"
              />

              <div
                v-else-if="data.item.state === 2"
                v-b-tooltip.hover.focus.v-secondary
                title="上架"
                class="state-dot dot-green"
                @click="onSubmitUpdate(data.item)"
              />

              <div
                v-else
                v-b-tooltip.hover.focus.v-secondary
                title="未啟用"
                class="state-dot dot-gray"
                @click="onSubmitUpdate(data.item)"
              />
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                @click="onSubmitUpdate(data.item)"
              >
                {{ data.item.name }}
              </b-link>

              <div
                v-if="data.item.remark_display"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  src="/dashboard/admin/images/table/chat-remark.svg"
                  height="20"
                  width="20"
                  class="cursor-pointer ml-25 mb-25"
                />
              </div>
            </div>
          </template>

          <!-- 欄位: 類別 -->
          <template #cell(type)="data">
            <div class="table-col">
              <div
                class="ribbon-col"
                @click="onSubmitUpdate(data.item)"
              >
                <span
                  :style="{ backgroundColor: resolveMachineType(data.item.type).color}"
                  class="text-nowrap"
                >
                  {{ resolveMachineType(data.item.type).label }}
                </span>
              </div>
            </div>
          </template>

          <!-- 欄位: 方案 -->
          <template #cell(plan)="data">
            <div
              class="d-block text-nowrap show-text"
              @click="onSubmitUpdate(data.item)"
            >
              <div v-if="data.item.plan_info && data.item.plan_info.name">
                <div>{{ data.item.plan_info.name }}</div>
                <small class="text-muted">
                  {{ resolvePlanMainType(data.item.type) }}
                </small>
              </div>
              <small
                v-else
                class="text-muted"
              >
                尚未設定
              </small>
            </div>
          </template>

          <!-- 欄位: 機器綁定 -->
          <template #cell(own)="data">
            <div
              class="table-col"
              @click="onSubmitDeviceLink(data.item)"
            >
              <div class="d-flex text-nowrap">
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'32px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="32"
                  :failed-image="'/dashboard/admin/images/deviceType/Component.svg'"
                  class="mr-50"
                >
                  <b-img
                    slot="image"
                    :src="`/dashboard/admin/images/deviceType/${data.item.own ? 'DedicatedServer' : 'ForeignServer'}.svg`"
                    height="32"
                    width="32"
                    class="icon-iamge"
                  />
                </VueLoadImage>

                <div class="d-block text-nowrap show-text">
                  <div v-if="data.item.main_device_info && data.item.main_device_info.name">
                    <div>{{ data.item.main_device_info.name }}</div>
                    <small class="text-muted">
                      {{ data.item.main_device_info.serialNumber }}
                    </small>
                  </div>
                  <small v-else>
                    ---
                  </small>
                </div>
              </div>
            </div>
          </template>

          <!-- 欄位: 機櫃 -->
          <template #cell(rack_id)="data">
            <div class="table-col d-block">
              <div v-if="data.item.own">
                <div
                  v-if="data.item.rack_info.id"
                  class="d-block text-nowrap show-text"
                >
                  <div>{{ data.item.rack_info.name }}</div>
                  <small class="text-muted">
                    {{ data.item.rack_info.serialNumber }}
                  </small>
                </div>

                <small
                  v-else
                  class="text-muted"
                >
                  尚未設定
                </small>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 地點 -->
          <template #cell(place)="data">
            <div class="table-title">
              <div v-if="data.item.main_device_info.id && data.item.main_device_info.place_id">
                <span>{{ resolvePlaceInfo(data.item.main_device_info.place_id) }}</span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 上次更新 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 詳細 -->
          <!-- <template #row-details="data">
            <div class="table-toggle-detail border-top p-1">
            </div>
          </template> -->

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-remark-botton-alert actions-link-btn"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="備註留言"
                  src="/dashboard/admin/images/table/chat.svg"
                  class="actions-link-btn-image"
                  rounded
                />
                <div
                  v-if="data.item.remark_display"
                  class="state-dot dot-red"
                />
              </div>

              <div
                v-if="checkAuthAbility('device', 'API.Admin.Machine.Update')"
                class="actions-link-btn"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('device', 'API.Admin.Machine.Delete')"
                class="actions-link-btn"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/dashboard/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('device', 'API.Admin.Device.Id')"
                class="actions-link-btn mr-25"
              >
                <b-link @click="onSubmitDeviceLink(data.item)">
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="元件詳情"
                    src="/dashboard/admin/images/table/external-link.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </b-link>
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>

      <admin-plan-modal
        ref="adminPlanExplorerModal"
        plan-explorer-id="adminPlanExplorerModal"
        :search-filter="'main'"
        :is-hiden-emit="false"
        :use-callback-data="true"
        @emit-hidden-function="() => {}"
        @call-back-data="callbacPlanExplorer"
      />

      <remark-modal
        v-if="selected"
        ref="machineRemarkExplorerModal"
        remark-explorer-id="machineRemarkExplorerModal"
        :key-array="keyArray"
        :is-hiden-emit="true"
        :use-callback-data="false"
        @emit-hidden-function="refetchTable"
        @call-back-data="() => {}"
      >
        <template v-slot:header-info>
          <div class="mb-1" />
        </template>
      </remark-modal>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BCardBody,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BCardHeader, BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'
import AdminPlanModal from '@/layouts/components/Modal/admin-plan-modal/planModal.vue'
import { useMachineList, useMachineSetting } from '../useMachine'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, usePublicFunction } from '@/libs/mixins/index'
import MachineListAddModal from './MachineListAddModal.vue'
import MachineListEditModal from './MachineListEditModal.vue'
import useStoreModule from '../useStoreModule'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BFormInput,
    BCardBody,
    BCardHeader,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    VueLoadImage,
    TableTimeRange,
    AdminPlanModal,
    MachineListAddModal,
    MachineListEditModal,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      if (!this.isSelectState) return this.tableColumns.filter(f => f.select).filter(f => f.key !== 'selected')
      return this.tableColumns.filter(f => f.select)
    },
    tableColumnsSearchable() {
      if (!this.isSelectState) return this.tableColumns.filter(f => f.searchable).filter(f => f.key !== 'selected')
      return this.tableColumns.filter(f => f.searchable)
      // return this.tableColumns.filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark(item) {
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      const resolveArray = []
      resolveArray.push(`machine-${item.id}`)
      if (item.main_device_id) resolveArray.push(`device-${item.main_device_id}`)
      this.keyArray = resolveArray
      this.selected = JSON.parse(JSON.stringify(item))
      setTimeout(() => { this.$refs.machineRemarkExplorerModal.getData() }, 200)
    },

    // (前往)元件詳情
    onSubmitDeviceLink(item) {
      if (!item.main_device_id) return
      const auth = this.checkAuthAbility('device', 'API.Admin.Device.Id')
      if (!auth) return
      this.$router.push({
        name: 'admin-device-view',
        params: {
          id: item.main_device_id,
        },
      })
    },

    // (檢查)是否為主要商品類型
    isProductMainType(type) {
      return this.$store.getters['appConfig/mainProductServerType'].map(item => item.key).includes(type)
    },

    // (檢查)主要商品類型
    resolvePlanMainType(type) {
      const isMain = this.isProductMainType(type)
      const typeDictionary = {
        host: '實體主機',
        vps: '雲端主機',
        web: '虛擬主機',
      }
      if (isMain) return typeDictionary[type]
      return '其他'
    },

    // (轉換)地點
    resolvePlaceInfo(placeId) {
      const placeInfo = this.placeOptions.find(el => el.id === placeId)
      if (placeInfo) return placeInfo.name
      return placeId
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        const auth = this.checkAuthAbility('device', 'API.Admin.Machine.Create')
        if (!auth) return
        this.$refs.machineAddModal.getData()
        return
      }
      const auth = this.checkAuthAbility('device', 'API.Admin.Machine.Update')
      if (!auth) return
      this.selected = item
      setTimeout(() => { this.$refs.machineEditModal.getData() }, 200)
    },

    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
      }
    },

    // (前往)機器詳情
    onSubmitMachineLink(item) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Machine.Id')
      if (!auth) return
      this.$router.push({
        name: 'admin-machine-view', params: { id: item.id },
      })
    },

    // (觸發)複數選取
    onSubmitMutiSelected(index) {
      this.isSelectState = true
      if (index !== null) this.selectRow(index)
    },

    // (觸發)選擇
    onRowClicked(item) {
      if (this.isSelectState) {
        const isLock = item.state === 1
        if (isLock) {
          this.useAlertToast(false, '此機器為使用中，無法進行操作')
        }
      }
    },

    // (選擇)列表物品
    onRowSelected(items) {
      if (this.isSelectState) {
        this.selectedTableData = items.filter(el => el.state !== 1)
        items.forEach(item => {
          if (item.state === 1) {
            const index = this.compareTableData.findIndex(el => el.id === item.id)
            this.unselectRow(index)
          }
        })
      } else this.clearSelected()
    },

    // (觸發)列表取消
    submitTableCancel() {
      this.isSelectState = false
      this.clearSelected()
      this.selectedTableData = []
    },

    // (更新)多選方案
    submitMutiPlan() {
      const items = this.selectedTableData.map(item => item.id)
      if (!items.length) {
        this.useAlertToast(false, '請選擇欲設定方案的機器')
        return
      }
      this.$refs.adminPlanExplorerModal.getData()
    },

    // (回傳)選擇方案
    callbacPlanExplorer(plan) {
      const machineIds = this.selectedTableData.map(el => el.id)
      this.useSwalAlertWarning('方案變更', `確定要將所選的 ${machineIds.length} 台機器變更方案嗎？`)
        .then(result => {
          if (result.value) {
            this.setMachineMutiUpdate({
              machines: machineIds,
              plan_id: plan.id,
            }).then(response => {
              this.refetchTable()
              this.useSwalAlertCenter(true, '變更成功', response.data.message)
              this.submitTableCancel()
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '變更失敗!', error.response.data.message)
              })
          }
        })
    },

    // (刪除)機器
    onSubmitDelete(item) {
      const auth = this.checkAuthAbility('device', 'API.Admin.Machine.Delete')
      if (!auth) return

      this.$swal({
        title: `刪除${item.own ? '自有' : '海外'}機器`,
        text: `你確定要永久刪除機器 ${item.name ? ` ${item.name} ` : null} 嗎?`,
        imageUrl: `/dashboard/admin/images/deviceType/${item.own ? 'DedicatedServer' : 'ForeignServer'}.svg`,
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: '機器',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        denyButtonText: '解除綁定',
        customClass: {
          confirmButton: 'btn btn-danger',
          denyButton: 'btn btn-outline-info ml-1',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.setMachineDelete({
              id: item.id,
              queryParams: {
                action: 'delete',
              },
            }).then(response => {
              this.refetchData()
              this.useSwalAlertCenter(response.data.success, `刪除${response.data.success ? '成功' : '失敗'}!`, response.data.message)
            })
              .catch(error => {
                // this.useHttpCodeAlert(error.response)
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          } else if (result.isDenied) {
            this.setMachineDelete({
              id: item.id,
              queryParams: {
                action: 'unbind',
              },
            }).then(response => {
              this.refetchData()
              this.useSwalAlertCenter(response.data.success, `解除綁定${response.data.success ? '成功' : '失敗'}!`, response.data.message)
            })
              .catch(error => {
                // this.useHttpCodeAlert(error.response)
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const MACHINE_ADMIN_STORE_MODULE_NAME = 'admin-machine'

    if (!store.hasModule(MACHINE_ADMIN_STORE_MODULE_NAME)) store.registerModule(MACHINE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(MACHINE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(MACHINE_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      refetchData,
      refetchTable,
      searchState,
      searchOwn,
      searchType,
      searchPlan,
      planOptions,
      placeOptions,
      deviceTypeOptions,

      isSelectState,
      isSelectedAll,
      selectedTableData,
      compareTableData,

      getMachineListData,
      setMachineDelete,
      // setMachineCreate,
      // setMachineUpdate,
      setMachineMutiUpdate,
      useAlertToast,
      useHttpCodeAlert,
    } = useMachineList()

    const {
      ui,
      stateOptions,
      ownOptions,
      resolveMachineType,

      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useMachineSetting()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    const selectAllRows = () => {
      isSelectedAll.value = true
      refDataListTable.value.selectAllRows()
    }

    const clearSelected = () => {
      isSelectedAll.value = false
      refDataListTable.value.clearSelected()
    }

    const selectRow = index => {
      refDataListTable.value.selectRow(index)
    }

    const unselectRow = index => {
      refDataListTable.value.unselectRow(index)
    }

    return {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      timeRange,
      refetchData,
      refetchTable,
      searchState,
      searchOwn,
      searchType,
      searchPlan,
      planOptions,
      placeOptions,
      deviceTypeOptions,
      getMachineListData,
      setMachineDelete,
      setMachineMutiUpdate,
      useAlertToast,
      useHttpCodeAlert,
      isSelectState,
      isSelectedAll,
      selectedTableData,
      compareTableData,

      ui,
      stateOptions,
      ownOptions,
      resolveMachineType,

      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
      avatarText,
      dateConfig2,
      selectAllRows,
      clearSelected,
      selectRow,
      unselectRow,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.machine-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(65, 159, 217, 1);
}
</style>

<style lang="scss" scoped>
.ribbon-col {
  span {
    padding: 3px 8px;
    border-radius: 10px;
    border-style: outset;
    background-color: #3498dbe7;
    color: #fff;
    text-shadow: 0 1px 1px rgb(0, 0, 0);
    text-transform: uppercase;
    text-align: center;
    font-size: 12px
  }
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}
.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}

.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}

.user-state-icon {
  max-width: 30px;
  margin-right: 10px;
}

.user-state-icon-hidden {
  opacity: 0 !important;
}
</style>
