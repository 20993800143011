<template>
  <div>
    <div :class="isModal ? 'p-1' : null">
      <div class="d-flex justify-content-between align-items-center mt-50">
        <label class="mb-0">鎖定位置</label>

        <toggle-button
          v-model="deviceSpecData.static"
          :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
          :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
          :width="60"
          :height="24"
          :labels="{checked: '鎖定', unchecked: '關閉'}"
          :sync="true"
        />
      </div>

      <div class="mt-1">
        <div v-if="deviceSpecData.type !== 'blank'">
          <div class="d-flex justify-content-between align-items-center">
            <label class="mb-0">元件類型</label>

            <div v-if="deviceSpecData.tpye === 'component' && deviceSpecData.value">
              <div
                class="actions-link-btn"
                @click="onSubmitCheckLinkDevice(deviceSpecData)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="前往元件"
                  src="/dashboard/admin/images/table/external-link.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>

          <div
            v-if="deviceSpecData.type === 'component'"
            class="d-flex justify-content-between align-items-center border mt-25 p-1 device-bind-info"
          >
            <div class="d-flex py-50 align-items-center cursor-pointer">
              <VueLoadImage
                :use-loading="true"
                :use-failed="true"
                :blank-height="'32px'"
                :loading-width="30"
                :loading-height="30"
                :failed-width="32"
                :failed-image="transDeviceErrorImage(deviceSpecData.type)"
                class="mr-50"
              >
                <b-img
                  slot="image"
                  :src="`/dashboard/admin/images/deviceType/${deviceSpecData.key}.svg`"
                  height="40"
                  width="40"
                  class="icon-iamge"
                />
              </VueLoadImage>

              <div>
                <div
                  v-if="deviceSpecData.value"
                  class="profile-user-info"
                >
                  <h6 class="mb-0">
                    <span class="mr-50">
                      {{ resolveDeviceIdInfo(deviceSpecData).name }}
                    </span>
                  </h6>

                  <small
                    class="text-muted"
                    style="word-break: break-all"
                  > {{ resolveDeviceIdInfo(deviceSpecData).serialNumber ? resolveDeviceIdInfo(deviceSpecData).serialNumber : deviceSpecData.value }}</small>
                </div>

                <div v-else>
                  <h6 class="mb-0">
                    <span class="mr-50">
                      {{ deviceSpecData.label ? deviceSpecData.label : '元件遺失' }}
                    </span>
                  </h6>

                  <small class="text-muted"> {{ deviceSpecData.key }}</small>
                </div>
              </div>
            </div>

            <div class="d-flex text-nowrap align-items-center">
              <div
                v-if="deviceSpecData.value"
                class="actions-link-btn mr-50"
                @click="() => { deviceSpecData.value = null }"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="解除"
                  src="/dashboard/admin/images/table/unlink.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                class="actions-link-btn"
                @click="submitBindDevice"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="綁定"
                  src="/dashboard/admin/images/table/bind.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>

          <div
            v-if="deviceSpecData.type === 'slot'"
            class="d-flex justify-content-between align-items-center border mt-25 p-1 device-bind-info"
          >
            <div class="d-flex py-50 align-items-center cursor-pointer">
              <VueLoadImage
                :use-loading="true"
                :use-failed="true"
                :blank-height="'32px'"
                :loading-width="30"
                :loading-height="30"
                :failed-width="32"
                :failed-image="transDeviceErrorImage(deviceSpecData.type)"
                class="mr-50"
              >
                <b-img
                  slot="image"
                  :src="`/dashboard/admin/images/deviceType/${deviceSpecData.key}.svg`"
                  height="40"
                  width="40"
                  class="icon-iamge"
                />
              </VueLoadImage>

              <div class="profile-user-info">
                <h6 class="mb-0">
                  <span class="mr-50">
                    {{ deviceSpecData.label }}
                  </span>
                </h6>

                <small class="text-muted"> {{ deviceSpecData.key }}</small>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="deviceSpecData.type === 'slot'"
          class="mt-1"
        >
          <div class="d-flex justify-content-between align-items-center">
            <label class="mb-0">連接元件</label>

            <div>
              <div
                class="actions-link-btn"
                @click="onSubmitCheckLinkDevice(deviceSpecData)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="前往元件"
                  src="/dashboard/admin/images/table/external-link.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center border mt-25 p-1 device-bind-info">
            <div class="d-flex py-50 align-items-center cursor-pointer">
              <VueLoadImage
                :use-loading="true"
                :use-failed="true"
                :blank-height="'32px'"
                :loading-width="30"
                :loading-height="30"
                :failed-width="32"
                :failed-image="transDeviceErrorImage(deviceSpecData.type)"
                class="mr-50"
              >
                <b-img
                  slot="image"
                  :src="`/dashboard/admin/images/deviceType/${resolveDeviceIdInfo(deviceSpecData).device_type}.svg`"
                  height="40"
                  width="40"
                  class="icon-iamge"
                />
              </VueLoadImage>

              <div>
                <div
                  v-if="deviceSpecData.bind"
                  class="profile-user-info"
                >
                  <h6 class="mb-0">
                    <span class="mr-50">
                      {{ resolveDeviceIdInfo(deviceSpecData).name }}
                    </span>
                  </h6>

                  <small
                    class="text-muted"
                    style="word-break: break-all"
                  > {{ resolveDeviceIdInfo(deviceSpecData).serialNumber ? resolveDeviceIdInfo(deviceSpecData).serialNumber : deviceSpecData.value }}</small>
                </div>

                <div v-else>
                  <h6 class="mb-0">
                    <span
                      class="mr-50 text-muted"
                    >
                      尚未綁定元件
                    </span>
                  </h6>
                </div>
              </div>
            </div>

            <div class="d-flex text-nowrap align-items-center">
              <div
                v-if="deviceSpecData.bind"
                class="actions-link-btn mr-50"
                @click="() => {
                  deviceSpecData.value = null
                  deviceSpecData.bind = null
                }"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="解除"
                  src="/dashboard/admin/images/table/unlink.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                class="actions-link-btn"
                @click="submitBindDevice"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="綁定"
                  src="/dashboard/admin/images/table/bind.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        v-if="!isModal"
        class="mt-2"
      >
        <b-button
          variant="primary"
          block
          class="mb-1"
          @click="submitUpdateDevice"
        >
          儲存
        </b-button>

        <b-button
          variant="outline-danger"
          block
          :disabled="deviceSpecData.static"
          @click="$emit('submit-remove-device', deviceData, deviceIndex)"
        >
          移除元件
        </b-button>
      </div>

      <div v-else>
        <hr class="mb-0">
        <div
          class="text-right"
          :class="isModal ? 'p-1' : null"
        >
          <b-button
            variant="outline-danger"
            :disabled="deviceSpecData.static"
            class="mr-1"
            @click="$emit('submit-remove-device', deviceData, deviceIndex)"
          >
            移除元件
          </b-button>

          <b-button
            variant="primary"
            @click="submitUpdateDevice(true)"
          >
            儲存
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BImg, VBTooltip,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import { useDeviceView, useDeviceSetting } from '../../useDevice'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'

export default {
  components: {
    BImg,
    BButton,
    ToggleButton,
    VueLoadImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    deviceData: {
      type: Object,
      default: () => null,
    },
    deviceIndex: {
      type: Number,
      default: () => null,
    },
    childrenDeviceList: {
      type: Array,
      required: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // (轉換)組件圖片
    transDeviceErrorImage(type) {
      if (type === 'slot') return '/dashboard/admin/images/deviceType/ZPlug.svg'
      return '/dashboard/admin/images/deviceType/Component.svg'
    },

    // (獲取)元件名稱
    resolveDeviceIdInfo(item) {
      if (item.type === 'component') {
        const deviceInfo = this.childrenDeviceList.find(f => f.id === item.value)
        if (deviceInfo) return deviceInfo
        return {
          id: item.value,
          name: '元件遺失',
          serialNumber: null,
        }
      }
      if (item.type === 'slot') {
        const deviceInfo = this.childrenDeviceList.find(f => f.id === item.bind)
        if (deviceInfo) return deviceInfo
        return {
          id: item.bind,
          name: '元件遺失',
          serialNumber: null,
        }
      }
      return {
        id: null,
        name: '空元件',
        serialNumber: null,
      }
    },

    // (觸發)綁定
    submitBindDevice() {
      this.$emit('submit-bind-device', this.deviceData, this.deviceIndex)
    },

    // (觸發)更新
    submitUpdateDevice(state) {
      if (state) {
        this.$emit('submit-update-device', this.deviceSpecData, this.deviceIndex)
        return
      }
      this.$swal({
        title: '更新元件',
        text: `你確定要更新當前元件 ${this.deviceSpecData.label} 嗎?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('submit-update-device', this.deviceSpecData, this.deviceIndex)
        }
      })
    },

    // (觸發)前往元件
    onSubmitCheckLinkDevice(item) {
      if (item.type === 'component' && item.value) {
        this.onSubmitLinkDevice(item.value)
      }
      if (item.type === 'slot' && item.bind) {
        this.onSubmitLinkDevice(item.bind)
      }
    },

    // (觸發)前往元件
    onSubmitLinkDevice(bindId) {
      const routeData = this.$router.resolve({
        name: 'admin-device-view',
        params: { id: bindId },
      })

      window.open(routeData.href, '_blank')
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankSpecData, this.deviceData),
      }
      this.deviceSpecData = resolveData
    },
  },
  setup() {
    const {
      syncObject,
    } = useDeviceSetting()

    const {
      blankSpecData,
    } = useDeviceView()

    const deviceSpecData = ref(null)

    deviceSpecData.value = JSON.parse(JSON.stringify(blankSpecData))

    const resetDeviceSpecData = () => {
      deviceSpecData.value = JSON.parse(JSON.stringify(blankSpecData))
    }

    return {
      syncObject,
      deviceSpecData,
      blankSpecData,
      resetDeviceSpecData,
    }
  },
}
</script>
