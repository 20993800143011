import { ref, watch } from '@vue/composition-api'
import { useTableComponent, useCommenSettings, useAlert } from '@/libs/mixins/index'
import store from '@/store'

export const useConfigSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,

    getMetaListData,
  }
}

export const useConfigList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    getMetaListData,
  } = useConfigSetting()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '分站', key: 'branch_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: 'key', key: 'source_key', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const configData = ref(null)
  const blankData = ref({})
  const searchBranch = ref(null)
  const branchOptions = ref([])
  const blankConfigData = {
    id: null,
    branch_id: null,
    name: null,
    source_key: null,
    format: null,
    value: null,
    updated_at: null,
    created_at: null,
  }

  const blankBranchInfoData = {
    id: null,
    name: null,
    branch: null,
  }

  const setConfigCreate = (...arg) => store.dispatch('admin-config/setConfigCreate', ...arg)
  const setConfigUpdate = (...arg) => store.dispatch('admin-config/setConfigUpdate', ...arg)
  const setConfigDelete = (...arg) => store.dispatch('admin-config/setConfigDelete', ...arg)

  const getBranchData = () => {
    getMetaListData({ key: 'branch', queryParams: { noPagination: true } }) //
      .then(response => {
        const { data } = response.data.data
        branchOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankBranchInfoData, item),
          }
          return resolve
        })
      })
  }

  const getConfigListData = (ctx, callback) => {
    store.dispatch('admin-config/getConfigList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, option } = response.data.data

        branchOptions.value = option.branch.map(item => {
          const resolve = {
            ...syncObject(blankBranchInfoData, item),
          }
          return resolve
        })

        const resolveBranchInfo = id => {
          if (!id) return syncObject(blankBranchInfoData, {})
          const branchInfo = branchOptions.value.find(item => item.id === id)
          return syncObject(blankBranchInfoData, branchInfo) || syncObject(blankBranchInfoData, {})
        }

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankConfigData, item),
            branch_info: resolveBranchInfo(item.branch_id),
          }
          return resolve
        })

        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankConfigData,
    refetchData,
    refetchTable,
    configData,
    blankData,
    searchBranch,
    branchOptions,

    setConfigUpdate,
    setConfigCreate,
    setConfigDelete,
    getConfigListData,
    useAlertToast,
    useHttpCodeAlert,
    getBranchData,
  }
}
