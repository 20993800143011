<template>
  <b-modal
    id="add-modal"
    ref="add-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        新增類別
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <!-- 名稱 -->
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      名稱
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="deviceData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入商品名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- 驅動 -->
            <validation-provider
              #default="validationContext"
              name="驅動"
              rules="required"
            >
              <b-form-group label-for="type">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      驅動
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </template>
                <b-form-input
                  id="name"
                  v-model="deviceData.type"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="請輸入元件驅動"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- 類型 -->
          <b-col
            cols="12"
          >
            <b-form-group label-for="slot">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    類型
                    <span class="text-danger">*</span>
                  </label>
                </div>
              </template>

              <v-select
                v-model="deviceData.slot"
                :options="slotOptions"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
                placeholder="請選擇類型"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDeviceSetting, useDeviceTypeList } from '../useDevice'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      isBusy: false,
      required,
      missingFields: [],
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDeviceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('add-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
        type: '驅動',
        slot: '類型',

      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.deviceData[Object.keys(requiredFields)[i]] === null || this.deviceData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setDeviceTypeCreate({
              ...this.deviceData,
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDeviceData()
                this.$nextTick(() => {
                  this.$bvModal.hide('add-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankDeviceData, {}),
      }
      this.deviceData = resolveData
      this.$bvModal.show('add-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      ui,
      syncObject,
      slotOptions,
    } = useDeviceSetting()

    const {
      deviceData,
      setDeviceTypeCreate,
    } = useDeviceTypeList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankDeviceData = {
      name: null,
      type: null,
      slot: false,
      properties: [],
    }

    deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))

    const resetDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDeviceData)

    return {
      ui,
      deviceData,
      blankDeviceData,
      setDeviceTypeCreate,
      syncObject,
      slotOptions,
      useAlertToast,
      useHttpCodeAlert,

      resetDeviceData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
